import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "@tanstack/react-location";
import BackArrow from "../../../assets/arrowleft";
import InputBox from "../../../common/InputBox";
import Button from "../../../common/Buttons";
import { useUserChangePasswordMutation } from "../../../redux/api/userProfile";
import { changePassword } from "../../../validation-schemas/auth-schema";
import toast from "react-hot-toast";

function ChangePassword() {
  const [updatePassword] = useUserChangePasswordMutation();

  const navigate = useNavigate();

  const initialValues = {
    newPassword: "",
    enterPassword: "",
    confirmNewPassowrd: "",
  };

  const onSubmit = (values) => {
    const payload = {
      oldPassword: values.enterPassword,
      newPassword: values.newPassword,
    };
    /* API for change password */
    updatePassword(payload)
      .then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.error);
        } else {
          toast.success("Your password updated successfully.");
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: changePassword,
    onSubmit,
  });

  return (
    <Container className="content-container settings changePassword ml-4">
      <h5 className="title fw-6 f22 mar-p">
      <a className="pointer" onClick={() => navigate({ to: "/discover/settings" })}>
          <BackArrow />
        </a>
        <span className="mt-2 black-color">Change Password</span>
      </h5>
      <form onSubmit={formik.handleSubmit} className='pb-1'>
        <Row>
          <Col sm={6}>
            <InputBox
              label="Current Password*"
              type="password"
              name="enterPassword"
              containerClass="mt-3"
              placeholder="*******"
              lableSize={"md"}
              formik={formik}
              values={formik.values.enterPassword}
              isError={
                formik.errors.enterPassword && formik.touched.enterPassword
              }
              error={formik.errors.enterPassword}
            />

            <InputBox
              label="New Password*"
              type="password"
              name="newPassword"
              containerClass="mt-3"
              placeholder="*******"
              lableSize={"md"}
              formik={formik}
              values={formik.values.newPassword}
              isError={formik.errors.newPassword && formik.touched.newPassword}
              error={formik.errors.newPassword}
            />

            <InputBox
              label="Confirm New Password*"
              type="password"
              name="confirmNewPassowrd"
              placeholder="*******"
              containerClass="mt-3"
              lableSize={"md"}
              formik={formik}
              values={formik.values.confirmNewPassowrd}
              isError={
                formik.errors.confirmNewPassowrd &&
                formik.touched.confirmNewPassowrd
              }
              error={formik.errors.confirmNewPassowrd}
            />
          </Col>
        </Row>
        <Container fluid={true} className="mt-5">
          <Button
            type="submit"
            label={"Update"}
            customizeClass="width-150 pu-btn-m"
          />
          <Button
            customizeClass="profile-cancel width-150 pu-btn-m"
            onClick={() => navigate({ to: "/discover/settings" })}
            label={"Cancel"}
          />
        </Container>
      </form>
    </Container>
  );
}

export default ChangePassword;
