import { useNavigate } from "@tanstack/react-location";
import { React, useEffect, useState } from "react";
// import Button from "../../../../common/Buttons";
import {
  useGetQuestionsMutation,
  useUpdateQuizMutation,
} from "../../../../redux/api/quiz";
import { DEFAULT_VALUES } from "../../../../constants/default-values";
import ArrowBack from "../../../../assets/prevArrow.svg";
import { prePath } from "../../../../utils/helper";
import {
  Container,
  ListGroup,
  Col,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-bootstrap";

function QuizQuestion() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quest] = useGetQuestionsMutation();
  const [updateQuiz] = useUpdateQuizMutation();
  const [progress, setProgress] = useState(0);
  const [image, setImage] = useState("");
  const [step, setStep] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [qiuzData, setQiuzData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UserData = useSelector((state) => state.userChallence);
  const userInfo = useSelector((state) => state?.userProfile?.data);
  const { secretIdentity } = UserData;

  const refrenceQuizData = [
    "challenge_mindset",
    "emotional_control",
    "mental_flexibility",
    "sense_of_purpose",
    "social_connectedness",
    "self_efficacy",
    "optimism",
  ];

  useEffect(() => {
    secretIdentity?.details?.profile_image &&
      setImage(`${prePath}${secretIdentity?.details?.profile_image}`);
  }, [secretIdentity]);

  useEffect(() => {
    setLoading(true);
    quest().then((response) => {
      setLoading(false);
      if (response?.error) throw new Error(response.error.data.error);
      let mainData = response.data.map((res) => {
        return { ...res, ans: "" };
      });
      setQuestions(mainData);
      dispatch({ type: "QUIZ_QUESTION", payload: response });
    });
  }, []);

  const hitQuizApi = () => {
    let finalData = {};
    refrenceQuizData.forEach((rr) => {
      let count = 0;
      qiuzData.forEach((res) => {
        if (rr === res.name) {
          count = count + res.count;
        }
      });
      finalData = { ...finalData, [rr]: count };
    });
    updateQuiz(finalData)
      .then((res) => {
        navigate({ to: "/discover/quiz-result" });
      })
      .catch((err) => {
        return;
      });
  };

  const nextStep = () => {
    setTimeout(() => {
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions?.length) {
        setCurrentQuestion(nextQuestion);
        setProgress(progress + 5);
        setStep(step + 1);
      } else {
        hitQuizApi();
      }
    }, 300);
  };

  const handleAnswerButtonClick = async (answer, indexs) => {
    const updatedAns = await questions.map((res, i) => {
      if (currentQuestion === i) {
        const obj = {
          name: res.type,
          count: indexs,
        };
        if (res.ans == "") {
          setQiuzData([...qiuzData, obj]);
        } else {
          let removeLast = qiuzData;
          removeLast.pop();
          setQiuzData([...removeLast, obj]);
        }
        return { ...res, ans: answer };
      } else {
        return res;
      }
    });
    setQuestions(updatedAns);
    nextStep();
  };

  return (
    <Container className="quiz-container">
      <Container className="main mb-5">
        <Row className="quiz-mark w-8">
          <Col className="card-img-s" sm={1}>
            {image ? (
              <img
                className="squad-profile-pic"
                width="72px"
                src={image}
                alt=""
              />
            ) : (
              <h3 className="squad-profile-pic without-img text-capitalize">
                {userInfo?.first_name?.charAt(0)}
              </h3>
            )}
          </Col>
          <Col className="mx-3">
            <h4 className="text-dark fw-6">{step + 1}/20</h4>
            {/* progress bar */}
            <ProgressBar striped variant="warning" now={progress} />
            {/* <ProgressBar percent={progress} filledBackground="#F5AB38">
              {questions.map(() => (
                <Step transition="scale">
                  {({ accomplished, index }) => (
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : null
                      } `}
                    ></div>
                  )}
                </Step>
              ))}
            </ProgressBar> */}
          </Col>
        </Row>
      </Container>
      {loading ? (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container className="content-container quiz mb-5">
          {/* question */}

          <h3 className="questiontext">
            {questions != "" ? questions[currentQuestion]?.question : ""}
          </h3>

          {/* options */}
          <ListGroup>
            {DEFAULT_VALUES.QUIZ.SCORE_DETAILS.map((v, i) => (
              <ListGroup.Item
                key={i}
                className={
                  questions[currentQuestion]?.ans === v
                    ? "mb-3 p-3 ps-4 cursor selectedAns"
                    : "mb-3 p-3 ps-4 list cursor"
                }
                onClick={() => handleAnswerButtonClick(v, i)}
              >
                <span className="fw-6 color-s mr-3 ">{i + 1} -</span> {v}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>
      )}

      {/* button for previous */}
      {!currentQuestion == 1 ? (
        ""
      ) : (
        <Button
          className="previous mt-5 bg-transparent border-0"
          onClick={() => {
            setCurrentQuestion(currentQuestion - 1);
            setProgress(progress - 5);
            setStep(step - 1);
          }}
        >
          {" "}
          <div className="d-flex align-items-center f18 fw-6 previous">
            <img src={ArrowBack} className="mr-1" alt="back" />
            Previous{" "}
          </div>
        </Button>
      )}
    </Container>
  );
}

export default QuizQuestion;
