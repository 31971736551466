import React, { useState } from "react";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearch } from "@tanstack/react-location";
import moment from "moment";
import Button from "../../../../common/Buttons";
import { data, lowerSection } from "./formConfig";
import { useBadAddReportMutation } from "../../../../redux/api/challenge";
import SideBottom from "../../../../assets/auth-landing-curves-2.svg";
import toast from "react-hot-toast";

function BadGuysForm() {
  const navigate = useNavigate();
  const search = useSearch();

  const UserData = useSelector((state) => state.userChallence);
  const ActivityDate = useSelector((state) => state?.userProfile?.activityDate);
  const { cardDetail, challengeDaysDetails } = UserData;
  const [triggerBadAddReport] = useBadAddReportMutation();
  const [error, setError] = useState("");
  const [how, setHow] = useState("");
  const [rate, setRate] = useState("");

  const currentDate = moment().format("YYYY-MM-DD");
  const Adata =
    ActivityDate.filter((res) => res?.day === challengeDaysDetails?.order) ||
    [];

  const handleSubmit = () => {
    const inner = {
      date: search?.page === "solo-play" ? currentDate : Adata[0]?.date,
      is_bad_guy_win: how === "Battle Win" ? true : false,
      rate_bad_guy: rate.replace("A ", "").toLocaleLowerCase(),
    };
    const payload = {
      cardDetail,
      inner,
    };
    if (how && rate) {
      triggerBadAddReport(payload)
        .then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
            return;
          } else {
            const result = response?.data?.result;

            navigate({
              to: "/select/details/bad-guys-feedback",
              search: {
                ...result,
                page: search?.page,
                strength: search?.strength,
                isBoss: rate === "Boss" ? true : false,
                isWin: how === "Battle Win" ? true : false,
                challengeId: cardDetail?.challenge_id,
                // ...(search?.page === "squad-play" && {
                //   order: Adata[0]?.day,
                //   date: Adata[0]?.date,
                // }),
              },
            });
          }
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } else {
      setError("Please select the details");
    }
  };

  const handleCheck = (check, sec) => {
    if (sec === "Question") {
      setHow(check);
      setRate("");
    } else {
      setRate(check);
    }
  };

  const rateSection = lowerSection.filter((res) => {
    if (how === "Battle Fail" && res.check === "Vanquished") {
      return null;
    } else {
      return res;
    }
  });

  return (
    <div className="bad-container form">
      <Container sm={2}>
        <Row className="justify-content-md-center">
          <Col sm={6} className="bad-form-heading">
            Battle Report
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Card ClassName="mt-4 px-0">
            <Card.Header className="px-0">How did it go?</Card.Header>
            <Card.Body className="px-0">
              <Form>
                {data.map((res, i) => (
                  <>
                    <Form.Check
                      key={i}
                      checked={how === res.check}
                      onClick={() => handleCheck(res.check, res.sec)}
                      type="radio"
                      label={res.check}
                    />
                    <p></p>
                  </>
                ))}
              </Form>
            </Card.Body>
          </Card>
        </Row>
        <Row className="justify-content-md-center margins">
          <Card>
            <Card.Header className="px-0">Rate this bad guy now.</Card.Header>
            <Card.Body className="px-0">
              <Form>
                {rateSection.map((res, i) => (
                  <>
                    <Form.Check
                      key={i}
                      checked={rate === res.check}
                      onClick={() => handleCheck(res.check, res.sec)}
                      type="radio"
                      disabled={!how}
                      label={res.check}
                    />
                    <p className="sec-text-rt fw-3">{res.sec}</p>
                  </>
                ))}
              </Form>
            </Card.Body>
          </Card>
        </Row>
        {error && (
          <Row className="justify-content-md-center margins">
            <Col className="centeringText">
              <p className="errorText">{error}</p>
            </Col>
          </Row>
        )}
        <Row className="justify-content-md-center margins">
          <Button
            label={"Submit"}
            onClick={handleSubmit}
            customizeClass="lower-button-form"
          />
        </Row>
      </Container>
      <img className="b-img" src={SideBottom} alt="s-logo" />
    </div>
  );
}

export default BadGuysForm;
