import { Navigate, Outlet, useNavigate } from "@tanstack/react-location";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "./layout";
import UserCard from "./user-card";
import toast from "react-hot-toast";
import {
  useGetProfileMutation,
  useUpdateProfileMutation,
} from "../../redux/api/userProfile";
import CommonPop from "../../common/Modal/commonModal";
import { useState } from "react";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function DiscoverDashboard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData, userDataValue] = useGetProfileMutation();
  const [updateProfileData] = useUpdateProfileMutation();
  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state) => state?.userProfile?.data);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    navigate({
      to: "/discover/manage-subscription",
    });
    closeModal();
  };

  useEffect(() => {
    userData().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        const data = response?.data;
        dispatch({ type: "PROFILE_DATA", payload: data?.details });
        localStorage.setItem("USER", JSON.stringify(data.details));
        if (
          data?.details?.timezone === null ||
          data?.details?.timezone !== localTimeZone
        ) {
          const payload = {
            timezone: localTimeZone,
            // timezone: "America/New_York",
          };
          updateProfileData(payload);
        }
        if (data?.details?.is_trial_completed) {
          setShowModal(true);
          const payload = {
            is_trial_expire_popup_shown: true,
          };
          updateProfileData(payload);
        }
      }
    });
  }, []);

  return (
    <>
      <Layout>
        <UserCard
          user={{
            name: `${user?.first_name} ${user?.last_name}`,
            streak: 4,
          }}
        />
        <Outlet />
      </Layout>
      <CommonPop
        showModal={showModal}
        hideModal={closeModal}
        handleConfirm={handleConfirm}
        buttonText="Subscribe"
        title={
          "Your free trial has expired. The account has been converted to a Player account"
        }
        isTxtHide={true}
        submitClassName="w-auto px-4 mt-2"
        titleClassName="lh-sm w-8 mt-4"
        isCloseButtonHide={false}
        freeTrialInfo={
          "Please subscribe to continue with the Hero account features"
        }
      />
    </>
  );
}

export default DiscoverDashboard;
