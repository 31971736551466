import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Button from "../../../../common/Buttons";
import Logo from "../../../../assets/allhands.svg";
import { useNavigate, useSearch } from "@tanstack/react-location";

function AllyFeedBack() {
  const navigate = useNavigate();
  const location = useSearch();

  const handleOnClick = () => {
    if (location?.challenge_completed) {
      navigate({ to: "/discover/weekly-feedBack" });
    } else if (location?.win_of_the_day_acquired) {
      navigate({
        to: "/discover/feedBack",
        search: {
          page: location?.page,
          challengeId: location?.challengeId,
        },
      });
    } else {
      navigate({
        to: `/discover/dashboard/${location?.page}/${location?.challengeId}`,
      });
    }
  };
  return (
    <div className="bad-container feedback discover-container">
      <Container className="d-flex flex-column justify-content-center">
        <Row className="d-flex justify-content-center">
          <Col sm={4} className="feedbacks-heading-ally mx-auto">
            Ally Check-in
          </Col>
        </Row>
        <Row className="d-flex justify-content-center w-8 mx-auto">
          <Col
            sm={4}
            md={4}
            lg={3}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {/* <div className="wd140"> */}
            <Image src={Logo} />
            {/* </div> */}
            {/* <Image src={Gaurd} className="Gaurd" />
            <p className="feedback-No">+1</p> */}
          </Col>
        </Row>
        {/* <Row className="justify-content-md-center">
          <Col sm={2} className="mt-4 mr-5">
            <Button
              size="sm"
              label={"Social Resilience"}
              customizeClass="social-resilience"
            />
          </Col>
        </Row> */}
        <Row className="d-flex justify-content-center mt-3">
          <Col sm={5} md={4} lg={3} className="feed-back-text fsc-24 mx-auto ">
            You are a hero
            <br />
            to others
          </Col>
        </Row>
        <Row className={`d-flex justify-content-center mt-5 pt-4 `}>
          <Button
            label={"Continue"}
            onClick={() => handleOnClick()}
            customizeClass=" pu-btn-f mx-auto"
          />
        </Row>
      </Container>
    </div>
  );
}

export default AllyFeedBack;
