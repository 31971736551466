import React from "react";
import InputBox from "../../../../common/InputBox";
import { APP_CONFIG } from "../../../../constants/app-config";
import SeoLayout from "../../../../common/SeoLayout";

function ResetPassword({ formik }) {
  return (
    <SeoLayout title="Reset Password" robot>
      {" "}
      <InputBox
        label="New Password"
        containerClass="mt-3"
        name="new_password"
        type="password"
        placeholder={APP_CONFIG.CONFIRM_PASSWORD_PLACEHOLDER}
        formik={formik}
        values={formik.values.new_password}
        isError={formik.errors.new_password && formik.touched.new_password}
        error={formik.errors.new_password}
      />
      <InputBox
        label="Confirm Password"
        containerClass="mt-3"
        type="password"
        name="confirm_new_password"
        placeholder={APP_CONFIG.NEW_PASSWORD_PLACEHOLDER}
        formik={formik}
        values={formik.values.confirm_new_password}
        isError={
          formik.errors.confirm_new_password &&
          formik.touched.confirm_new_password
        }
        error={formik.errors.confirm_new_password}
      />
    </SeoLayout>
  );
}

export default ResetPassword;
