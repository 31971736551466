import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate, useMatch } from "@tanstack/react-location";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Card from "../../../../common/Cards";
import epicWinIcon from "../../../../assets/util-icons/epicWinIcon.svg";
import SPowerUp from "../../../../assets/sPowerUP";
import SBadGuy from "../../../../assets/sBadGuy";
import STimer from "../../../../assets/sTimer";
import SQuest from "../../../../assets/sQuest";
import Add from "../../../../assets/Add.png";
import epicWinAchieveIcon from "../../../../assets/epicWinAchieveIcon.svg";
import plusIcon from "../../../../assets/util-icons/plusIcon.svg";
import ActivityCard from "../../../../common/ActivityCard";
import { API_MOCKS } from "../../../../constants/api-mocks";
import { prePath } from "../../../../utils/helper";
import {
  useLastSelectedChallengeMutation,
  useBadgeCountMutation,
  useActivityTrackMutation,
  useGetChallengeByIdMutation,
  useClaimEpicWinMutation,
} from "../../../../redux/api/challenge";
import toast from "react-hot-toast";
import { useCallback } from "react";
import { getGreetingTime } from "../../../../utils/helper";
import CommonPop from "../../../../common/Modal/commonModal";
import CustomSoloLibrary from "./CustomSoloLibrary";
import { useGetLibraryMutation } from "../../../../redux/api/library";
import ScrollToTop from "../../../../utils/scrollToTop";
import Button from "../../../../common/Buttons";

function SoloPlay() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customLibraryActivityData = JSON.parse(
    localStorage.getItem("customLibraryActivityData")
  );

  const UserData = useSelector((state) => state.userChallence);
  const userInfo = useSelector((state) => state?.userProfile?.data);
  const { userChallenge, secretIdentity } = UserData;
  const [userData, setUserData] = useState(null);
  const [allActivity, setAllActivity] = useState("");
  const [badgeData, setBadgeData] = useState(null);
  const [sName, setSName] = useState("");
  const [getting, setGetting] = useState(false);
  const [showingPup, setShowingPup] = useState("");
  const [showingBguy, setShowingBguy] = useState("");
  const [image, setImage] = useState("");
  const data = userData?.activities;
  const request = useRef(false);
  const triggerRequest = useRef(false);
  const [isActivityDeleted, setIsActivityDeleted] = useState(false);
  const [isCustomLibrary, setIsCustomLibrary] = useState(
    customLibraryActivityData ? true : false
  );
  const [isCustomChallenge, setIsCustomChallenge] = useState(false);
  const [customActivityData, setCustomActivityData] = useState(
    customLibraryActivityData ? customLibraryActivityData : null
  );

  const [randomPowerUps, setRandomPowerUps] = useState(null);
  useEffect(() => {
    const datas = async () => {
      setShowingBguy(data?.bad_guy);
    };
    datas();
  }, [data]);

  useEffect(() => {
    const datas = async () => {
      setShowingPup(data?.power_ups);
    };
    datas();
  }, [data]);

  useEffect(() => {
    if (userInfo?.user_type === "player") {
      navigate({ to: "/discover/dashboard" });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!userData?.is_cloned && userData?.created_by === "user") {
      setIsCustomChallenge(true);
    } else {
      setIsCustomChallenge(false);
    }
  }, [userData?.created_by, userData?.is_cloned]);

  const [triggerLastSelected, UpdateLastSelected] =
    useLastSelectedChallengeMutation();
  const [triggerGetChallengeById] = useGetChallengeByIdMutation();
  const [claimEpicWin] = useClaimEpicWinMutation();
  const [triggerBadgeCount, badgeCountFun] = useBadgeCountMutation();
  const [triggerActivityTrack] = useActivityTrackMutation();
  const [triggerLibrary] = useGetLibraryMutation();

  const [activityTrackData, setActivityTrackData] = useState([]);
  const [epicWinLoading, setEpicWinLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { params } = useMatch();
  const challengeId = params?.id || null;

  const closeModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setEpicWinLoading(true);
    const payload = {
      id: userData?.id,
    };
    claimEpicWin(payload).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.data?.error);
        setEpicWinLoading(false);
      } else {
        setShowModal(!showModal);
        setEpicWinLoading(false);
        navigate({
          to: "/discover/epic-win-feedback",
        });
      }
    });
  };

  useEffect(() => {
    secretIdentity?.details?.secret_identity !== null &&
      secretIdentity?.details?.secret_identity &&
      setSName(secretIdentity?.details?.secret_identity);
    secretIdentity?.details?.profile_image !== null &&
      secretIdentity?.details?.profile_image &&
      setImage(`${prePath}${secretIdentity?.details?.profile_image}`);
  }, [secretIdentity]);

  const activityDeleteHandler = (value) => {
    setIsActivityDeleted(value);
  };

  const getBadgeCount = useCallback(
    (id) => {
      if (!!id) {
        const payload = {
          id: id,
          date: { date: moment().format("YYYY-MM-DD") },
        };
        triggerBadgeCount(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.message);
            return;
          }
          setBadgeData(response?.data?.result);
        });
      }
    },
    [triggerBadgeCount]
  );

  const getActivityTrack = useCallback(
    (id) => {
      const data = {
        challenge_id: id,
      };
      if (!!id) {
        triggerActivityTrack(data).then((res) => {
          if (res?.error) {
            toast.error(res?.error?.message);
            return;
          } else {
            setActivityTrackData(res.data);
            dispatch({ type: "ACTIVITY_CHALLENGE_DATA", payload: res.data });
          }
        });
      }
    },
    [triggerActivityTrack]
  );

  const getChallengesById = useCallback(() => {
    setGetting(true);
    triggerRequest.current = true;
    const data = {
      id: challengeId,
      solo: true,
      date: moment().format("YYYY-MM-DD"),
    };
    triggerGetChallengeById(data).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
        setGetting(false);
      } else {
        setUserData(res.data);
        localStorage.setItem(
          "feedbackData",
          JSON.stringify({
            epicWin: res.data.epic_win,
            skillBadge: res.data.skill_badge,
          })
        );
        setGetting(false);
      }
    });
  }, [challengeId, triggerGetChallengeById]);

  const getLastChallenges = useCallback(() => {
    setGetting(true);
    request.current = true;
    /** Last Challenge API */
    const payload = {
      isSolo: true,
    };
    triggerLastSelected(payload)
      .then((response) => {
        if (response?.error) {
          setGetting(false);

          return;
        } else {
          getBadgeCount(response.data.id);
          getActivityTrack(response.data.id);
          setUserData(response.data);
          localStorage.setItem(
            "feedbackData",
            JSON.stringify({
              epicWin: response.data.epic_win,
              skillBadge: response.data.skill_badge,
            })
          );
          setGetting(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        setGetting(false);
      });
  }, [getActivityTrack, getBadgeCount, triggerLastSelected]);

  useEffect(() => {
    if (!request.current && challengeId === null) {
      getLastChallenges();
    } else if (challengeId !== null && !triggerRequest.current) {
      getChallengesById();
      getBadgeCount(challengeId);
      getActivityTrack(challengeId);
    }
  }, [
    getBadgeCount,
    getLastChallenges,
    getActivityTrack,
    getChallengesById,
    challengeId,
    userData?.id,
  ]);

  useEffect(() => {
    if (isActivityDeleted) {
      if (!challengeId) {
        getLastChallenges(activityTrackData);
      } else {
        getChallengesById();
      }
      setIsActivityDeleted(false);
    }
  }, [
    activityTrackData,
    challengeId,
    getChallengesById,
    getLastChallenges,
    isActivityDeleted,
  ]);

  const allySection = () => {
    return (
      <>
        {data?.ally_check_in?.filter((obj) => obj.is_completed)?.length ===
        data?.ally_check_in?.length ? (
          <div className="d-contents ">
            {data?.ally_check_in
              ?.filter((obj) => obj.is_completed)
              .map(
                (v, i) =>
                  i === 0 && (
                    <ActivityCard
                      key={"activity-card-" + i}
                      containerClass="act-mr30 mb-4 mt-5 act-bg"
                      quest={v}
                      soloPlay={true}
                      ally={true}
                      color={API_MOCKS.ACTIVITY.ALLY.color}
                      title={
                        i === 0 ? (
                          <div className="d-flex">
                            <div>
                              <STimer active />
                            </div>
                            <div className="ml-3 mt-1 b-fn b-fn">ALLY</div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      // total={data?.ally_check_in?.length}
                      completed={
                        data?.ally_check_in.length > 0
                          ? data?.ally_check_in.filter(
                              (obj) => obj.is_completed === true
                            ).length
                          : 0
                      }
                      index={i}
                    />
                  )
              )}
          </div>
        ) : (
          <div className="d-contents ">
            {data?.ally_check_in
              ?.filter((obj) => obj.is_completed === false)
              .map(
                (v, i) =>
                  i === 0 && (
                    <ActivityCard
                      key={"activity-card-" + i}
                      containerClass="act-mr30 mb-4 mt-5 act-bg"
                      quest={v}
                      soloPlay={true}
                      ally={true}
                      color={API_MOCKS.ACTIVITY.ALLY.color}
                      title={
                        i === 0 ? (
                          <div className="d-flex">
                            <div>
                              <STimer active />
                            </div>
                            <div className="ml-3 mt-1 b-fn">ALLY</div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      // total={data?.ally_check_in?.length}
                      completed={
                        data?.ally_check_in.length > 0
                          ? data?.ally_check_in.filter(
                              (obj) => obj.is_completed === true
                            ).length
                          : 0
                      }
                      index={i}
                    />
                  )
              )}
          </div>
        )}
      </>
    );
  };

  const getLibrary = useCallback(
    (page = 1, per_page = 3, id) => {
      const data = {
        page,
        per_page,
        type: "power_ups",
        is_random: true,
        challenge_id: id,
      };
      triggerLibrary(data).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.error);
        } else {
          setRandomPowerUps(res.data?.result);
        }
      });
    },
    [triggerLibrary]
  );
  useEffect(() => {
    if (userData?.is_cloned && userData?.created_by === "user") {
      getLibrary(1, 3, userData?.id);
    }
  }, [getLibrary, userData?.created_by, userData?.id, userData?.is_cloned]);

  const handleOpenCustomLibrary = (data) => {
    setIsCustomLibrary(true);
    setCustomActivityData(data);
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("customLibraryActivityData");
    };
  }, []);

  return (
    <>
      {!isCustomLibrary ? (
        <Container className="content-container squad-play px-0 mb-2">
          <ScrollToTop />

          <CommonPop
            showModal={showModal}
            hideModal={closeModal}
            handleConfirm={handleConfirm}
            icon={epicWinAchieveIcon}
            loading={epicWinLoading}
            buttonText="I Did It!"
            title={"Epic Win Achieved?"}
            txt="Not Yet"
          />
          {userData?.title && (
            <div className="b-fn mt-3 fsc-20 w-8 fw-6">{userData?.title}</div>
          )}
          {!allActivity ? (
            <>
              <Card
                containerClass="mt-0 medal-card px-0 w-8"
                titleClass="px-0"
                title={
                  <>
                    <p className="fs-2 mb-2 text-capitalize">
                      {getGreetingTime(moment())},
                    </p>
                    <h4 className="title ">
                      {sName
                        ? sName
                        : `${userInfo?.first_name} ${
                            userInfo?.last_name ? userInfo.last_name : ""
                          }`}
                    </h4>
                  </>
                }
                customRightBtn={
                  <Row>
                    <Col sm={3}>
                      {image ? (
                        <img className="squad-profile-pic" src={image} alt="" />
                      ) : (
                        <h3 className="squad-profile-pic without-img text-uppercase">
                          {sName
                            ? sName?.charAt(0)
                            : userInfo?.first_name?.charAt(0)}{" "}
                        </h3>
                      )}
                    </Col>
                    <Col sm={7}>
                      <h6 className=" title">Win the Day</h6>
                      <div className="d-flex medal-container">
                        <SPowerUp
                          active={
                            !badgeData?.power_ups_completed ||
                            badgeData?.power_ups_completed.length < 1
                              ? false
                              : true
                          }
                        />
                        <SPowerUp
                          active={
                            !badgeData?.power_ups_completed ||
                            badgeData?.power_ups_completed.length < 2
                              ? false
                              : true
                          }
                        />
                        <SPowerUp
                          active={
                            !badgeData?.power_ups_completed ||
                            badgeData?.power_ups_completed.length < 3
                              ? false
                              : true
                          }
                        />
                        <SBadGuy
                          active={badgeData?.is_bad_guy_completed === true}
                        />
                        <SQuest
                          active={badgeData?.is_quest_completed === true}
                        />
                        <STimer
                          active={badgeData?.is_ally_completed === true}
                        />
                      </div>
                    </Col>
                  </Row>
                }
              />
              {getting ? (
                <div className="loading mt-5">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : !data ||
                (data?.power_ups.length <= 0 &&
                  data?.bad_guy.length <= 0 &&
                  randomPowerUps?.length <= 0 &&
                  data?.ally_check_in.length <= 0 &&
                  data?.quests.length <= 0) ? (
                <div className="no_invitation w-8">
                  {" "}
                  <div className="d-flex flex-column text-center">
                    <h6 className="title lh-1 mb-2 fw-6">
                      No challenge selected
                    </h6>
                    <p
                      className="see-invitaion pointer"
                      onClick={() =>
                        navigate({
                          to: "/discover/dashboard",
                          search: { back: "PgGoal" },
                        })
                      }
                    >
                      Select a challenge to play
                    </p>
                  </div>
                </div>
              ) : userData !== null ? (
                <>
                  <div className="d-flex align-items-start w-8 pb-3 mb-4 mt-4 border-bottom-1">
                    <div
                      className={`${isCustomChallenge && "pointer"}`}
                      onClick={
                        isCustomChallenge
                          ? () => {
                              setShowModal(true);
                            }
                          : null
                      }
                    >
                      <img src={epicWinIcon} alt="" style={{ width: "28px" }} />
                    </div>
                    <div className=" d-flex justify-content-center align-items-start fs-14  ms-2">
                      <span className="fw-6 b-fn text-nowrap"> Epic Win: </span>
                      <span className="ms-2 fw-6 b-fn ">
                        {userData?.epic_win}
                      </span>
                    </div>
                  </div>
                  <Row>
                    <div className="d-flex flex-column wrap mt-5 mb-2">
                      {showingPup?.length === 0 && isCustomChallenge && (
                        <div
                          className="d-flex flex-column justify-content-start align-items-start"
                          style={{ width: "190px" }}
                        >
                          {" "}
                          <div className="d-flex">
                            <div>
                              <SPowerUp active />
                            </div>
                            <div className="ml-3  mt-1 fw-5 fsc-18 b-fn">
                              Power-ups
                            </div>
                          </div>
                          <div className="activity-add my-3">
                            <div
                              className={`  pointer activity-symbol d-flex justify-content-center align-items-center `}
                              onClick={() =>
                                isCustomChallenge
                                  ? handleOpenCustomLibrary({
                                      id: userData?.id,
                                      page: "solo-play",
                                      type: "power_ups",
                                    })
                                  : navigate({
                                      to: "/select/add/power-up-form",
                                      search: {
                                        id: userData?.id,
                                        page: "solo-play",
                                      },
                                    })
                              }
                            >
                              <img src={Add} alt="img" />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-flex wrap ">
                        {(isCustomChallenge ? showingPup : randomPowerUps)
                          ?.slice(0, 3)
                          ?.map((v, i) => (
                            <ActivityCard
                              key={"power-ups-" + v.id}
                              containerClass="act-mr30 mb-4"
                              quest={v}
                              challengeids={userData?.id}
                              color={API_MOCKS.ACTIVITY.POWER_UPS.color}
                              lButton={
                                i === 2 &&
                                (isCustomChallenge
                                  ? showingPup.length > 3
                                  : true)
                              }
                              isCustomChallenge={isCustomChallenge}
                              setAllActivity={() =>
                                isCustomChallenge
                                  ? setAllActivity("powerUp")
                                  : navigate({
                                      to: "/select/add-power-ups",
                                      search: {
                                        IdChl: userData?.id,
                                        page: "solo-play",
                                      },
                                    })
                              }
                              title={
                                <>
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: "190px" }}
                                  >
                                    {" "}
                                    {i === 0 && (
                                      <div className="d-flex">
                                        <div>
                                          <SPowerUp active />
                                        </div>
                                        <div className="ml-3 mt-1 b-fn">
                                          Power-ups
                                        </div>
                                      </div>
                                    )}
                                    <div className="add-Img">
                                      {i + 1 ===
                                        showingPup?.slice(0, 3)?.length &&
                                        isCustomChallenge && (
                                          <img
                                            width="24px"
                                            src={plusIcon}
                                            alt="icon"
                                            className="pointer"
                                            onClick={() =>
                                              isCustomChallenge
                                                ? handleOpenCustomLibrary({
                                                    id: userData?.id,
                                                    page: "solo-play",
                                                    type: "power_ups",
                                                  })
                                                : navigate({
                                                    to: "/select/add/power-up-form",
                                                    search: {
                                                      id: userData?.id,
                                                      page: "solo-play",
                                                    },
                                                  })
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </>
                              }
                              total={data?.power_ups?.length}
                              completed={
                                (isCustomChallenge
                                  ? data?.power_ups
                                  : randomPowerUps
                                )?.length > 0
                                  ? (isCustomChallenge
                                      ? data?.power_ups
                                      : randomPowerUps
                                    ).filter((obj) => obj.is_completed === true)
                                      .length
                                  : 0
                              }
                              soloPlay={true}
                              index={i}
                              more={isCustomChallenge ? false : true}
                              setActivityDeleteState={activityDeleteHandler}
                            />
                          ))}
                      </div>
                    </div>

                    <div className="d-flex flex-column wrap  mb-2">
                      {showingBguy?.length === 0 && (
                        <div
                          className="d-flex flex-column justify-content-start align-items-start"
                          style={{ width: "190px" }}
                        >
                          {" "}
                          <div className="d-flex">
                            <div>
                              <SBadGuy active />
                            </div>
                            <div className="ml-3  mt-1 fw-5 fsc-18 b-fn">
                              BAD GUYS
                            </div>
                          </div>
                          <div className="activity-add my-3">
                            <div
                              className={`  pointer activity-symbol d-flex justify-content-center align-items-center `}
                              onClick={() =>
                                handleOpenCustomLibrary({
                                  id: userData?.id,
                                  page: "solo-play",
                                  type: "bad_guy",
                                })
                              }
                            >
                              <img src={Add} alt="img" />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-contents wrap">
                        {showingBguy?.slice(0, 3)?.map((v, i) => (
                          <ActivityCard
                            key={"bads-guys-" + v.id}
                            containerClass="act-mr30 mb-4 mt-5"
                            quest={v}
                            soloPlay={true}
                            color={API_MOCKS.ACTIVITY.BAD_GUY.color}
                            setAllActivity={() => setAllActivity("badGuy")}
                            lButton={i === 2 && showingBguy?.length > 3}
                            setActivityDeleteState={activityDeleteHandler}
                            isCustomChallenge={isCustomChallenge}
                            title={
                              <>
                                <div className="d-flex justify-content-between align-items-center">
                                  {i === 0 && (
                                    <div className="d-flex">
                                      <div>
                                        <SBadGuy active />
                                      </div>
                                      <div className="ml-3 mt-1 b-fn">
                                        BAD GUYS
                                      </div>
                                    </div>
                                  )}
                                  <div className="add-Img">
                                    {i + 1 ===
                                      showingBguy?.slice(0, 3)?.length && (
                                      <img
                                        width="24px"
                                        src={plusIcon}
                                        alt="icon"
                                        className="pointer"
                                        onClick={() =>
                                          handleOpenCustomLibrary({
                                            id: userData?.id,
                                            page: "solo-play",
                                            type: "bad_guy",
                                          })
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            }
                            completed={
                              data?.bad_guy.length > 0
                                ? data?.bad_guy.filter(
                                    (obj) => obj.is_completed === true
                                  ).length
                                : 0
                            }
                            index={i}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="d-contents wrap">
                      {data?.quests?.length === 0 && (
                        <div
                          className="d-flex flex-column justify-content-start align-items-start"
                          style={{ width: "190px", marginRight: "30px" }}
                        >
                          {" "}
                          <div className="d-flex">
                            <div>
                              <SQuest active />
                            </div>
                            <div className="ml-3  mt-1 fw-5 fsc-18 b-fn">
                              QUESTS
                            </div>
                          </div>
                          <div className="activity-add my-3">
                            <div
                              className={`  pointer activity-symbol d-flex justify-content-center align-items-center `}
                              onClick={() =>
                                isCustomChallenge
                                  ? handleOpenCustomLibrary({
                                      id: userData?.id,
                                      page: "solo-play",
                                      type: "quests",
                                    })
                                  : navigate({
                                      to: "/select/add/quest-form",
                                      search: {
                                        id: userData?.id,
                                        page: "solo-play",
                                      },
                                    })
                              }
                            >
                              <img src={Add} alt="img" />
                            </div>
                          </div>
                        </div>
                      )}

                      {data?.quests?.slice(0, 3)?.map((v, i) => (
                        <ActivityCard
                          key={"quest-" + v.id}
                          containerClass="act-mr30 mb-4 mt-5"
                          quest={v}
                          isCustomChallenge={isCustomChallenge}
                          color={API_MOCKS.ACTIVITY.QUEST.color}
                          lButton={i === 2 && data?.quests.length > 3}
                          soloPlay={true}
                          setAllActivity={() => setAllActivity("quest")}
                          title={
                            <>
                              <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ width: "190px" }}
                              >
                                {i === 0 && (
                                  <div className="d-flex">
                                    <div className="d-flex">
                                      <div>
                                        <SQuest active />
                                      </div>
                                      <div className="ml-3 mt-1 b-fn">
                                        QUESTS
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="add-Img">
                                  {i + 1 ===
                                    data?.quests?.slice(0, 3)?.length &&
                                    isCustomChallenge && (
                                      <img
                                        width="24px"
                                        src={plusIcon}
                                        alt="icon"
                                        className="pointer"
                                        onClick={() =>
                                          isCustomChallenge
                                            ? handleOpenCustomLibrary({
                                                id: userData?.id,
                                                page: "solo-play",
                                                type: "quests",
                                              })
                                            : navigate({
                                                to: "/select/add/quest-form",
                                                search: {
                                                  id: userData?.id,
                                                  page: "solo-play",
                                                },
                                              })
                                        }
                                      />
                                    )}
                                </div>
                              </div>
                            </>
                          }
                          total={data?.quests?.length}
                          completed={
                            data?.quests.length > 0
                              ? data?.quests.filter(
                                  (obj) => obj.is_completed === true
                                ).length
                              : 0
                          }
                          setActivityDeleteState={activityDeleteHandler}
                        />
                      ))}
                      {allySection()}
                    </div>
                  </Row>
                  <Row></Row>
                </>
              ) : (
                getting && (
                  <div className="loading">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )
              )}
            </>
          ) : (
            <>
              {allActivity === "quest" && (
                <>
                  <div>
                    <p className="Activity-title mb-4 mt-4">All Quests</p>
                  </div>
                  <div className="d-flex flex-wrap mt-5">
                    {data?.quests?.map((v, i) => (
                      <ActivityCard
                        key={"quest-" + v.id}
                        containerClass="act-mr30 mb-4 mt-5 act-bg"
                        quest={v}
                        isCustomChallenge={isCustomChallenge}
                        color={API_MOCKS.ACTIVITY.QUEST.color}
                        soloPlay={true}
                        setAllActivity={() => setAllActivity(true)}
                        title={
                          i === 0 ? (
                            <div className="d-flex">
                              <div>
                                <SQuest active />
                              </div>
                              <div className="ml-3 mt-1 b-fn">QUESTS</div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        // total={data?.quests?.length}
                        completed={
                          data?.quests.length > 0
                            ? data?.quests.filter(
                                (obj) => obj.is_completed === true
                              ).length
                            : 0
                        }
                        setActivityDeleteState={activityDeleteHandler}
                        index={i}
                      />
                    ))}
                  </div>
                </>
              )}
              {allActivity === "powerUp" && (
                <>
                  <div>
                    <p className="Activity-title mb-4 mt-4">All Power-ups</p>
                  </div>
                  <div className="d-flex flex-wrap mt-5">
                    {data?.power_ups?.map((v, i) => (
                      <ActivityCard
                        key={"quest-" + v.id}
                        containerClass="act-mr30 mb-4 mt-5 act-bg"
                        quest={v}
                        color={API_MOCKS.ACTIVITY.POWER_UPS.color}
                        soloPlay={true}
                        isCustomChallenge={isCustomChallenge}
                        title={
                          i === 0 && (
                            <div className="d-flex">
                              <div>
                                <SPowerUp active />
                              </div>
                              <div className="ml-3 mt-1 b-fn">Power-ups</div>
                            </div>
                          )
                        }
                        // total={data?.quests?.length}
                        completed={
                          data?.power_ups?.length > 0
                            ? data?.power_ups?.filter(
                                (obj) => obj.is_completed === true
                              ).length
                            : 0
                        }
                        setActivityDeleteState={activityDeleteHandler}
                        index={i}
                      />
                    ))}
                  </div>
                </>
              )}
              {allActivity === "badGuy" && (
                <>
                  <div>
                    <p className="Activity-title mb-4 mt-4">All Bad Guys</p>
                  </div>
                  <div className="d-flex flex-wrap mt-5">
                    {data?.bad_guy?.map((v, i) => (
                      <ActivityCard
                        key={"quest-" + v.id}
                        containerClass="act-mr30 mb-4 mt-5 act-bg"
                        quest={v}
                        color={API_MOCKS.ACTIVITY.BAD_GUY.color}
                        soloPlay={true}
                        isCustomChallenge={isCustomChallenge}
                        title={
                          i === 0 ? (
                            <div className="d-flex">
                              <div>
                                <SBadGuy active />
                              </div>
                              <div className="ml-3 mt-1 b-fn">BAD GUYS</div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        // total={data?.quests?.length}
                        completed={
                          data?.bad_guy?.length > 0
                            ? data?.bad_guy?.filter(
                                (obj) => obj.is_completed === true
                              ).length
                            : 0
                        }
                        index={i}
                        setActivityDeleteState={activityDeleteHandler}
                      />
                    ))}
                  </div>
                </>
              )}

              <Button
                label={"Back"}
                onClick={() => setAllActivity(false)}
                customizeClass=" back-button-1 position-relative mt-5 pb-0 fw-600"
                variant="light"
              />
            </>
          )}
        </Container>
      ) : (
        <Container className="content-container squad-play px-0 mb-2">
          <CustomSoloLibrary
            isCustomChallenge={isCustomChallenge}
            customActivityData={customActivityData}
            challengeId={userData?.id}
            backHandler={() => setIsCustomLibrary(false)}
          />
        </Container>
      )}
    </>
  );
}

export default SoloPlay;
