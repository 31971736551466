import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { API_BASE_URL } from "../constants/app-config";
import { Mutex } from "async-mutex";

// create a new mutex
const mutex = new Mutex();

export const baseQueryWithReAuth =
  ({ baseUrl }) =>
  async (args, api, extraOptions) => {
    try {
      await mutex.waitForUnlock();
      const baseQuery = fetchBaseQuery({ baseUrl: baseUrl });

      let result = await baseQuery(args, api, extraOptions);
      if (result?.error && result?.error?.status === 401) {
        // try to get a new token

        if (!mutex.isLocked()) {
          const release = await mutex.acquire();

          try {
            const refreshBaseQuery = fetchBaseQuery({
              baseUrl: `${API_BASE_URL}user/auth`,
            });

            const refreshResult = await refreshBaseQuery(
              {
                url: `/refresh-token`,
                method: "POST",
                body: {
                  refresh_token: localStorage.getItem("REFRESH_TOKEN"),
                },
              },
              api,
              extraOptions
            );
            if (refreshResult?.data?.data) {
              // store the new token
              const storeToken = () => {
                return new Promise((resolve, reject) => {
                  localStorage.setItem(
                    "ACCESS_TOKEN",
                    refreshResult.data.data.access.token
                  );
                  localStorage.setItem(
                    "ACCESS_TOKEN_EXPIRES",
                    refreshResult.data.data.access.expires
                  );
                  localStorage.setItem(
                    "REFRESH_TOKEN",
                    refreshResult.data.data.refresh.token
                  );
                  localStorage.setItem(
                    "REFRESH_TOKEN_EXPIRES",
                    refreshResult.data.data.refresh.expires
                  );

                  if (
                    localStorage.getItem("ACCESS_TOKEN") ===
                    refreshResult.data.data.access.token
                  ) {
                    resolve("");
                  }
                });
              };

              await storeToken();

              // retry the initial query
              const newBaseQuery = fetchBaseQuery({
                baseUrl: baseUrl,
                prepareHeaders: (headers, { getState }) => {
                  const token = refreshResult.data.data.access.token;

                  // If we have a token set in storage, let's assume that we should be passing it.
                  if (token) {
                    headers.set("authorization", `Bearer ${token}`);
                  }

                  return headers;
                },
              });
              result = await newBaseQuery(args, api, extraOptions);
            } else {
              window.localStorage.clear();
              window.location.href = "/auth/login";
              setTimeout(() => {
                api.dispatch({ type: "USER_LOGOUT" });
              }, 1000);
            }
          } finally {
            // release must be called once the mutex should be released again.
            release();
          }
        } else {
          await mutex.waitForUnlock();
          const newBaseQuery = fetchBaseQuery({
            baseUrl: baseUrl,
            prepareHeaders: (headers, { getState }) => {
              const token = localStorage.getItem("ACCESS_TOKEN");

              // If we have a token set in storage, let's assume that we should be passing it.
              if (token) {
                headers.set("authorization", `Bearer ${token}`);
              }

              return headers;
            },
          });
          result = await newBaseQuery(args, api, extraOptions);
        }
      }
      return result;
    } catch (err) {
      // window.localStorage.clear();
      // // api.dispatch({ type: "USER_LOGOUT" });
      // window.location.href = "/auth/login";
    }
  };
