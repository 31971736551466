import React from "react";
import InputBox from "../../../../common/InputBox";
import { APP_CONFIG } from "../../../../constants/app-config";

function ChildRegister({ formik,guardianError }) {
  return (
    <>
      <InputBox
        label="Parent, Guardian or Teacher Email*"
        type="email"
        name="guardian_email"
        containerClass="mt-3"
        placeholder={APP_CONFIG.EMAIL_PLACEHOLDER_LOGIN}
        formik={formik}
        values={formik.values.guardian_email}
        isError={(formik.errors.guardian_email && formik.touched.guardian_email) || guardianError}
        error={formik.errors.guardian_email || guardianError}
      />
    </>
  );
}

export default ChildRegister;
