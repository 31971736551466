import React from "react";
import { Form } from "react-bootstrap";

function Select(props) {
  return (
    <>
      <Form.Select
        name={props.name}
        id={props.name}
        onChange={props.onChange}
        value={props.value}
        className={
          (props.name === "Newsletter" || props.name === "PowerSelect") &&
          "noFocus"
        }
      >
        {props.defaultOption && !props.value && (
          <option>{props.defaultOption}</option>
        )}

        {props.options.map((v, i) => {
          return (
            <option value={v} key={i}>
              {v}
            </option>
          );
        })}
      </Form.Select>
    </>
  );
}

export default Select;
