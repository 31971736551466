import { useNavigate } from "@tanstack/react-location";
import React from "react";
import { Container, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import BackArrow from "../../../assets/arrowleft";

function ScienceBehind(props) {
  const navigate = useNavigate();
  const UserData = useSelector((state) => state.userChallence);
  const { cardSelected } = UserData;

  const Description = cardSelected?.description?.replace(/&lt;/g, "<");
  return (
    <Container className="content-container science-behind">
      <h6 style={{ color: props.color }} className="fw-6 f22 mb-4 ex-s mar-ps">
        <div
          className="pointer mr-1 d-inline"
          onClick={() => navigate({ to: "/discover/dashboard" })}
        >
          <BackArrow />
        </div>
        {!UserData?.loading && `The Science Behind ${cardSelected?.title}`}
      </h6>
      {UserData?.loading ? (
        <div className="loading d-flex align-items-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : Description ? (
        <div
          className="termsCondition"
          dangerouslySetInnerHTML={{ __html: Description }}
        />
      ) : null}
      {/* <div className="bc-con">
      <Button
        label="Back"
        customizeClass="back-btn"
        customProps={{
          style: { backgroundColor: props.color, border: "none" },
        }}
        onClick={() => location.history.back()}
      />
      </div> */}
    </Container>
  );
}

export default ScienceBehind;
