import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearch } from "@tanstack/react-location";
import Button from "../../../common/Buttons";
import Bulbs from "../../../assets/battleTips.svg";
// import { BETTLE_DESCRIPTION } from "./contants/index";
import BadIcon from "../../../assets/bad-Owl.svg";
import Bulb from "../../../assets/r_bulb.svg";
import Hand from "../../../assets/hands.svg";
import Brain from "../../../assets/Brain.svg";
import Heart from "../../../assets/heart.svg";
import Person from "../../../assets/phyRes.svg";
import Notes from "../../../assets/notes.svg";

function BadGuyDetails() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const UserData = useSelector((state) => state.userChallence);
  const { cardDetail = {} } = UserData;
  const Bstratergy = cardDetail?.battle_strategy?.split("|");
  const search = useSearch();

  return (
    <div className="bad-container mainPaid bg-paid">
      <div className="top-corner-notes">
        <img
          className="mr-1 index cursor"
          onClick={() =>
            navigate({
              to: "/select/details/notes",
              search: {
                page: search?.page,
              },
            })
          }
          src={Notes}
          alt=""
        />
      </div>
      <div className="top-corner-button">
        <img className="mr-1 index" src={Brain} alt="" />
        <img className="mr-1 index" src={Brain} alt="" />
        {cardDetail?.strength_to_build === "physical" && (
          <>
            <img
              className="index mr-1 "
              src={Person}
              alt=""
              width="36px"
              height="36px"
            />
            <img
              className="index mr-1 "
              src={Person}
              alt=""
              width="36px"
              height="36px"
            />
          </>
        )}
        {cardDetail?.strength_to_build === "mental" && (
          <>
            <img className="index mr-1 " src={Brain} alt="" />{" "}
            <img className="index mr-1 " src={Brain} alt="" />
          </>
        )}
        {cardDetail?.strength_to_build === "emotional" && (
          <>
            <img className="index mr-1 " src={Heart} alt="" />{" "}
            <img className="index mr-1 " src={Heart} alt="" />{" "}
          </>
        )}
        {cardDetail?.strength_to_build === "social" && (
          <>
            {" "}
            <img className="index mr-1 " src={Hand} alt="" />{" "}
            <img className="index mr-1 " src={Hand} alt="" />
          </>
        )}
      </div>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col sm={3} className="bad-main-heading">
            Boss Bad Guy
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="badIcon">
            <img src={BadIcon} alt="" />
          </Col>
        </Row>
        <Container className="middle-activity-sec">
          <Row className="top-inner">
            <Col sm={12} className="bad-heading px-0">
              {cardDetail?.title}
            </Col>
          </Row>
          <Row className="middle-inner">
            <Col className="fs-2.8 text-light analytics-text px-0">
              {cardDetail?.description}
            </Col>
          </Row>
          {cardDetail?.battle_strategy !== null &&
            cardDetail?.battle_strategy && (
              <>
                <Row className="lower-inner d-flex justify-content-between">
                  <Col sm={4} className="battle-heading px-0">
                    Battle strategy
                  </Col>
                  <Col sm={4} className="d-flex justify-content-end">
                    <img
                      src={Bulb}
                      width="28px"
                      height="28px"
                      alt=""
                      onClick={() => setShowModal(!showModal)}
                    />
                    {search?.tips && showModal && (
                      <div className="sec-bd">
                        <h5 className="title mt-2 f20">
                          <img
                            width="30px"
                            alt=""
                            src={Bulbs}
                            className="mr-1"
                          />{" "}
                          Boss Battle Tips
                        </h5>
                        <ul className="d-flex justify-content-start flex-column px-0 mt-2">
                          {search?.tips?.map((res) => (
                            <li
                              className="marg f14 justify-content-start marg-pop mb-3"
                              key={res}
                            >
                              {res}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="middle-inner ">
                  <Col className="battle-Detail px-5 py-3">
                    {Bstratergy.map((res, i) => (
                      <Row
                        key={i}
                        className="fs-2.8 fw-4 text-light analytics-text inner-battle"
                      >
                        <Col className="fs-2.8 fw-4 text-light analytics-text inner-text-area px-0">
                          {res}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </>
            )}
        </Container>
        {cardDetail?.is_completed === false &&
          (cardDetail?.is_attempted_today === false ||
            search?.page === "solo-play") && (
            <Row className="justify-content-md-center w-8 mx-auto mt-4">
              <Button
                label={"Battled"}
                customizeClass="bad-activated w-3 pu-btn-m mt-2"
                onClick={() =>
                  navigate({
                    to: "/select/details/bad-form",
                    search: {
                      page: search?.page,
                      strength: cardDetail?.strength_to_build,
                    },
                  })
                }
              />
            </Row>
          )}
        <Row className="justify-content-md-center text-dark">
          <Col
            sm={1}
            className="lowerbad cursor"
            onClick={() =>
              navigate({
                to: `/discover/dashboard/${search?.page}${
                  search?.page === "squad-play"
                    ? `/${cardDetail.challenge_id}`
                    : ""
                }`,
              })
            }
          >
            {cardDetail?.is_completed === false &&
            (cardDetail?.is_attempted_today === false ||
              search?.page === "solo-play")
              ? "Later"
              : "Back"}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BadGuyDetails;
