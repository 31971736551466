import Container from "react-bootstrap/Container";
import { useCallback, useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { APP_CONFIG } from "../../constants/app-config";
import { useSelector } from "react-redux";
import {
  useGetTotalNotificationsMutation,
  useGetProfileMutation,
} from "../../redux/api/userProfile";
import navbarLogo from "../../assets/navbar-logo.png";
import { Link, useNavigate, useSearch } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

function CustomNavbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useSearch();

  const userProfileData = useSelector((state) => state.userProfile);
  const { data = "" } = userProfileData;

  const [notification, setNotification] = useState(0);
  const [paymentPage, setPaymentPage] = useState(true);

  const url = window.location.href;
  const hrefParts = url.split("/");
  const lastPath = hrefParts[hrefParts.length - 1];

  const [triggerGetNotifications] = useGetTotalNotificationsMutation();
  const [userData] = useGetProfileMutation();

  const getAllNotifications = useCallback(() => {
    triggerGetNotifications().then((res) => {
      if (!res.error) {
        setNotification(res.data.result.count);
      }
    });
  }, [triggerGetNotifications]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (
        !!localStorage.getItem("USER") &&
        !!localStorage.getItem("ACCESS_TOKEN")
      ) {
        getAllNotifications();
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [getAllNotifications]);

  useEffect(() => {
    getAllNotifications();
  }, [getAllNotifications]);

  useEffect(() => {
    if (lastPath === "stripe?show=true") {
      setPaymentPage(true);
    }
    if (userProfileData && data) {
      if (!data?.is_plan_viewed && lastPath !== "selectAccount?show=true") {
        navigate({ to: "/select/selectAccount?show=true" });
      }
      if (lastPath === "selectAccount?show=true" && data?.is_plan_viewed) {
        navigate({ to: "/discover/dashboard" });
      }
      if (
        paymentPage &&
        data?.user_type === "hero" &&
        !data?.is_subscribed &&
        lastPath !== "stripe?show=true"
      ) {
        navigate({ to: "/select/stripe?show=true" });
      }
      if (
        data?.is_plan_viewed &&
        data?.is_subscribed &&
        lastPath === "stripe?show=true"
      ) {
        navigate({ to: "/discover/dashboard" });
      }
    } else {
      userData().then((response) => {
        if (response?.error) {
          toast.error(response?.error?.message);
        } else {
          const data = response?.data;
          dispatch({ type: "PROFILE_DATA", payload: data?.details });
          localStorage.setItem("USER", JSON.stringify(data.details));
        }
      });
    }

    //  else if (
    //   data?.is_plan_viewed && data?.user_type !== 'player'&&
    //   (lastPath !== "select-challenges" || lastPath !== "how-to-play" || lastPath.includes("further-goal?name=")) &&
    //   (!data?.goal || data?.goal === null)
    // ) {
    //   navigate({ to: "/select/select-challenges" });
    // }
  }, [userProfileData]);

  return (
    <Navbar className="custom-navbar py-3" bg="light" expand="lg">
      <Container fluid>
        <Link to="/discover/dashboard">
          <Navbar.Brand className="d-flex ml-5 fw-6 brand-container">
            <img
              className="navbar-logo"
              src={navbarLogo}
              alt="Superbetter logo"
            />
            <h6 className="title my-0 fs-3">SuperBetter</h6>
          </Navbar.Brand>
        </Link>
        {!lastPath.includes("how-to-play") && (
          <Nav className="d-flex flex-row">
            {lastPath !== "stripe?show=true" &&
              lastPath !== "selectAccount?show=true" &&
              lastPath !== "select-challenges" &&
              (search?.page === "goal" ||
                !lastPath.includes("further-goal?")) &&
              APP_CONFIG.NAVBAR_ICONS.map((v, i) => (
                <Link
                  key={"link-url-" + i}
                  to={"/discover/" + v.name.toLowerCase()}
                  style={v.name === "Settings" ? { zIndex: 999 } : {}}
                  onClick={() => {
                    if (v.name === "Notification") {
                      setNotification(0);
                      dispatch({ type: "UPDATE_SIDE_BAR", payload: false });
                    } else {
                      dispatch({ type: "UPDATE_SIDE_BAR", payload: false });
                    }
                  }}
                >
                  <img src={v.icon} style={{ marginRight: "12px" }} alt="" />{" "}
                  {notification > 0 && i === 1 && (
                    <Badge className="badge notification-bg">
                      {notification}
                    </Badge>
                  )}
                </Link>
              ))}
          </Nav>
        )}

        {/* <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll"> */}
        {/* {props.showSearch && (
            <InputBox
              type="search"
              name="search "
              containerClass="search-container"
              placeholder={APP_CONFIG.SEARCH_PLACEHOLDER}
            />
          )} */}

        {/* showing user profile data */}
        {/* { userProfileData.data.user_type === 'hero' && (
            <>
              <img
                className="avatar-img"
                src={
                  data?.profile_image
                    ? `${prePath}${data?.profile_image}`
                    : "https://www.w3schools.com/howto/img_avatar.png"
                }
                alt={props.user.name}
              />
              <h6 className="title mb-0">{`${data?.first_name}${" "}${
                data?.last_name
              }`}</h6>
            </>
          )} */}
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
