import React, { useState, useEffect } from "react";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import Navbar from "../../common/Navbar";
import SeoLayout from "../../common/SeoLayout";

function Layout(props) {
  const UserName = localStorage.getItem("DETAIL_USER");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("USER") === null ||
      !localStorage.getItem("USER") ||
      localStorage.getItem("ACCESS_TOKEN") === null ||
      !localStorage.getItem("ACCESS_TOKEN")
    ) {
      window.localStorage.clear();
      dispatch({ type: "USER_LOGOUT" });
      setTimeout(() => {
        navigate({ to: "/auth/login" });
      }, 1000);
    } else {
      setShow(true);
    }
  }, []);
  return (
    <SeoLayout title="" robot>
      {show && (
        <>
          <Navbar
            showSearch={true}
            user={{
              avatar: "https://www.w3schools.com/howto/img_avatar.png",
              name: UserName, // TODO:static data
            }}
          />
          {props.children}
        </>
      )}
    </SeoLayout>
  );
}

export default Layout;
