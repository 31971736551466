import { Link, useSearch } from "@tanstack/react-location";
import React, { useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import Button from "../../../common/Buttons";
import { APP_CONFIG, APP_ID } from "../../../constants/app-config";
import { useFormik } from "formik";
import { DEFAULT_VALUES } from "../../../constants/default-values";
import {
  IosAndAndroidLinks,
  isAbove13,
  storeToken,
} from "../../../utils/helper";
import moment from "moment";
import { useNavigate } from "@tanstack/react-location";
import ParentRegister from "./parent-register";
import ChildRegister from "./child-register";
import {
  migratingUserRegisterSchema,
  registerSchema,
} from "../../../validation-schemas/auth-schema";
import {
  useRegisterMutation,
  useEmailCheckMutation,
  useGoogleAuthMutation,
} from "../../../redux/api/auth";
import toast from "react-hot-toast";
import EmailConfirmation from "./emailVerification";
import { useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import jwt_decode from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import SeoLayout from "../../../common/SeoLayout";
export default function Register() {
  const search = useSearch();
  const navigate = useNavigate();
  // tracking which page to show kept it as local state as state is reset after navigating to other page
  const [step, setStep] = useState(null);
  const [parent, setParent] = useState(false);
  const [triggerRegister, registerValues] = useRegisterMutation();

  const [handleEmailCheck] = useEmailCheckMutation();
  const [handleGoogleAuth] = useGoogleAuthMutation();
  const [registerUser, setRegisterUser] = useState(false);
  const [guardianError, setGuardianError] = useState("");
  const [emailID, setEmailID] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const [formData, setFormData] = useState({});
  const [socialLoginData, setSocialLoginData] = useState(null);
  const [disableEmail, setDisableEmail] = useState(false);
  const [isMigratingUser, setIsMigratingUser] = useState(false);
  const [emailVerificationToken, setEmailVerificationToken] = useState("");

  const initialValues = {
    guardian_email: "",
  };

  useEffect(() => {
    IosAndAndroidLinks();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setGuardianError("");
    }, 2000);
  }, [guardianError]);

  const handelRegisterApi = (data) => {
    triggerRegister(data)
      .then((response) => {
        if (response?.error) throw new Error(response.error.data.error);
        toast.success(response.data.message);
        const userData = response?.data?.details?.user;
        setEmailVerificationToken(
          response?.data?.details?.tokens?.access?.token
        );
        if (!userData?.is_email_verified) {
          storeToken(response.data.details);

          setRegisterUser(true);
        } else if (userData?.is_email_verified && !userData?.is_subscribed) {
          storeToken(response.data.details);
          localStorage.setItem("isFirstTimeUser", true);
          navigate({ to: "/select/selectAccount?show=true" });
        } else if (userData?.is_email_verified && userData?.is_subscribed) {
          storeToken(response.data.details);
          navigate({ to: "/discover/dashboard" });
        }
      })
      .catch((e) => toast.error(e.message));
  };

  const registerData = (values) => {
    setEmailID(values.email);
    const parsedDate = new Date(
      values.year,
      moment.monthsShort().indexOf(values.month),
      values.day
    );

    let data = {
      dob: moment(parsedDate).format("YYYY-MM-DD"),
      email: values.email,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name ? values.last_name : null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (values?.guardian_email && values?.guardian_email !== values?.email) {
      data = { ...data, guardian_email: values.guardian_email };
      setEmailID(values.guardian_email);
    } else if (values?.guardian_email) {
      setGuardianError("Guardian email should not match with child email");
    }

    if (socialLoginData !== null) {
      data.type = socialLoginData.type;
      data.device = socialLoginData.device;
      data.identityToken = socialLoginData.identityToken;
    }

    if (step === 1) {
      handelRegisterApi(data);
    } else if (
      values?.guardian_email &&
      values?.guardian_email !== values?.email &&
      step === 2
    ) {
      handelRegisterApi(data);
    }
  };

  const handleData = (dataForm, payload, values) => {
    if (step === 1) {
      const parsedDate = new Date(
        values.year,
        moment.monthsShort().indexOf(values.month),
        values.day
      );
      setParent(isAbove13(parsedDate));

      if (isAbove13(parsedDate)) {
        registerData(payload);
      } else {
        setFormData(values);
        setStep(2);
      }
    } else {
      dataForm.guardian_email = values.guardian_email;
      registerData(dataForm);
    }
  };

  const onSubmit = (values) => {
    if (step === 1 && (!values?.month || !values?.year || !values?.day)) {
      registerformik.setFieldError("date", "Please provide valid date");
      formik.setFieldError("date", "Please provide valid date");
      registerformikSocialLogin.setFieldError(
        "date",
        "Please provide valid date"
      );
      return;
    }
    if (isMigratingUser || disableEmail) {
      delete values?.password;
    }
    const dataForm = { ...formData };
    const payload = { ...values };
    const data = {
      email: values.email,
    };
    if (emailVerify && step === 1 && !isMigratingUser) {
      handleEmailCheck(data)
        .then((res) => {
          if (!res?.error) {
            if (res?.data?.details?.available) {
              handleData(dataForm, payload, values);
            } else {
              toast.error(res?.data?.message);
            }
          } else {
            toast.error(res?.error?.message);
          }
        })
        .catch((e) => toast.error(e.message));
    } else {
      handleData(dataForm, payload, values);
    }
  };

  const handleCredentialResponse = useCallback((res) => {
    const decoded = jwt_decode(res);
    setSocialLoginData({
      type: "google",
      device: "web",
      identityToken: res,
    });
    DEFAULT_VALUES.REGISTER_INITIAL_VALUES.email = decoded.email;
    DEFAULT_VALUES.REGISTER_INITIAL_VALUES.first_name = decoded.given_name;
    DEFAULT_VALUES.REGISTER_INITIAL_VALUES.last_name = decoded.family_name;
    setDisableEmail(true);
    setStep(1);
    setParent(true);
  }, []);

  const socialLoginType = (name) => {
    switch (name) {
      case "email":
        setParent(true);
        setStep(1);
        setEmailVerify(true);
        break;
      default:
    }
  };

  const responseFacebook = (res) => {
    setSocialLoginData({
      type: "facebook",
      device: "web",
      identityToken: res.accessToken,
    });
    const name = res.name.split(" ");
    DEFAULT_VALUES.REGISTER_INITIAL_VALUES.email = res.email;
    DEFAULT_VALUES.REGISTER_INITIAL_VALUES.first_name =
      name.length >= 1 ? name[0] : name;
    DEFAULT_VALUES.REGISTER_INITIAL_VALUES.last_name =
      name.length >= 2 ? name[1] : name;
    setStep(1);
    setParent(true);
    setDisableEmail(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: DEFAULT_VALUES.registerChildSchema,
    onSubmit,
  });

  const registerformik = useFormik({
    initialValues: DEFAULT_VALUES.REGISTER_INITIAL_VALUES,
    validationSchema: isMigratingUser
      ? migratingUserRegisterSchema
      : registerSchema,
    onSubmit,
  });

  const registerformikSocialLogin = useFormik({
    initialValues: DEFAULT_VALUES.REGISTER_INITIAL_VALUES,
    onSubmit,
  });

  useEffect(() => {
    if (search?.migrated && search?.email) {
      setIsMigratingUser(true);
      setParent(true);
      setStep(1);
      setEmailVerify(true);
      registerformik.setFieldValue("email", search?.email);
    }
  }, [search?.email, search?.migrated]);

  const loginGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (tokenResponse) => {
      const data = {
        code: tokenResponse.code,
      };
      handleGoogleAuth(data).then((res) => {
        if (res.data.id_token) {
          handleCredentialResponse(res.data.id_token);
        }
      });
    },
  });

  return (
    <SeoLayout
      title="Sign up for a Player Account"
      keywords="player account sign up"
      descriptionContent="SuperBetter uses the psychology of game play to overcome obstacles in all of life. Over 1 million people have played SuperBetter."
    >
      <div className="auth-content-container mt-5">
        {!registerUser ? (
          <>
            <h2 className="color-s content-heading mb-2">
              {step === null && "Welcome to SuperBetter"}
              {step === 1 && "Tell us about youself "}
              {step === 2 && "One more step"}
              <span className="bg-color-s ml-2"></span>
            </h2>

            <p className=" mb-5 fsc-16 fw-4">
              {step === null && "Create an account to start playing"}
              {step === 2 &&
                "Since you're under age 13 your parent, guardian or teacher will need to approve your account."}
            </p>

            <form
              onSubmit={
                step === 1 && disableEmail
                  ? registerformikSocialLogin.handleSubmit
                  : step === 1 && !disableEmail
                  ? registerformik.handleSubmit
                  : formik.handleSubmit
              }
            >
              {step === 1 && parent ? (
                <ParentRegister
                  formik={
                    step === 1 && disableEmail
                      ? registerformikSocialLogin
                      : registerformik
                  }
                  disableEmail={disableEmail}
                  email={search?.email ? search?.email : ""}
                  isMigratedUser={isMigratingUser}
                />
              ) : (
                step === 2 && (
                  <ChildRegister
                    guardianError={guardianError}
                    formik={formik}
                  />
                )
              )}

              <Container fluid={true} className="text-center mt-5 px-0 fs-2">
                {step === null ? (
                  <Container className="mb-5 px-0 d-flex justify-content-center flex-column">
                    {APP_CONFIG.AVAILABLE_OAUTHS_SIGNUP.map((v, i) =>
                      v.name === "facebook" ? (
                        <div key={i}>
                          <FacebookLogin
                            appId={APP_ID}
                            fields="name,email"
                            scope="public_profile,email"
                            callback={responseFacebook}
                            cssClass="w-100 border-0 bg-white p-0"
                            textButton={
                              <>
                                <div
                                  key={"social" + i}
                                  className="cursor social-icons-fb justify-content-start"
                                >
                                  <div className="d-flex justify-content-center align-items-center">
                                    <div className="d-flex tx-s">
                                      <img
                                        src={v.icon}
                                        alt=""
                                        className="me-2"
                                      />{" "}
                                    </div>{" "}
                                    <div className="ml-2 fsc-14 text-nowrap text-dark text-left">
                                      {v.text}
                                    </div>
                                  </div>
                                </div>
                              </>
                            }
                          />
                        </div>
                      ) : v.name === "google" ? (
                        <div
                          key={"social" + i}
                          onClick={() => loginGoogle()}
                          className="cursor social-icons justify-content-start"
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex tx-s">
                              <img src={v.icon} alt="" className="me-2" />{" "}
                            </div>{" "}
                            <div className="ml-2 fsc-14 text-nowrap text-dark text-left">
                              {v.text}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={"social" + i}
                          onClick={() => socialLoginType(v.name)}
                          className="cursor social-icons justify-content-start"
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex tx-s">
                              <img src={v.icon} alt="" className="me-2" />{" "}
                            </div>{" "}
                            <div className="ml-2 fsc-14 text-nowrap text-dark text-left">
                              {v.text}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </Container>
                ) : (
                  <Button
                    type="submit"
                    customizeClass="mb-4 login-bt"
                    label={
                      registerValues.isLoading
                        ? "Please wait..."
                        : step === 1
                        ? "Continue"
                        : "Request Approval"
                    }
                    customProps={{
                      disabled: registerValues.isLoading,
                    }}
                  />
                )}
                <p className={step === 2 ? "mt-4" : "fs-p-14 mt-3"}>
                  Already have an account?{" "}
                  <Link className="color-s" to={"../login"}>
                    Log in
                  </Link>
                </p>

                {step !== 2 && (
                  <p
                    className={
                      step === 1
                        ? "mb-5 pb-5 fs-p-11 "
                        : "mb-5 pb-5 fs-p-11 mt150"
                    }
                    style={{ right: 0, left: 0 }}
                  >
                    By continuing you agree to <br /> SuperBetter's{" "}
                    <a
                      className="color-s"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://superbetter.com/terms"
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      className="color-s"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://superbetter.com/privacy"
                    >
                      Privacy Policy
                    </a>
                  </p>
                )}
              </Container>
            </form>
          </>
        ) : (
          <EmailConfirmation
            parent={parent}
            emailID={emailID}
            token={emailVerificationToken}
          />
        )}
      </div>
    </SeoLayout>
  );
}
