import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../common/Tabs";
import Navbar from "../../common/Navbar";
import SeoLayout from "../../common/SeoLayout";

function Layout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const currentPathName =
    location.current.pathname.split("/").length === 3
      ? location.current.pathname.split("/")[2]
      : location.current.pathname.split("/")[3];

  const pathName = location.current.pathname.split("/")[2];

  const tabInfo = t("DASHBOARD.TAB_MENUS_TAB", { returnObjects: true });

  const UserName = localStorage.getItem("DETAIL_USER");
  const [show, setShow] = useState(false);
  const sideBar = useSelector((state) => state?.userProfile?.sideBar || false);

  useEffect(() => {
    if (
      localStorage.getItem("USER") === null ||
      !localStorage.getItem("USER") ||
      localStorage.getItem("ACCESS_TOKEN") === null ||
      !localStorage.getItem("ACCESS_TOKEN") ||
      JSON.parse(localStorage.getItem("USER"))?.is_email_verified === false
    ) {
      window.localStorage.clear();
      dispatch({ type: "USER_LOGOUT" });
      setTimeout(() => {
        navigate({ to: "/auth/login" });
      }, 1000);
    } else {
      setShow(true);
    }
  }, [dispatch, navigate]);

  return (
    <SeoLayout title="" robot>
      {show && (
        <>
          <Navbar
            showSearch={true}
            user={{
              avatar: "https://www.w3schools.com/howto/img_avatar.png",
              name: UserName, // TODO:static data
            }}
          />
          <Container className="discover-container" fluid={true}>
            {pathName === "dashboard" && (
              <div className="d-sm-flex d-md-flex  d-lg-flex d-xl-none">
                <Tabs
                  value={tabInfo}
                  // count={discoverCount}
                  sLab={true}
                  active={
                    currentPathName === "" || currentPathName === "dashboard"
                      ? t("DASHBOARD.DISCOVER")
                      : currentPathName === "secret-lab"
                      ? t("DASHBOARD.SECRET-LAB")
                      : currentPathName === "squad-play"
                      ? t("DASHBOARD.SQUAD-PALY")
                      : currentPathName === "solo-play" &&
                        t("DASHBOARD.SOLO-PALY")
                  }
                />
              </div>
            )}
            <Row className="h-ta">
              <Col
                className={
                  !sideBar
                    ? "d-sm-none d-md-none  d-lg-none d-xl-flex"
                    : "m-top"
                }
                xl={4}
                lg={12}
                md={12}
                sm={12}
              >
                {props.children[0]}
              </Col>
              <Col
                className={
                  sideBar && "d-sm-none d-md-none  d-lg-none d-xl-flex"
                }
                xl={8}
                lg={12}
                md={12}
                sm={12}
              >
                {props.children[1]}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </SeoLayout>
  );
}

export default Layout;
