import {useEffect } from "react";
import {useLocation} from "@tanstack/react-location";

export default function ScrollToTop() {
    const {current} = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [current]);

    return null;
}