import React, { useState } from "react";
import { Modal, Row, Image, Col } from "react-bootstrap";
import Button from "../Buttons";
import ModalCrossIcon from "../../assets/modalCross";
import { useFormik } from "formik";
import { promoCodeSchema } from "../../validation-schemas/auth-schema";
import InputBox from "../InputBox";
import { useRef } from "react";
import { useEffect } from "react";
import icon from "../../assets/promoCodeIcon.svg";

function PromoCodeModal({
  hideModal,
  showModal,
  handleConfirm,
  loading,
  error,
  promoCodeDetails,
  feedbackHandler,
}) {
  const [showPromoCodeFeedback, setShowPromoCodeFeedback] = useState(false);

  const initialValues = useRef({
    promoCode: "",
  });
  const onSubmit = (values) => {
    handleConfirm(values);
  };

  // Formik Form
  const formik = useFormik({
    initialValues: initialValues.current,
    validationSchema: promoCodeSchema,
    onSubmit,
  });

  useEffect(() => {
    if (error?.status_code === 400) {
      formik.setFieldError("promoCode", error?.error);
    }
  }, [error]);

  useEffect(() => {
    if (promoCodeDetails?.freeTrial) {
      setShowPromoCodeFeedback(true);
    }
  }, [promoCodeDetails?.freeTrial]);

  return (
    <Modal
      contentClassName="mx-auto "
      size="md"
      show={showModal}
      centered
      onHide={hideModal}
      backdrop="static"
    >
      {!showPromoCodeFeedback && (
        <div onClick={loading ? null : hideModal}>
          <ModalCrossIcon className="hero-cross pointer" />
        </div>
      )}
      <Modal.Body className="px-1 py-2">
        {showPromoCodeFeedback ? (
          <Row className="justify-content-md-center mb-3 mt-4 main-modal-section ">
            <Image className="mt-3" src={icon} width="86px" height="86px" />
            <Col sm={12} className="fsc-20 text-primary fw-6">
              Congratulations
            </Col>
            <Col
              sm={12}
              className="fsc-16 p-3 rounded mt-3  black-color fw-4"
              style={{ opacity: "0.9", background: "#f5f5f5" }}
            >
              You have received a {promoCodeDetails?.freeTrial} free Hero
              account trial.
            </Col>
            <Col
              sm={10}
              className="fsc-14  rounded my-3  black-color fw-4"
              style={{ opacity: "0.8" }}
            >
              To continue after your trial go to Manage Subscriptions in your
              Settings
            </Col>
            <Row className="justify-content-md-center mt-4 mb-3">
              <Button
                label={"Okay"}
                onClick={feedbackHandler}
                disabled={loading}
              />
            </Row>
          </Row>
        ) : (
          <Row className="justify-content-md-center mb-3 mt-4 main-modal-section ">
            <form onSubmit={formik.handleSubmit} className="dis-for">
              <Row>
                <Col sm={12}>
                  <InputBox
                    type="text"
                    name="promoCode"
                    containerClass="mt-1"
                    placeholder={"Enter Promo Code"}
                    formik={formik}
                    withoutLabel
                    values={formik.values.promoCode}
                    isError={
                      formik.errors.promoCode && formik.touched.promoCode
                    }
                    error={formik.errors.promoCode}
                  />
                </Col>
              </Row>
              <Row className="justify-content-md-center mt-4 mb-3">
                <Button
                  label={"Apply"}
                  customType={"submit"}
                  disabled={loading}
                />
              </Row>
            </form>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default PromoCodeModal;
