import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearch } from "@tanstack/react-location";
import {
  useAllyCheckedInMutation,
  useTrackChallengeMutation,
} from "../../../redux/api/challenge";
import moment from "moment";
import {
  useRefreshAllyMutation,
  useAllyNewActivityMutation,
} from "../../../redux/api/userProfile";
import Button from "../../../common/Buttons";
import AllyIcon from "../../../assets/ally.svg";
import allyRefresh from "../../../assets/allyRefresh.png";
import Hand from "../../../assets/hands.svg";
import Notes from "../../../assets/notes.svg";
import { prePath } from "../../../utils/helper";
import toast from "react-hot-toast";

function AllyDetails() {
  const navigate = useNavigate();
  const search = useSearch();

  const [trackChallenge] = useTrackChallengeMutation();
  const [triggerAllyCheckIn] = useAllyCheckedInMutation();
  const [triggerAllyNewActivity] = useAllyNewActivityMutation();
  const [question, setQuestion] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [values, setValues] = useState("");
  const [image, setImage] = useState("");
  const [triggerRefreshAlly] = useRefreshAllyMutation();

  const UserData = useSelector((state) => state.userChallence);
  const userInfo = useSelector((state) => state?.userProfile?.data);
  const ActivityDate = useSelector((state) => state?.userProfile?.activityDate);
  const { cardDetail = {}, secretIdentity, challengeDaysDetails } = UserData;

  const currentDate = moment().format("YYYY-MM-DD");
  const Adata =
    ActivityDate.filter((res) => res?.day === challengeDaysDetails?.order) ||
    [];

  useEffect(() => {
    trackChallenge(cardDetail).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      }
    });
  }, []);

  useEffect(() => {
    if (
      secretIdentity?.details?.profile_image !== null &&
      secretIdentity?.details?.profile_image
    ) {
      setImage(`${prePath}${secretIdentity?.details?.profile_image}`);
    }
  }, [secretIdentity]);

  const handleCheckIn = (comingData) => {
    const newData = {
      ...comingData,
      newDate: {
        date: search?.page === "solo-play" ? currentDate : Adata[0]?.date,
      },
    };
    triggerAllyCheckIn(newData).then((res) => {
      if (res.error) {
        toast.error(res.error.data.error);
      } else {
        const result = res?.data?.result;
        navigate({
          to: "/select/details/ally-feedBack",
          search: {
            ...result,
            page: search?.page,
            strength: cardDetail?.strength_to_build,
            challengeId: newData?.challenge_id,
          },
        });
      }
    });
  };

  useEffect(() => {
    setQuestion(cardDetail?.question);
  }, []);

  const checkedIn = () => {
    if (values) {
      const payload = {
        c_id: cardDetail?.challenge_id,
        data: {
          question: values?.question,
          type: values?.type,
          clone_from: values?.id,
          oldAlly: cardDetail?.id,
        },
      };
      triggerAllyNewActivity(payload).then((res) => {
        if (res.error) {
          toast.error(res.error.data.error);
        } else {
          handleCheckIn(res?.data?.result);
        }
      });
    } else {
      handleCheckIn(cardDetail);
    }
  };

  const handleRefresh = () => {
    setRefreshing(true);
    triggerRefreshAlly().then((res) => {
      if (res.error) {
        toast.error(res.error.data.error);
      } else {
        setQuestion(res?.data?.question);
        setValues(res?.data);
      }
      setRefreshing(false);
    });
  };

  return (
    <div className="ally-container  main bg-ally">
      <div className="top-corner-notes">
        <img
          className="mr-1 index cursor"
          onClick={() =>
            navigate({
              to: "/select/details/notes",
              search: {
                page: search?.page,
              },
            })
          }
          src={Notes}
          alt=""
        />
      </div>
      <div className="top-corner-button">
        <img className="mr-1 index" src={Hand} alt="" />
        <img className="mr-1 index" src={Hand} alt="" />
      </div>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col
            sm={3}
            className="ally-main-heading d-flex justify-content-center"
          >
            Ally Check-in
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="allyIcon">
            <img src={AllyIcon} alt="" />
          </Col>
        </Row>
        <Container className="middle-ally">
          <Row className="flex-column mb-4 ally-middle">
            <Col className="mx-auto ps-5" sm={7} lg={5}>
              <h3>
                <span
                  className="text-white detail_underline pt-1 fw-6 position-relative  mt-4 f30"
                  style={{ zIndex: 100 }}
                >
                  Check-in with an Ally
                </span>
              </h3>
            </Col>
            <Col
              className="mx-auto fsc-16 fw-4 text-white mt-3 ps-5"
              sm={7}
              lg={5}
            >
              Take a minute and discuss anything you choose.
            </Col>
          </Row>
          <Row className="top-inner justify-content-md-center">
            <Col sm={4} className="ally-heading ">
              Need a topic?
            </Col>
          </Row>
          {question && (
            <Row className="middle-inner justify-content-md-center ">
              <Col sm={6} className="ally-heading ">
                <h6 className="chats d-flex  align-items-center wh-pre-wrap position-relative">
                  {question}
                  {cardDetail?.created_by === "user" &&
                    cardDetail?.is_completed === false && (
                      <div
                        className="ally-refresh"
                        onClick={() => handleRefresh()}
                      >
                        <img
                          src={allyRefresh}
                          className={
                            refreshing
                              ? "ally-refresh-img rotating"
                              : "ally-refresh-img"
                          }
                          alt="button"
                        />
                      </div>
                    )}
                  {image ? (
                    <div className="avtar-ally">
                      <img className="squad-profile-pic" src={image} alt="" />
                    </div>
                  ) : (
                    <div className="avtar-ally">
                      <h3 className="squad-profile-pic text-capitalize without-img">
                        {userInfo?.first_name?.charAt(0)}
                      </h3>
                    </div>
                  )}
                </h6>
              </Col>
            </Row>
          )}
          {cardDetail?.is_completed === false && (
            <Row className="justify-content-md-center w-6 mx-auto">
              <Button
                label={"Checked-in"}
                onClick={() => checkedIn()}
                customizeClass="ally-activated pu-btn-m mt-2 pt-2 "
              />
            </Row>
          )}
          <Row className="justify-content-md-center text-dark ">
            <Col
              sm={1}
              className="lowerally text-black cursor"
              onClick={() =>
                navigate({
                  to: `/discover/dashboard/${search?.page}${
                    search?.page === "squad-play"
                      ? `/${cardDetail.challenge_id}`
                      : ""
                  }`,
                })
              }
            >
              {cardDetail?.is_completed === false ? "Later" : "Back"}
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default AllyDetails;
