import React, { memo } from "react";
import { useNavigate } from "@tanstack/react-location";
import BackArrow from "../../../assets/arrowleft";
import { Container, Row, Col } from "react-bootstrap";

const ContactUs = memo((props) => {
  const navigate = useNavigate();

  return (
    <Container className="content-container settings contact">
      <h5 className="title fw-6 mar-p">
      <a className="pointer" onClick={() => navigate({ to: "/discover/settings" })}>
          <BackArrow />
        </a>
        <span className="color-dark">Contact Us</span>
      </h5>
      <p className="fw-5 fs-3 top-contact">Alliances & Partnerships</p>
      <p className="mb-4">
        Are you a leader in an organization seeking to bring proven innovation
        to your industry?
      </p>
      <p className="title fw-5">Start a conversation:</p>
      <p className="contact-email fs-2">alliances@superbetter.com</p>
    </Container>
  );
});

export default ContactUs;
