import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { HowtoPlayData } from "../../auth/strip/config";
import BackArrow from "../../../assets/arrowleft";

function Rules() {
  const navigate = useNavigate();
  return (
    <div className="select-account-conatiner position-relative top-0 ml-4">
      <Container>
        <h5 className="title fw-6 mar-ps">
          <a
            className="pointer mr-1"
            onClick={() => navigate({ to: "/discover/dashboard" })}
          >
            <BackArrow />
          </a>
          How to Play
        </h5>
        {HowtoPlayData.map((res, i) => (
          <Row key={i} className="d-flex justify-content-center mt-2 px-1">
            <Col sm={3} className="card-Play">
              <img className="mr-5 how-pic" src={res[0].picture} alt="rules" />
              <div className="align-self-center ml-5  right-padding">
                <h4 className="text-dark fw-6 mb-3">{res[0].title}</h4>
                <p>{res[0].para}</p>
              </div>
            </Col>
            <Col sm={3} className="card-Play justify-content-between">
              <div className="align-self-center ml-5 right-padding">
                <h4 className="text-dark fw-6 mb-3">{res[1].title}</h4>
                <p>{res[1].para}</p>
              </div>
              <div className="d-flex justify-content-end">
                <img
                  className="ml-5 how-pic"
                  src={res[1].picture}
                  alt="rules"
                />
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
}

export default Rules;
