import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import ReactSlider from "react-slider";
import { useFormik } from "formik";
import InputBox from "../../../common/InputBox";
import Select from "../../../common/Select";
import Button from "../../../common/Buttons";
import uploadButton from "../../../assets/blueUpload.svg";
import Ally from "../../../assets/science-behind/ally.svg";
import { optionPowerUp, optionschedule } from "./config";
import { soloSchema } from "../../../validation-schemas/auth-schema";

function SoloAddAlly() {
  const hiddenFileInput = useRef(null);
  const [picture, setPicture] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [oneWeek, setOneWeek] = useState("");
  const [oneTimeWeek, setOneTimeWeek] = useState("");

  const initialValues = {
    title: "",
    description: "",
    powerSelect: "Emotional",
    schedule: "",
  };

  const onSubmit = (values) => {};
  const formik = useFormik({
    initialValues,
    validationSchema: soloSchema,
    onSubmit,
  });

  useEffect(() => {
    setPicture(Ally);
  }, []);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let imgString = URL.createObjectURL(img);
      setImgFile(img);
      setPicture(imgString);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="soloPlay form">
      <Container className="justify-content-md-center" sm={4}>
        <Row className="justify-content-md-center">
          <Col sm={3} className="heading">
            Add a Ally
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={3}>
            <div className="d-flex justify-content-center">
              <img className="pic" src={picture} alt="pic" />
              <div onClick={handleClick}>
                <img
                  src={uploadButton}
                  className="uploading"
                  alt="upload button"
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  onChange={(e) => onImageChange(e)}
                  accept="image/*"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={1} className="mt-4 ml-1">
            <h6 className="title fw-6">Add Photo</h6>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={1} className="ml-5">
            <p>optional</p>
          </Col>
        </Row>
        <form onSubmit={formik.handleSubmit}>
          <Row className="justify-content-md-center mb-1">
            <Col sm={6}>
              <InputBox
                label="Title"
                type="title"
                name="title"
                containerClass="mt-3"
                inputClass="input-title"
                lableSize={"md"}
                formik={formik}
                values={formik.values.title}
                isError={formik.errors.title && formik.touched.title}
                error={formik.errors.title}
              />
              <InputBox
                label="Description"
                type="description"
                name="description"
                containerClass="mt-3"
                inputClass="text-area-input"
                lableSize={"md"}
                formik={formik}
                values={formik.values.description}
                isError={
                  formik.errors.description && formik.touched.description
                }
                error={formik.errors.description}
                asText
              />

              <hr className="divider" />

              <div className="mt-3">
                <div className={"color-dark fw-6 lable14 mb-2"}>Schedule</div>
                <Select
                  name="schedule"
                  options={optionschedule}
                  formik={formik}
                  onChange={formik.handleChange}
                  value={formik.values.schedule}
                  isError={formik.errors.schedule && formik.touched.schedule}
                  error={formik.errors.schedule}
                />
              </div>
              <div className="mt-4">
                <Row>
                  <Col sm={6}>
                    <Form.Label className="color-dark fw-6 lable14 mb-2">
                      1 Time(s) a week
                    </Form.Label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      onAfterChange={(v) => setOneTimeWeek(v)}
                      value={oneTimeWeek}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label className="color-dark fw-6 lable14 mb-2">
                      For 1 week(s)
                    </Form.Label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      onAfterChange={(v) => setOneWeek(v)}
                      value={oneWeek}
                    />
                  </Col>
                </Row>
              </div>
              <hr className="divider" />
              <div className="mt-3">
                <div className={"color-dark fw-6 lable14 mb-2"}>
                  What strength does this build most?
                </div>
                <Select
                  name="powerSelect"
                  options={optionPowerUp}
                  formik={formik}
                  onChange={formik.handleChange}
                  value={formik.values.powerSelect}
                  isError={
                    formik.errors.powerSelect && formik.touched.powerSelect
                  }
                  error={formik.errors.powerSelect}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Button
              type="submit"
              label={"Create"}
              customizeClass="lower-button"
            />
          </Row>
        </form>
      </Container>
    </div>
  );
}

export default SoloAddAlly;
