import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate, useSearch } from "@tanstack/react-location";
import {
  useQuestCompletedMutation,
  useTrackChallengeMutation,
  useGetActivityDetailsMutation,
} from "../../../redux/api/challenge";
import Button from "../../../common/Buttons";
import QuestsIcon from "../../../assets/Quests.svg";
import Hand from "../../../assets/hands.svg";
import Brain from "../../../assets/Brain.svg";
import Heart from "../../../assets/heart.svg";
import Person from "../../../assets/phyRes.svg";
import Notes from "../../../assets/notes.svg";
import toast from "react-hot-toast";
import ScienceCardModal from "../../../common/Modal/scienceCardModal";

function QuestDetails() {
  const navigate = useNavigate();
  const search = useSearch();
  const [trackChallenge] = useTrackChallengeMutation();
  const [triggerQuestCompleted] = useQuestCompletedMutation();
  const [getActivityDetails] = useGetActivityDetailsMutation();
  const [activityDetail, setActivityDetail] = useState({});
  const [showModal, setShowModal] = useState(false);
  const UserData = useSelector((state) => state.userChallence);
  const ActivityDate = useSelector((state) => state?.userProfile?.activityDate);
  const { cardDetail = {}, challengeDaysDetails } = UserData;
  const [isScienceCardRead, setIsScienceCardRead] = useState(false);

  useEffect(() => {
    trackChallenge(cardDetail).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      }
    });
  }, []);

  const currentDate = moment().format("YYYY-MM-DD");
  const Adata =
    ActivityDate?.filter((res) => res?.day === challengeDaysDetails?.order) ||
    [];
  const questCompleted = () => {
    const newData = {
      ...cardDetail,
      newDate: {
        date: search?.page === "solo-play" ? currentDate : Adata[0]?.date,
      },
    };
    triggerQuestCompleted(newData).then((res) => {
      if (res.error) {
        toast.error(res.error.data.error);
      } else {
        const result = res?.data?.result;
        navigate({
          to: "/select/details/quest-feedback",
          search: {
            ...result,
            page: search?.page,
            strength: cardDetail?.strength_to_build,
            challengeId: newData?.challenge_id,
          },
        });
      }
    });
  };

  const fetchActivityDetails = useCallback(() => {
    getActivityDetails({
      challenge_id: cardDetail.challenge_id,
      activity_id: cardDetail.id,
    }).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.message);
      } else {
        setActivityDetail(res?.data);
      }
    });
  }, [cardDetail.challenge_id, cardDetail.id, getActivityDetails]);

  useEffect(() => {
    fetchActivityDetails();
  }, [fetchActivityDetails]);

  const handleModal = () => {
    setShowModal(false);
    setIsScienceCardRead(true);
  };
  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div className="bad-container main bg-q">
      <div className="top-corner-notes">
        <img
          className="mr-1 index cursor"
          onClick={() =>
            navigate({
              to: "/select/details/notes",
              search: {
                page: search?.page,
              },
            })
          }
          src={Notes}
          alt=""
        />
      </div>
      <div className="top-corner-button">
        <img
          className="index mr-1"
          src={Person}
          alt=""
          width="36px"
          height="36px"
        />
        <img
          className="index mr-1"
          src={Person}
          alt=""
          width="36px"
          height="36px"
        />

        {activityDetail?.strength_to_build === "physical" && (
          <>
            <img
              className="index mr-1"
              src={Person}
              alt=""
              width="36px mr-1 "
              height="36px"
            />
            <img
              className="index mr-1"
              src={Person}
              alt=""
              width="36px"
              height="36px"
            />
          </>
        )}
        {activityDetail?.strength_to_build === "mental" && (
          <>
            <img className="index mr-1 " src={Brain} alt="" />{" "}
            <img className="index mr-1 " src={Brain} alt="" />
          </>
        )}
        {activityDetail?.strength_to_build === "emotional" && (
          <>
            <img className="index mr-1 " src={Heart} alt="" />{" "}
            <img className="index mr-1 " src={Heart} alt="" />{" "}
          </>
        )}
        {activityDetail?.strength_to_build === "social" && (
          <>
            {" "}
            <img className="index mr-1 " src={Hand} alt="" />{" "}
            <img className="index mr-1 " src={Hand} alt="" />
          </>
        )}
      </div>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col sm={3} className="bad-main-heading text-center">
            Quest
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="badIcon">
            <img src={QuestsIcon} alt="" />
          </Col>
        </Row>
        <Container className="middle-activity-sec">
          <Row className="top-inner">
            <Col sm={12} className="bad-heading">
              {activityDetail?.title}
            </Col>
          </Row>
          <Row className="middle-inner">
            <Col className="fs-2.8 wh-pre-wrap  text-light analytics-text">
              {activityDetail?.description}
            </Col>
          </Row>
          {activityDetail?.science_card?.id && (
            <Row className="middle-inner">
              <Col className="fsc-16 fw-600    analytics-text">
                <span className="text-light">Science Card: </span>{" "}
                <span
                  className="text-decoration-underline color-p pointer"
                  onClick={openModal}
                >
                  {activityDetail?.science_card?.title}
                </span>
              </Col>
            </Row>
          )}
          {cardDetail?.is_completed === false && (
            <Row className="justify-content-md-center mt-4">
              <Button
                label={"Completed"}
                disabled={
                  activityDetail?.science_card?.id && !isScienceCardRead
                }
                onClick={() => questCompleted()}
                customizeClass="quest-completed pu-btn-m mt-2 w-4"
              />
            </Row>
          )}
          <Row className="justify-content-md-center text-dark  ">
            <Col
              sm={1}
              className="lowerbad cursor"
              onClick={() =>
                navigate({
                  to: `/discover/dashboard/${search?.page}${
                    search?.page === "squad-play"
                      ? `/${cardDetail.challenge_id}`
                      : ""
                  }`,
                })
              }
            >
              {cardDetail?.is_completed === false ? "Later" : "Back"}
            </Col>
          </Row>
        </Container>
      </Container>
      <ScienceCardModal
        showModal={showModal}
        hideModal={handleModal}
        modalDetails={activityDetail?.science_card}
      />
    </div>
  );
}

export default QuestDetails;
