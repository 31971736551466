import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../../../common/Buttons";
import Bulbs from "../../../assets/battleTips2.svg";
import tutorialIcon1 from "../../../assets/tutorialIcon1.svg";
import tutorialIcon2 from "../../../assets/tutorialIcon2.svg";
import tutorialIcon3 from "../../../assets/tutorialIcon3.svg";
import { useLocation, useNavigate } from "@tanstack/react-location";
import yellowArrow from "../../../assets/yellow-Arrow.svg";

const tutorialData = [
  {
    icon: tutorialIcon1,
    title: "Select a solo challenge from the library",
    feature: [
      "Choose from over 40 options",
      " The library is organized by resilience-type",
    ],
  },
  {
    icon: tutorialIcon2,
    title: "Create your own challenge",
    feature: [
      "Go for a personal epic win",
      "Add custom power-ups, bad guys & quests",
    ],
  },
  {
    icon: tutorialIcon3,
    title: "Join a squad challenge",
    feature: [
      "Join challenges when invited by a host",
      "Play a weekly challenge from SuperBetter",
    ],
  },
];

function Tutorial(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container
      className="center-container position-relative pb-5"
      style={{
        height: "80vh",
      }}
    >
      <h5 className=" mt-5 mb-4 fsc-20 fw-6" style={{ color: "#121212" }}>
        Get started
      </h5>
      <Row>
        <Col
          sm={8}
          md={6}
          lg={4}
          className="d-flex gap-2   px-3 py-4 mx-auto  justify-content-start align-items-start"
          style={{
            background: "#f5f5f5",
            borderRadius: "8px",
          }}
        >
          <div>
            <img src={Bulbs} alt="" />
          </div>
          <div>
            <div className="black-color mb-0 fsc-14 fw-4 text-start">
              <p className="black-color mb-0 fw-6">Pro Tip</p>
              Start with a solo challenge from the library. As you master
              SuperBetter, you can identify personal epic wins and create your
              own challenges.
            </div>
          </div>
        </Col>
      </Row>
      <Row className="flex-column justify-content-start">
        <Col
          sm={8}
          md={6}
          lg={4}
          className=" mx-auto text-start px-0 mt-4 fsc-20 fw-6 black-color "
        >
          Three ways to play
        </Col>
        {tutorialData.map((tutorial, i) => (
          <Col
            sm={8}
            md={6}
            lg={4}
            key={i}
            className="d-flex gap-2   px-0 mt-2 mx-auto  justify-content-start align-items-start"
          >
            <div>
              <img src={tutorial.icon} alt="" />
            </div>
            <div
              className="d-flex border-bottom mb-2 pb-4 flex-grow-1 flex-column justify-content-start"
              style={{ borderColor: "rgba(0, 0, 0, 0.04" }}
            >
              <div className="fsc-16 text-start fw-6 black-color">
                {tutorial.title}
              </div>
              {tutorial.feature.map((feature, i) => (
                <div
                  key={`feature-${i}`}
                  className="d-flex align-items-center justify-content-start text-light analytics-text inner-battle "
                >
                  <div className="me-auto">
                    <img
                      className={`bad-Arrow 
                `}
                      src={yellowArrow}
                      height={14}
                      width={14}
                      alt=""
                    />
                  </div>
                  <div
                    className={`fsc-14 flex-grow-1 ms-1  opacity-75 fw-4 text-dark  text-start inner-text-area px-0 `}
                  >
                    {feature}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        ))}
      </Row>
      <Row className="d-flex  w-100 justify-content-between">
        <Button
          label={"Back"}
          onClick={() => location.history.back()}
          customizeClass="back-button-1 position-static text-black border-0 fw-600 mt-2 mb-0"
          variant="primary"
        />
        <Button
          label={"Select a Challenge"}
          onClick={() => {
            navigate({ to: "/discover/dashboard", search: { back: "PgGoal" } });
            localStorage.removeItem("isFirstTimeUser");
          }}
          customizeClass="next-button fw-600 mt-2 mb-0"
          variant="primary"
        />
      </Row>
    </Container>
  );
}

export default Tutorial;
