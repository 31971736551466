import React from "react";
import Button from "../Buttons";
import moment from "moment";
import { dateToISOString, getUTCTimeStamp } from "../../utils/helper";

function ScheduleBtn({ data, getChallengesById, activityOrder }) {
  const currentDate = moment().unix();

  return (
    <div className="weekday-container d-flex g-2 align-items-center mt-3">
      <span className="ops"> Day:</span>
      <div className="ml-5 d-flex br-af">
        {data.map((v, i) => (
          <div className="me-3 right-border" key={"week-day-" + i}>
            <Button
              onClick={() => getChallengesById(v.date, v.day)}
              label={v.day}
              disabled={
                getUTCTimeStamp(dateToISOString(v?.date)) > currentDate ||
                data[i - 1]?.win_of_the_day_acquired === false
              }
              customizeClass={
                v.win_of_the_day_acquired
                  ? "round-btn schedule active text-uppercase"
                  : activityOrder === v.day
                  ? "round-btn schedule ac-bdr text-uppercase"
                  : "round-btn schedule text-uppercase"
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ScheduleBtn;
