import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Button from "../../../../common/Buttons";
import Social from "../../../../assets/big-hand.svg";
import Guard from "../../../../assets/screenGaurd.svg";
import Heart from "../../../../assets/heartLogo.svg";
import HeartGuard from "../../../../assets/heartGuard.svg";
import Brain from "../../../../assets/big-brain-up.svg";
import BrainGuard from "../../../../assets/brainGuard.svg";
import PhyStrength from "../../../../assets/phyRes.svg";
import PhyGuard from "../../../../assets/phyGuard.svg";
import { useNavigate, useSearch } from "@tanstack/react-location";

function PowerUpsFeedBack() {
  const navigate = useNavigate();
  const location = useSearch();

  const handleOnClick = () => {
    if (location?.challenge_completed) {
      navigate({ to: "/discover/weekly-feedBack" });
    } else if (location?.win_of_the_day_acquired) {
      navigate({
        to: "/discover/feedBack",
        search: {
          page: location?.page,
          challengeId: location?.challengeId,
        },
      });
    } else {
      navigate({
        to: `/discover/dashboard/${location?.page}/${location?.challengeId}`,
      });
    }
  };
  return (
    <div className="power-container feedback discover-container">
      <Container className="d-flex flex-column justify-content-center">
        <Row className="d-flex justify-content-center">
          <Col sm={3} className="feedbacks-heading mx-auto">
            Power-up
          </Col>
        </Row>
        <Row className="d-flex justify-content-center w-7 mx-auto">
          <Col
            sm={4}
            md={5}
            lg={3}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <Image src={Heart} width="140px" className="" />
            <Image
              src={HeartGuard}
              className="Gaurd"
              width="57px"
              height="68px"
            />
            <p className="feedback-No">
              {location?.strength === "emotional" ? "+2" : "+1"}
            </p>
            <Button
              size="sm"
              label={"Emotional Resilience"}
              customizeClass="heart-resilience mx-auto"
            />
          </Col>
          {location?.strength === "social" && (
            <Col
              sm={4}
              md={5}
              lg={3}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Image src={Social} width="140px" className="" />
              <Image src={Guard} className="Gaurd" width="57px" height="68px" />
              <p className="feedback-No">+1</p>
              <Button
                size="sm"
                label={"Social Resilience"}
                customizeClass="social-resilience mx-auto"
              />
            </Col>
          )}
          {location?.strength === "physical" && (
            <Col
              sm={4}
              md={5}
              lg={3}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Image src={PhyStrength} width="140px" className="" />
              <Image
                src={PhyGuard}
                className="Gaurd"
                width="57px"
                height="68px"
              />
              <p className="feedback-No">+1</p>
              <Button
                size="sm"
                label={"Physical Resilience"}
                customizeClass="physical-resilience mx-auto"
              />
            </Col>
          )}
          {location?.strength === "mental" && (
            <Col
              sm={4}
              md={5}
              lg={3}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Image src={Brain} width="140px" className="" />
              <Image
                src={BrainGuard}
                className="Gaurd"
                width="57px"
                height="68px"
              />
              <p className="feedback-No">+1</p>
              <Button
                size="sm"
                label={"Mental Resilience"}
                customizeClass="bad-resilience mx-auto"
              />
            </Col>
          )}
        </Row>
        <Row className="d-flex justify-content-center">
          <Col sm={5} md={4} lg={3} className="feed-back-text fsc-24 mx-auto ">
            You are building
            <br /> your resilience{" "}
          </Col>
        </Row>

        <Row className={`d-flex justify-content-center mt-5 pt-4`}>
          <Button
            label={"Continue"}
            onClick={() => handleOnClick()}
            customizeClass=" pu-btn-f mx-auto"
          />
        </Row>
      </Container>
    </div>
  );
}

export default PowerUpsFeedBack;
