import React from "react";
import { Card, Dropdown } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import playBtn from "../../assets/util-icons/p-bt.svg";
import plusIcon from "../../assets/powerPlus.svg";
import { useDeleteChallengeActivityMutation } from "../../redux/api/challenge";
import ActivityThreeDotIcon from "../../assets/activityThreeDotIcon.svg";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import DeleteImg from "../../assets/util-icons/delete.svg";

import CommonPop from "../Modal/commonModal";
import { useState } from "react";
import { toast } from "react-hot-toast";

function ActivityCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteActivity] = useDeleteChallengeActivityMutation();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handlePlay = (more) => {
    if (!more && !props?.soloPlay) {
      dispatch({
        type: "USER_CHALLENGE_DAYS",
        payload: {
          date: props?.plusButtonDate,
          order: props?.quest?.order || props?.powerUpsOrder,
        },
      });
    } else if (!more) {
      dispatch({
        type: "USER_CHALLENGE_DAYS",
        payload: {},
      });
    }

    if (more) {
      dispatch({
        type: "SELECTED_CARD_DETAIL",
        payload: {
          ...props?.quest,
          isLibraryActivity: true,
          challenge_id: props?.challengeids,
          accpted_ch: true,
        },
      });
      navigate({
        to: "/select/details/power-ups",
        search: {
          page: props?.soloPlay ? "solo-play" : "squad-play",
        },
      });
    } else {
      if (props?.quest?.type === "power_ups") {
        navigate({
          to: "/select/details/power-ups",
          search: {
            page: props?.soloPlay ? "solo-play" : "squad-play",
          },
        });
      } else if (props?.quest?.type === "bad_guy") {
        navigate({
          to: "/select/details/bad-guy",
          search: {
            page: props?.soloPlay ? "solo-play" : "squad-play",
            idC: props?.challengeids,
            ...props?.quest,
            order: "",
          },
        });
      } else if (props?.quest?.type === "quests") {
        navigate({
          to: "/select/details/quests",
          search: {
            page: props?.soloPlay ? "solo-play" : "squad-play",
          },
        });
      } else if (props?.quest?.type === "ally_check_in") {
        navigate({
          to: "/select/details/ally",
          search: {
            page: props?.soloPlay ? "solo-play" : "squad-play",
          },
        });
      }
      dispatch({
        type: "SELECTED_CARD_DETAIL",
        payload: props?.quest,
        accpted_ch: true,
      });
    }
  };

  const deleteHandler = (id) => () => {
    setShowModal(true);
  };

  const handleModal = () => {
    setDeleteLoading(true);
    const payload = {
      challenge_id: props.quest.challenge_id,
      activity_id: props.quest.id,
    };
    deleteActivity(payload).then((response) => {
      setDeleteLoading(false);
      if (response?.error) {
        toast.error(response?.error?.data?.error);
      } else {
        setShowModal(!showModal);
        props?.setActivityDeleteState(true);
      }
    });
  };

  return (
    <Card
      key={props?.quest?.id}
      className={`${props.containerClass} activity-card-container`}
    >
      <div
        style={
          props?.quest?.is_completed || props?.quest?.is_attempted_today
            ? { backgroundColor: "#D9D9D9" }
            : { backgroundColor: props.color }
        }
        className="opaque-mask"
      ></div>
      {props.title && <p className="Cardtitle fs-2 fw-5">{props?.title}</p>}

      <Card.Body
        className={`hover-container ${
          (props?.quest?.is_completed || props?.quest?.is_attempted_today) &&
          "pointer"
        }`}
        onClick={() =>
          props?.quest?.is_completed || props?.quest?.is_attempted_today
            ? handlePlay(props.more || false)
            : null
        }
      >
        {props.quest ? (
          <>
            {props?.quest?.is_completed || props?.quest?.is_attempted_today ? (
              ""
            ) : (
              <>
                <div
                  className="d-flex justify-content-end pointer"
                  onClick={() =>
                    props?.quest?.is_completed ||
                    props?.quest?.is_attempted_today
                      ? null
                      : handlePlay(props.more || false)
                  }
                >
                  <img src={playBtn} alt="" />
                </div>

                {((!props?.isCustomChallenge && !props.quest?.is_cloned) ||
                  (props?.isCustomChallenge && !props.quest?.is_cloned)) &&
                  props?.soloPlay &&
                  ["bad_guy", "power_ups", "quests"].includes(
                    props?.quest?.type
                  ) &&
                  props.quest?.created_by === "user" && (
                    <Dropdown className="filters-discover d-flex justify-content-end  p-0 m-0 ">
                      <Dropdown.Toggle className=" hover-icon  pointer m-0 p-0 bg-transparent mt-2 border-0">
                        <img src={ActivityThreeDotIcon} alt="" />
                      </Dropdown.Toggle>
                      <DropdownMenu align={"end"} className="mt-2 px-3 py-1">
                        <Dropdown.Item
                          className="p-2"
                          onClick={() => {
                            if (props.quest.type === "power_ups") {
                              navigate({
                                to: "/select/add/power-up-form",
                                search: {
                                  id: props?.quest?.challenge_id,
                                  activityId: props?.quest?.id,
                                  page: props?.soloPlay
                                    ? "solo-play"
                                    : "squad-play",
                                },
                              });
                            } else if (props.quest.type === "bad_guy") {
                              navigate({
                                to: "/select/add/bad-guy-form",
                                search: {
                                  id: props?.quest?.challenge_id,
                                  activityId: props?.quest?.id,
                                  page: props?.soloPlay
                                    ? "solo-play"
                                    : "squad-play",
                                },
                              });
                            } else if (props.quest.type === "quests") {
                              navigate({
                                to: "/select/add/quest-form",
                                search: {
                                  id: props?.quest?.challenge_id,
                                  activityId: props?.quest?.id,
                                  page: props?.soloPlay
                                    ? "solo-play"
                                    : "squad-play",
                                },
                              });
                            }
                          }}
                        >
                          <EditIcon />
                          <span className="ms-2 black-color fsc-16 fw-4">
                            Edit
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          className="p-2"
                          onClick={deleteHandler(props.quest.id)}
                        >
                          <DeleteIcon />
                          <span className="ms-2 black-color fsc-16 fw-4">
                            Delete
                          </span>
                        </Dropdown.Item>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                <CommonPop
                  showModal={showModal}
                  hideModal={() => setShowModal(false)}
                  handleConfirm={() => handleModal()}
                  icon={DeleteImg}
                  loading={deleteLoading}
                  title={"Are you sure you want to delete this activity?"}
                />
              </>
            )}
            {props?.ally ? (
              <p className="quest fw-6">Check-in</p>
            ) : (
              <p className="quest fw-6 text-truncate-2">
                {props?.quest?.title}
              </p>
            )}
          </>
        ) : (
          <>
            {
              <div onClick={props.onClick} className="plus-card-icon">
                <img width="50px" src={plusIcon} alt="plus" />
              </div>
            }
          </>
        )}
      </Card.Body>
      {props?.lButton && (
        <p className="ac-see pointer" onClick={props?.setAllActivity}>
          See All
        </p>
      )}
    </Card>
  );
}

export default ActivityCard;
