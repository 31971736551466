import React, { useRef } from "react";
import { Modal, Row, InputGroup, Form, Spinner } from "react-bootstrap";
import Button from "../Buttons";
import ModalCrossIcon from "../../assets/modalCross";
import uploadButton from "../../assets/camera.svg";

function AvatarModal(props) {
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <Modal
      size="md"
      show={props.showModal}
      centered
      onHide={() => {
        props.isSecretIdentity
          ? props.setMainIdentity(props.identityName)
          : props.setMainIdentity("");
        props.setShowModal(false);
      }}
      className="md-w"
    >
      <div
        className="pointer"
        onClick={() => {
          props.isSecretIdentity
            ? props.setMainIdentity(props.identityName)
            : props.setMainIdentity("");
          props.setShowModal(false);
        }}
      >
        <ModalCrossIcon className="modal-cross" />
      </div>
      <Modal.Body>
        <Row className="justify-content-md-center mb-4">
          <div>
            <h6 className="text-center text-dark fw-6 mt-2 mb-3 f18">
              Choose a secret identity that
              <br /> inspires you
            </h6>
          </div>
          <div className="d-flex justify-content-center">
            {props?.load ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : props.picture ? (
              <img
                className="modal-pic"
                width="70px"
                height="70px"
                src={props.picture}
                alt="pic"
              />
            ) : (
              <h3 className="squad-profile-pic without-img text-capitalize">
                {props?.identityName?.charAt(0)}
              </h3>
            )}
            {!props?.load && (
              <div className="modal-upload" onClick={handleClick}>
                <img src={uploadButton} alt="upload button" />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  onChange={(e) => props.onImageChange(e)}
                  accept="image/*"
                />
              </div>
            )}
          </div>
        </Row>
        <Row>
          <h6 className="text-dark fw-5 mb-2 f14 c-ss">My Secret Identity</h6>
          <InputGroup size="lg" className="modal-input">
            <Form.Control
              aria-label="Large"
              aria-describedby="inputGroup-sizing-sm"
              value={props.mainIdentity}
              placeholder="e.g.: Dragon Slayer"
              onChange={(e) => props.setMainIdentity(e.target.value)}
            />
          </InputGroup>
        </Row>
        <Row className="justify-content-md-center mt-4 mb-1">
          <Button
            label="Save"
            customizeClass="save-modal pu-btn-m f16 fw-6"
            disabled={props?.load}
            onClick={() => props.handleIdentitySave()}
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AvatarModal;
