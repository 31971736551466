import { useLocation, useNavigate } from "@tanstack/react-location";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "../../../common/Tabs";
import SimpleModal from "../../../common/Modal/SimpleModal";
import { useDispatch } from "react-redux";
import { useSubscriptionPlanMutation } from "../../../redux/api/subscription";
import YellowArrowImg from "../../../assets/yellow-Arrow.svg";
import { Image, Spinner } from "react-bootstrap";
import SeoLayout from "../../../common/SeoLayout";

function Layout(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("USER"));

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [triggerSubscriptionPlan] = useSubscriptionPlanMutation();
  const [subscriptionPlans, setSubscriptionPlans] = useState(null);

  const currentPathName =
    location.current.pathname.split("/").length === 3
      ? location.current.pathname.split("/")[2]
      : location.current.pathname.split("/")[3];

  const tabInfo = t("DASHBOARD.TAB_MENUS", { returnObjects: true });

  const getSubscriptionPlan = useCallback(() => {
    setLoading(true);
    triggerSubscriptionPlan().then((res) => {
      if (res.data.result.length > 0) {
        setSubscriptionPlans(
          res.data.result.filter((obj) => obj.key === "hero")[0]
        );
      }
      setLoading(false);
    });
  }, [triggerSubscriptionPlan]);

  useEffect(() => {
    getSubscriptionPlan();
  }, [getSubscriptionPlan]);

  if (userData === null) {
    navigate({ to: "/" });
  }

  useEffect(() => {
    if (
      localStorage.getItem("USER") === null ||
      !localStorage.getItem("USER") ||
      localStorage.getItem("ACCESS_TOKEN") === null ||
      !localStorage.getItem("ACCESS_TOKEN")
    ) {
      window.localStorage.clear();
      dispatch({ type: "USER_LOGOUT" });
      setTimeout(() => {
        navigate({ to: "/auth/login" });
      }, 1000);
    } else {
      setShow(true);
    }
  }, []);

  return (
    <SeoLayout title="" robot>
      {show && (
        <div>
          <SimpleModal
            show={isVisible}
            centered
            component={
              <div className="upgrade-plans">
                <h4>
                  Upgrade To Hero Account{" "}
                  {subscriptionPlans?.pricing || "25$/year"}
                </h4>
                <div className="mt-2">
                  {subscriptionPlans?.features.length > 0 && !loading
                    ? subscriptionPlans.features.map((item, i) => (
                        <div key={"feature-" + i} className="my-2">
                          <Image src={YellowArrowImg} className="me-3" />
                          {item}
                        </div>
                      ))
                    : subscriptionPlans?.features.length === 0 &&
                      !loading &&
                      "No feature found."}
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <Spinner animation="border" />
                    </div>
                  )}

                  <button
                    onClick={() =>
                      navigate({
                        to: "/select/selectAccount",
                        search: { show: true },
                      })
                    }
                  >
                    Upgrade
                  </button>
                </div>
              </div>
            }
            onHide={(e) => setIsVisible(e)}
          />
          <div className="d-sm-none d-md-none d-lg-none d-xl-flex">
            <Tabs
              value={tabInfo}
              // count={discoverCount}
              active={
                currentPathName === "" || currentPathName === "dashboard"
                  ? t("DASHBOARD.DISCOVER")
                  : currentPathName === "squad-play"
                  ? t("DASHBOARD.SQUAD-PALY")
                  : t("DASHBOARD.SOLO-PALY")
              }
            />
          </div>
          {props.children}
        </div>
      )}
    </SeoLayout>
  );
}

export default Layout;
