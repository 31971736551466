import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Row, Spinner } from "react-bootstrap";
import { useNavigate, useLocation, useSearch } from "@tanstack/react-location";
import Button from "../../../common/Buttons";
import Card from "../../../common/Cards";
import dummyImage from "../../../assets/skill-badges/thinking.png";
import epicWin from "../../../assets/invites/epic-win.svg";
import resilience from "../../../assets/invites/Res.svg";
import quest from "../../../assets/invites/quest.svg";
import bad from "../../../assets/invites/bad.svg";
import skillBadge from "../../../assets/invites/badgeInvitation.svg";

import {
  useUpdateUserChallengeMutation,
  useCreateChallengeMutation,
  useAddChallengePlayMutation,
} from "../../../redux/api/challenge";
import { toast } from "react-hot-toast";
import { prePath } from "../../../utils/helper";
import BackArrow from "../../../assets/arrowleft";

function AddChallenge() {
  const navigate = useNavigate();
  const [challengeDescription, setChallengeDescription] = useState(null);
  const [triggerChallenge] = useUpdateUserChallengeMutation();
  const [triggerCreateChallenge] = useCreateChallengeMutation();
  const [triggerAddChallengePlay] = useAddChallengePlayMutation();
  const location = useLocation();
  const search = useSearch();
  const id = +location.current.pathname.split("/")[3];
  const [loading, setLoading] = useState(false);

  const page = search?.page ? true : false;

  useEffect(() => {
    if (!!id) {
      setLoading(true);
      triggerChallenge(id).then((response) => {
        if (!response.error) {
          setChallengeDescription(response?.data);
        }
        setLoading(false);
      });
    }
  }, []);

  const payload = [
    {
      mTitle: "Epic Win:",
      result: challengeDescription?.epic_win,
      logo: epicWin,
    },
    ...(challengeDescription?.skill_badge
      ? [
          {
            mTitle: "Skill Badge:",
            result: challengeDescription?.skill_badge,
            logo: skillBadge,
          },
        ]
      : []),
    {
      mTitle: "Resilience:",
      result: challengeDescription?.resilience,
      logo: resilience,
    },
    {
      mTitle: "Quests:",
      result: challengeDescription?.activities?.quests?.length,
      logo: quest,
    },
    {
      mTitle: "Bad Guys:",
      result: challengeDescription?.activities?.bad_guy?.length,
      logo: bad,
    },
  ];

  const handleAccept = () => {
    const data = { id: challengeDescription.id };
    let innerData;
    if (search?.type === "power_ups") {
      innerData = {
        type: search?.type,
        title: search?.title,
        description: search?.description,
        clone_from: challengeDescription.id,
        strength_to_build: search?.strength_to_build,
      };
    }
    const payload = {
      c_id: search?.idC,
      data: innerData,
    };
    if (page) {
      triggerAddChallengePlay(payload).then((res) => {
        if (res.error) {
          toast.error(res.error.data.error);
        } else {
          navigate({ to: `/discover/dashboard/${search?.page}` });
        }
      });
    } else {
      triggerCreateChallenge(data).then((res) => {
        if (res.error) {
          toast.error(res.error.data.error);
        } else {
          navigate({
            to: `/discover/dashboard/solo-play/${res?.data?.result?.id}`,
          });
        }
      });
    }
  };

  return (
    <Container className="content-container invitations">
      {!search?.page && (
        <h5 className="title fw-6 ex-s mar-ps ">
          <div
            className="pointer mr-1 d-inline"
            onClick={() => navigate({ to: "/discover/dashboard" })}
          >
            <BackArrow />
          </div>
          Solo Play
        </h5>
      )}
      {!loading && challengeDescription !== null ? (
        <>
          <Card
            containerClass="mt-4 mb-4 w-8"
            titleClass="ps-0"
            title={
              <div className="d-flex">
                <div className="me-3">
                  <img
                    width="65px"
                    height="65px"
                    className="rounded-circle"
                    src={
                      !!challengeDescription?.file
                        ? prePath + challengeDescription?.file
                        : dummyImage
                    }
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center px-0">
                  {/* <p className="mb-2">{challengeDescription?.epic_win}</p> */}
                  <h6 className="title fw-6 f20 mt-1">
                    {challengeDescription?.title}
                  </h6>
                </div>
              </div>
            }
            customRightBtn=""
          />
          <p className="w-8 py-3">{challengeDescription?.description}</p>

          <ListGroup className="stats mb-5 w-7 cl-table">
            {payload.map((res, i) => (
              <ListGroup.Item
                key={i}
                className={
                  i + 1 !== payload.length ? "title lt-gr-it" : "title"
                }
              >
                <Row
                  className={
                    i + 1 === payload.length
                      ? "mx-2 d-flex align-items-center"
                      : "row-br mx-2 pb-3 d-flex align-items-center"
                  }
                >
                  <Col
                    className={
                      i === 0
                        ? "fw-6 title px-0 mt-1"
                        : i + 1 === payload.length
                        ? "fw-6 title px-0 mb-1"
                        : "fw-6 px-0 title"
                    }
                  >
                    <img src={res?.logo} alt="win" className="mr-2" />
                    {res?.mTitle}
                  </Col>
                  <Col
                    className={
                      i === 0 ? "mt-1" : i + 1 === payload.length && "mb-1"
                    }
                  >
                    <span className="text-capitalize">{res?.result}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>

          <div className="d-flex pt-4">
            <Button
              customizeClass="fw-6 lr-btn"
              label={!search?.page ? "Start Challenge" : "Add Activity"}
              onClick={handleAccept}
            />
            {/* <Button
              customizeClass="grey-btn ml-4 fw-6 grey-btn-cl dfl-bt"
              label="No Thanks"
              onClick={() => navigate({ to: "/discover/dashboard" })}
            /> */}
          </div>
        </>
      ) : (
        <>
          {loading && (
            <div className="d-flex justify-content-center align-items-center my-5 py-5">
              <Spinner animation="border"></Spinner>
            </div>
          )}
        </>
      )}
    </Container>
  );
}

export default AddChallenge;
