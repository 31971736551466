import bestListener from "../assets/skill-badges/best-listener.png";
import empathy from "../assets/skill-badges/empathy.png";
import adaptability from "../assets/skill-badges/adaptability.png";

import thinking from "../assets/skill-badges/thinking.png";

import ally from "../assets/science-behind/ally.svg";
import badGuy from "../assets/science-behind/bad-guy.svg";
import powerUp from "../assets/science-behind/power-up.svg";
import quest from "../assets/science-behind/quest.svg";

export const API_MOCKS = {
  BADGES: [
    {
      value: "Best Listener",
      image: bestListener,
    },

    {
      value: "Empathy",
      image: empathy,
    },
    {
      value: "Adaptability",
      image: adaptability,
    },
    {
      value: "Thinking",
      image: thinking,
    },
  ],

  DISCOVER_CARDS: [
    {
      title: "Ms. Smith Social Studies Class",
      type: "Rigid Thoughts Challenge",
    },
    {
      title: "Ms. Smith Social Studies Class",
      type: "Self Critic Challenge",
    },
    
  ],

  ACTIVITY: {
    POWER_UPS: {
      value: [
        "Lorem ipsu inte dolor sit amet consectetur",
        "Need to talk",
        ,
        "Need to talk",
        ,
      ],
      color: "#F6BF01",
      icon: powerUp,
    },

    BAD_GUY: {
      value: ["Lorem ipsu inte dolor sit amet consectetur", "Need to talk",],
      color: " #9D59AE ",
      icon: badGuy,
    },
    QUEST: {
      value: ["Lorem ipsu inte dolor sit amet consectetur", "Need to talk"],
      color: "#48C98E",
      icon: quest,
    },
    ALLY: {
      value: ["Lorem ipsu inte dolor sit amet consectetur", "Need to talk"],
      color: "#45A2E5",
      icon: ally,
    },
  },
};
