const RigthCaret = ({active=false}) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="14.875" cy="14.875" r="13.125" fill="#4A60AB" />
      <circle cx="13.125" cy="13.125" r="13.125" fill={active ? "white" :"#4A60AB"} />
      <path
        d="M10.875 17.625L15.0089 13.9205C15.4971 13.483 15.4971 12.767 15.0089 12.3295L10.875 8.625"
        stroke={active ? "#4A60AB":"white"}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RigthCaret;
