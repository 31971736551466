import React from "react";
import { Button as Btn } from "react-bootstrap";

function Button(props) {
  return (
    <>
      <Btn
        className={`${props.customizeClass} default-btn bg-color-p`}
        variant={props?.variant ? props?.variant : "primary"}
        type={
          props.customType
            ? props.customType
            : props.type !== undefined
            ? "submit"
            : props.type
        }
        disabled={props.disabled}
        onClick={props.onClick}
        {...props.customProps}
      >
        {props.label}
      </Btn>
    </>
  );
}

export default Button;
