import React, { useEffect, useState } from "react";
import { Container, Spinner, Col } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import Button from "../../../common/Buttons";
import ChallengeCard from "../../../common/select-challenges-card/outline-card";
import { useGetChallengeSelectMutation } from "../../../redux/api/userProfile";
import toast from "react-hot-toast";

const MoreChallenges = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [something, setSomething] = useState("");
  const [section, setSection] = useState("mental");
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getChallenges] = useGetChallengeSelectMutation();

  useEffect(() => {
    setLoading(true);
    getChallenges(section).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
      } else {
        const data = res?.data?.[section];
        setCurrentData(data);
      }
      setLoading(false);
    });
  }, [section]);

  const handleClick = (res) => {
    if (res.name === "Something Else") {
      setShowModal(!showModal);
      setSomething(res);
    } else {
      navigate({ to: "/discover/challenge/" + res.id });
      dispatch({ type: "SELECTED_INVITATION", payload: res });
    }
  };

  return (
    <Container className="center-container position-relative pb-5">
      <h5 className=" mt-5 mb-4 f26 fw-6" style={{ color: "#121212" }}>
        Challenges
      </h5>
      <div className="d-flex mt-4 mb-5">
        <Button
          customizeClass={
            section !== "mental"
              ? "mr-2  grey-btn pu-btn"
              : "mr-2 pu-btn pu-active-btn"
          }
          label="Mental"
          onClick={() => setSection("mental")}
        />
        <Button
          customizeClass={
            section !== "emotional"
              ? "mr-2  grey-btn pu-btn"
              : "mr-2 pu-btn pu-active-btn"
          }
          label="Emotional"
          onClick={() => setSection("emotional")}
        />
        <Button
          customizeClass={
            section !== "social"
              ? "mr-2  grey-btn pu-btn"
              : "mr-2 pu-btn pu-active-btn"
          }
          label="Social"
          onClick={() => setSection("social")}
        />
        <Button
          customizeClass={
            section !== "physical"
              ? "mr-2  grey-btn pu-btn"
              : "mr-2 pu-btn pu-active-btn"
          }
          label="Physical"
          onClick={() => setSection("physical")}
        />
      </div>
      {loading ? (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Col className="d-flex justify-content-start w-8 mt-3 mb-4 more-chl-container cursor">
            {currentData?.map((res, index) => (
              <div key={res.id} className="d-flex mt-2 mb-4">
                <ChallengeCard
                  containerClass="mr-2 mb-3"
                  name={res?.title}
                  icon={res?.file}
                  epic={res?.epic_win}
                  onClick={() => handleClick(res)}
                />
              </div>
            ))}
          </Col>

          {/* {currentData?.length > showNumber && (
            <Button
              onClick={() => setShowNumber(showNumber + 6)}
              customizeClass="mr-2 mt-3 mb-4 pu-btn-m"
              label="More"
            />
          )} */}
          <Button
            label={"Back"}
            onClick={() =>
              navigate({
                to: `/discover/dashboard`,
                search: { back: "PgGoal" },
              })
            }
            customizeClass=" back-button-1 fw-600"
            variant="light"
          />
        </>
      )}
    </Container>
  );
};

export default MoreChallenges;
