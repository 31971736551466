import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import AlertModal from "../../../common/Modal/alertModal";
import BackArrow from "../../../assets/arrowleft";
import "react-datepicker/dist/react-datepicker.css";
import InputBox from "../../../common/InputBox";
import Select from "../../../common/Select";
import Button from "../../../common/Buttons";
import { useDeleteAccountMutation } from "../../../redux/api/subscription";
import del from "../../../assets/settings-icons/delete-account.svg";
import {
  useGetProfileMutation,
  useUpdateProfileMutation,
} from "../../../redux/api/userProfile";
import { profileSchema } from "../../../validation-schemas/auth-schema";
import toast from "react-hot-toast";
import moment from "moment";
import { useCallback } from "react";
import { useRef } from "react";

function Profile() {
  const [profileData] = useGetProfileMutation();
  const [updateProfileData, updateProfileValue] = useUpdateProfileMutation();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [userType, setUserType] = useState("");
  const optionNewsletter = ["Subscribed", "Unsubscribed"];
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteAccount, deleteAccountValue] = useDeleteAccountMutation();
  const deleteTitle = "Are you sure you want to delete the account?";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = useRef({
    fullname: "",
    lastname: "",
    email: "",
    specialCode: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    newsletter_subscribed: "",
    birthdate: "",
  });

  // Get Profile data from BE and set in initials values
  const getProfileData = useCallback(() => {
    setLoading(true);
    profileData().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
        setLoading(false);
        return;
      }
      const data = response?.data;
      setUserData(data);
      initialValues.current.fullname = `${data?.details?.first_name}`;
      initialValues.current.lastname = data?.details?.last_name;
      initialValues.current.specialCode = !!data?.details?.special_code
        ? data?.details?.special_code
        : "";
      initialValues.current.timezone =
        !!data?.details?.timezone && data?.details?.timezone !== null
          ? data?.details?.timezone
          : Intl.DateTimeFormat().resolvedOptions().timeZone;
      initialValues.current.newsletter_subscribed =
        data?.details?.newsletter_subscribed !== null &&
        data?.details?.newsletter_subscribed
          ? "Subscribed"
          : "Unsubscribed";
      initialValues.current.email =
        data?.details?.email !== null && data?.details?.email;
      setUserType(data?.details?.user_type);
      setDate(new Date(data?.details?.dob));
      setLoading(false);
    });
  }, [profileData]);

  // Call getProfileDeatils
  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  // Submit the Form and update the data in BE
  const onSubmit = (values) => {
    const firstName = values.fullname;
    const lastName = values.lastname;
    const makingData = {
      first_name: firstName,
      last_name: lastName,
      dob: moment(values.birthdate).format("YYYY-MM-DD"),
      special_code: values.specialCode,
      newsletter_subscribed:
        values.newsletter_subscribed === "Subscribed" ? true : false,
      email: values.email,
      user_type: userType,
    };

    updateProfileData(makingData).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
      } else {
        const data = res?.data;
        dispatch({ type: "PROFILE_DATA", payload: data?.details });
        localStorage.setItem("USER", JSON.stringify(data.details));
        toast.success("Profile updated successfully.");
      }
    });
  };

  // Formik Form
  const formik = useFormik({
    initialValues: initialValues.current,
    validationSchema: profileSchema,
    onSubmit,
  });

  const handleModal = () => {
    deleteAccount().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.data?.error);
      } else {
        toast.success("Account deleted successfully.");
        window.localStorage.clear();
        navigate({ to: "/auth/login" });
        setTimeout(() => {
          dispatch({ type: "USER_LOGOUT" });
        }, 1000);
      }
    });
    setShowModal(!showModal);
  };

  useEffect(() => {
    formik.values.birthdate = date.toString();
  }, [date, formik.values]);

  return (
    <Container className="content-container settings profile ml-4">
      <h5 className="title fw-6 mar-p">
        <a
          className="pointer"
          onClick={() => navigate({ to: "/discover/settings" })}
        >
          <BackArrow />
        </a>
        Profile
      </h5>
      {loading && (
        <div className="d-flex justify-content-center align-items-center py-5">
          <Spinner animation="border"></Spinner>
        </div>
      )}
      {!loading && (
        <form onSubmit={formik.handleSubmit} className="dis-for">
          <Row>
            <Col sm={6}>
              <InputBox
                label="First Name*"
                type="fullname"
                name="fullname"
                containerClass="mt-3"
                inputClass="right-br"
                lableSize={"md"}
                formik={formik}
                values={formik.values.fullname}
                isError={formik.errors.fullname && formik.touched.fullname}
                error={formik.errors.fullname}
              />

              <InputBox
                label="Email"
                type="email"
                name="email"
                containerClass="mt-3 all-br"
                lableSize={"md"}
                formik={formik}
                disabled={formik.values.email}
                values={formik.values.email}
                isError={formik.errors.email && formik.touched.email}
                error={formik.errors.email}
              />
              <div className="mt-3">
                <div className={"color-dark fw-6 lable14 mb-2"}>Newsletter</div>
                <Select
                  name="newsletter_subscribed"
                  onChange={formik.handleChange}
                  options={optionNewsletter}
                  value={formik.values.newsletter_subscribed}
                  isError={
                    formik.errors.newsletter_subscribed &&
                    formik.touched.newsletter_subscribed
                  }
                  error={formik.errors.newsletter_subscribed}
                />
              </div>
            </Col>
            <Col sm={6}>
              <InputBox
                label="Last Name*"
                type="lastname"
                name="lastname"
                containerClass="mt-3"
                lableSize={"md"}
                inputClass="right-br"
                formik={formik}
                values={formik.values.lastname}
                isError={formik.errors.lastname && formik.touched.lastname}
                error={formik.errors.lastname}
              />

              <InputBox
                label={
                  <p className="d-flex mb-0 pb-0 color-dark">
                    Secret Code{" "}
                    <p className="mb-0 pb-0 ml-1"> (if you have one)</p>
                  </p>
                }
                type="specialCode"
                name="specialCode"
                inputClass="right-br"
                containerClass="mt-3 right-check"
                lableSize={"md"}
                placeholder="Not Specified"
                formik={formik}
                values={formik.values.specialCode}
                isError={
                  formik.errors.specialCode && formik.touched.specialCode
                }
                error={formik.errors.specialCode}
              />
            </Col>
          </Row>
          <Container fluid={true} className="mt-5 px-0">
            <Button
              type="submit dfl-bt"
              disabled={updateProfileValue.isLoading}
              label={"Save Changes"}
            />
            <Button
              customizeClass="profile-cancel pu-btn-m"
              onClick={() => navigate({ to: "/discover/settings" })}
              label={"Cancel"}
            />
            <p
              className="f16 mt-5 pointer"
              onClick={() => setShowModal(!showModal)}
            >
              <img src={del} className="mr-2 mb-1" alt="delete" />
              Delete Account
            </p>
          </Container>
        </form>
      )}
      <AlertModal
        delete={true}
        setShowModal={() => setShowModal(!showModal)}
        showModal={showModal}
        title={deleteTitle}
        buttonText={"Keep Account"}
        endPlanDate={""}
        handleCancelPlan={() => setShowModal(false)}
        handleDelete={() => handleModal()}
      />
    </Container>
  );
}

export default Profile;
