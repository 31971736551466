import { Link, useNavigate } from "@tanstack/react-location";
import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Container, Spinner } from "react-bootstrap";
import Button from "../../../common/Buttons";
import InputBox from "../../../common/InputBox";
import { APP_CONFIG, APP_ID, CLIENT_ID } from "../../../constants/app-config";
import { useFormik } from "formik";
import { loginSchema } from "../../../validation-schemas/auth-schema";
import {
  useLoginMutation,
  useSocialLoginMutation,
} from "../../../redux/api/auth";
// import { useGetProfileMutation } from "../../../redux/api/userProfile";
import { IosAndAndroidLinks, storeToken } from "../../../utils/helper";
import toast from "react-hot-toast";
import { useState } from "react";
import Verification from "./Verification";
import FacebookLogin from "react-facebook-login";
import SeoLayout from "../../../common/SeoLayout";

function Login() {
  const initialValues = {
    emailOrUsername: "",
    password: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailVerificationToken, setEmailVerificationToken] = useState("");

  useEffect(() => {
    if (
      localStorage.getItem("USER") !== null &&
      localStorage.getItem("USER") &&
      localStorage.getItem("ACCESS_TOKEN") !== null &&
      localStorage.getItem("ACCESS_TOKEN")
    ) {
      const user = JSON.parse(localStorage.getItem("USER"));
      if (user?.is_email_verified) {
        navigate({ to: "/discover/dashboard" });
      }
    }
  }, []);

  useEffect(() => {
    IosAndAndroidLinks();
  }, []);

  const [triggerLogin, loginValues] = useLoginMutation();
  const [triggerSocialLogin] = useSocialLoginMutation();
  const [verify, setVerify] = useState("");
  const [isChild, setIsChild] = useState(false);
  // const [profileData] = useGetProfileMutation();

  const verifyAccess = useCallback(
    (res) => {
      storeToken(res.data.details);
      setEmailVerificationToken(res.data.details.tokens.access.token);
      let email =
        res?.data?.details?.user?.guardian_email ||
        res?.data?.details?.user?.email;

      setIsChild(res?.data?.details?.user?.guardian_email ? true : false);
      // storing token and expiration to know when to call refresh token API
      if (res?.data?.details?.user?.is_email_verified) {
        dispatch({ type: "PROFILE_DATA", payload: res?.data?.details?.user });
        localStorage.setItem("USER", JSON.stringify(res.data.details));

        if (
          res?.data?.details?.user?.is_migrated_user &&
          !res?.data?.details?.user?.is_plan_viewed &&
          !res?.data?.details?.user?.first_name
        ) {
          navigate({
            to: "/auth/register",
            search: { migrated: true, email: res?.data?.details?.user?.email },
          });
        } else if (!res?.data?.details?.user?.is_plan_viewed) {
          localStorage.setItem("isFirstTimeUser", true);
          navigate({ to: "/select/selectAccount", search: { show: true } });
        } else {
          navigate({ to: "/discover/dashboard" });
        }
      } else {
        setVerify(email);
      }
    },
    [dispatch, navigate]
  );
  const onSubmit = (values) => {
    // triggering login after verification
    triggerLogin({
      email: values.emailOrUsername,
      password: values.password,
    })
      .then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.error);
        } else {
          verifyAccess(res);
        }
      })
      .catch((e) => toast.error(e?.message));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit,
  });

  const handleCredentialResponse = useCallback(
    (res) => {
      const data = {
        type: "google",
        device: "web",
        identityToken: res.credential,
      };
      triggerSocialLogin(data).then((res) => {
        if (res.error) {
          toast.error(res?.error?.data?.error);
        } else {
          verifyAccess(res);
        }
      });
    },
    [triggerSocialLogin, verifyAccess]
  );

  const responseFacebook = (res) => {
    const data = {
      type: "facebook",
      device: "web",
      identityToken: res.accessToken,
    };
    triggerSocialLogin(data).then((res) => {
      if (res.error) {
        toast.error(res?.error?.data?.error);
      } else {
        verifyAccess(res);
      }
    });
  };

  const googleSignIn = useCallback(() => {
    window?.google?.accounts?.id.initialize({
      client_id: CLIENT_ID,
      callback: handleCredentialResponse,
    });
    window?.google?.accounts?.id?.renderButton(
      document.getElementById("loginWithGoogle"),
      {
        theme: "outline",
        size: "large",
        type: "icon",
        shape: "pill",
        text: "signup_with",
        logo_alignment: "center",
      } // customization attributes
    );
  }, [handleCredentialResponse]);

  useEffect(() => {
    const googleInterval = setInterval(() => {
      if (window?.google !== undefined) {
        googleSignIn();
        clearInterval(googleInterval);
      }
    }, [10]);
  }, [handleCredentialResponse, googleSignIn]);

  return (
    <SeoLayout
      title="Player Account Login"
      keywords="player account login"
      descriptionContent="Are you ready to activate power-ups and battle bad guys in all of life? Login to play SuperBetter here."
    >
      <div className="auth-content-container">
        {verify !== "" ? (
          <Verification
            isChild={isChild}
            emailID={verify}
            token={emailVerificationToken}
          />
        ) : (
          <>
            <h2 className="color-s content-heading">
              Welcome Back <span className="bg-color-s ms-1"></span>
            </h2>
            <form onSubmit={formik.handleSubmit}>
              <p className="mb-5 fsc-16 fw-4">
                Log in to your SuperBetter Account
              </p>

              <InputBox
                label="Email"
                type="email"
                name="emailOrUsername"
                containerClass="mt-3 login-box"
                placeholder={APP_CONFIG.EMAIL_PLACEHOLDER_LOGIN}
                formik={formik}
                values={formik.values.emailOrUsername}
                isError={
                  formik.errors.emailOrUsername &&
                  formik.touched.emailOrUsername
                }
                error={formik.errors.emailOrUsername}
              />

              <InputBox
                label="Password"
                type="password"
                name="password"
                containerClass="mt-3"
                formik={formik}
                values={formik.values.password}
                isError={formik.errors.password && formik.touched.password}
                error={formik.errors.password}
                placeholder="**********"
              />

              <Container className="text-end fs-2 mt-2 mr-0">
                <Link to={"../forgot-password"} className="fo-pss">
                  Forgot Password?
                </Link>
              </Container>

              <Container fluid={true} className="text-center mt-5">
                <Button
                  customProps={{
                    disabled: loginValues.isLoading,
                  }}
                  customizeClass="login-bt pointer"
                  type="submit"
                  label={
                    loginValues.isLoading ? (
                      <Spinner animation="border pointer" />
                    ) : (
                      "Log in"
                    )
                  }
                />

                <p className="mb36 mt36 fs-2">Or, Log in with</p>

                <Container className="d-flex justify-content-center gap-2">
                  {APP_CONFIG.AVAILABLE_OAUTHS.map((v, i) =>
                    v.name === "facebook" ? (
                      <FacebookLogin
                        appId={APP_ID}
                        fields="email"
                        scope="email"
                        callback={responseFacebook}
                        cssClass="w-100 border-0 bg-white"
                        key={"social" + i}
                        textButton={
                          <>
                            <div
                              key={"social" + i}
                              className="cursor social-icons justify-content-center align-items-center"
                              style={{ width: "40px", height: "40px" }}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={v.icon}
                                    alt="facebook-icon"
                                    width="20px"
                                    height="20px"
                                  />{" "}
                                </div>{" "}
                                <div>{v.text}</div>
                              </div>
                            </div>
                          </>
                        }
                      />
                    ) : (
                      <div id="loginWithGoogle" key="google-sbtn"></div>
                    )
                  )}
                </Container>

                <p className="mt36 fs-2">
                  New to SuperBetter?{" "}
                  <Link className="color-s" to={"../register"}>
                    Sign up here
                  </Link>
                </p>
              </Container>
            </form>
          </>
        )}
      </div>
    </SeoLayout>
  );
}

export default Login;
