import React from "react";
import { Modal, Row,  } from "react-bootstrap";
import { useFormik } from "formik";
import Button from "../Buttons";
import ModalCrossIcon from "../../assets/modalCross";
import InputBox from "../InputBox";
import { notesSchema } from "../../validation-schemas/auth-schema";

function SomthingModal(props) {
  const initialValues = {
    notes: props.notesValue,
  };

  const onSubmit = (values) => {
    props.handleOnSubmit(values)
  };

  const formik = useFormik({
    initialValues,
    validationSchema: notesSchema,
    onSubmit,
  });

  return (
    <Modal
      size="md"
      show={props.showModal}
      centered
      onHide={() => props.setShowModal(false)}
      contentClassName="modal-width"
    >
      <form onSubmit={formik.handleSubmit}>
        <div onClick={() => props.setShowModal(false)}>
          <ModalCrossIcon className="hero-cross" />
        </div>
        <Modal.Body>
          <Row className="mb-3 pr-4 pl-4">
            <InputBox
              label={!!props.label ? props.label : "What would you like to work on first?"}
              type="notes"
              name="notes"
              containerClass="mt-5"
              placeholder="Type your goal area here"
              lableSize={"lg"}
              inputClass="p-3 mt-1"
              formik={formik}
              values={formik.values.notes}
              isError={formik.errors.notes && formik.touched.notes}
              error={formik.errors.notes}
            />
          </Row>
          <Row className="justify-content-md-center mb-4">
            <Button
              label="Continue"
              customizeClass="save-modal"
              onClick={() => props.handleCancelPlan()}
            />
          </Row>
        </Modal.Body>
      </form>
    </Modal>
  );
}

export default SomthingModal;
