import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const auth = createApi({
  reducerPath: "userData",
  baseQuery: baseQueryWithReAuth({ baseUrl: `${API_BASE_URL}user/auth` }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (body) => ({
        url: `/register`,
        method: "POST",
        body: body,
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    login: builder.mutation({
      query: (body) => ({
        url: `/login`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    logout: builder.mutation({
      query: (body) => ({
        url: `/logout`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),
    otpVerification: builder.mutation({
      query: (body) => ({
        url: `/passcode-email-verification`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    refreshToken: builder.mutation({
      query: (body) => ({
        url: `/refresh-token`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    socialLogin: builder.mutation({
      query: (body) => ({
        url: `/social-login`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `/forgot-password`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    resetPassword: builder.mutation({
      query: (body) => ({
        url: `/reset-password`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    checkUsername: builder.mutation({
      query: (body) => ({
        url: `/check-username`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    emailVerification: builder.mutation({
      query: (token) => ({
        url: `/email-verification/${token}`,
        method: "POST",
      }),
      transformResponse: (response) => response.data,
    }),

    resendEmailVerification: builder.mutation({
      query: (token) => ({
        url: `/resend-verification-link`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    verification: builder.mutation({
      query: (data) => ({
        url: `/email-verification/${data?.token}`,
        method: "POST",
        body: data?.payload,
      }),
      transformResponse: (response) => response.data,
    }),
    verifyResetToken: builder.mutation({
      query: (token) => ({
        url: `/verify-reset-token?token=${token}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),

    emailCheck: builder.mutation({
      query: (body) => ({
        url: `/check-email`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),

    googleAuth: builder.mutation({
      query: (body) => ({
        url: `/google-oauth`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useOtpVerificationMutation,
  useSocialLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useEmailVerificationMutation,
  useResendEmailVerificationMutation,
  useVerificationMutation,
  useVerifyResetTokenMutation,
  useEmailCheckMutation,
  useGoogleAuthMutation,
} = auth;
