import adaptability from "../assets/skill-badges/adaptability.png";
import thinking from "../assets/skill-badges/thinking.png";

export const DEFAULT_VALUES = {
  REGISTRATION_DAY: 1,
  REGISTRATION_MONTH: "Jan",
  REGISTRATION_YEAR: 1970,
  DISCOVER_TOGGLE_BTN: [
    {
      label: "Progress",
      value: "progress",
    },
    {
      label: "Goals",
      value: "goals",
    },
    {
      label: "Reflections",
      value: "reflections",
    },
  ],

  ANALYTICS_DISCOVER: [
    {
      label: "Emotional",
      color: "#CF4476",
    },
    {
      label: "Physical",
      color: "#F9A623",
    },
    {
      label: "Social",
      color: "#B9D435",
    },
    {
      label: "Mental",
      color: "#2844A3",
    },
  ],

  QUIZ: {
    ABOUT1: `Playing SuperBetter brings the strengths of game play to all of life. This quiz helps you learn about your gameful strengths.`,
    ABOUT2: `Take this quiz periodically (once a month is plenty) to track changes in your gameful strengths over time.`,
    SCORE_DETAILS: [
      "No way",
      // "Strongly Disagree",
      "Somewhat Disagree",
      "Neither Agree nor Disagree",
      "Somewhat Agree",
      "Heck Yeah !",
    ],
  },

  TAB_MENUS: [
    {
      value: "",
      label: "Discover",
    },
    {
      value: "squad-play",
      label: "Squad Play",
    },
  ],

  ACTIVE_SQUADS: [
    {
      squad_name: "Spanish Club Squad",
      review: "Absurdly Grateful",
      epic_win: "Better Sleep",
      color: "#F5AB38",
      image: adaptability,
    },
    {
      squad_name: "Portugal Club Squad",
      review: "Absurdly Grateful",
      epic_win: "Better Sleep",
      color: "#9D59AE",
      image: thinking,
    },
  ],

  ACTIVE_COLOR: [
    "#F5AB38",
    "#9D59AE",
    "#8FAD3A",
    "#C4C4C4",
    "#CF4476",
    "red",
    "#2844A3",
  ],

  REGISTER_INITIAL_VALUES: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    month: "",
    day: "",
    year: "",
    newsletter_subscribed: false,
  },
};
