import React from "react";
import { Row, Card as CardBootstrap, Col } from "react-bootstrap";
import moment from "moment";
import clock from "../../assets/r-clock.svg";
import calendar from "../../assets/r-calendar.svg";

function NCard(props) {
  return (
    <>
      <CardBootstrap
        onClick={props.onClick}
        style={props.style}
        className={`${props.containerClass} default-card-container bg-color-gray`}
      >
        <CardBootstrap.Body>
          <Row sm={10}>
            <Col className="fsc-16 wh-pre-wrap ">{props.title}</Col>
          </Row>
        </CardBootstrap.Body>
        <div className="d-flex justify-content-end ">
          <p className="mr-2 fsc-12 mt-2 mb-1">
            {" "}
            <img src={calendar} alt="Clock" className="mr-1 mb-1 r-Dt-c" />
            {props?.date && moment(props?.date).format("M/D/YYYY")}
          </p>
          <p className="mr-2 fsc-12 r-Dt-c mt-2 px-2 mb-1">
            <img src={clock} alt="Clock" className="mr-1 mb-1" />
            {props?.date && moment(props?.date).format("hh:mm A")}
          </p>
        </div>
      </CardBootstrap>
    </>
  );
}

export default NCard;
