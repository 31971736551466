import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate, useLocation, useMatch } from "@tanstack/react-location";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Card from "../../../../common/Cards";
import epicWinIcon from "../../../../assets/util-icons/epicWinIcon.svg";
import SPowerUp from "../../../../assets/sPowerUP";
import SBadGuy from "../../../../assets/sBadGuy";
import STimer from "../../../../assets/sTimer";
import SQuest from "../../../../assets/sQuest";
import challengeEndedIcon from "../../../../assets/challengeEndedIcon.svg";

import ActivityCard from "../../../../common/ActivityCard";
import ScheduleBtn from "../../../../common/ScheduleBtn";
import { API_MOCKS } from "../../../../constants/api-mocks";
import {
  dateToISOString,
  getUTCTimeStamp,
  prePath,
} from "../../../../utils/helper";
import {
  useLastSelectedChallengeMutation,
  useBadgeCountMutation,
  useActivityTrackMutation,
  useGetChallengeByIdMutation,
} from "../../../../redux/api/challenge";
import toast from "react-hot-toast";
import { getGreetingTime } from "../../../../utils/helper";
import { useCallback } from "react";
import CommonPop from "../../../../common/Modal/commonModal";

function SquadPlay() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [getting, setGetting] = useState(false);
  const UserData = useSelector((state) => state.userChallence);
  const userInfo = useSelector((state) => state?.userProfile?.data);
  const { userChallenge, secretIdentity, challengeDaysDetails } = UserData;
  const [userData, setUserData] = useState(null);
  const [badgeData, setBadgeData] = useState(null);
  const [showingBguy, setShowingBguy] = useState("");
  const [sName, setSName] = useState("");
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const [showingPup, setShowingPup] = useState("");
  const [activityOrder, setActivityOrder] = useState(null);
  const data = userData?.activities;
  const request = useRef(false);
  const triggerRequest = useRef(false);
  const [PlusButtonDate, setPlusButtonDate] = useState("");
  const [isActivityDeleted, setIsActivityDeleted] = useState(false);

  useEffect(() => {
    let p_up = [];
    const datas = async () => {
      await data?.power_ups?.forEach((res) => {
        if (p_up.length <= 2 && res?.is_completed) {
          p_up = [...p_up, res];
        }
      });
      await data?.power_ups?.forEach((res) => {
        if (p_up.length <= 2 && !res?.is_completed) {
          p_up = [res, ...p_up];
        }
      });
      setShowingPup(p_up);
    };
    datas();
  }, [data]);

  useEffect(() => {
    let b_up = [];
    const datas = async () => {
      await data?.bad_guy?.forEach((res) => {
        if (b_up.length <= 1 && !res?.is_completed) {
          b_up = [res];
        }
      });
      await data?.bad_guy?.forEach((res) => {
        if (b_up.length <= 1 && res?.is_completed) {
          b_up = [res];
        }
      });
      setShowingBguy(b_up);
    };
    datas();
  }, [data]);

  const [triggerLastSelected, UpdateLastSelected] =
    useLastSelectedChallengeMutation();
  const [triggerGetChallengeById] = useGetChallengeByIdMutation();
  const [triggerBadgeCount, badgeCountFun] = useBadgeCountMutation();
  const [triggerActivityTrack] = useActivityTrackMutation();
  const [activityTrackData, setActivityTrackData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [hasActiveChallenge, setHasActiveChallenge] = useState(false);
  const currentPathName = location.current.pathname.split("/")[3];
  const solo = currentPathName !== "squad-play" ? true : false;

  const { params } = useMatch();
  const challengeId = params?.id || null;

  const closeModal = () => {
    setShowModal(false);
  };

  const filterOrder = activityTrackData?.filter((res, i) => {
    if (
      res?.win_of_the_day_acquired === false &&
      getUTCTimeStamp(dateToISOString(res?.date)) <= moment().unix()
    ) {
      return true;
    } else if (
      res?.win_of_the_day_acquired === true &&
      res?.date === moment().format("YYYY-MM-DD")
    ) {
      return true;
    }
    return false;
  });

  const activityDeleteHandler = (value) => {
    setIsActivityDeleted(value);
  };

  useEffect(() => {
    secretIdentity?.details?.secret_identity !== null &&
      secretIdentity?.details?.secret_identity &&
      setSName(secretIdentity?.details?.secret_identity);
    secretIdentity?.details?.profile_image !== null &&
      secretIdentity?.details?.profile_image &&
      setImage(`${prePath}${secretIdentity?.details?.profile_image}`);
  }, [secretIdentity]);

  useEffect(() => {
    setGetting(true);
    setTimeout(() => {
      setGetting(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (count === 0 && userData && activityTrackData) {
      setCount(1);
      getLastChallenges(activityTrackData);
    }
  }, [activityTrackData]);

  const getBadgeCount = useCallback(
    (id, date) => {
      if (!!id) {
        const payload = {
          id: id,
          date: {
            date: date,
          },
        };
        triggerBadgeCount(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.message);
            return;
          }
          setBadgeData(response?.data?.result);
        });
      }
    },
    [triggerBadgeCount]
  );

  const getActivityTrack = useCallback(
    (id) => {
      const data = {
        challenge_id: id,
      };

      if (id !== undefined) {
        triggerActivityTrack(data).then((res) => {
          if (res?.error) {
            toast.error(res?.error?.message);
            return;
          } else {
            setActivityTrackData(res.data);
            dispatch({ type: "ACTIVITY_CHALLENGE_DATA", payload: res.data });
          }
        });
      }
    },
    [triggerActivityTrack]
  );

  const getChallengesById = useCallback(
    async (date, order) => {
      setLoading(true);
      setUserData(null);
      setPlusButtonDate(
        date ||
          challengeDaysDetails?.date ||
          filterOrder[0]?.date ||
          activityTrackData[0]?.date ||
          moment().format("YYYY-MM-DD")
      );
      setActivityOrder(
        order ||
          Number(challengeDaysDetails?.order) ||
          filterOrder[0]?.day ||
          activityTrackData[0]?.day
      );
      triggerRequest.current = true;
      const data = {
        id: challengeId || userData?.id,
        solo: solo,
        date:
          date ||
          challengeDaysDetails?.date ||
          filterOrder[0]?.date ||
          activityTrackData[0]?.date ||
          moment().format("YYYY-MM-DD"),
        order:
          order ||
          Number(challengeDaysDetails?.order) ||
          filterOrder[0]?.day ||
          activityTrackData[0]?.day,
      };
      triggerGetChallengeById(data)
        .then((res) => {
          if (res?.error) {
            toast.error(res?.error?.data?.error);
            setLoading(false);
          } else {
            getBadgeCount(
              res.data?.id,
              date ||
                challengeDaysDetails?.date ||
                filterOrder[0]?.date ||
                activityTrackData[0]?.date
            );

            setUserData(res.data);
            localStorage.setItem(
              "feedbackData",
              JSON.stringify({
                epicWin: res.data.epic_win,
                skillBadge: res.data.skill_badge,
              })
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [challengeId, userData?.id, solo, filterOrder, triggerGetChallengeById]
  );

  const getLastChallenges = useCallback(
    async (track) => {
      const filterOrder = await track?.filter((res, i) => {
        if (
          res?.win_of_the_day_acquired === false &&
          getUTCTimeStamp(dateToISOString(res?.date)) <= moment().unix()
        ) {
          return true;
        } else if (
          res?.win_of_the_day_acquired === true &&
          res?.date === moment().format("YYYY-MM-DD")
        ) {
          return true;
        }
        return false;
      });

      request.current = true;

      setPlusButtonDate(
        track
          ? filterOrder?.length > 0
            ? filterOrder[0]?.date
            : track[0]?.date
          : moment().format("YYYY-MM-DD")
      );
      setActivityOrder(
        track ? (filterOrder.length > 0 ? filterOrder[0]?.day : 1) : 1
      );
      /** Last Challenge API */
      const payload = {
        isSolo: solo,
        order: track ? (filterOrder.length > 0 ? filterOrder[0]?.day : 1) : 1,
      };
      if (!userChallenge) {
        triggerLastSelected(payload)
          .then((response) => {
            if (response?.error) {
              if (response.error.data?.data?.isChallengeEnded) {
                setHasActiveChallenge(
                  response.error.data?.data?.hasActiveChallenge ? true : false
                );
                setShowModal(true);
              }

              return;
            } else {
              getActivityTrack(response.data?.id);
              if (track?.length)
                getBadgeCount(
                  response.data?.id,
                  track
                    ? filterOrder.length > 0
                      ? filterOrder[0]?.date
                      : track[0]?.date
                    : moment().format("YYYY-MM-DD")
                );
              setUserData(response.data);
              localStorage.setItem(
                "feedbackData",
                JSON.stringify({
                  epicWin: response.data.epic_win,
                  skillBadge: response.data.skill_badge,
                })
              );
            }
          })
          .catch((error) => {
            toast.error(error?.message);
          });
      } else {
        setUserData(userChallenge);
      }
    },
    [solo, triggerLastSelected, getActivityTrack, userChallenge, getBadgeCount]
  );

  useEffect(() => {
    if (challengeId === null && activityTrackData?.length === 0) {
      getLastChallenges(activityTrackData);
    } else if (activityTrackData?.length > 0 && challengeId !== null) {
      getChallengesById();
    }
  }, [activityTrackData, challengeId]);

  useEffect(() => {
    if (isActivityDeleted) {
      if (!challengeId) {
        getLastChallenges(activityTrackData);
      } else {
        getChallengesById();
      }
      setIsActivityDeleted(false);
    }
  }, [
    activityTrackData,
    challengeId,
    getChallengesById,
    getLastChallenges,
    isActivityDeleted,
  ]);

  const handleConfirm = () => {
    navigate({
      to: "/discover/dashboard",
      search: { back: hasActiveChallenge ? "" : "PgGoal" },
    });
  };

  useEffect(() => {
    if (challengeId !== null && !triggerRequest.current) {
      getActivityTrack(challengeId);
    }
  }, [getActivityTrack, challengeId]);

  return (
    <Container className="content-container squad-play px-0 mb-2">
      <CommonPop
        showModal={showModal}
        hideModal={closeModal}
        handleConfirm={handleConfirm}
        icon={challengeEndedIcon}
        buttonText="Play a different challenge"
        title={"This challenge has ended"}
        isTxtHide={true}
        submitClassName="w-auto px-4 mt-2"
        titleClassName="w-5 mt-4"
        isCloseButtonHide={true}
      />

      <div className="b-fn mt-3 fsc-16 w-8 fw-6" style={{ color: "#f5c10e" }}>
        {userData?.squad.name}
      </div>
      {userData?.title && (
        <div className="b-fn mt-1 fsc-20 w-8 fw-6">{userData?.title}</div>
      )}
      <Card
        containerClass="mt-0 medal-card px-0 w-8"
        titleClass="px-0"
        title={
          <>
            <p className="fs-2 mb-2 text-capitalize">
              {getGreetingTime(moment())},
            </p>
            <h4 className="title ">
              {sName
                ? sName
                : `${userInfo?.first_name} ${
                    userInfo?.last_name ? userInfo.last_name : ""
                  }`}
            </h4>
          </>
        }
        customRightBtn={
          <Row>
            <Col sm={3}>
              {image ? (
                <img className="squad-profile-pic" src={image} alt="" />
              ) : (
                <h3 className="squad-profile-pic without-img text-uppercase">
                  {sName ? sName?.charAt(0) : userInfo?.first_name?.charAt(0)}{" "}
                </h3>
              )}
            </Col>
            <Col sm={7}>
              <h6 className=" title">Win the Day</h6>
              <div className="d-flex medal-container">
                <SPowerUp
                  active={
                    badgeData === null ||
                    badgeData?.power_ups_completed.length < 1
                      ? false
                      : true
                  }
                />
                <SPowerUp
                  active={
                    badgeData === null ||
                    badgeData?.power_ups_completed.length < 2
                      ? false
                      : true
                  }
                />
                <SPowerUp
                  active={
                    badgeData === null ||
                    badgeData?.power_ups_completed.length < 3
                      ? false
                      : true
                  }
                />
                <SBadGuy active={badgeData?.is_bad_guy_completed === true} />
                <SQuest active={badgeData?.is_quest_completed === true} />
                <STimer active={badgeData?.is_ally_completed === true} />
              </div>
            </Col>
          </Row>
        }
      />
      {getting || loading ? (
        <div className="loading d-flex align-items-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : !data ||
        (data?.power_ups.length <= 0 &&
          data?.bad_guy.length <= 0 &&
          data?.quests.length <= 0 &&
          !loading) ? (
        <div className="no_invitation w-8">
          <div className="d-flex flex-column text-center">
            <h6 className="title lh-1 fw-6 mb-2">
              Accept an invitation to play a Challenge
            </h6>
            <p
              className="see-invitaion pointer"
              onClick={() =>
                navigate({
                  to: "/discover/dashboard",
                  search: { back: "PgGoal" },
                })
              }
            >
              See invitations
            </p>
          </div>
        </div>
      ) : data && !loading ? (
        <>
          <div className="d-flex align-items-start w-8 pb-3 mb-4 mt-4 border-bottom-1">
            <div>
              <img src={epicWinIcon} alt="" style={{ width: "28px" }} />
            </div>
            <div className=" d-flex justify-content-center align-items-start fs-14  ms-2">
              <span className="fw-6 b-fn text-nowrap"> Epic Win: </span>
              <span className="ms-2 fw-6 b-fn ">{userData?.epic_win}</span>
            </div>
          </div>
          <Row>
            <div className="d-flex wrap mt-5">
              {showingPup?.map((v, i) =>
                i <= 2 ? (
                  <ActivityCard
                    key={"power-ups-" + v.id}
                    containerClass="act-mr30 mb-4"
                    plusButtonDate={PlusButtonDate}
                    quest={{ ...v, order: activityOrder }}
                    color={API_MOCKS.ACTIVITY.POWER_UPS.color}
                    title={
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ width: "190px" }}
                        >
                          {" "}
                          {i === 0 && (
                            <div className="d-flex">
                              <div>
                                <SPowerUp active />
                              </div>
                              <div className="ml-3 mt-1 b-fn">Power-ups</div>
                            </div>
                          )}
                        </div>
                      </>
                    }
                    total={data?.power_ups?.length}
                    completed={
                      data?.power_ups.length > 0
                        ? data?.power_ups.filter(
                            (obj) => obj.is_completed === true
                          ).length
                        : 0
                    }
                    soloPlay={solo}
                    index={i}
                  />
                ) : (
                  ""
                )
              )}
              <ActivityCard
                containerClass="act-mr30 mb-4"
                onClick={() => {
                  navigate({
                    to: "/select/add-power-ups",
                    search: {
                      IdChl: userData?.id,
                      page: "squad-play",
                    },
                  });
                  dispatch({
                    type: "USER_CHALLENGE_DAYS",
                    payload: {
                      date: PlusButtonDate,
                      order: activityOrder,
                    },
                  });
                }}
                color={API_MOCKS.ACTIVITY.POWER_UPS.color}
                title={
                  data?.power_ups?.filter((obj) => obj.is_completed).length <=
                    0 &&
                  data?.power_ups?.filter((obj) => obj.is_completed === false)
                    .length <= 0 ? (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ width: "190px" }}
                    >
                      <div className="d-flex">
                        <div>
                          <SPowerUp active />
                        </div>
                        <div className="ml-3 mt-1 b-fn">Power-ups</div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />
            </div>
            <div className="d-contents">
              {showingBguy?.map((v, i) =>
                i === 0 ? (
                  showingBguy?.length &&
                  v?.is_completed === true &&
                  (getUTCTimeStamp(v?.completed_at) <
                    getUTCTimeStamp(
                      dateToISOString(
                        activityTrackData[activityOrder - 1]?.date
                      )
                    ) ||
                    badgeData?.is_bad_guy_completed === false) ? (
                    <>
                      <ActivityCard
                        containerClass="act-mr30 mb-4 ml-3 mb-4 mt-5 mr-4"
                        onClick={() => {
                          navigate({
                            to: "/select/add/bad-guy-form",
                            search: {
                              id: userData?.id,
                              page: "squad-play",
                            },
                          });
                          dispatch({
                            type: "USER_CHALLENGE_DAYS",
                            payload: {
                              date: PlusButtonDate,
                              order: activityOrder,
                            },
                          });
                        }}
                        color={API_MOCKS.ACTIVITY.BAD_GUY.color}
                        title={
                          <>
                            {i === 0 && (
                              <div className="d-flex">
                                <div>
                                  <SBadGuy active />
                                </div>
                                <div className="ml-3 mt-1 b-fn">BAD GUYS</div>
                              </div>
                            )}
                          </>
                        }
                      />
                    </>
                  ) : (
                    <ActivityCard
                      key={"bads-guys-" + v.id}
                      containerClass="ml-3 mb-4 mt-5 mr-4"
                      quest={{ ...v, order: activityOrder }}
                      plusButtonDate={PlusButtonDate}
                      color={API_MOCKS.ACTIVITY.BAD_GUY.color}
                      setActivityDeleteState={activityDeleteHandler}
                      title={
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            {i === 0 && (
                              <div className="d-flex">
                                <div>
                                  <SBadGuy active />
                                </div>
                                <div className="ml-3 mt-1 b-fn">BAD GUYS</div>
                              </div>
                            )}
                          </div>
                        </>
                      }
                      total={data?.bad_guy?.length}
                      completed={
                        data?.bad_guy.length > 0
                          ? data?.bad_guy.filter(
                              (obj) => obj.is_completed === true
                            ).length
                          : 0
                      }
                      noShow={i === 0 ? true : false}
                      soloPlay={solo}
                      handleAdd={() =>
                        navigate({
                          to: "/select/add/bad-guy-form",
                          search: { id: userData?.id },
                        })
                      }
                      index={i}
                    />
                  )
                ) : (
                  ""
                )
              )}
            </div>

            <div className="d-contents ">
              {data?.quests?.map((v, i) =>
                i === 0 ? (
                  <ActivityCard
                    key={"quest-" + v.id}
                    containerClass="ml-3 mb-4 mt-5 mr-4 act-bg"
                    plusButtonDate={PlusButtonDate}
                    quest={{ ...v, order: activityOrder }}
                    color={API_MOCKS.ACTIVITY.QUEST.color}
                    title={
                      i === 0 ? (
                        <div className="d-flex">
                          <div>
                            <SQuest active />
                          </div>
                          <div className="ml-3 mt-1 b-fn">Quest</div>
                        </div>
                      ) : (
                        ""
                      )
                    }
                    total={data?.quests?.length}
                    completed={
                      data?.quests.length > 0
                        ? data?.quests.filter(
                            (obj) => obj.is_completed === true
                          ).length
                        : 0
                    }
                    noShow={i === 0 ? true : false}
                    index={i}
                  />
                ) : (
                  ""
                )
              )}
            </div>

            <div className="d-contents ">
              {data?.ally_check_in?.map((v, i) =>
                i === 0 ? (
                  <ActivityCard
                    key={"activity-card-" + i}
                    containerClass="ml-3 mb-4 mt-5 mr-4 act-bg"
                    ally={true}
                    plusButtonDate={PlusButtonDate}
                    quest={{ ...v, order: activityOrder }}
                    color={API_MOCKS.ACTIVITY.ALLY.color}
                    title={
                      i === 0 ? (
                        <div className="d-flex">
                          <div>
                            <STimer active />
                          </div>
                          <div className="ml-3 mt-1 b-fn">Ally</div>
                        </div>
                      ) : (
                        ""
                      )
                    }
                    total={data?.ally_check_in?.length}
                    completed={
                      data?.ally_check_in.length > 0
                        ? data?.ally_check_in.filter(
                            (obj) => obj.is_completed === true
                          ).length
                        : 0
                    }
                    noShow={i === 0 ? true : false}
                    index={i}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </Row>

          <ScheduleBtn
            data={activityTrackData}
            getChallengesById={getChallengesById}
            activityOrder={activityOrder}
          />
        </>
      ) : (
        loading && (
          <div className="loading">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )
      )}
    </Container>
  );
}

export default SquadPlay;
