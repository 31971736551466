import React from "react";
import { PieChart, Pie, Cell } from "recharts";

export function DonutChartContainer(props) {

  const data02 = props?.data?.map((res,i)=>{
    return {
      name: props?.labels[i],
      value: res
    }
  });

  const COLORS = props?.colorTheme;
  const COLORS1 = ["#f5dae4", "#feedd3", "#d4daec", "#f1f6d7"];

  const renderCustomizedLabel = ({
    x,
    y,
    value,
    fill,
  }) => {
    return (
      <text x={x} y={y} fill={fill} fontSize={14} textAnchor="middle">
        {`${value.toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="donutchart-container d-flex justify-content-center align-items-center flex-column">
      <PieChart width={300} height={260}>
        <Pie
          data={data02}
          isAnimationActive={true}
          dataKey="value"
          cx={150}
          cy={120}
          innerRadius={40}
          outerRadius={90}
          fill="#82ca9d"
        >
          {data02.map((entry, index) => (
            <Cell
              dataKey="value"
              key={`cell-${index}`}
              fill={COLORS1[index % COLORS1.length]}
            />
          ))}
        </Pie>
        <Pie
          data={data02}
          isAnimationActive={true}
          dataKey="value"
          cx={150}
          cy={120}
          label={renderCustomizedLabel}
          labelLine={false}
          innerRadius={50}
          outerRadius={90}
          fill="#82ca9d"
        >
          {data02.map((entry, index) => (
            <Cell
              dataKey="value"
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        
        </Pie>
      </PieChart>
      <ul className="p-0 list-unstyled d-flex justify-content-lg-around align-items-center data-chart gap-4">
        {props?.labels.map((item , i) => (
          <li className={item} key={'label-data-'+i}>{item}</li>
        ))}
      </ul>
      {props?.value >= 0 && (
            <div className="resilience-container">
              <h4 className="title fw-7 f26 mb-1">{props.value}</h4>
              <p className="title fw-4 f12 text-center">
                {props.valueText}
              </p>
            </div>
          )}
    </div>
  );
}
