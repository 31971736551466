import React, { useState } from "react";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import Card from "../../../../common/Cards";
import tickImage from "../../../../assets/util-icons/tick-mark.svg";
import crossImage from "../../../../assets/util-icons/cross-mark.svg";
import PlayButton from "../../../../assets/util-icons/play-btn-b.svg";
import plusIcon from "../../../../assets/util-icons/plusIcon.svg";
import { DEFAULT_VALUES } from "../../../../constants/default-values";
import { prePath } from "../../../../utils/helper";
import { useNavigate } from "@tanstack/react-location";
import DiscoverImg from "../../../../assets/util-icons/discover.svg";
let minimumNumber = 3;

const Explore = ({
  challenges,
  loading,
  handleClick,
  suggestion,
  hero,
  activeSwitch,
}) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(minimumNumber);

  const moreButtonHandler = () => {
    setCurrent((state) => state + minimumNumber);
  };

  return (
    <div>
      {challenges?.length > 0 && (
        <>
          <div className="d-flex justify-content-between active-cl align-items-center">
            <h5 className="title fw-6 mb-3 mt-4 f22 fs-p-22">
              {activeSwitch === "Play"
                ? "Squad challenges you can join"
                : "Squad Invitations"}
            </h5>
            {!loading && current < challenges?.length && (
              <h4
                className="color-p fsc-16 mt-4 mb-3 fw-6 pointer"
                onClick={moreButtonHandler}
              >
                View More
              </h4>
            )}
          </div>
          {loading ? (
            <div className="loading">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            challenges?.slice(0, current)?.map((v, i) => (
              <Card
                key={"squad-invitation-" + v.id}
                containerClass={
                  i + 1 === challenges.length
                    ? "squad-invitation mb-4 active-cl"
                    : "squad-invitation mb-3 active-cl"
                }
                title={
                  <div className="ml-1">
                    <p className="squad-name mb-1">{v.squad_name}</p>
                    <h6 className="invitation-title">{v.challenge_title}</h6>
                  </div>
                }
                customRightBtn={
                  <Row>
                    <Col
                      onClick={() => handleClick(v, true)}
                      className="px-0 pointer"
                    >
                      <img src={tickImage} alt="" />
                    </Col>
                    <Col
                      onClick={() => handleClick(v, false)}
                      className="pointer"
                    >
                      <img src={crossImage} alt="" />
                    </Col>
                  </Row>
                }
              />
            ))
          )}
        </>
      )}
      {hero && !loading && (
        <>
          {activeSwitch === "Explore" && (
            <div className="d-flex mb-3 mt-4  justify-content-between active-cl align-items-center">
              <h5 className="title mb-0 fw-6 f22"> Solo Play Suggestions</h5>
              <div
                className="pointer d-flex justify-content-start align-items-center "
                onClick={() =>
                  navigate({
                    to: "/discover/add-challenge",
                    search: { tab: "Explore" },
                  })
                }
              >
                <span className="fsc-14  me-2">Create Your Own</span>
                <img width="24px" src={plusIcon} alt="icon" />
              </div>
            </div>
          )}
          {activeSwitch === "Explore" && suggestion.length > 0
            ? suggestion.map((v, i) => (
                <Card
                  containerClass="colorful-card mt-3 active-cl"
                  style={{
                    borderColor: "#EBEBEB",
                  }}
                  key={"solo-challenges-" + i}
                  title={
                    <Row>
                      <Col sm={3}>
                        {v.file !== null ? (
                          <Image
                            src={`${prePath}${v.file}`}
                            alt="active squad image"
                            width="65px"
                            height="65px"
                            className="rounded-circle"
                          />
                        ) : (
                          <p
                            className="discover-circle circle-Sb mb-0"
                            style={{
                              backgroundColor: DEFAULT_VALUES.ACTIVE_COLOR[i],
                            }}
                          >
                            SB
                          </p>
                        )}
                      </Col>

                      <Col className="title ml-2">
                        <p
                          style={{
                            color: DEFAULT_VALUES.ACTIVE_COLOR[i],
                          }}
                          className="fw-6 mb-0 fs-p-14"
                        >
                          {v.squad_name}
                        </p>

                        <h6 className="title fsc-18 fw-6 mb-1">{v.title}</h6>

                        {/* <p className="fs-p-14 mb-0">
                          <span className="fw-6 title faded-title">
                            Epic Win:
                          </span>{" "}
                          {v.epic_win}
                        </p> */}
                      </Col>
                    </Row>
                  }
                  customRightBtn={
                    <Row>
                      <Col
                        onClick={() =>
                          navigate({
                            to: `/discover/challenge/${v.id}`,
                            state: { id: v.id },
                          })
                        }
                      >
                        <img src={PlayButton} alt="" />
                      </Col>
                    </Row>
                  }
                />
              ))
            : activeSwitch === "Explore"
            ? "No suggested challenge found."
            : ""}
          {activeSwitch === "Explore" && suggestion.length === 2 && (
            <Card
              containerClass="colorful-card mt-3 active-cl "
              style={{
                borderColor: "#EBEBEB",
              }}
              onClick={() => navigate({ to: "/select/more-challenges" })}
              key={"solo-challenges-discover"}
              title={
                <Row>
                  <Col sm={3}>
                    <Image
                      src={DiscoverImg}
                      alt="active squad image"
                      width="65px"
                      height={"65px"}
                      className="rounded-circle"
                    />
                  </Col>

                  <Col className="title ml-2">
                    <h6 className="title fsc-18 fw-6 mb-1">
                      Search the Library
                    </h6>
                  </Col>
                </Row>
              }
              customRightBtn={""}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Explore;
