import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import moment from "moment";
import Card from "../../../common/Cards";
import yellowArrow from "../../../assets/yellow-Arrow.svg";
import BackArrow from "../../../assets/arrowleft";
import AlertModal from "../../../common/Modal/alertModal";
import UpgradePop from "../../../common/Modal/upgradePop";
import { HeroPlan } from "./config";
import {
  useActiveSubscribeMutation,
  useCancelSubscribeMutation,
  useSubscriptionPlanMutation,
  useSubscribeMutation,
  useApplyPromoCodeMutation,
} from "../../../redux/api/subscription";
import toast from "react-hot-toast";
import PromoCodeModal from "../../../common/Modal/promoCodeModal";
import { useGetProfileMutation } from "../../../redux/api/userProfile";

const ManageSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeSubscribe] = useActiveSubscribeMutation();
  const [cancelSubscription] = useCancelSubscribeMutation();
  const [triggerApplyPromoCode] = useApplyPromoCodeMutation();
  const [subscriptionPlan] = useSubscriptionPlanMutation();
  const [subscribe] = useSubscribeMutation();
  const [userData] = useGetProfileMutation();

  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);
  const [error, setError] = useState(null);
  const [promoCodeDetails, setPromoCodeDetails] = useState(null);

  const getSubscription = useCallback(() => {
    setLoading(true);

    activeSubscribe()
      .then((response) => {
        if (response?.error) {
          toast.error(response?.error?.message);
          setLoading(false);
        } else {
          setSubscription(response?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [activeSubscribe]);

  useEffect(() => {
    /* checking active subscription */
    getSubscription();
  }, [getSubscription]);

  const {
    started_at = "",
    ended_at = "",
    plan_title = "Free",
    plan_price = "",
    status = "",
  } = subscription !== null && subscription;

  useEffect(() => {
    /* plans Data fetch */
    setLoading(true);
    subscriptionPlan().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
        setLoading(false);
      } else {
        const payload = [response?.data?.result[1], response?.data?.result[0]];
        setPlans(payload);
        setLoading(false);
      }
    });
  }, [subscriptionPlan]);

  const handleUpgradeModal = () => {
    const payload = {
      plan_id: plans[0]?.id,
    };
    /* subscribe the plan */
    subscribe(payload)
      .then((response) => {
        if (response?.error) {
          toast.error(response?.error?.data?.error);
        } else {
          const mainData = {
            ...response?.data,
            ...plans[0],
          };
          dispatch({ type: "STRIPE_ID", payload: mainData });
          navigate({
            to: "/select/stripe",
            search: {
              show: true,
              back: "ms_s",
              isPromoOrTrial:
                subscription?.is_trial || subscription?.promo_code_id
                  ? true
                  : false,
            },
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleModal = () => {
    setShowModal(!setShowModal);
    if (plan_title !== "Free") {
      cancelSubscription()
        .then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            getSubscription();
            toast.success("Cancel Subscription Successfully.");
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const closePromoModal = () => {
    setShowPromoModal(false);
  };

  const openPromoModal = () => {
    setShowPromoModal(true);
    setUpgradeModal(false);
  };

  const handleConfirm = (value) => {
    setPromoCodeLoading(true);
    const payload = {
      promo_code: value?.promoCode,
    };
    triggerApplyPromoCode(payload).then((response) => {
      if (response?.error) {
        if (response?.error?.data?.status_code !== 400) {
          toast.error(response.error.data.error);
        }
        setError(response.error.data);
        setPromoCodeLoading(false);
      } else {
        setPromoCodeLoading(false);
        setPromoCodeDetails(response?.data?.result);
      }
    });
  };

  const feedbackHandler = () => {
    userData().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        const data = response?.data;
        dispatch({ type: "PROFILE_DATA", payload: data?.details });
        localStorage.setItem("USER", JSON.stringify(data.details));
        closePromoModal();
        getSubscription();
      }
    });
  };

  const activePlanDate = moment(started_at).utc().format("MMM D, YYYY");
  const endPlanDate = moment(ended_at).utc().format("MMM D, YYYY");

  const handleSubscription = () => {
    setShowModal(!showModal);
    setTitle("Are you sure you want to cancel your subscription?");
    setText(
      `Note that this plan will be active until ${endPlanDate} and then convert to a player account.`
    );
  };

  return (
    <Container className="content-container settings subscription ml-4">
      <h5 className="title fw-6 ex-s mar-ps  ">
        <div
          className="pointer  d-inline"
          onClick={() => navigate({ to: "/discover/settings" })}
        >
          <BackArrow />
        </div>
        Manage Subscription
      </h5>

      {loading ? (
        <div className="loading mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {plan_title === "Free" ? (
            <>
              <Card
                containerClass="mt-4 subscription-card"
                md={8}
                title={
                  <>
                    <h5
                      className="title main-title mb-3"
                      style={{ width: "30em" }}
                    >
                      {`Subscribe To a ${
                        plans[0]?.title
                      } Account ${plans[0]?.pricing?.split(" ")?.join("")}`}
                    </h5>
                    {plans[0]?.features?.map((res, i) => (
                      <Row
                        key={i}
                        className="fsc-14 align-items-start fw-4 text-light analytics-text inner-battle mt-2"
                      >
                        <Col sm={1}>
                          <img
                            className={`bad-Arrow ${
                              HeroPlan.length === i + 1 && "bottom-margin"
                            }`}
                            src={yellowArrow}
                            alt=""
                          />
                        </Col>
                        <Col
                          className={`fsc-14 px-1 fw-4 text-dark analytics-text inner-text-area ${
                            HeroPlan.length === i + 1 && "bottom-margin"
                          }`}
                        >
                          {res}
                        </Col>
                      </Row>
                    ))}
                  </>
                }
                customRightBtn={
                  <p
                    className="link-button f14"
                    onClick={() => setUpgradeModal(true)}
                  >
                    SUBSCRIBE
                  </p>
                }
              />
              <Card
                containerClass="mt-4 subscription-card-wh"
                title={
                  <>
                    <h5 className="title main-title mb-3">
                      Continue with a free Player account{" "}
                    </h5>
                    <p className="fsc-16  mb-2 title">{`Plan Started on ${
                      activePlanDate &&
                      activePlanDate !== undefined &&
                      activePlanDate
                    }`}</p>
                  </>
                }
                customRightBtn={""}
              />
            </>
          ) : (
            <>
              <Card
                containerClass={
                  status && status !== "canceled"
                    ? "mt-4 subscription-card"
                    : "mt-4 cancel-card"
                }
                md={8}
                title={
                  <>
                    <h5 className="title f20">Current Plan</h5>
                    <h5 className="main-Hero">
                      <p className="title f20">
                        {`Hero account
                        ${
                          (subscription?.is_trial ||
                            subscription?.promo_code_id ||
                            "") &&
                          "free trial"
                        }`}
                      </p>
                      <p className="right-title f20">
                        {subscription?.is_trial || subscription?.promo_code_id
                          ? `${moment(subscription?.ended_at)
                              .utc()
                              .diff(moment(), "days")} days left`
                          : plan_price}
                      </p>
                    </h5>
                    {status !== "canceled" && (
                      <p className="title mt-1 f16 c-ss">{`${
                        subscription?.is_trial || subscription?.promo_code_id
                          ? "Trial started"
                          : "Plan started on"
                      } ${
                        activePlanDate &&
                        activePlanDate !== undefined &&
                        activePlanDate
                      }`}</p>
                    )}
                    <p
                      className={`${
                        (subscription?.is_trial ||
                          subscription?.promo_code_id) &&
                        "mb-2"
                      }
                       ${
                         status !== "canceled"
                           ? "title f16 c-ss end-paln"
                           : "title f16  c-sssubscription-Cancelled"
                       }
                      `}
                    >{`${
                      subscription?.is_trial || subscription?.promo_code_id
                        ? "Trial ends"
                        : "Plan ends on"
                    } ${endPlanDate}`}</p>
                  </>
                }
                customRightBtn={
                  status === "canceled" ||
                  subscription?.payment_gateway === "manual" ||
                  subscription?.promo_code_id ||
                  subscription?.is_trial ? (
                    <></>
                  ) : (
                    <p
                      className="link-button f14"
                      onClick={() => handleSubscription()}
                    >
                      CANCEL SUBSCRIPTION
                    </p>
                  )
                }
              />
              {subscription?.is_trial || subscription?.promo_code_id ? (
                <Card
                  containerClass="mt-4 subscription-card"
                  md={8}
                  title={
                    <>
                      <h5
                        className="title main-title mb-3"
                        style={{ width: "30em" }}
                      >
                        {`Subscribe to a ${
                          plans[0]?.title
                        } account ${plans[0]?.pricing?.split(" ")?.join("")}`}
                      </h5>

                      {plans[0]?.features?.map((res, i) => (
                        <Row
                          key={i}
                          className="fsc-14 align-items-start fw-4 text-light analytics-text inner-battle mt-2"
                        >
                          <Col sm={1}>
                            <img
                              className={`bad-Arrow ${
                                HeroPlan.length === i + 1 && "bottom-margin"
                              }`}
                              src={yellowArrow}
                              alt=""
                              style={{ filter: "brightness(0.2)" }}
                            />
                          </Col>
                          <Col
                            className={`fsc-14 px-1 fw-4 text-dark analytics-text inner-text-area ${
                              HeroPlan.length === i + 1 && "bottom-margin"
                            }`}
                          >
                            {res}
                          </Col>
                        </Row>
                      ))}
                    </>
                  }
                  customRightBtn={
                    <p
                      className="link-button f14"
                      onClick={() => setUpgradeModal(true)}
                    >
                      SUBSCRIBE
                    </p>
                  }
                />
              ) : (
                <p className="mt-3 f16 r-Dt-c">
                  If canceled, your account will remain active until the next
                  billing date and
                  <br /> then convert to a Player account.
                </p>
              )}
            </>
          )}
          <AlertModal
            setShowModal={() => setShowModal(!showModal)}
            showModal={showModal}
            title={title}
            text={text}
            endPlanDate={endPlanDate}
            handleCancelPlan={() => handleModal()}
            txt={"cancel"}
          />
          {upgradeModal && (
            <UpgradePop
              setShowModal={() => setUpgradeModal(!upgradeModal)}
              showModal={upgradeModal}
              isFreeTrialOrPromo={
                !!(subscription?.is_trial || subscription?.promo_code_id)
              }
              plan={plans[0]}
              handleCancelPlan={() => handleUpgradeModal()}
              openPromoModal={openPromoModal}
            />
          )}

          {showPromoModal && (
            <PromoCodeModal
              showModal={showPromoModal}
              hideModal={closePromoModal}
              handleConfirm={handleConfirm}
              error={error}
              promoCodeDetails={promoCodeDetails}
              feedbackHandler={feedbackHandler}
              loading={promoCodeLoading || loading}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ManageSubscription;
