import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useSearch } from "@tanstack/react-location";
import ChallengeCard from "../../../common/select-challenges-card";
import SometingModal from "../../../common/Modal/someting";
import { challengeData } from "./config";

const SelectChallenges = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [something, setSomething] = useState("");
  const search = useSearch();
  const page = search?.page;

  const handleClick = (res) => {
    if (res.name === "Something Else") {
      setShowModal(!showModal);
      setSomething(res);
    } else {
      navigate({
        to: "/select/further-goal",
        search: { name: res.name, icon: res.icon, page: page },
      });
    }
  };

  const handleOnSubmit = (v) => {
    navigate({
      to: "/select/further-goal",
      search: { name: v.notes, icon: something.icon, page: page },
    });
  };

  return (
    <Container className="center-container pt-3">
      <h5 className="color-p mt-4 mb-3 fw-6 fs-4">
        {`What would you like to work on ${
          page === "goal" ? "next" : "first"
        }?`}
      </h5>
      <p>You can change this later</p>
      <Row className="justify-content-md-center mt-4 mb-5 spacing">
        {challengeData.map((res, index) => (
          <Col
            key={index}
            className="d-flex w-6 mt-3 mb-4 card-container cursor"
          >
            <ChallengeCard
              containerClass="mr-2 mb-3"
              name={res.name}
              icon={res.icon}
              onClick={() => handleClick(res)}
            />
          </Col>
        ))}
      </Row>
      <SometingModal
        showModal={showModal}
        setShowModal={() => setShowModal(!showModal)}
        handleOnSubmit={(v) => handleOnSubmit(v)}
      />
    </Container>
  );
};

export default SelectChallenges;
