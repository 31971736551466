export const data=[
    {
    check:"Battle Win",
    sec:"Question"
},{
    check:"Battle Fail",
    sec:"Question"
}]
export const lowerSection=[{
    check:"Villain",
    sec:"A regular bad guy."
},{
    check:"Boss",
    sec:"An extra tough bad guy."
},{
    check:"Vanquished",
    sec:"No longer a bad guy."
}]