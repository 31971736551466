import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate, useSearch } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import Logo from "../../../assets/logo.png";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useGetProfileMutation } from "../../../redux/api/userProfile";
import { useCheckSubscriptionStatusMutation } from "../../../redux/api/subscription";

const CheckoutForm = ({ data }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useSearch();
  const [loading, setLoading] = useState(false);
  const [showStripe, setShowStrip] = useState(true);
  const [userData] = useGetProfileMutation();
  const [checkSubscriptionStatus] = useCheckSubscriptionStatusMutation();
  const [error, setError] = useState("");

  const obj = location;

  const { pricing } = data;

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [error]);

  const handleUser = () => {
    userData().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        const data = response?.data;
        dispatch({ type: "PROFILE_DATA", payload: data?.details });
        localStorage.setItem("USER", JSON.stringify(data.details));
        if (
          data?.details?.user_type === "hero" &&
          data?.details?.is_subscribed &&
          !obj?.isPromoOrTrial
        ) {
          navigate({ to: "/select/how-to-play", search: { show: true } });
        }
      }
    });
  };

  useEffect(() => {
    /* Checking Payment intent */
    if (obj?.redirect_status) {
      setShowStrip(false);
      if (obj?.back && obj?.back === "ms_s") {
        checkSubscriptionStatus({
          payment_intent_id: obj?.payment_intent,
        }).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.message);
          } else {
            navigate({ to: "/discover/manage-subscription" });
          }
        });
      } else {
        checkSubscriptionStatus({
          payment_intent_id: obj?.payment_intent,
        }).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.message);
          } else {
            handleUser();
          }
        });
      }
    } else {
      handleUser();
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      })
      .then((result) => {
        if (result?.error) {
          setError(result?.error?.message);
        } else {
          navigate({ to: "/select/how-to-play", search: { show: true } });
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Payment Failed");
      });
  };

  return (
    <div className="select-account-conatiner pt-5">
      {obj?.show && showStripe ? (
        <form
          className="d-flex justify-content-center "
          onSubmit={handleSubmit}
        >
          <div className="payment-class">
            <div className="d-flex justify-content-center mt-3 mb-5">
              <img src={Logo} alt="logo" />
            </div>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <>
              <PaymentElement id="payment-element" />
              <button
                type="submit"
                className="Payment-button mt-4"
                disabled={!stripe || !elements}
              >
                {!loading
                  ? `Pay ${pricing?.split(" ").join("")}`
                  : "... Loading"}
              </button>
            </>
          </div>
        </form>
      ) : (
        <>
          {loading && (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" className="py-5" />{" "}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CheckoutForm;
