import { Navigate, ReactLocation } from "@tanstack/react-location";
import AuthenticationComponent from "./pages/auth";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import SelectAccount from "./pages/auth/select-account";
import StripPage from "./pages/auth/strip/stripe";
import HowToPlay from "./pages/auth/strip/howToPlay";
import ForgotPassword from "./pages/auth/forgot-password";
import DiscoverDashboard from "./pages/discover";
import Quiz from "./pages/discover/quiz";
import Rules from "./pages/discover/rules";
import ScienceBehind from "./pages/discover/science-behind";
import Settings from "./pages/discover/settings";
import MainProfile from "./pages/discover/profile";
import ChangePassword from "./pages/discover/changePassword";
import Prefrences from "./pages/discover/Preferences";
import ManageSubscription from "./pages/discover/manageSubscription";
import { SCIENCE_BEHIND } from "./constants/science-behind";
import Dashboard from "./pages/discover/dashboard";
import Discover from "./pages/discover/dashboard/discover";
import SquadPlay from "./pages/discover/dashboard/squad-play";
import FeedBack from "./pages/discover/dashboard/feedBack";
import WeeklyFeedBack from "./pages/discover/dashboard/feedBack/weekly";
import Invitations from "./pages/discover/invitation";
import SelectPages from "./pages/select-pages";
import AddPowerUps from "./pages/select-pages/power-ups";
import AddBadGuys from "./pages/select-pages/soloplay-add/bad-guy";
import StartChallenge from "./pages/select-pages/add-challenges";
import BadGuyDetails from "./pages/select-pages/details/bad-guy";
import BadGuy from "./pages/select-pages/details/bad-guy-paid";
import AllyDetails from "./pages/select-pages/details/ally";
import QuestDetails from "./pages/select-pages/details/quest";
import PowerDetails from "./pages/select-pages/details/power-ups";
import PowerDetailsFeedback from "./pages/select-pages/details/feedBack/power-ups-feedBack";
import QuestFeedback from "./pages/select-pages/details/feedBack/quest-feedback";
import AllyFeedback from "./pages/select-pages/details/feedBack/ally-feedBack";
import BadDetailsFeedback from "./pages/select-pages/details/feedBack/bad-guy-feedback";
import BadForm from "./pages/select-pages/details/feedBack/bad-guy-form";
import Notes from "./pages/select-pages/details/feedBack/notes";
import QuizQuestion from "./pages/discover/quiz/Question";
import Result from "./pages/discover/quiz/Result";
import TermsAndCondition from "./pages/discover/terms&condition";
import PrivacyPolicy from "./pages/discover/privacyPolicy";
import FAQs from "./pages/discover/faqs";
import ContactUs from "./pages/discover/contactUs";
import Reminder from "./pages/discover/Preferences/reminder";
import SelectChallenges from "./pages/auth/select-challenges";
import FurtherGoal from "./pages/auth/select-challenges/furtherGoal";
import FormPowerUP from "./pages/select-pages/soloplay-add/power-ups";
import FormBadGuy from "./pages/select-pages/soloplay-add/bad-guy";
import FormAlly from "./pages/select-pages/soloplay-add/ally";
import QuestForm from "./pages/select-pages/soloplay-add/quest";
import EmailVerification from "./pages/auth/emailVerification";
import Verification from "./pages/auth/register/verification";
// import SuggestChallenges from "./pages/auth/select-challenges/suggest-challanges";
import Notifications from "./pages/discover/notifications/notifications";
import AddChallenge from "./pages/discover/invitation/addChallenge";
import SoloPlay from "./pages/discover/dashboard/solo-play";
import NotFound from "./pages/notFound";
import MoreChallenges from "./pages/auth/select-challenges/more-challenges";
import CustomSoloChallenge from "./pages/select-pages/soloplay-add/CustomSoloChallenge";
import EpicWinFeedback from "./pages/discover/dashboard/feedBack/EpicWinFeedback";
import Tutorial from "./pages/auth/select-account/Tutorial";

// Set up a ReactLocation instance
export const location = new ReactLocation();

export const routes = [
  {
    path: "auth",
    element: <AuthenticationComponent />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },

      {
        path: "reset-password",
        element: <ForgotPassword step={2} />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "email-verification/:id",
        element: <EmailVerification />,
      },
      // {
      //   path: "email-verification",
      //   element: <EmailVerificationTest />,
      // },
      {
        path: "invitation/:id",
        element: <Verification />,
      },
    ],
  },

  {
    path: "discover",
    element: <DiscoverDashboard />,
    children: [
      {
        path: "add-challenge",
        element: <CustomSoloChallenge />,
      },
      {
        path: "quiz/:quizId",
        element: <Quiz />,
      },

      {
        path: "take-quiz",
        element: <QuizQuestion />,
      },
      {
        path: "quiz-result",
        element: <Result />,
      },
      {
        path: "invitations/:invitationId",
        element: <Invitations />,
      },
      {
        path: "challenge/:challengeId",
        element: <AddChallenge />,
      },
      {
        path: "/weekly-feedBack",
        element: <WeeklyFeedBack />,
      },
      {
        path: "/epic-win-feedBack",
        element: <EpicWinFeedback />,
      },
      {
        path: "/feedBack",
        element: <FeedBack />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        children: [
          { path: "/", element: <Discover /> },
          { path: "/squad-play/:id", element: <SquadPlay /> },
          { path: "/squad-play", element: <SquadPlay /> },
          { path: "/solo-play/:id", element: <SoloPlay /> },
          { path: "/solo-play", element: <SoloPlay /> },
        ],
      },

      {
        path: "rules",
        element: <Rules />,
      },
      {
        path: "science-behind",
        children: SCIENCE_BEHIND.map((v) => {
          return {
            path: v.value.replace(" ", "-").toLowerCase(),
            element: (
              <ScienceBehind value={v.value} text={v.text} color={v.color} />
            ),
          };
        }),
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "profile",
        element: <MainProfile />,
      },
      {
        path: "changePassword",
        element: <ChangePassword />,
      },
      {
        path: "notification-setting",
        element: <Prefrences />,
      },
      {
        path: "manage-subscription",
        element: <ManageSubscription />,
      },
      {
        path: "terms&condition",
        element: <TermsAndCondition />,
      },
      {
        path: "privacyPolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "faqs",
        element: <FAQs />,
      },
      {
        path: "contactUs",
        element: <ContactUs />,
      },
      {
        path: "reminder",
        element: <Reminder />,
      },
      {
        path: "notification",
        element: <Notifications />,
      },
    ],
  },

  {
    path: "select",
    element: <SelectPages />,
    children: [
      {
        path: "add-power-ups",
        element: <AddPowerUps />,
      },
      {
        path: "add-bad-guy",
        element: <AddBadGuys />,
      },
      {
        path: "add-challenges",
        element: <StartChallenge />,
      },
      {
        path: "how-to-play",
        element: <HowToPlay />,
      },
      {
        path: "selectAccount",
        element: <SelectAccount />,
      },
      {
        path: "/tutorial",
        element: <Tutorial />,
      },
      {
        path: "more-challenges",
        element: <MoreChallenges />,
      },
      {
        path: "stripe",
        element: <StripPage />,
      },
      {
        path: "select-challenges",
        element: <SelectChallenges />,
      },
      {
        path: "further-goal",
        element: <FurtherGoal />,
      },
      // {
      //   path: "suggest-challanges",
      //   element: <SuggestChallenges />,
      // },
      {
        path: "details",
        children: [
          {
            path: "bad-guy",
            element: <BadGuyDetails />,
          },
          {
            path: "bad-guy-Boss",
            element: <BadGuy />,
          },
          {
            path: "ally",
            element: <AllyDetails />,
          },
          {
            path: "ally-feedBack",
            element: <AllyFeedback />,
          },
          {
            path: "quests",
            element: <QuestDetails />,
          },
          {
            path: "power-ups",
            element: <PowerDetails />,
          },
          {
            path: "power-ups-feedback",
            element: <PowerDetailsFeedback />,
          },
          {
            path: "bad-guys-feedback",
            element: <BadDetailsFeedback />,
          },
          {
            path: "bad-form",
            element: <BadForm />,
          },
          {
            path: "notes",
            children: [
              { path: "/", element: <Notes /> },
              { path: "/:id", element: <Notes /> },
            ],
          },

          {
            path: "quest-feedback",
            element: <QuestFeedback />,
          },
        ],
      },
      {
        path: "add",
        children: [
          {
            path: "power-up-form",
            element: <FormPowerUP />,
          },
          {
            path: "bad-guy-form",
            element: <FormBadGuy />,
          },
          {
            path: "ally-form",
            element: <FormAlly />,
          },
          {
            path: "quest-form",
            element: <QuestForm />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/auth/login" />,
    exact: true,
  },
  {
    path: "/auth/login",
    element: <Navigate to="/auth/login" />,
    exact: true,
  },
  {
    path: "*",
    element: <NotFound />,
    exact: true,
  },
];
