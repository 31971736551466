import React from "react";
import { Card, Dropdown } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import playBtn from "../../assets/util-icons/p-bt.svg";
import ActivityThreeDotIcon from "../../assets/activityThreeDotIcon.svg";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import DeleteImg from "../../assets/util-icons/delete.svg";
import CommonPop from "../Modal/commonModal";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDeleteLibraryActivityMutation } from "../../redux/api/library";

function LibraryActivityCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteActivity] = useDeleteLibraryActivityMutation();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handlePlay = () => {
    if (props?.quest?.type === "power_ups") {
      navigate({
        to: "/select/details/power-ups",
        search: {
          page: "solo-play",
        },
      });
    } else if (props?.quest?.type === "bad_guy") {
      navigate({
        to: "/select/details/bad-guy",
        search: {
          page: "solo-play",
        },
      });
    } else if (props?.quest?.type === "quests") {
      navigate({
        to: "/select/add/quest-form",
        search: {
          page: "solo-play",
        },
      });
    }
    dispatch({
      type: "SELECTED_CARD_DETAIL",
      payload: { isCustomLibraryActivity: true, ...props?.quest },
    });
  };

  const handleEdit = () => {
    if (props?.quest?.type === "power_ups") {
      navigate({
        to: "/select/add/power-up-form",
        search: {
          page: "solo-play",
        },
      });
    } else if (props?.quest?.type === "bad_guy") {
      navigate({
        to: "/select/add/bad-guy-form",
        search: {
          page: "solo-play",
        },
      });
    } else if (props?.quest?.type === "quests") {
      navigate({
        to: "/select/add/quest-form",
        search: {
          page: "solo-play",
        },
      });
    }
    dispatch({
      type: "SELECTED_CARD_DETAIL",
      payload: { isEditCustomLibraryActivity: true, ...props?.quest },
    });
  };

  const deleteHandler = (id) => () => {
    setShowModal(true);
  };

  const handleModal = () => {
    setDeleteLoading(true);
    const payload = {
      activity_id: props.quest.id,
    };
    deleteActivity(payload).then((response) => {
      setDeleteLoading(false);
      if (response?.error) {
        toast.error(response?.error?.data?.error);
      } else {
        setShowModal(!showModal);
        props?.setActivityDeleteState(true);
      }
    });
  };

  return (
    <Card
      key={props?.quest?.id}
      className={`${props.containerClass} activity-card-container`}
    >
      <div
        style={{ backgroundColor: props.color }}
        className="opaque-mask"
      ></div>
      {props.title && <p className="Cardtitle fs-2 fw-5">{props?.title}</p>}

      <Card.Body
        className={`hover-container ${
          (props?.quest?.is_completed || props?.quest?.is_attempted_today) &&
          "pointer"
        }`}
      >
        <div
          className="d-flex justify-content-end pointer"
          onClick={() => handlePlay()}
        >
          <img src={playBtn} alt="" />
        </div>

        <Dropdown className="filters-discover d-flex justify-content-end  p-0 m-0 ">
          <Dropdown.Toggle className=" hover-icon  pointer m-0 p-0 bg-transparent mt-2 border-0">
            <img src={ActivityThreeDotIcon} alt="" />
          </Dropdown.Toggle>
          <DropdownMenu align={"end"} className="mt-2 px-3 py-1">
            <Dropdown.Item className="p-2" onClick={() => handleEdit()}>
              <EditIcon />
              <span className="ms-2 black-color fsc-16 fw-4">Edit</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              className="p-2"
              onClick={deleteHandler(props.quest.id)}
            >
              <DeleteIcon />
              <span className="ms-2 black-color fsc-16 fw-4">Delete</span>
            </Dropdown.Item>
          </DropdownMenu>
        </Dropdown>

        <CommonPop
          showModal={showModal}
          hideModal={() => setShowModal(false)}
          handleConfirm={() => handleModal()}
          icon={DeleteImg}
          loading={deleteLoading}
          title={"Are you sure you want to delete this activity?"}
        />

        <p className="quest fw-6 text-truncate-2">{props?.quest?.title}</p>
      </Card.Body>
    </Card>
  );
}

export default LibraryActivityCard;
