export const prefrences_data = [
  {
    title: "Daily Reminder",
    type: "arrow",
  },
  // {
  //     title:"App Settings",
  //     subTitle:'Open “Settings” in app',
  //     type:"arrow"
  // },

  // {
  //     title:"Reminder Time",
  //     type:"check"
  // }
];
