import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActivityCard from "../../../common/ActivityCard";
import Button from "../../../common/Buttons";
import { useGetLibraryMutation } from "../../../redux/api/library";
import { useNavigate, useSearch } from "@tanstack/react-location";
import toast from "react-hot-toast";
import { useCallback } from "react";

const PER_PAGE = 8;

function AddPowerUps(props) {
  const navigate = useNavigate();
  const [triggerLibrary] = useGetLibraryMutation();
  const [section, setSection] = useState("Mental");
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const search = useSearch();

  const getLibrary = useCallback(
    (page = 1, PER_PAGE, section) => {
      setLoading(true);
      const data = {
        page,
        per_page: PER_PAGE,
        strength_to_build: section.toLowerCase(),
        type: "power_ups",
      };
      triggerLibrary(data).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.error);
        } else {
          if (currentData?.result) {
            setCurrentData((prevState) => ({
              ...res?.data,
              result: [...prevState?.result, ...res?.data?.result],
            }));
          } else {
            setCurrentData(res.data);
          }
        }
        setLoading(false);
      });
    },
    [triggerLibrary, page]
  );

  useEffect(() => {
    getLibrary(page, PER_PAGE, section);
  }, [getLibrary, page, section]);

  const moreButtonHandler = () => {
    setPage((state) => state + 1);
  };

  const handleStrengthToBuild = (item) => {
    setSection(item);
    setCurrentData(null);
    setPage(1);
  };
  return (
    <Container className="center-container position-relative pb-5">
      <h5 className=" mt-5 mb-4 f26 fw-6" style={{ color: "#121212" }}>
        Power-ups
      </h5>
      <div className="d-flex mt-4 mb-5">
        <Button
          customizeClass={
            section !== "Mental" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn"
          }
          label="Mental"
          onClick={() => handleStrengthToBuild("Mental")}
        />
        <Button
          customizeClass={
            section !== "Emotional" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn"
          }
          label="Emotional"
          onClick={() => handleStrengthToBuild("Emotional")}
        />
        <Button
          customizeClass={
            section !== "Social" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn"
          }
          label="Social"
          onClick={() => handleStrengthToBuild("Social")}
        />
        <Button
          customizeClass={
            section !== "Physical" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn"
          }
          label="Physical"
          onClick={() => handleStrengthToBuild("Physical")}
        />
      </div>
      {loading && !currentData?.result?.length ? (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="d-flex w-8 mt-5 mb-5  card-container">
            {currentData?.result?.length > 0 ? (
              currentData?.result?.map((res, i) => (
                <ActivityCard
                  key={res.id}
                  containerClass="mr-2 mb-3  pu-crd"
                  quest={res}
                  color="#F6BF01"
                  soloPlay={search?.page === "solo-play"}
                  more={true}
                  challengeids={search?.IdChl}
                />
              ))
            ) : (
              <h6 className="title lh-1 mb-4">No Activity found.</h6>
            )}
          </div>
          {currentData?.total > currentData?.result?.length && (
            <Button
              customizeClass="mr-2 mt-3 mb-4 pu-btn-m"
              onClick={moreButtonHandler}
              disable={loading}
              label="More"
            />
          )}

          <Button
            label={"Back"}
            onClick={() =>
              navigate({
                to: `/discover/dashboard/${page ? search?.page : ""}`,
              })
            }
            customizeClass=" back-button-1 fw-600"
            variant="light"
          />
        </>
      )}
    </Container>
  );
}

export default AddPowerUps;
