import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";
import { PUBLISH_KEY } from "../../../constants/app-config";

const stripePromise = loadStripe(PUBLISH_KEY);

const StripPayment = () => {
  const Ids = useSelector((state) => state?.subscription?.stripeId);
  const { customer = "", paymentIntent = "" } = Ids || {};

  const [paymentId, setPaymentId] = useState("");

  useEffect(() => {
    setPaymentId(paymentIntent);
  }, [paymentIntent, customer]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: paymentId,
  };

  return (
    <>
      {stripePromise && paymentId ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm data={Ids} />
        </Elements>
      ) : (
        <div className="loading mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
};

export default StripPayment;
