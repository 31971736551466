import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment/moment";
import ReactDatePicker from "react-datepicker";
import { ReactComponent as Calender } from "../../assets/calendar.svg";

function DatePicker(props) {
  const [invitationDates, setInvitationDates] = useState();
  const { formik } = props;
  const handleInvitationDate = (date) => {
    const year = date ? moment(date).format("YYYY") : "";
    const month = date ? moment(date).format("MMM") : "";
    const day = date ? moment(date).format("D") : "";

    formik.setFieldValue("month", month);
    formik.setFieldValue("year", year);
    formik.setFieldValue("day", day);
    setInvitationDates(date);
  };

  return (
    <div className="mt-3">
      <Form.Group className="datepicker-container">
        {!props.withoutLabel && (
          <Form.Label className="color-dark fw-6">{props.label}</Form.Label>
        )}
        <Row className="g-0">
          <Col className="position-relative">
            <ReactDatePicker
              selected={invitationDates}
              placeholderText="MM/DD/YYYY"
              showYearDropdown
              showMonthDropdown
              maxDate={moment().toDate()}
              onChange={(date) => handleInvitationDate(date)}
              className=" date-picker-label-invitation form-control  "
              dateFormat="MMM/dd/yyyy"
              yearDropdownItemNumber={100}
              scrollableYearDropdown={true}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />

            <span
              className="position-absolute"
              style={{ right: "15px", top: "10px" }}
            >
              <Calender />
            </span>
          </Col>

          {formik.errors?.date && (
            <Form.Control.Feedback type="invalid" className="ps-0">
              {formik.errors?.date}
            </Form.Control.Feedback>
          )}
        </Row>
      </Form.Group>
    </div>
  );
}

export default DatePicker;
