import { Link } from "@tanstack/react-location";
import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

function Tabs(props) {
  const userData = useSelector((state) => state?.userProfile?.data);
  const dispatch = useDispatch();

  const handleValue = (l) => {
    if (l === "secret-lab") {
      dispatch({ type: "UPDATE_SIDE_BAR", payload: true });
    } else {
      dispatch({ type: "UPDATE_SIDE_BAR", payload: false });
    }

    if (l === "squad-play") {
      dispatch({
        type: "USER_CHALLENGE_DAYS",
        payload: {},
      });
    }
  };

  return (
    <div className="tab-container w-8">
      <Nav variant="tabs pb-3">
        {props.value.map((v, i) =>
          userData?.user_type === "hero" &&
          userData?.is_subscribed &&
          v.value === "solo-play" ? (
            <Nav.Item
              key={"nav-link-tab-" + i}
              className={
                v.label === "Discover" ? "discover-class" : "dis-spces"
              }
            >
              <Link
                className={`color-dark fw-6 pb-3 ${
                  props.active === v.label ? "selected" : ""
                }`}
                to={`/discover/dashboard/${v.value}`}
                onClick={() => handleValue(v.value)}
              >
                {v.label}
              </Link>
            </Nav.Item>
          ) : v.value !== "solo-play" ? (
            <Nav.Item
              key={"nav-link-tab-" + i}
              className={
                v.label === "Discover"
                  ? "discover-class dis-spces"
                  : "dis-spces"
              }
            >
              <Link
                className={`color-dark fw-6 pb-3 ${i === 0 && "ml-4"}${" "}${
                  props.active === v.label ? "selected" : ""
                }`}
                to={`/discover/dashboard/${v.value}`}
                onClick={() => handleValue(v.value)}
              >
                {v.label}{" "}
                {/* {v.label === "Discover" && props.count > 0 && (
                  <Badge className="badge">{props.count}</Badge>
                )} */}
              </Link>
            </Nav.Item>
          ) : (
            ""
          )
        )}
      </Nav>
    </div>
  );
}

export default Tabs;
