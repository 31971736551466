import React from "react";

const PlayFilter = ({active}) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833008 1.33301V14.6663L11.6663 7.99967L0.833008 1.33301Z"
        stroke={active?'#F5AB38':"#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default PlayFilter;
