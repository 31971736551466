import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import { usePieChartMutation } from "../../../redux/api/userProfile";
import { useGetSkillBadgeMutation } from "../../../redux/api/secretLab";
import { APP_CONFIG } from "../../../constants/app-config";
import { DEFAULT_VALUES } from "../../../constants/default-values";
import { DonutChartContainer } from "../../../common/donutChart";
import { prePath } from "../../../utils/helper";
import CustomCard from "../../../common/Cards/quizCard";
import { useNavigate } from "@tanstack/react-location";
import toast from "react-hot-toast";

const PER_PAGE = 4;

function Progress(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pieChart] = usePieChartMutation();
  const [skillBadge] = useGetSkillBadgeMutation();

  const [pieChartData, setPieChartData] = useState(null);
  const [skillBadgeData, setSkillBadgeData] = useState(null);
  const [pieLoading, setPieLoading] = useState(false);
  const [pieMainData, setPieMainData] = useState("");
  const [colorTheme, setColorTheme] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const pieDataArr = [
    pieChartData?.emotional,
    pieChartData?.physical,
    pieChartData?.social,
    pieChartData?.mental,
  ];

  useEffect(() => {
    if (pieChartData) {
      const upadatePieValue = [];
      const theme = [];
      pieDataArr?.forEach((res, i) => {
        if (res !== 0) {
          if (i === 0) {
            theme.push({
              label: "Emotional",
              color: "#CF4476",
            });
          } else if (i === 1) {
            theme.push({
              label: "Physical",
              color: "#F9A623",
            });
          } else if (i === 2) {
            theme.push({
              label: "Social",
              color: "#B9D435",
            });
          } else if (i === 3) {
            theme.push({
              label: "Mental",
              color: "#2844A3",
            });
          }
          upadatePieValue.push(res);
        }
      });
      setPieMainData(upadatePieValue);
      setColorTheme(theme);
    }
  }, [pieChartData]);

  useEffect(() => {
    /**Pie chart Api to get data */
    setPieLoading(true);
    pieChart()
      .then((res) => {
        if (!res?.error) {
          setPieChartData(res.data.result);
          props.getWinStreak(res.data.result.win_streak);
          dispatch({ type: "USER_PIE_CHART", payload: res });
        } else {
          toast.error(res?.error?.message);
        }
        setPieLoading(false);
      })
      .catch((err) => {
        dispatch({ type: "USER_PIE_CHART", payload: err?.message });
        setPieLoading(false);
      });
  }, []);

  useEffect(() => {
    /**skill Badge Api to get data */
    setLoading(true);
    skillBadge({ page: page, per_page: PER_PAGE })
      .then((res) => {
        if (!res?.error) {
          if (skillBadgeData?.rows?.length) {
            setSkillBadgeData((prevState) => ({
              ...res?.data?.result,
              rows: [...prevState?.rows, ...res?.data?.result?.rows],
            }));
          } else {
            setSkillBadgeData(res.data.result);
          }
        } else {
          toast.error(res?.error?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  }, [page, skillBadge]);

  const moreButtonHandler = () => {
    setPage((state) => state + 1);
  };

  return (
    <div className="user-card">
      <ListGroup.Item className="bg-color-gray only_bottom_border py-0">
        {!pieLoading ? (
          pieChartData !== null &&
          pieMainData &&
          colorTheme.length === pieMainData.length && (
            <DonutChartContainer
              labels={DEFAULT_VALUES?.ANALYTICS_DISCOVER.map((v) => v.label)}
              data={pieMainData && pieMainData}
              colorTheme={DEFAULT_VALUES?.ANALYTICS_DISCOVER.map(
                (v) => v.color
              )}
              value={pieChartData?.total_resilience_score}
              valueText="Resilience Score"
            />
          )
        ) : (
          <div className="loading">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <p className="f12 r-Dt-c text-center mb-4 analytics-text">
          {APP_CONFIG.DISCOVER_ANAYTICS_TEXT}
        </p>
      </ListGroup.Item>
      <ListGroup.Item className="bg-color-gray only_bottom_border px-3 pt-4 pb-1">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="title fw-6 px-1 mb-2 fs-3">Take the Quiz</h6>
          <h4
            className="color-p fsc-16 fw-6 pointer"
            onClick={() => {
              navigate({ to: "/discover/quiz-result" });
              dispatch({ type: "UPDATE_SIDE_BAR" });
            }}
          >
            Past Results
          </h4>
        </div>
        <CustomCard
          title={"How Gameful Are You?"}
          onClick={() => {
            navigate({ to: "quiz/1" });
            dispatch({ type: "UPDATE_SIDE_BAR" });
          }}
          containerClass="quizCard-container mb-4 pointer"
          md={8}
        />
      </ListGroup.Item>
      <ListGroup.Item className="bg-color-gray pb-4 no_border pt-4 bottom-border">
        <h6 className="title fw-6">Skill Badges</h6>
        <Row className="mt-4 text-center">
          {skillBadgeData && skillBadgeData.rows?.length > 0 ? (
            skillBadgeData.rows.map((v, i) => (
              <Col key={"skill-bagde-" + i} sm={3} className="px-2">
                <img
                  width={"62px"}
                  height="62px"
                  src={`${prePath}${v.skill_badge_icon}`}
                  alt={v.skill_badge}
                  className="rounded-circle"
                />
                <p className="color-dark fs-2 mt-2 text-truncate-2">
                  {" "}
                  {v.skill_badge}
                </p>
              </Col>
            ))
          ) : (
            <div className="d-flex flex-column text-center mt-5">
              <p>
                Skill badges earned on completion of <br /> challenges will
                appear here
              </p>
            </div>
          )}
          {skillBadgeData !== undefined ? (
            skillBadgeData?.count > skillBadgeData?.rows?.length ? (
              <>
                {loading && (
                  <div
                    variant="outline-primary "
                    className="mt-3 w-100 border-0 mb-2  fs-16 fw-600 mx-auto"
                    style={{
                      display: "block",
                    }}
                    disabled={true}
                  >
                    <div className="loading">
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  </div>
                )}

                {!loading && (
                  <Button
                    variant={" border-0  w-50"}
                    className="mt-3 w-100 fs-16 fw-600 mx-auto"
                    onClick={moreButtonHandler}
                    disabled={loading}
                  >
                    <span className="color-p fw-6"> View More</span>
                  </Button>
                )}
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Row>
      </ListGroup.Item>
    </div>
  );
}

export default Progress;
