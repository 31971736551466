import { Outlet } from "@tanstack/react-location";
import React from "react";
import Layout from "./layout";

function Dashboard(props) {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default Dashboard;
