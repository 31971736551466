import { useEffect, useState } from "react";
import { Button, Dropdown, Image, ListGroup, Spinner } from "react-bootstrap";
import CustomCard from "../../../common/NormalCards";
import { prePath } from "../../../utils/helper";
import { useNavigate } from "@tanstack/react-location";
import {
  useGetNotesMutation,
  useDeleteNoteMutation,
} from "../../../redux/api/secretLab";
import toast from "react-hot-toast";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import ThreeDotIcon from "../../../assets/threeDotIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { useCallback } from "react";
import CommonPop from "../../../common/Modal/commonModal";
import DeleteImg from "../../../assets/util-icons/delete.svg";

const PER_PAGE = 4;

function Reflection(props) {
  const [notes] = useGetNotesMutation();
  const navigate = useNavigate();
  const [deleteNote] = useDeleteNoteMutation();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [notesData, setNotesData] = useState({});
  const [page, setPage] = useState(1);

  const getNotes = useCallback(
    (page = 1, PER_PAGE, isDeleted) => {
      setLoading(true);

      /**list of cards */
      notes({ page: page, per_page: PER_PAGE })
        .then((res) => {
          if (!res?.error) {
            if (notesData?.result && !isDeleted) {
              setNotesData((prevState) => ({
                ...res?.data,
                result: [...prevState?.result, ...res?.data?.result],
              }));
            } else {
              setNotesData(res.data);
            }
          } else {
            toast.error(res?.error?.data?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [notes, page]
  );

  const deleteReflectionHandler = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };
  useEffect(() => {
    getNotes(page, PER_PAGE);
  }, [getNotes, page]);

  const handleModal = () => {
    setDeleteLoading(true);
    deleteNote(deleteId).then((response) => {
      setDeleteLoading(false);
      if (response?.error) {
        toast.error(response?.error?.data?.message);
      } else {
        setShowModal(!showModal);
        getNotes(1, page * PER_PAGE, true);
        setDeleteId("");
      }
    });
  };

  const moreButtonHandler = () => {
    setPage((state) => state + 1);
  };

  return (
    <div className="user-card more_height bg-color-gray bottom-border">
      <CommonPop
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        handleConfirm={() => handleModal()}
        icon={DeleteImg}
        loading={deleteLoading}
        title={"Are you sure you want to delete this Reflection?"}
      />
      {loading && !notesData?.result?.length ? (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : notesData?.result?.length > 0 ? (
        notesData?.result?.map((res) => (
          <ListGroup.Item key={res.id} className="no_border bg-color-gray px-4">
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex flex-row justify-content-start align-items-start">
                <div className="d-flex flex-row m-2 mt-0">
                  <img
                    src={`${prePath}${res.challenge_activity_image}`}
                    alt="BAD"
                    width={"28px"}
                    className="rounded-circle"
                    height={"28px"}
                  />
                </div>
                <div className="d-flex flex-column justify-content-start align-items-start flex-grow-1">
                  <h6 className="title fw-5 fsc-18  mt-1">
                    {res?.challenge_title}
                  </h6>
                  <h6 className="fw-5 black-color fsc-14 m-2 ms-0 mt-0">
                    {res?.challenge_activity_title}
                  </h6>
                </div>
              </div>
              <Dropdown className="filters-discover p-0 m-0">
                <Dropdown.Toggle className="d-flex align-item-center m-0 p-0 bg-transparent border-0">
                  <Image src={ThreeDotIcon} className="" />
                </Dropdown.Toggle>
                <DropdownMenu align={"end"} className="mt-2 px-3 py-1">
                  <Dropdown.Item
                    className="p-2"
                    onClick={() => {
                      navigate({ to: `/select/details/notes/${res.id}` });
                    }}
                  >
                    <EditIcon />
                    <span className="ms-2 black-color fsc-16 fw-4">Edit</span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="p-2"
                    onClick={() => deleteReflectionHandler(res.id)}
                  >
                    <DeleteIcon />
                    <span className="ms-2 black-color fsc-16 fw-4">Delete</span>
                  </Dropdown.Item>
                </DropdownMenu>
              </Dropdown>
            </div>
            <CustomCard
              title={res.note}
              onClick={() => null}
              containerClass="quizCard-container mb-4"
              date={res.created_at}
            />
          </ListGroup.Item>
        ))
      ) : (
        <div className="no_invitation mt-5 pt-2">
          {" "}
          <div className="d-flex flex-column text-center mt-5">
            <h6 className="title lh-1 mb-3 fw-6">No reflections added yet</h6>
            <p>
              Click the Notes icon at the top of any activity
              <br /> to capture your thoughts and ideas
            </p>
          </div>
        </div>
      )}
      {notesData?.total > notesData?.result?.length && (
        <>
          {loading && (
            <div
              variant="outline-primary "
              className="mt-3 w-100 border-0 mb-2  fs-16 fw-600 mx-auto"
              style={{
                display: "block",
              }}
              disabled={true}
            >
              <div className="loading">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            </div>
          )}
          {!loading && (
            <Button
              variant={" border-0  w-100"}
              className="mb-3 fs-16 fw-600 mx-auto"
              style={{ backgroundColor: "#f9f9f9f9" }}
              onClick={moreButtonHandler}
            >
              <span className="color-p fw-6"> View More</span>
            </Button>
          )}
        </>
      )}
    </div>
  );
}

export default Reflection;
