import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../common/Cards";
import BackArrow from "../../../assets/arrowleft";
import { APP_CONFIG } from "../../../constants/app-config";
import AlertModal from "../../../common/Modal/alertModal";
import { useLogoutMutation } from "../../../redux/api/auth";
import { useDeleteAccountMutation } from "../../../redux/api/subscription";
import toast from "react-hot-toast";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logOut] = useLogoutMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const userData = useSelector((state) => state?.userProfile?.data);

  const [showModal, setShowModal] = useState(false);
  const deleteTitle = "Are you sure you want to Delete this Account?";
  const handleLogOut = () => {
    const Token = {
      refresh_token: localStorage.getItem("REFRESH_TOKEN"),
    };
    logOut(Token).then((res) => {
      if (!res?.error) {
        window.localStorage.clear();
        navigate({ to: "/auth/login" });
        setTimeout(() => {
          dispatch({ type: "USER_LOGOUT" });
        }, 1000);
      }
    });
  };

  const handleModal = () => {
    deleteAccount().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.data?.error);
      } else {
        toast.success("Account deleted successfully.");
        window.localStorage.clear();
        navigate({ to: "/auth/login" });
        setTimeout(() => {
          dispatch({ type: "USER_LOGOUT" });
        }, 1000);
      }
    });
    setShowModal(!showModal);
  };

  const handleOnClick = (v) => {
    if (v.onClick === "logout") {
      handleLogOut();
    } else if (v.onClick === "profile") {
      navigate({ to: "/discover/profile" });
    } else if (v.onClick === "changePassword") {
      navigate({ to: "/discover/changePassword" });
    } else if (v.onClick === "Notifications") {
      navigate({ to: "/discover/notification-setting" });
    } else if (v.onClick === "subscription") {
      navigate({ to: "/discover/manage-subscription" });
    } else if (v.onClick === "delete") {
      setShowModal(!showModal);
    } else if (v.onClick === "Terms&Conditions") {
      window.open("https://superbetter.com/terms", "_blank").focus();
      // dispatch({
      //   type: "ADMIN_CMS",
      //   payload: "",
      // });
      // navigate({ to: "/discover/terms&condition" });
    } else if (v.onClick === "PrivacyPolicy") {
      window.open("https://superbetter.com/privacy", "_blank").focus();
      // dispatch({
      //   type: "ADMIN_CMS",
      //   payload: "",
      // });
      // navigate({ to: "/discover/privacyPolicy" });
    } else if (v.onClick === "FAQs") {
      window
        .open(
          "https://superbetter.zendesk.com/hc/en-us/categories/13026840510363-Frequently-Asked-Questions",
          "_blank"
        )
        .focus();
      // navigate({ to: "/discover/faqs" });
    } else if (v.onClick === "ContactUs") {
      // navigate({ to: "/discover/contactUs" });
      window.open("https://superbetter.zendesk.com/", "_blank").focus();
    }
  };

  return (
    <Container className="content-container settings ml-4">
      <h5 className="title fw-6 ex-s mar-ps ">
        <div
          className="pointer mr-1 d-inline"
          onClick={() => navigate({ to: "/discover/dashboard" })}
        >
          <BackArrow />
        </div>
        Settings
      </h5>
      {APP_CONFIG.SETTINGS.map((v, i) => {
        return v.value === "Change Password" && !userData.is_social_signup ? (
          <Card
            key={"menu--bar-item" + i}
            title={
              <>
                <img src={v.icon} alt={v.value} />
                <span
                  className="ml-4 title  fw-400"
                  style={{
                    fontSize: "16px !important",
                    color: "#121212 !important",
                  }}
                >
                  {v.value}
                </span>
              </>
            }
            containerClass="mb-3 settings-card"
            hideNavBtn={v.singleOption}
            onClick={() => handleOnClick(v)}
          />
        ) : (
          v.value !== "Change Password" && (
            <Card
              key={"menu--bar-item" + i}
              title={
                <>
                  <img src={v.icon} alt={v.value} />
                  <span
                    className="ml-4 title  fw-400"
                    style={{
                      fontSize: "16px !important",
                      color: "#121212 !important",
                    }}
                  >
                    {v.value}
                  </span>
                </>
              }
              containerClass="mb-3 settings-card"
              hideNavBtn={v.singleOption}
              onClick={() => handleOnClick(v)}
            />
          )
        );
      })}
      <div className="d-flex">
        {APP_CONFIG.SETTING_LOWER.map((v, i) => {
          return (
            <Card
              key={"menu--bar-" + i}
              titleClass="col-md-12"
              title={
                <div style={{ display: "contents" }}>
                  <img src={v.icon} alt={v.value} />
                  <span
                    className="ml-4 title "
                    style={{
                      fontSize: "16px !important",
                      color: "#121212 !important",
                    }}
                  >
                    {v.value}
                  </span>
                </div>
              }
              containerClass="mb-3 settings-card setting-lower-section"
              hideNavBtn={v.singleOption}
              onClick={() => handleOnClick(v)}
            />
          );
        })}
      </div>
      <AlertModal
        delete={true}
        setShowModal={() => setShowModal(!showModal)}
        showModal={showModal}
        title={deleteTitle}
        endPlanDate={""}
        handleCancelPlan={() => handleModal()}
      />
    </Container>
  );
}

export default Settings;
