import React from "react";

export default function history({active}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.66699V10.0003L11.6667 11.667"
        stroke={active?'#F5AB38':"#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.54199 9.16868C2.72873 7.33539 3.58388 5.63502 4.94427 4.39198C6.30466 3.14894 8.07507 2.45025 9.91772 2.4292C11.7604 2.40815 13.5463 3.06623 14.9347 4.27787C16.3231 5.48951 17.2169 7.16991 17.4455 8.99845C17.674 10.827 17.2214 12.6757 16.174 14.1918C15.1265 15.7079 13.5575 16.7854 11.7664 17.2186C9.97526 17.6517 8.08733 17.4103 6.46282 16.5404C4.83831 15.6705 3.59093 14.2329 2.95866 12.502"
        stroke={active?'#F5AB38':"#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.54199 16.6686V12.502H6.70866"
        stroke={active?'#F5AB38':"#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
