import React from "react";
import empIcon from "../../assets/skill-badges/thinking.png";
import { prePath } from "../../utils/helper";

const OutlineChallengesCard = (props) => {
  return (
    <div className="oultline-challenge-card" onClick={props.onClick}>
      <div>
        <img
          width="74px"
          height="74px"
          className="mt-4 mb-2 rounded-circle"
          src={props?.icon === null ? empIcon : `${prePath}${props?.icon}`}
          alt="icon"
        />
      </div>
      <h5 className="mt-2 title spaces fw-6 mb-3">{props.name}</h5>
      {/* <h6 className="mt-2 title spaces fw-5 text-dark mb-3 fw-4 text-truncate"><b className="text-dark fw-6">Epic Win: </b>{props.epic}</h6> */}
    </div>
  );
};

export default OutlineChallengesCard;
