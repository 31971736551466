import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const invitation = createApi({
  reducerPath: "invitationData",
  baseQuery: baseQueryWithReAuth({ baseUrl: `${API_BASE_URL}user/auth` }),
  endpoints: (builder) => ({
    getInvitations: builder.mutation({
      query: (body) => ({
        url: `/invitations`,
        method: "GET",
        params: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    updateInvitations: builder.mutation({
      query: (body) => ({
        url: `/invitation-verfication/${body}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetInvitationsMutation, useUpdateInvitationsMutation } =
  invitation;
