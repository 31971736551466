import { useNavigate } from "@tanstack/react-location";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/logo.png";
import { APP_CONFIG } from "../../constants/app-config";

function Landing(props) {
  const navigate = useNavigate();
  return (
    <Container className="landing-container">
      <Row>
        <Col xs={12}></Col>
        <Col>
          <img
            src={logo}
            alt="Super Better"
            className="cursor"
            onClick={() => navigate({ to: "/" })}
          />
          <h6 className=" tagline-color fonts-It f20">
            {APP_CONFIG.LANDING_AUTH_TAGLINE}
          </h6>
        </Col>
        <Col xs={12}></Col>
      </Row>
    </Container>
  );
}

export default Landing;
