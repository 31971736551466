const initState = {
  userChallenge: {},
  cardDetail: {},
  cardSelected: {},
  pieChart: {},
  secretIdentity: null,
  challengeDaysDetails: {},
};

const userChallengeReducer = (state = initState, action) => {
  switch (action.type) {
    case "USER_CHALLENGE":
      return {
        ...state,
        userChallenge: action?.payload?.data,
      };
    case "USER_CHALLENGE_TIPS":
      return {
        ...state,
        userChallenge: { tips: action?.payload, ...state?.userChallenge },
      };
    case "SELECTED_CARD_DETAIL":
      return {
        ...state,
        cardDetail: action?.payload,
      };
    case "USER_CARD_SELECTED":
      return {
        ...state,
        loading: action?.payload?.loading,
        cardSelected: action?.payload?.data,
      };
    case "USER_PIE_CHART":
      return {
        ...state,
        pieChart: action?.payload?.data,
      };
    case "USER_SECRET_IDENTITY":
      return {
        ...state,
        secretIdentity: action?.payload,
      };
    case "USER_CHALLENGE_DAYS":
      return {
        ...state,
        challengeDaysDetails: action?.payload,
      };
    default:
      return state;
  }
};

export default userChallengeReducer;
