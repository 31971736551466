import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "../Buttons";
import ModalCrossIcon from "../../assets/modalCross";
import yellowArrow from "../../assets/yellow-Arrow.svg";

function UpgradePop(props) {
  return (
    <Modal
      size="md"
      contentClassName="mx-auto"
      show={props.showModal}
      centered
      onHide={() => props.setShowModal(false)}
    >
      <div onClick={() => props.setShowModal(false)}>
        <ModalCrossIcon className="hero-cross" />
      </div>
      <Modal.Body>
        <Row className="mb-3 modal-section-pop upgarde-pop-bg">
          <h5 className="title mt-2 f18">
            {props?.isFreeTrialOrPromo ? "Hero Account" : "Hero Account"} <br />{" "}
            {props?.plan?.pricing?.split(" ")?.join("")}
          </h5>
        </Row>
        <div className="mt-4 pt-1 mb-4">
          {props?.plan?.features?.map((res, i) => (
            <Row
              key={i}
              className="fs-2 fw-4 text-light analytics-text inner-battle mt-2"
            >
              <Col sm={1}>
                <img
                  className={`bad-Arrow ${
                    props.plan.length === i + 1 && "bottom-margin"
                  }`}
                  src={yellowArrow}
                  alt=""
                />
              </Col>
              <Col
                className={`f16 fw-4 text-dark analytics-text inner-text-area px-1 ${
                  props.plan.length === i + 1 && "bottom-margin"
                }`}
              >
                {res}
              </Col>
            </Row>
          ))}
        </div>
        <Row className="justify-content-md-center mb-2">
          <Button
            label={props?.isFreeTrialOrPromo ? "Subscribe" : "Subscribe"}
            customizeClass="save-modal  w-auto"
            onClick={() => props.handleCancelPlan()}
          />
          {!props?.isFreeTrialOrPromo && (
            <Col sm={12} className="mb-2 mt-3">
              {" "}
              <h4
                className="color-p text-center fsc-16 fw-6 pointer"
                onClick={() => {
                  props.openPromoModal();
                }}
              >
                Enter a promo code
              </h4>
            </Col>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default UpgradePop;
