const initState = {
    alert: {
        varient:"",
        massage:""
    },
  };
  
  const alertReducer = (state = initState, action) => {
    switch (action.type) {
      case "ERROR":
        return {
          ...state,
          alert: {
            varient:"danger",
            massage:action.payload
          },
        };
      case "Success":
        return {
          ...state,
          alert: {
            varient:"success",
            massage:action.payload
          },
        };
      default:
        return state;
    }
  };
  
  export default alertReducer;