import { useNavigate } from "@tanstack/react-location";
import Nav from "react-bootstrap/Nav";
import Button from "../Buttons";

function Switch(props) {
  const navigate = useNavigate();

  return (
    <Nav className={`switch ${props.containerClass}`} variant="pills">
      {props.values.map((v, i) => (
        <Nav.Item key={"nav-pills--" + i}>
          <Button
            customizeClass={`pill-btn fw-6 ${
              props.activeSwitch === v.label ? "active" : ""
            }`}
            label={v.label}
            onClick={() => {
              navigate({ search: { switch: v.label } });
              props.setActiveSwitch(v.label);
            }}
          />
        </Nav.Item>
      ))}
    </Nav>
  );
}

export default Switch;
