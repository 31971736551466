import { Outlet, Router } from "@tanstack/react-location";
import { location, routes } from "./routes";
import ScrollToTop from "./utils/scrollToTop"

function App() {
  return (
    <Router location={location} routes={routes}>
      <ScrollToTop/>
      {/* rendering pages */}
      <Outlet />
    </Router>
  );
}

export default App;
