import { Container, Image } from "react-bootstrap";
import { toast } from "react-hot-toast";
import EmailVerified from "../../../assets//email-verified.svg";
import {
  useOtpVerificationMutation,
  useResendEmailVerificationMutation,
} from "../../../redux/api/auth";
import { useFormik } from "formik";
import { OTPSchema } from "../../../validation-schemas/auth-schema";
import Button from "../../../common/Buttons";
import InputBox from "../../../common/InputBox";
import { useNavigate } from "@tanstack/react-location";

const Verification = ({ isChild, token, emailID }) => {
  const navigate = useNavigate();
  const [otpVerification, otpVerificationValues] = useOtpVerificationMutation();

  const [triggerResendEmailVerification] = useResendEmailVerificationMutation();
  const initialValues = {
    otp: "",
  };

  const onSubmit = (values) => {
    otpVerification({
      passcode: values.otp,
    })
      .then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.error);
        } else {
          localStorage.setItem("isFirstTimeUser", true);
          navigate({ to: "/select/selectAccount", search: { show: true } });
        }
      })
      .catch((e) => toast.error(e?.message));
  };
  const formik = useFormik({
    initialValues,
    validationSchema: OTPSchema,
    onSubmit,
  });

  const resendEmail = () => {
    triggerResendEmailVerification(token).then((res) => {
      if (!res.error) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data?.error?.message);
      }
    });
  };

  return (
    <>
      {isChild ? (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <Image src={EmailVerified} className="img-fluid" />
          <h1 className="text-dark fsc-30 fw-6 mt-4">
            {isChild ? "Email sent for approval" : "Check Your Email"}
          </h1>

          <p className="text-center">
            We’ve sent a verification link to <br />
            <span className="color-p">{emailID}</span> <br />
            Click the link to verify your account.
          </p>

          <p className="mt-4">
            Didn’t receive the mail yet?{" "}
            <span className="color-s cursor" onClick={() => resendEmail()}>
              Resend Mail
            </span>
          </p>
        </div>
      ) : (
        <>
          <h2 className="color-s content-heading ">
            Passcode Verification <span className="bg-color-s ml-1"></span>
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <p className="">
              We’ve sent a verification passcode to <br />
              <span className="color-p">{emailID}</span> <br />
            </p>

            <InputBox
              label="Enter Passcode"
              type="text"
              containerClass="mt-3"
              name="otp"
              placeholder={"Passcode"}
              formik={formik}
              values={formik.values.otp}
              isError={formik.errors.otp && formik.touched.otp}
              error={formik.errors.otp}
            />
            <Container fluid={true} className="text-center mt-5 fs-2">
              <Button
                type="submit"
                customizeClass="mb-4"
                label={"Submit"}
                customProps={{
                  disabled: otpVerificationValues.isLoading,
                }}
              />

              <p className="mt-4 em-l-txt">
                Didn’t receive the Passcode yet?{" "}
                <span className="color-s cursor" onClick={() => resendEmail()}>
                  Resend Passcode
                </span>
              </p>
            </Container>
          </form>
        </>
      )}
    </>
  );
};
export default Verification;
