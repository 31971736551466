import React from "react";
import Button from "../../../common/Buttons";
import { Container, ListGroup } from "react-bootstrap";
import { DEFAULT_VALUES } from "../../../constants/default-values";
import { useNavigate } from "@tanstack/react-location";
import BackArrow from "../../../assets/arrowleft";

function Quiz() {
  const navigate = useNavigate();

  return (
    <Container className="content-container quiz c-qz">
      <h5 className="title fw-6 ex-s mar-ps  ">
        <div
          className="pointer  d-inline"
          onClick={() => navigate({ to: "/discover/dashboard" })}
        >
          <BackArrow />
        </div>
        How Gameful Are You?
      </h5>
      {/* <h5 className="title mb-4 f22">How Gameful Are You?</h5> */}
      <h6 className="title mb-3 mt-4 f20">About This Quiz</h6>
      <div className="mb-4">
        <p>{DEFAULT_VALUES.QUIZ.ABOUT1}</p>
        <p className="mb-4">{DEFAULT_VALUES.QUIZ.ABOUT2}</p>
      </div>
      <h6 className="title mb-3 mt-4 f20">Score Details</h6>

      <ListGroup className="mb-5 bd-rd10">
        {DEFAULT_VALUES.QUIZ.SCORE_DETAILS.map((v, i) => (
          <ListGroup.Item
            key={i}
            className={i === 4 ? "title br-btm" : "title"}
          >
            <div
              className={
                i === 0
                  ? "quiz-box-f b-fn f16"
                  : i !== 4
                  ? "quiz-box-h b-fn f16"
                  : "quiz-box-nb b-fn f16"
              }
            >
              <span className="fw-6 color-s mr-3 ">{i + 1} -</span>{" "}
              <span
                className=" fw-3 fsc-16"
                style={{ color: "#121212", opacity: "0.9" }}
              >
                {v}
              </span>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Button
        customizeClass="mr-2 mt-5 mb-4 btn-i80 pu-btn-m"
        label="Take Quiz"
        onClick={() => navigate({ to: "/discover/take-quiz" })}
      />
    </Container>
  );
}

export default Quiz;
