import React from "react";
import { Modal } from "react-bootstrap";

const SimpleModal = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      {props.component}
    </Modal>
  );
};

export default SimpleModal;
