import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, ListGroup } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { ProgressBar } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { data } from "./config";
import {
  useGetQuizMutation,
  useGetHistoryMutation,
} from "../../../../redux/api/quiz";
import BackArrow from "../../../../assets/arrowleft";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "../../../../common/Buttons";
import { useDispatch } from "react-redux";

const emptyFunction = () => {
  return (
    <div className="no_invitation mt-5">
      {" "}
      <div className="d-flex  flex-column text-center">
        <h6 className="title lh-1 fw-6  mb-3">
          Discover your gameful strengths
        </h6>
        <p>
          Find your scores here after you
          <br /> complete the quiz
        </p>
      </div>
    </div>
  );
};

function Result() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [quizResult, setQuizResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [historyDetail, setHistoryDetail] = useState([]);
  const [getQuiz] = useGetQuizMutation();
  const [getHistory] = useGetHistoryMutation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        grid: {
          borderDash: [8, 4],
          color: "#BDBDBD",
        },
      },

      y: {
        min: 0,
        max: 100,

        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          // text: "In %",
          font: {
            size: 16,
            color: "#121212",
            weight: 600,
          },
        },
        grid: {
          borderDash: [8, 4],
          color: "#BDBDBD",
        },
      },
    },
  };

  const labels = historyDetail.map((res) => res.created_at);
  const dataSet = {
    labels,
    datasets: [
      {
        label: "Score",
        fill: true,
        lineTension: 0.1,
        borderColor: "#F5AB38",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#F5AB38");
          gradient.addColorStop(1, "rgba(255, 255, 255, 0.3)");
          return gradient;
        },
        pointBorderColor: "#F5AB38",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#F5AB38",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 5,
        data: historyDetail.map((res) => res.total),
      },
    ],
  };

  useEffect(() => {
    setLoading(true);
    /** API to get Quiz Result */
    getQuiz().then((res) => {
      if (!res?.error) {
        setQuizResult(res.data);
      }
      setLoading(false);
    });
    getHistory().then((res) => {
      if (!res?.error) {
        setHistoryDetail(res.data);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Container className="content-container">
      <h6 className="title fw-6 ex-s mar-ps f24 mb-4">
        <span
          className="pointer mr-1"
          onClick={() => navigate({ to: "/discover/dashboard" })}
        >
          <BackArrow />
        </span>
        How Gameful Are You?
      </h6>

      {loading ? (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : !quizResult && quizResult === null ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          {emptyFunction()}
          <div className="w-100 d-flex justify-content-center">
            <Button
              type="button"
              label={"Take the Quiz"}
              customizeClass="lower-button mx-auto"
              onClick={() => {
                navigate({ to: "/discover/quiz/1" });
                dispatch({ type: "UPDATE_SIDE_BAR" });
              }}
            />
          </div>
        </div>
      ) : (
        !loading && (
          <section className="main">
            {/* first div */}
            <div className="first-div mb-4">
              {/* Score */}
              <div className="first-div-score mr-4">
                <span className="color-dark fw-6 f20 black-color">
                  Overall Score
                </span>
                {/* <div className="bar">
                  <CircularProgressbarWithChildren
                    circleRatio={1}
                    value={quizResult?.total || 0}
                    styles={buildStyles({
                      rotation: 1 / 2.25 + 1 / 8,
                      strokeLinecap: "round",
                      pathTransitionDuration: 0.9,
                      pathColor: "#4A60AB",
                      textColor: "#f88",
                      trailColor: "#F9F9F9",
                      backgroundColor: "#3e98c7",
                    })}
                  >
                    <strong className="f40">{quizResult?.total || 0}</strong>
                    <p className="f20">out of 100</p>
                  </CircularProgressbarWithChildren>
                </div> */}
                <div className="bar p-2 pe-3">
                  <CircularProgressbarWithChildren
                    circleRatio={0.85}
                    strokeWidth="12"
                    className="progress-bar-1"
                    value={quizResult?.total || 0}
                    styles={buildStyles({
                      rotation: 1 / 2.25 + 1 / 8,
                      strokeLinecap: "round",
                      pathTransitionDuration: 0.9,
                      pathColor: "#4A60AB",
                      textColor: "#f88",
                      trailColor: "#F9F9F9",
                      backgroundColor: "#3e98c7",
                    })}
                  >
                    <strong className="f40 lh-1 black-color">
                      {quizResult?.total}
                    </strong>
                    <p className="f20 chart-inner-color">Out of 100</p>
                    {/* </CircularProgressbarWithChildren> */}
                  </CircularProgressbarWithChildren>
                </div>
              </div>

              {/* strengths */}
              <div className="first-div-strength">
                <div className="top px-3">
                  <span className="color-dark fw-6 f20 black-color">
                    Gameful Strengths
                  </span>
                  <span className="color-light f14 fw-3">( Out of 10 )</span>
                </div>
                <div className="stripe c-qz">
                  <ListGroup>
                    {quizResult &&
                      data?.map((v, i) => (
                        <ListGroup.Item key={i} className="title bd-n">
                          <div
                            className={
                              i + 1 === data.length
                                ? "p-box-nb b-fn px-0 f16"
                                : "p-box-h b-fn px-0 f16"
                            }
                          >
                            <Row className="justify-content-flex-start align-items-center px-0 game-ch">
                              <Col className="title" sm={4}>
                                {" "}
                                {v.title}
                              </Col>
                              {/* <Col sm={7} className="cl-game">
                                {" "}
                                <ProgressBar
                                  striped
                                  variant="warning"
                                  now={`${parseInt(quizResult[v.name]) + "0"}`}
                                />
                              </Col> */}
                              <Col md={7}>
                                <ProgressBar
                                  striped
                                  style={{ height: "0.5rem" }}
                                  variant="warning"
                                  now={`${parseInt(quizResult[v.name]) + "0"}`}
                                />
                              </Col>
                              <Col
                                sm={1}
                                className="fw-6 f12 b-fn text-right cl-game2"
                              >
                                {quizResult[v.name] || 0}
                              </Col>
                            </Row>
                          </div>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </div>
              </div>
            </div>
            <hr className="divd-his fw-3" />
            <div className="summary mt-2">
              <span className="black-color fw-6 f18">
                Interpreting Your Results{" "}
              </span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                How Gameful Are You? Your overall score measures the benefits of
                adopting a gameful mindset in all life. The score is a way to
                compare how your gameful mindset is changing over time.
              </p>
            </div>
            <div className="summary mt-1">
              <span className="black-color fw-6 f18">Challenge Mindset: </span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                A Challenge Mindset is the ability to energy with obstacles by
                focusing on the potential for growth & positive outcome. The
                SuperBetter rule of Challenging Yourself can positively
                influence this score.
              </p>
            </div>
            <div className="summary mt-1">
              <span className="black-color fw-6 f18">Emotional Control: </span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                Emotional control is the ability to identify and regulate your
                emotional state. Collecting and activating power-ups can
                positively impact this core.
              </p>
            </div>
            <div className="summary mt-1">
              <span className="black-color fw-6 f18">Mental Flexibility: </span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                The capacity to adapt your thinking and behaviour in response to
                your environment. Finding and battling bad guys can positively
                impact this score.
              </p>
            </div>
            <div className="summary mt-1">
              <span className="black-color fw-6 f18">Sense of Purpose: </span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                Awareness of an intention to achieve a long-term goal that is
                personally meaningful and makes a positive mark on the world.
                Seeking out and completing Quests toward a longer-term goal can
                positively impact this score.
              </p>
            </div>
            <div className="summary mt-1">
              <span className="black-color fw-6 f18">Self Efficacy: </span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                A belief in one’s ability to successfully achieve their goals.
                Paying extra attention to your secret identity can positively
                impact this score.
              </p>
            </div>
            <div className="summary mt-1">
              <span className="black-color fw-6 f18">Optimism:</span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                The belief that a favourable outcome is not just possible; but
                likely. Going for a motivating epic win can positively impact
                this score.
              </p>
            </div>
            <div className="summary mt-1">
              <span className="black-color fw-6 f18">
                Social Connectedness:{" "}
              </span>
              <p className="h-txt fw-4 mt-3 f16 op-c">
                A state of feeling connected to other people. Supporting and
                being supported by allies is a way to positively impact this
                score.
              </p>
            </div>

            <div className="historiacl-data"></div>
          </section>
        )
      )}
      {!loading && quizResult && (
        <div className="w-8">
          <h6
            className="title text-end fw-6 f20 pe-4"
            style={{ width: "710px" }}
          >
            Historical Data
          </h6>
          <Line options={options} data={dataSet} width={710} height={350} />
        </div>
      )}
    </Container>
  );
}

export default Result;
