const initState = {
  allInvitation: [],
  selectedInvitation: {},
};

const invitationReducer = (state = initState, action) => {
  switch (action.type) {
    case "ALL_INVITATION":
      return {
        ...state,
        allInvitation: [action.payload.data],
      };
    case "SELECTED_INVITATION":
      return {
        ...state,
        selectedInvitation: action.payload,
      };
    default:
      return state;
  }
};

export default invitationReducer;
