import React, { memo } from "react";
import { useNavigate } from "@tanstack/react-location";
import BackArrow from "../../../assets/arrowleft";
import { Container, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useAdminCmsMutation } from "../../../redux/api/subscription";
import { useState } from "react";
import { useCallback } from "react";

const TermsAndCondition = memo((props) => {
  const navigate = useNavigate();
  const [adminCms] = useAdminCmsMutation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleApi = useCallback(() => {
    setLoading(true);
    adminCms(3).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.data?.error);
      } else {
        if (response.data.description !== "") {
          const { description = "" } = response.data;
          const updatedDes = description.replace(/&lt;/g, "<");
          setData(updatedDes);
        }
      }
      setLoading(false);
    });
  }, [adminCms]);

  useEffect(() => {
    handleApi();
  }, [handleApi]);

  return (
    <Container className="content-container settings profile ml-4">
      <h5 className="title fw-6 mar-p">
      <a className="pointer" onClick={() => navigate({ to: "/discover/settings" })}>
          <BackArrow />
        </a>
        Terms And Condition
      </h5>
      {loading && data === null && (
        <div className="d-flex justify-content-center align-items-center py-5">
          <Spinner animation="border" />
        </div>
      )}
      {data !== null && !loading && (
        <div
          className="termsCondition"
          dangerouslySetInnerHTML={{ __html: data }}
        />
      )}
      {data === null && !loading && 'No data found.'}
    </Container>
  );
});

export default TermsAndCondition;
