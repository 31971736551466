import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMatch, useNavigate, useSearch } from "@tanstack/react-location";
import Button from "../../../../common/Buttons";
import { useAddNotesMutation } from "../../../../redux/api/challenge";
import toast from "react-hot-toast";
import {
  useGetNoteDetailsMutation,
  useUpdateNoteMutation,
} from "../../../../redux/api/secretLab";

function Notes() {
  const navigate = useNavigate();
  const search = useSearch();
  const { params } = useMatch();
  const { id } = params;
  const UserData = useSelector((state) => state.userChallence);
  const { cardDetail } = UserData;
  const [triggerAddNotes] = useAddNotesMutation();
  const [getNoteDetails] = useGetNoteDetailsMutation();
  const [updateNote] = useUpdateNoteMutation();
  const [error, setError] = useState("");
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  // const count = notes.length;
  const handleSubmit = () => {
    const payload = {
      cardDetail,
      inner: { note: notes },
    };
    if (notes) {
      if (id) {
        delete payload.cardDetail;
        payload.id = id;
        updateNote(payload)
          .then((response) => {
            if (response?.error) {
              toast.error(response?.error?.data?.message);
            } else {
              navigate({
                to: `/discover/dashboard/`,
                search: {
                  switch: "Reflections",
                },
              });
            }
          })
          .catch((err) => {
            toast.error(err?.message);
          });
      } else {
        triggerAddNotes(payload)
          .then((response) => {
            if (response?.error) {
              toast.error(response?.error?.data?.message);
            } else {
              navigate({
                to: `/select/details/${
                  cardDetail.type === "ally_check_in"
                    ? "ally"
                    : cardDetail.type.replace("_", "-")
                }`,
                search: {
                  page: search?.page,
                },
              });
            }
          })
          .catch((err) => {
            toast.error(err?.message);
          });
      }
    } else {
      setError("Please write a note");
    }
  };

  const handleNotes = (value) => {
    if (value.length <= 500) {
      setNotes(value);
    }

    setTotalCount(value.length);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getNoteDetails(id).then((response) => {
        if (response?.error) {
          toast.error(response?.error?.data?.message);
        } else {
          setNotes(response.data.note);
          setTotalCount(response.data.note.length);
        }
        setLoading(false);
      });
    }
  }, [getNoteDetails, id]);

  return (
    <div className="notesContainer notes">
      <Container sm={2}>
        <Row className="justify-content-md-center">
          <Col sm={6} className="justify-content-md-center notes-heading">
            Reflections
          </Col>
        </Row>
        {loading ? (
          <div className="loading">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          <>
            <Form>
              <Row className="justify-content-md-center">
                <Col sm={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="notes-title fsc-16">
                      My Notes
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      maxLength={500}
                      value={notes}
                      onChange={(e) => handleNotes(e.target.value)}
                      placeholder="What did I notice?"
                    />
                    <p className="counting">{totalCount}/500</p>
                    {error && <p className="noteError">{error}</p>}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row className="justify-content-md-center margins">
              <Button
                label={id ? "Save" : "Log It"}
                onClick={handleSubmit}
                customizeClass="lower-button pu-btn-m"
              />
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}

export default Notes;
