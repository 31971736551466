import * as Yup from "yup";
import { VALIDATION_MESSAGE } from "../constants/validation-message";
import { passwordValidation } from "../utils/helper";

export const loginSchema = Yup.object().shape({
  emailOrUsername: Yup.string().required(
    VALIDATION_MESSAGE.EMAIL_OR_USERNAME_EMPTY
  ),
  password: Yup.string().required(VALIDATION_MESSAGE.PASSWORD_EMPTY),
});
export const OTPSchema = Yup.object().shape({
  otp: Yup.string()
    .required("Please enter your passcode")
    .min(6, "Please should be 6 letter")
    .max(6, "Please should be 6 letter"),
});

export const forgotPasswordSchema = Yup.object().shape({
  emailOrUsername: Yup.string()
    .email(VALIDATION_MESSAGE.EMAIL_INVALID)
    .required(VALIDATION_MESSAGE.EMAIL_EMPTY),
});

export const resetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required(VALIDATION_MESSAGE.PASSWORD_EMPTY)
    .min(8, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH)
    .matches(/\d/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .matches(/[a-zA-Z]/, VALIDATION_MESSAGE.PASSWORD_INVALID),

  confirm_new_password: Yup.string()
    .required(VALIDATION_MESSAGE.PASSWORD_EMPTY)
    .min(8, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH)
    .matches(/\d/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .matches(/[a-zA-Z]/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .oneOf([Yup.ref("new_password"), null], VALIDATION_MESSAGE.PASSWORD_MATCH),
});

export const registerSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "First name can only contain letters."
    )
    .required(VALIDATION_MESSAGE.FIRST_NAME),
  last_name: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Last name can only contain letters."
    )
    .required(VALIDATION_MESSAGE.LAST_NAME),
  email: Yup.string().required(VALIDATION_MESSAGE.EMAIL_OR_USERNAME_EMPTY),
  password: Yup.string()
    .required(VALIDATION_MESSAGE.PASSWORD_EMPTY)
    .min(8, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH)
    .matches(/\d/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .matches(/[a-zA-Z]/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .test({
      name: "password-validation",
      test: function (value) {
        const { path, createError } = this;

        return passwordValidation(value, createError, path);
      },
    }),
});

export const registerSchemaVerification = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "First name can only contain letters."
    )
    .required(VALIDATION_MESSAGE.FIRST_NAME),
  last_name: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Last name can only contain letters."
    )
    .required(VALIDATION_MESSAGE.LAST_NAME),
  email: Yup.string(),
  password: Yup.string()
    .required(VALIDATION_MESSAGE.PASSWORD_EMPTY)
    .min(8, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH)
    .matches(/\d/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .matches(/[a-zA-Z]/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .test({
      name: "password-validation",
      test: function (value) {
        const { path, createError } = this;

        return passwordValidation(value, createError, path);
      },
    }),
});
export const migratingUserRegisterSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "First name can only contain letters."
    )
    .required(VALIDATION_MESSAGE.FIRST_NAME),
  last_name: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Last name can only contain letters."
    )
    .required(VALIDATION_MESSAGE.LAST_NAME),
});

export const registerChildSchema = Yup.object().shape({
  guardian_email: Yup.string()
    .email()
    .required(VALIDATION_MESSAGE.GUARDIAN_EMAIL),
});

export const profileSchema = Yup.object().shape({
  fullname: Yup.string().required(VALIDATION_MESSAGE.FIRST_NAME),
  lastname: Yup.string().required(VALIDATION_MESSAGE.LAST_NAME),
  email: Yup.string()
    .email(VALIDATION_MESSAGE.EMAIL_INVALID)
    .required(VALIDATION_MESSAGE.EMAIL_EMPTY),
  newsletter_subscribed: Yup.string().required(),
  birthdate: Yup.string().required(),
});

export const soloSchema = Yup.object().shape({
  title: Yup.string().required(VALIDATION_MESSAGE.TITLE),
  description: Yup.string().required(VALIDATION_MESSAGE.DESCRIPTION),
  powerSelect: Yup.string().required(),
});
export const customSoloChallengeSchema = Yup.object().shape({
  challengeTitle: Yup.string().required("Please enter a challenge name"),
  epicWin: Yup.string().required("Please add your epic win"),
  strengthToBuild: Yup.string().required("Please select strength"),
});
export const promoCodeSchema = Yup.object().shape({
  promoCode: Yup.string()
    .required("Please enter a promo code")
    .matches(/^[0-9A-Za-z]+$/, "Must be a alphanumeric value"),
});

export const notesSchema = Yup.object().shape({
  notes: Yup.string().required(VALIDATION_MESSAGE.NOTES),
});

export const changePassword = Yup.object().shape({
  newPassword: Yup.string()
    .required(VALIDATION_MESSAGE.PASSWORD_EMPTY)
    .min(8, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH)
    .matches(/\d/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .matches(/[a-zA-Z]/, VALIDATION_MESSAGE.PASSWORD_INVALID),
  enterPassword: Yup.string()
    .required(VALIDATION_MESSAGE.PASSWORD_EMPTY)
    .min(8, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH)
    .matches(/\d/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .matches(/[a-zA-Z]/, VALIDATION_MESSAGE.PASSWORD_INVALID),
  confirmNewPassowrd: Yup.string()
    .required(VALIDATION_MESSAGE.PASSWORD_EMPTY)
    .min(8, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH)
    .matches(/\d/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .matches(/[a-zA-Z]/, VALIDATION_MESSAGE.PASSWORD_INVALID)
    .oneOf([Yup.ref("newPassword"), null], VALIDATION_MESSAGE.PASSWORD_MATCH),
});
