import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const challenge = createApi({
  reducerPath: "challengeData",
  baseQuery: baseQueryWithReAuth({ baseUrl: `${API_BASE_URL}user/challenge` }),
  endpoints: (builder) => ({
    getChallenge: builder.mutation({
      query: (res) => ({
        url: `?tab=${res}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    rateActivity: builder.mutation({
      query: (res) => ({
        url: `/${res.challenge_id}/${res.activity_id}/rate-activity`,
        method: "POST",
        body: { like: res.rate },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    getActivityDetails: builder.mutation({
      query: (res) => ({
        url: `/${res.challenge_id}/activity/${res.activity_id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    getInvitations: builder.mutation({
      query: (body) => ({
        url: `/invitations`,
        method: "GET",
        params: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    updateInvitations: builder.mutation({
      query: (array) => ({
        url: `/invitation/${array.id}`,
        method: "PATCH",
        body: { is_invitation_accepted: array.value },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),

      transformResponse: (response) => response.data,
    }),

    lastSelectedChallenge: builder.mutation({
      query: (body) => ({
        url: `/last-selected?isSolo=${body.isSolo}${
          body?.order ? "&&order=" : ""
        }${body?.order ? body?.order : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    updateUserChallenge: builder.mutation({
      query: (body) => ({
        url: `/${body}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    activeChallenge: builder.mutation({
      query: (body) => ({
        url: `?is_active=${body}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    activationChallenge: builder.mutation({
      query: (body) => ({
        url: `/${body.challenge_id}/power-up/${body.id}/activate`,
        method: "PATCH",
        body: body?.newDate,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    activityTrack: builder.mutation({
      query: (body) => ({
        url: `/${body.challenge_id}/activity-track`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    suggestionChallenges: builder.mutation({
      query: (body) => ({
        url: `/suggestion`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    badAddReport: builder.mutation({
      query: (body) => ({
        url: `/${body.cardDetail.challenge_id}/bad-guy/${body.cardDetail.id}/add-report`,
        method: "POST",
        body: body.inner,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    allyCheckedIn: builder.mutation({
      query: (body) => ({
        url: `/${body.challenge_id}/ally/${body.id}/checked-in`,
        method: "PATCH",
        body: body?.newDate,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    questCompleted: builder.mutation({
      query: (body) => ({
        url: `/${body.challenge_id}/quest/${body.id}/completed`,
        method: "PATCH",
        body: body.newDate,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    addNotes: builder.mutation({
      query: (body) => ({
        url: `/${body.cardDetail.challenge_id}/activity/${body.cardDetail.id}/add-note`,
        method: "POST",
        body: body.inner,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    badgeCount: builder.mutation({
      query: (body) => ({
        url: `/${body.id}/badge-counts`,
        method: "POST",
        body: body.date,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    addChallenge: builder.mutation({
      query: (body) => ({
        url: `/${body.id}/activity`,
        method: "POST",
        body: body.data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    trackChallenge: builder.mutation({
      query: (body) => ({
        url: `${body.challenge_id}/${body.id}/select`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    createChallenge: builder.mutation({
      query: (body) => ({
        url: ``,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    getChallengeById: builder.mutation({
      query: (body) => ({
        url: `/${body.id}?isSolo=${body.solo}&date=${body.date}${
          body?.order ? "&order=" : ""
        }${body?.order ? body?.order : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    addChallengePlay: builder.mutation({
      query: (body) => ({
        url: `/${body.c_id}/activity`,
        method: "POST",
        body: body?.data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    updateChallengeActivity: builder.mutation({
      query: (payload) => ({
        url: `/${payload.id}/activity/${payload.activity_id}`,
        method: "PATCH",
        body: payload?.data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    deleteChallengeActivity: builder.mutation({
      query: (payload) => ({
        url: `/${payload.challenge_id}/activity/${payload.activity_id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    archiveOrRestoreChallenge: builder.mutation({
      query: (payload) => ({
        url: `${payload.id}/archive-or-restore`,
        body: payload.body,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    claimEpicWin: builder.mutation({
      query: (payload) => ({
        url: `${payload.id}/claim-epic-win`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    trackBadGuy: builder.mutation({
      query: (body) => ({
        url: `/${body.challenge_id}/activity/${body.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetChallengeMutation,
  useGetInvitationsMutation,
  useUpdateInvitationsMutation,
  useLastSelectedChallengeMutation,
  useUpdateUserChallengeMutation,
  useActiveChallengeMutation,
  useActivationChallengeMutation,
  useBadAddReportMutation,
  useAddNotesMutation,
  useBadgeCountMutation,
  useAddChallengeMutation,
  useTrackChallengeMutation,
  useActivityTrackMutation,
  useSuggestionChallengesMutation,
  useCreateChallengeMutation,
  useGetChallengeByIdMutation,
  useAllyCheckedInMutation,
  useQuestCompletedMutation,
  useRateActivityMutation,
  useGetActivityDetailsMutation,
  useAddChallengePlayMutation,
  useUpdateChallengeActivityMutation,
  useDeleteChallengeActivityMutation,
  useArchiveOrRestoreChallengeMutation,
  useClaimEpicWinMutation,
  useTrackBadGuyMutation,
} = challenge;
