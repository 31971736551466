import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useNavigate, useSearch } from "@tanstack/react-location";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../common/Buttons";
import yellowArrow from "../../../assets/yellow-Arrow.svg";
import {
  useSubscriptionPlanMutation,
  useSubscribeMutation,
  useApplyPromoCodeMutation,
  useFreeTrialMutation,
} from "../../../redux/api/subscription";
import toast from "react-hot-toast";
import { useUpdateProfileMutation } from "../../../redux/api/userProfile";
import SeoLayout from "../../../common/SeoLayout";
import moment from "moment";
import PromoCodeModal from "../../../common/Modal/promoCodeModal";

const isAbove18 = (date) => {
  const currentDate = moment();
  const dob = moment(date);

  const result = currentDate.diff(dob, "years");
  return result >= 18 ? true : false;
};

const SelectAccount = () => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);
  const [subscriptionPlan] = useSubscriptionPlanMutation();
  const [triggerApplyPromoCode] = useApplyPromoCodeMutation();
  const [subscribe] = useSubscribeMutation();
  const [triggerFreeTrial] = useFreeTrialMutation();
  const [triggerUpdateProfile] = useUpdateProfileMutation();
  const userProfileData = useSelector((state) => state.userProfile);
  const [newsCheck, setNewsCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [promoCodeDetails, setPromoCodeDetails] = useState(null);
  const [isFreeTrial] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();

  const { show = false, plan_title = "" } = search;

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setLoading(true);
    /* plans Data fetch */
    subscriptionPlan().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
        setLoading(false);
      } else {
        setPlans(response?.data?.result);
        setLoading(false);
      }
    });
  }, []);

  const updateProfileData = (sb, mainData) => {
    const data = {
      is_plan_viewed: true,
      newsletter_subscribed: newsCheck,
    };
    triggerUpdateProfile(data).then((res) => {
      if (res.error) {
        toast.error(res?.error?.message);
      } else {
        dispatch({ type: "PROFILE_DATA", payload: res?.data?.details });
        localStorage.setItem("USER", JSON.stringify(res.data.details));
        if (sb.title === "Hero") {
          dispatch({ type: "STRIPE_ID", payload: mainData });
          navigate({ to: "/select/stripe", search: { show: true } });
        } else if (sb.title === "freeTrial") {
          navigate({ to: "/select/how-to-play", search: { show: true } });
        } else if (sb.title === "promoCode") {
          navigate({ to: "/select/how-to-play", search: { show: true } });
          setShowModal(false);
        } else {
          navigate({ to: "/select/how-to-play?show=true&account=player" });
        }
      }
      setLoading(false);
    });
  };

  const handleConfirm = (value) => {
    setPromoCodeLoading(true);
    const payload = {
      promo_code: value?.promoCode,
    };
    triggerApplyPromoCode(payload).then((response) => {
      if (response?.error) {
        if (response?.error?.data?.status_code !== 400) {
          toast.error(response.error.data.error);
        }
        setError(response.error.data);
        setPromoCodeLoading(false);
      } else {
        setPromoCodeLoading(false);
        setPromoCodeDetails(response?.data?.result);
      }
    });
  };

  const feedbackHandler = () => {
    updateProfileData({ title: "promoCode" });
  };

  useEffect(() => {
    if (userProfileData?.data?.dob) {
      setNewsCheck(isAbove18(userProfileData?.data?.dob));
    }
  }, [userProfileData?.data?.dob]);

  const handleSubscription = (res) => {
    if (res.title === "Hero") {
      const payload = {
        plan_id: res.id,
      };
      setPromoCodeLoading(true);
      /* subscribe the plan */
      subscribe(payload).then((response) => {
        if (response?.error) {
          toast.error(response?.error?.data?.error);
          setPromoCodeLoading(false);
        } else {
          const mainData = {
            ...response?.data,
            ...res,
          };
          updateProfileData(res, mainData);
          setPromoCodeLoading(false);
        }
      });
    } else if (res.title === "Player") {
      updateProfileData(res, "");
      setPromoCodeLoading(false);
    }
  };

  const handleFreeTrial = (res) => {
    setPromoCodeLoading(true);
    triggerFreeTrial().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.data?.error);
        setPromoCodeLoading(false);
      } else {
        updateProfileData({ title: "freeTrial" });
        setPromoCodeLoading(false);
      }
    });
  };

  return (
    <SeoLayout title="" robot>
      {show ? (
        <div className="select-account-conatiner pt-5">
          <Container fluid>
            <Row className="justify-content-md-center mt-4">
              <Col
                sm={3}
                className={`main-heading ${isFreeTrial && "text-black"}`}
              >
                {" "}
                Select an Account
              </Col>
            </Row>
            {loading ? (
              <div className="loading mt-5">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {showModal && (
                  <PromoCodeModal
                    showModal={showModal}
                    hideModal={closeModal}
                    handleConfirm={handleConfirm}
                    error={error}
                    promoCodeDetails={promoCodeDetails}
                    loading={promoCodeLoading || loading}
                    feedbackHandler={feedbackHandler}
                  />
                )}
                <div className="d-flex justify-content-center gap-5">
                  {plans.map((res, i) => (
                    <Card
                      key={"packages-" + i}
                      className={`cc-d mb-4 ${
                        isFreeTrial && res.key === "hero" ? "hero" : ""
                      } `}
                    >
                      <div className="card-top position-relative">
                        {isFreeTrial && res.key === "hero" && (
                          <div
                            className={`py-1 position-absolute text-center text-white  fsc-14 fw-500  top-0 start-0 end-0 bg-color-p fw-4`}
                            style={{
                              borderTopLeftRadius: "8px",
                              borderTopRightRadius: "8px",
                            }}
                          >
                            14 days free - no credit card required
                          </div>
                        )}
                        <div className="d-flex justify-content-end p-3">
                          <Button
                            label={isFreeTrial ? "14 days free" : res.pricing}
                            customizeClass="top-button"
                            size="sm"
                            customProps={{
                              style: {
                                visibility: !isFreeTrial ? "visible" : "hidden",
                              },
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                          <p className={`top-card-heading fsc-60 }`}>
                            {res.title.toUpperCase()}
                          </p>
                          <h3
                            className={`${
                              isFreeTrial ? "color-p" : "black-color"
                            } fw-4 fsc-16  mb-0`}
                            style={{ lineHeight: "23px", letterSpacing: "1px" }}
                          >
                            {res.key === "player"
                              ? isFreeTrial
                                ? `Squad Play only, ${res?.pricing?.toLowerCase()}`
                                : "Play with squads"
                              : res.key === "hero"
                              ? isFreeTrial
                                ? `Play on your own, ${
                                    res?.pricing?.split(" ")?.join("") || ""
                                  }`
                                : "Play on your own,"
                              : ""}
                          </h3>
                        </div>
                        <div className="d-flex justify-content-center">
                          <p className="lower-heading"></p>
                        </div>
                      </div>
                      <Card.Body className="px-0">
                        {res.features.map((res, i) => (
                          <Row
                            key={"features-" + i}
                            className="fs-2.8 fw-4 w-100 text-light analytics-text inner-battle mt-2"
                          >
                            <Col sm={1}>
                              {isFreeTrial ? (
                                <img
                                  className="bad-Arrow"
                                  src={yellowArrow}
                                  alt=""
                                  style={{ filter: "brightness(0.2)" }}
                                />
                              ) : (
                                <img
                                  className="bad-Arrow"
                                  src={yellowArrow}
                                  alt=""
                                />
                              )}
                            </Col>
                            <Col className="fs-2.8 fw-4 text-dark analytics-text inner-text-area px-0">
                              {res}
                            </Col>
                          </Row>
                        ))}
                        <div className="card-lower-button">
                          <Button
                            disabled={
                              (res.pricing === "Free" &&
                                plan_title === "Free") ||
                              promoCodeLoading
                            }
                            label={
                              res.key === "hero" && isFreeTrial
                                ? "Try for free"
                                : isFreeTrial
                                ? "Sign up"
                                : "Get Started"
                            }
                            customizeClass={`fw-6 dfl-bt ${
                              isFreeTrial && "free-trial"
                            } ${
                              isFreeTrial && res.pricing === "Free" && "player"
                            }`}
                            onClick={() =>
                              res.key === "hero" && isFreeTrial
                                ? handleFreeTrial(res)
                                : handleSubscription(res)
                            }
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
                {plans?.length && (
                  <Row className="justify-content-md-center mt-4">
                    <Col sm={12} className="mb-4 mt-1 sg-up-n">
                      <Form.Check
                        type="checkbox"
                        label={"Get our newsletter for tips, news & updates"}
                        className={`mt-3 d-flex justify-content-center  `}
                        name="newsletter_subscribed"
                        onChange={() => setNewsCheck(!newsCheck)}
                        checked={newsCheck}
                      />
                    </Col>
                    <Col sm={12} className="mb-5 mt-1 sg-up-n">
                      <h4
                        className={`color-p text-center fsc-16 fw-6 pointer`}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Enter a promo code
                      </h4>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Container>
        </div>
      ) : (
        ""
      )}
    </SeoLayout>
  );
};

export default SelectAccount;
