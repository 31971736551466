import MenIcon from "../../../assets/badges/menIcon.svg"
import Dots from "../../../assets/badges/threedots.svg"
import School from "../../../assets/badges/school.svg"
import Brain from "../../../assets/badges/brain.svg"
import Performance from "../../../assets/badges/performance.svg" 
import Care from "../../../assets/badges/care.svg"
import Social from "../../../assets/badges/social.svg"
import SetBack from "../../../assets/badges/setBack.svg"


export const challengeData=[
    {
        name:"Life Skills",
        icon:MenIcon
    },
    {
        name:"School / Work Achievement",
        icon:School 
    },
    {
        name:"Mental Wellbeing",
        icon:Brain 
    },
    {
        name:"Athletic Performance",
        icon:Performance 
    },
    {
        name:"Self Care",
        icon:Care 
    },
    {
        name:"Social Impact",
        icon:Social 
    },
    {
        name:"Overcome a Setback",
        icon:SetBack 
    }
    ,
    {
        name:"Something Else",
        icon:Dots 
    }
]