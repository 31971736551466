import React from "react";
import { Modal, Row, Image } from "react-bootstrap";
import ModalCrossIcon from "../../assets/modalCross";

function ScienceCardModal({ hideModal, showModal, modalDetails }) {
  return (
    <Modal
      contentClassName="mx-auto w-100"
      dialogClassName=""
      size="lg"
      show={showModal}
      centered
      onHide={hideModal}
    >
      <div onClick={hideModal}>
        <ModalCrossIcon className="hero-cross pointer" />
      </div>
      <Modal.Body className="d-flex flex-column p-4 justify-content-center align-items-start">
        <h6
          style={{ color: modalDetails?.color }}
          className="fw-6 mx-auto f22 mb-4 "
        >
          {`The Science Behind ${modalDetails?.title}`}
        </h6>
        <div className="mx-auto">
          <div
            className="termsCondition"
            dangerouslySetInnerHTML={{ __html: modalDetails?.description }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ScienceCardModal;
