import React,{useEffect} from "react";
import { Col, Container, Row,} from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";


function Layout(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("USER") !== null &&
      localStorage.getItem("USER") &&
      localStorage.getItem("ACCESS_TOKEN") !== null &&
      localStorage.getItem("ACCESS_TOKEN")
    ) {

      const user =  JSON.parse(localStorage.getItem("USER"))
      if(user?.is_email_verified){
        navigate({ to: "/discover/dashboard" });
      }
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;

    document.body.appendChild(script);

   
  }, []);

  return (
    <>
    <Container fluid={true} className="auth-container">
      <Row>
        <Col className="d-none d-sm-none d-md-none d-lg-none d-xl-flex">
          {/* generally image component */}
          {props.children[0]}
        </Col>
        <Col className="position-relative">
          {/* main component */}
          {props.children[1]}
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Layout;
