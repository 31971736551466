import { Outlet } from "@tanstack/react-location";
import React from "react";
import Layout from "./layout";

function SelectPages(props) {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default SelectPages;
