import React from "react";
import {
  useGetAllGoalsMutation,
  useSetGoalsMutation,
} from "../../../redux/api/userProfile";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import PenImg from "../../../assets/util-icons/pen.svg";
import SomthingModal from "../../../common/Modal/someting";
import { toast } from "react-hot-toast";
import { useNavigate } from "@tanstack/react-location";

const Goals = () => {
  const navigate = useNavigate();
  const [goalList, setGoalList] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [triggerGeAllGoals] = useGetAllGoalsMutation();
  const [triggerSetGoals] = useSetGoalsMutation();
  const [loading, setLoading] = useState(false);

  const getGoalsList = useCallback(() => {
    setLoading(true);
    triggerGeAllGoals().then((res) => {
      if (!res?.error) {
        setGoalList([res.data.details]);
      }
      setLoading(false);
    });
  }, [triggerGeAllGoals]);

  useEffect(() => {
    getGoalsList();
  }, [getGoalsList]);

  const handleSubmit = (v) => {
    const data = { ...editData };
    data.note = v.notes;
    delete data.image;
    triggerSetGoals(data).then((res) => {
      if (res.error) {
        toast.error(res.error.data.error);
      } else {
        setVisible(false);
        setEditData(null);
        toast.success(res.data.message);
        getGoalsList();
      }
    });
  };

  return (
    <div
      className="px-5 more_height bottom-border"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      {isVisible && (
        <SomthingModal
          handleOnSubmit={(e) => handleSubmit(e)}
          notesValue={editData?.note}
          showModal={isVisible}
          setShowModal={(e) => setVisible(e)}
          handleCancelPlan={(e) => null}
        />
      )}
      {goalList.length === 0 && loading && (
        <div className="d-flex justify-content-center align-items-center py-2">
          <Spinner animation="border"></Spinner>
        </div>
      )}
      {/* {goalList.length > 0 && !loading
        ? goalList.map((item, i) => (
            <div key={"goals-item-" + i} className="my-3">
              <div className="fw-6 text-dark fs-2 mb-1">{item?.title}</div>
              <Card>
                <Card.Body className="d-flex justify-content-between py-2 ">
                  <div> {item?.note}</div>
                  <div>
                    <Image src={PenImg} onClick={() => { setEditData(item); setVisible(true)}} />
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))
        : !loading && <div className="text-center">No goal found.</div>} */}
      {goalList.length > 0 && !loading && (
        <>
          <div className="my-3">
            <div className="fw-6 text-dark f14 c-gts mb-1">My Goal Area</div>
            <Card>
              <Card.Body className="d-flex justify-content-between py-2 ">
                <div>
                  {goalList[0]?.title
                    ? goalList[0]?.title
                    : "Choose a goal area"}
                </div>
                <div>
                  <Image
                    className="pointer"
                    src={PenImg}
                    onClick={() =>
                      navigate({
                        to: "/select/select-challenges",
                        search: { page: "goal" },
                      })
                    }
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
          {goalList[0]?.note && (
            <div className="my-3">
              <div className="fw-6 text-dark f14 c-gts mb-1">
                My Specific Goal
              </div>
              <Card>
                <Card.Body className="d-flex justify-content-between py-2 ">
                  <div className="wh-pre-wrap ">{goalList[0]?.note}</div>
                  <div>
                    <Image
                      src={PenImg}
                      className="pointer"
                      onClick={() => {
                        goalList[0]?.title
                          ? navigate({
                              to: "/select/further-goal",
                              search: {
                                name: goalList[0]?.title,
                                icon: goalList[0]?.image,
                                text: goalList[0]?.note,
                                page: "goal",
                              },
                            })
                          : navigate({
                              to: "/select/select-challenges",
                              search: { page: "goal" },
                            });
                      }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Goals;
