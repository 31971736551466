import React, { useState } from "react";
import { Form, Image, InputGroup } from "react-bootstrap";
import eyeSlash from "../../assets/eye-slash.svg";
import showIcon from "../../assets/show-icon.svg";
import searchIcon from "../../assets/search-normal.svg";
import pencilIcon from "../../assets/pencil.svg";

function InputBox(props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Form.Group className={props.containerClass}>
        {!props.withoutLabel && (
          <Form.Label
            className={
              props.lableSize === "md"
                ? "color-dark fw-6 lable14"
                : "color-dark fw-6"
            }
          >
            {props.label}
          </Form.Label>
        )}
        <InputGroup className={`input-group-type-${props.type}`}>
          <Form.Control
            as={props.asText ? "textarea" : "input"}
            type={props.type && !showPassword ? props.type : "text"}
            placeholder={props.placeholder}
            name={props.name}
            id={props.name}
            value={props.values}
            onChange={props.formik?.handleChange}
            disabled={props.disabled}
            className={props?.inputClass}
            {...props.customProps}
          />

          {props.type === "password" && (
            <InputGroup.Text
              onClick={() => setShowPassword(!showPassword)}
              id="password-adornment"
              className="bg-none"
            >
              <Image src={showPassword ? showIcon : eyeSlash} />
            </InputGroup.Text>
          )}

          {props.type === "search" && (
            <InputGroup.Text id="search-adornment" className="bg-none">
              <Image src={searchIcon} />
            </InputGroup.Text>
          )}

          {(props.type === "fullname" ||
            props.type === "lastname" ||
            props.type === "birthdate" ||
            props.type === "challengeTitle" ||
            props.type === "epicWin" ||
            props.type === "specialCode") && (
            <InputGroup.Text id={`${props.type}-adornment`} className="bg-none">
              <Image src={pencilIcon} />
            </InputGroup.Text>
          )}
        </InputGroup>

        {props.isError && (
          <Form.Control.Feedback type="invalid">
            {props.error}
          </Form.Control.Feedback>
        )}

        <Form.Text className="text-muted">{props.mutedText || ""}</Form.Text>
      </Form.Group>
    </>
  );
}

export default InputBox;
