import React, { useCallback, useEffect, useState } from "react";
import { DEFAULT_VALUES } from "../../../../constants/default-values";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import { prePath } from "../../../../utils/helper";
import Card from "../../../../common/Cards";
import restartIcon from "../../../../assets/restartChallenge.svg";
import popupRestoreIcon from "../../../../assets/popupRestoreIcon.svg";
import CommonPop from "../../../../common/Modal/commonModal";
import { toast } from "react-hot-toast";
import tickImage from "../../../../assets/util-icons/tick-mark.svg";

import {
  useArchiveOrRestoreChallengeMutation,
  useGetInvitationsMutation,
} from "../../../../redux/api/challenge";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";

let minimumNumber = 2;

const scrollToTop = () => {
  document.documentElement.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant", // Optional if you want to skip the scrolling animation
  });
};

const History = ({ activeChallenges, loading, changeFilter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [archiveChallengeId, setArchiveChallengeId] = useState("");
  const [archiveChallenge] = useArchiveOrRestoreChallengeMutation();
  const [getInvitation] = useGetInvitationsMutation();
  const [invitationLoading, setInvitationLoading] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [seeAllSection, setSeeAllSection] = useState(null);
  const [restoreLoading, setRestoreLoading] = useState(false);
  const [current, setCurrent] = useState(minimumNumber);
  const [currentPausedSquad, setCurrentPausedSquad] = useState(minimumNumber);
  const [currentPausedSolo, setCurrentPausedSolo] = useState(minimumNumber);
  const [currentCompletedSquad, setCurrentCompletedSquad] =
    useState(minimumNumber);
  const [currentCompletedSolo, setCurrentCompletedSolo] =
    useState(minimumNumber);

  const archiveChallengeHandler = (id) => (e) => {
    e.stopPropagation();
    setShowModal(true);
    setArchiveChallengeId(id);
  };

  const closeModal = () => {
    setShowModal(false);
    setArchiveChallengeId("");
  };

  const handleConfirm = (value) => {
    setRestoreLoading(true);
    const payload = {
      id: archiveChallengeId,
      body: {
        is_archive: false,
      },
    };

    if (value === "reset") {
      payload.body.is_reset = true;
    } else {
      payload.body.is_reset = false;
    }
    archiveChallenge(payload).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.data?.error);
        setRestoreLoading(false);
      } else {
        setShowModal(!showModal);
        changeFilter();
        setRestoreLoading(false);
      }
    });
  };

  const getChallenges = useCallback(() => {
    setInvitationLoading(true);
    getInvitation({ decline_invitation: true })
      .then((response) => {
        if (response?.error) {
          toast.error(response?.error?.data?.error);
          return;
        }
        setChallenges(response.data);
        dispatch({ type: "ALL_INVITATION", payload: response });
        setInvitationLoading(false);
      })
      .catch((err) => {
        setInvitationLoading(false);
      });
  }, [dispatch, getInvitation]);

  useEffect(() => {
    getChallenges();
  }, [getChallenges]);

  const handleClick = (response, click) => {
    if (click === true) {
      navigate({ to: "/discover/invitations/" + response.id });
      dispatch({ type: "SELECTED_INVITATION", payload: response });
    }
  };

  const moreButtonHandler = () => {
    // setCurrent((state) => state + minimumNumber);

    setCurrent((state) => {
      if (state === minimumNumber) {
        setSeeAllSection("declineInvitation");
        return Infinity;
      } else if (state === Infinity) {
        setSeeAllSection(null);
        return minimumNumber;
      }
    });
    scrollToTop();
  };

  const pausedSquadMoreButtonHandler = () => {
    // setCurrentPausedSquad((state) => state + minimumNumber);
    setCurrentPausedSquad((state) => {
      if (state === minimumNumber) {
        setSeeAllSection("archiveSquad");
        return Infinity;
      } else if (state === Infinity) {
        setSeeAllSection(null);
        return minimumNumber;
      }
    });
    scrollToTop();
  };

  const pausedSoloMoreButtonHandler = () => {
    // setCurrentPausedSolo((state) => state + minimumNumber);

    setCurrentPausedSolo((state) => {
      if (state === minimumNumber) {
        setSeeAllSection("archiveSolo");
        return Infinity;
      } else if (state === Infinity) {
        setSeeAllSection(null);
        return minimumNumber;
      }
    });
    scrollToTop();
  };

  const completedSquadMoreButtonHandler = () => {
    // setCurrentCompletedSquad((state) => state + minimumNumber);

    setCurrentCompletedSquad((state) => {
      if (state === minimumNumber) {
        setSeeAllSection("completedSquad");
        return Infinity;
      } else if (state === Infinity) {
        setSeeAllSection(null);
        return minimumNumber;
      }
    });
    scrollToTop();
  };
  const completedSoloMoreButtonHandler = () => {
    // setCurrentCompletedSolo((state) => state + minimumNumber);
    setCurrentCompletedSolo((state) => {
      if (state === minimumNumber) {
        setSeeAllSection("completedSolo");
        return Infinity;
      } else if (state === Infinity) {
        setSeeAllSection(null);
        return minimumNumber;
      }
    });
    scrollToTop();
  };
  return loading ? (
    <div className="loading">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <div>
      <CommonPop
        showModal={showModal}
        hideModal={closeModal}
        handleConfirm={() => handleConfirm("notReset")}
        icon={popupRestoreIcon}
        loading={restoreLoading}
        buttonText={"From where I left off"}
        title={"How would you like to play?"}
        txt={"Start over"}
        notCancelHandle={() => {
          handleConfirm("reset");
        }}
      />
      {(!seeAllSection || seeAllSection === "declineInvitation") &&
        challenges?.length > 0 && (
          <>
            <div className="d-flex justify-content-between active-cl align-items-center">
              <h5 className="title fw-6 mb-3 mt-4 f22 fs-p-22">
                Squad Invitations
              </h5>
              {!invitationLoading && minimumNumber < challenges?.length && (
                <h4
                  className="color-p fsc-16 mt-4 mb-3 fw-6 pointer"
                  onClick={moreButtonHandler}
                >
                  {current === minimumNumber ? "See All" : "Back"}
                </h4>
              )}
            </div>
            {invitationLoading ? (
              <div className="loading">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              challenges?.slice(0, current)?.map((v, i) => (
                <Card
                  key={"squad-invitation-" + v.id}
                  containerClass={
                    i + 1 === challenges.length
                      ? "squad-invitation mb-0 active-cl"
                      : "squad-invitation mb-4 active-cl"
                  }
                  title={
                    <div className="ml-1">
                      <p className="squad-name mb-1">{v.squad_name}</p>
                      <h6 className="invitation-title">{v.challenge_title}</h6>
                    </div>
                  }
                  customRightBtn={
                    <Row className="me-2">
                      <Col
                        onClick={() => handleClick(v, true)}
                        className="px-0 pointer"
                      >
                        <img src={tickImage} alt="" />
                      </Col>
                    </Row>
                  }
                />
              ))
            )}
          </>
        )}
      {(!seeAllSection || seeAllSection === "archiveSquad") &&
        activeChallenges?.archive?.squad?.length > 0 && (
          <div>
            <div className="d-flex justify-content-between active-cl align-items-center">
              <h5 className="title fw-6 mb-1 mt-4">Paused Squad Challenges</h5>
              {!loading &&
                minimumNumber < activeChallenges?.archive?.squad?.length && (
                  <h4
                    className="color-p fsc-16 mt-4 mb-1 fw-6 pointer"
                    onClick={pausedSquadMoreButtonHandler}
                  >
                    {currentPausedSquad === minimumNumber ? "See All" : "Back"}
                  </h4>
                )}
            </div>
            {activeChallenges?.archive?.squad
              ?.slice(0, currentPausedSquad)
              ?.map((v, i) => (
                <Card
                  containerClass=" hover-container mt-4 active-cl"
                  style={{
                    borderColor: "1px solid #949699",
                    borderRadius: "10px",
                    cursor: "default",
                  }}
                  key={i}
                  title={
                    <Row>
                      <Col md={3} lg={3}>
                        {v.file !== null && v.file ? (
                          <Image
                            src={`${prePath}${v.file}`}
                            alt="active squad image"
                            className="border-warning"
                            style={{
                              width: "68px",
                              height: "68px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <p
                            className="discover-circle circle-Sb mb-0"
                            style={{
                              backgroundColor: DEFAULT_VALUES.ACTIVE_COLOR[i],
                              filter: "grayscale(1)",
                            }}
                          >
                            SB
                          </p>
                        )}
                      </Col>

                      <Col className="title ps-lg-4 ps-md-2" md={8} lg={9}>
                        <h6 className="title fsc-18 fw-6 title mb-1">
                          {v.title}
                        </h6>
                      </Col>
                    </Row>
                  }
                  customRightBtn={
                    <Row className="position-relative ">
                      <Col
                        className="hover-icon position-absolute pointer  rounded-circle p-2 "
                        style={{ top: "-3.2rem", right: "2.2rem" }}
                        onClick={archiveChallengeHandler(v.id)}
                      >
                        <img src={restartIcon} alt="" />
                      </Col>
                    </Row>
                  }
                />
              ))}
          </div>
        )}
      {(!seeAllSection || seeAllSection === "archiveSolo") &&
        activeChallenges?.archive?.solo?.length > 0 && (
          <div>
            <div className="d-flex justify-content-between active-cl align-items-center">
              <h5 className="title fw-6 mb-1 mt-4">Paused Solo Challenges</h5>
              {!loading &&
                minimumNumber < activeChallenges?.archive?.solo?.length && (
                  <h4
                    className="color-p fsc-16 mt-4 mb-1 fw-6 pointer"
                    onClick={pausedSoloMoreButtonHandler}
                  >
                    {currentPausedSolo === minimumNumber ? "See All" : "Back"}
                  </h4>
                )}
            </div>
            {activeChallenges?.archive?.solo
              ?.slice(0, currentPausedSolo)
              ?.map((v, i) => (
                <Card
                  containerClass=" hover-container mt-4 active-cl"
                  style={{
                    borderColor: "1px solid #949699",
                    borderRadius: "10px",
                    cursor: "default",
                  }}
                  key={i}
                  title={
                    <Row>
                      <Col md={3} lg={3}>
                        {v.file !== null && v.file ? (
                          <Image
                            src={`${prePath}${v.file}`}
                            alt="active squad image"
                            className="border-warning"
                            style={{
                              width: "68px",
                              height: "68px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <p
                            className="discover-circle circle-Sb mb-0"
                            style={{
                              backgroundColor: DEFAULT_VALUES.ACTIVE_COLOR[i],
                              filter: "grayscale(1)",
                            }}
                          >
                            SB
                          </p>
                        )}
                      </Col>

                      <Col className="title ps-lg-4 ps-md-2" md={8} lg={9}>
                        <h6 className="title fsc-18 fw-6 title mb-1">
                          {v.title}
                        </h6>
                      </Col>
                    </Row>
                  }
                  customRightBtn={
                    <Row className="position-relative ">
                      <Col
                        className="hover-icon position-absolute pointer  rounded-circle p-2 "
                        style={{ top: "-3.2rem", right: "2.2rem" }}
                        onClick={archiveChallengeHandler(v.id)}
                      >
                        <img src={restartIcon} alt="" />
                      </Col>
                    </Row>
                  }
                />
              ))}
          </div>
        )}
      {(!seeAllSection || seeAllSection === "completedSquad") &&
        activeChallenges?.completed?.squad?.length > 0 && (
          <div>
            <div className="d-flex justify-content-between active-cl align-items-center">
              <h5 className="title fw-6 mb-1 mt-4">
                Completed Squad Challenges
              </h5>
              {!loading &&
                minimumNumber < activeChallenges?.completed?.squad?.length && (
                  <h4
                    className="color-p fsc-16 mt-4 mb-1 fw-6 pointer"
                    onClick={completedSquadMoreButtonHandler}
                  >
                    {currentCompletedSquad === minimumNumber
                      ? "See All"
                      : "Back"}
                  </h4>
                )}
            </div>
            {activeChallenges?.completed?.squad
              ?.slice(0, currentCompletedSquad)
              ?.map((v, i) => (
                <Card
                  containerClass="colorful-card mt-4 active-cl"
                  style={{
                    borderColor: "rgb(245, 171, 56)",
                    cursor: "default",
                  }}
                  key={i}
                  title={
                    <Row>
                      <Col md={3} lg={3}>
                        {v.file !== null && v.file ? (
                          <Image
                            src={`${prePath}${v.file}`}
                            alt="active squad image"
                            className="border-warning"
                            style={{
                              width: "68px",
                              height: "68px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <p
                            className="discover-circle circle-Sb mb-0"
                            style={{
                              backgroundColor: DEFAULT_VALUES.ACTIVE_COLOR[i],
                            }}
                          >
                            SB
                          </p>
                        )}
                      </Col>

                      <Col className="title ps-lg-4 ps-md-2" md={8} lg={9}>
                        <h6 className="title fsc-18 fw-6 title mb-1">
                          {v.title}
                        </h6>
                      </Col>
                    </Row>
                  }
                  customRightBtn={""}
                />
              ))}
          </div>
        )}
      {(!seeAllSection || seeAllSection === "completedSolo") &&
        activeChallenges?.completed?.solo?.length > 0 && (
          <div>
            <div className="d-flex justify-content-between active-cl align-items-center">
              <h5 className="title fw-6 mb-1 mt-4">
                Completed Solo Challenges
              </h5>
              {!loading &&
                minimumNumber < activeChallenges?.completed?.solo?.length && (
                  <h4
                    className="color-p fsc-16 mt-4 mb-1 fw-6 pointer"
                    onClick={completedSoloMoreButtonHandler}
                  >
                    {currentCompletedSolo === minimumNumber
                      ? "See All"
                      : "Back"}
                  </h4>
                )}
            </div>
            {activeChallenges?.completed?.solo
              ?.slice(0, currentCompletedSolo)
              ?.map((v, i) => (
                <Card
                  containerClass="colorful-card mt-4 active-cl"
                  style={{
                    borderColor: "rgb(245, 171, 56)",
                    cursor: "default",
                  }}
                  key={i}
                  title={
                    <Row>
                      <Col md={3} lg={3}>
                        {v.file !== null && v.file ? (
                          <Image
                            src={`${prePath}${v.file}`}
                            alt="active squad image"
                            className="border-warning"
                            style={{
                              width: "68px",
                              height: "68px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <p
                            className="discover-circle circle-Sb mb-0"
                            style={{
                              backgroundColor: DEFAULT_VALUES.ACTIVE_COLOR[i],
                            }}
                          >
                            SB
                          </p>
                        )}
                      </Col>

                      <Col className="title ps-lg-4 ps-md-2" md={8} lg={9}>
                        <h6 className="title fsc-18 fw-6 title mb-1">
                          {v.title}
                        </h6>
                      </Col>
                    </Row>
                  }
                  customRightBtn={""}
                />
              ))}
          </div>
        )}

      {activeChallenges?.completed?.squad?.length === 0 &&
        activeChallenges?.completed?.solo?.length === 0 &&
        activeChallenges?.archive?.solo?.length === 0 &&
        activeChallenges?.archive?.squad?.length === 0 &&
        !challenges?.length && (
          <div className="no_invitation">
            {" "}
            <div className="d-flex flex-column text-center">
              <h6 className="title lh-1 fw-6 mb-3">No history yet</h6>
              <p>
                Your completed and paused challenges
                <br />
                will appear here
              </p>
            </div>
          </div>
        )}
    </div>
  );
};

export default History;
