import React from "react";
import { Modal, Row, Image } from "react-bootstrap";
import Button from "../Buttons";
import ModalCrossIcon from "../../assets/modalCross";
import infoIcon from "../../assets/info.svg";

function CommonPop({
  hideModal,
  showModal,
  title,
  text,
  buttonText,
  txt,
  handleConfirm,
  icon,
  loading,
  notCancelHandle,
  isTxtHide,
  submitClassName,
  titleClassName,
  isCloseButtonHide,
  info,
  freeTrialInfo,
}) {
  return (
    <Modal
      contentClassName="mx-auto "
      size="md"
      show={showModal}
      centered
      onHide={hideModal}
      backdrop="static"
    >
      {!isCloseButtonHide && (
        <div onClick={loading ? null : hideModal}>
          <ModalCrossIcon className="hero-cross pointer" />
        </div>
      )}
      <Modal.Body className="p-2">
        <Row className="justify-content-md-center mb-3 mt-2 main-modal-section ">
          {!!icon && (
            <Image className="mt-3" src={icon} width="86px" height="86px" />
          )}
          <h5 className={`black-color mt-2 fw-6 fsc-18 ${titleClassName}`}>
            {title}
          </h5>
          {text && <p className="mt-2">{text}</p>}
        </Row>

        {freeTrialInfo && (
          <Row className={`d-flex justify-content-end mb-2`}>
            <div
              className="d-flex w-8 px-3 py-4 mx-auto  justify-content-center align-items-center"
              style={{
                background: "#f3f3f3",
                borderRadius: "8px",
              }}
            >
              <p className="black-color mb-0 fsc-16 fw-4 text-center">
                {freeTrialInfo}
              </p>
            </div>
          </Row>
        )}
        <Row className="justify-content-md-center mb-3">
          <Button
            label={!!buttonText ? buttonText : "Yes"}
            onClick={handleConfirm}
            disabled={loading}
            customizeClass={submitClassName}
          />
        </Row>
        {!isTxtHide && (
          <Row
            className={`d-flex justify-content-end ${!loading && "pointer"}`}
          >
            <p
              className="color-p fsc-14 fw-4 text-center"
              onClick={
                loading ? null : notCancelHandle ? notCancelHandle : hideModal
              }
            >
              {txt ? txt : "No"}
            </p>
          </Row>
        )}
        {info && (
          <Row className={`d-flex justify-content-end mb-2`}>
            <div
              className="d-flex  p-2 mx-auto  justify-content-center align-items-center"
              style={{
                background: "#f3f3f3",
                borderRadius: "4px",
                width: "max-content",
              }}
            >
              <Image className=" me-1" src={infoIcon} />

              <p className="black-color mb-0 opacity-50 fsc-14 fw-4 text-center">
                {info}
              </p>
            </div>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default CommonPop;
