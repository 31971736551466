import React from "react";
import { Modal, Row, Image } from "react-bootstrap";
import Button from "../Buttons";
import ModalCrossIcon from "../../assets/modalCross";
import DeleteImg from "../../assets/util-icons/delete.svg";

function AlertModal(props) {
  return (
    <Modal
      size="md"
      show={props.showModal}
      centered
      onHide={() => props.setShowModal(false)}
    >
      <div onClick={() => props.setShowModal(false)}>
        <ModalCrossIcon className="hero-cross pointer" />
      </div>
      <Modal.Body>
        <Row className="justify-content-md-center mb-3 main-modal-section mt-4">
          {!!props.delete && (
            <Image
              className="mt-4"
              src={DeleteImg}
              width="83px"
              height="83px"
            />
          )}
          <h5 className="title mt-2 f24">{props.title}</h5>
          {props?.text && <p className="mt-2">{props.text}</p>}
        </Row>
        <Row className="justify-content-md-center mb-3">
          <Button
            label={!!props.buttonText ? props.buttonText : "Yes"}
            customizeClass={
              props.txt === "cancel" ? "save-modal cn-bt" : "save-modal"
            }
            onClick={() => props.handleCancelPlan()}
          />
        </Row>
        {props.txt !== "cancel" && props.txt !== "general" && (
          <Row className="d-flex justify-content-end mb-4 pointer">
            <p
              className="color-p text-center"
              onClick={() => props.handleDelete()}
            >
              Delete Account
            </p>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AlertModal;
