import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Card from "../../../common/Cards";
import TimePicker from "react-bootstrap-time-picker";
import BackArrow from "../../../assets/arrowleft";
import {
  useUserPrefrencesMutation,
  useGetProfileMutation,
} from "../../../redux/api/userProfile";
import toast from "react-hot-toast";

const Prefrences = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfileData = useSelector((state) => state.userProfile);
  const [value, onChange] = useState("32400");
  const [dailyreminder, setDailyReminder] = useState(userProfileData?.data?.is_notifications_enable);
  const [prefrencesUpdate, prefrencesUpdateValue] = useUserPrefrencesMutation();
  const [userData, userDataValue] = useGetProfileMutation();

  const handleProfileData = () => {
    userData().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        const data = response?.data;
        dispatch({ type: "PROFILE_DATA", payload: data?.details });
      }
    });
  };

  useEffect(() => {
    handleProfileData();
  }, []);

  useEffect(() => {
    if (userProfileData) {
      const sec = moment(
        userProfileData?.data?.reminder_time,
        "HH:mm:ss: A"
      ).diff(moment().startOf("day"), "seconds");
      onChange(sec);
      // setDailyReminder(userProfileData?.data?.is_daily_reminder_enable);
    }
  }, [userProfileData]);

  const handleAPI = (e, d) => {
    const payload = {
      is_notifications_enable: userProfileData?.is_notifications_enable,
      // is_daily_reminder_enable: d,
      reminder_time: e?.toString(),
    };
    /* updating Prefrences data */
    prefrencesUpdate(payload).then((response) => {
      if (response?.error) {
        handleProfileData();
        toast.error(response?.error?.message);
        return;
      }
      toast.success("Preferences updated successfully.");
    });
  };

  const secondsToTime = (e) => {
    const h = Math.floor(e / 3600)
        .toString()
        .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return h + ":" + m + ":" + s;
  };

  const formatDate = (timeString) => {
    return new Date("1970-01-01T" + timeString + "Z").toLocaleTimeString(
      "en-US",
      { timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
    );
  };

  const handleRemider = (e) => {
    const value = secondsToTime(e);
    const times = formatDate(value);
    onChange(times);
    handleAPI(times, dailyreminder);
  };

  const handleCheck = (d) => {
    setDailyReminder(d);
    handleAPI(value, d);
  };

  return (
    <Container className="content-container settings reminder">
      <h5 className="title fw-6 mar-p">
      <a className="pointer" onClick={() => navigate({ to: "/discover/settings" })}>
          <BackArrow />
        </a>
        Reminder
      </h5>
      <Card
        containerClass="mt-4 reminder-card"
        title={
          <>
            <TimePicker
              className="timer-css"
              onChange={(e) => handleRemider(e)}
              value={value}
              clockicon={true}
              clearicon={true}
            />
          </>
        }
        customRightBtn={
          <Row>
            <Col>
              <Form.Check type="switch" id="check-api-checkbox">
                <Form.Check.Input
                  type="checkbox"
                  isValid
                  checked={dailyreminder}
                  onChange={() => handleCheck(!dailyreminder)}
                />
              </Form.Check>
            </Col>
          </Row>
        }
      />
    </Container>
  );
};

export default Prefrences;
