import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HowtoPlayData } from "./config";
import { useNavigate, useSearch } from "@tanstack/react-location";
import Button from "../../../common/Buttons";
import { useUpdateProfileMutation } from "../../../redux/api/userProfile";
import logo from "../../../assets/auth-landing-curves-2.svg";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const HowToPlay = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useSearch();
  const [triggerUpdateProfile] = useUpdateProfileMutation();

  const updateProfileData = useCallback(() => {
    const data = {
      is_plan_viewed: true,
    };
    triggerUpdateProfile(data).then((res) => {
      if (res.error) {
        toast.error(res?.error?.message);
      } else {
        dispatch({ type: "PROFILE_DATA", payload: res?.data?.details });
        localStorage.setItem("USER", JSON.stringify(res.data.details));
      }
    });
  }, [dispatch, triggerUpdateProfile]);

  useEffect(() => {
    updateProfileData();
  }, [updateProfileData]);

  const { show = false } = search;
  return (
    <>
      {show ? (
        <div className="select-account-conatiner pt-5">
          <Container className="px-5">
            <Row className="justify-content-md-center mt-4">
              <Col sm={3} className="main-heading">
                How to Play
              </Col>
            </Row>
            {HowtoPlayData.map((res, i) => (
              <Row
                key={i}
                className="d-flex justify-content-center top-section"
              >
                <Col sm={3} className="card-Play">
                  <img
                    className="mr-5 how-pic"
                    src={res[0].picture}
                    alt="rules"
                  />
                  <div className="align-self-center ml-5  right-padding">
                    <h4 className="text-dark fw-6 mb-3">{res[0].title}</h4>
                    <p>{res[0].para}</p>
                  </div>
                </Col>
                <Col sm={3} className="card-Play mb-0 justify-content-between">
                  <div className="align-self-center ml-5 right-padding">
                    <h4 className="text-dark fw-6 mb-3">{res[1].title}</h4>
                    <p>{res[1].para}</p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <img
                      className="ml-5  how-pic"
                      src={res[1].picture}
                      alt="rules"
                    />
                  </div>
                </Col>
              </Row>
            ))}
            <div className="button-sec">
              <Button
                label="Next"
                onClick={() => {
                  search?.account && search?.account === "player"
                    ? navigate({
                        to: "/discover/dashboard",
                        search: { back: "PgGoal" },
                      })
                    : navigate({ to: "/select/select-challenges" });
                  search?.account === "player" &&
                    localStorage.removeItem("isFirstTimeUser");
                }}
                customizeClass="how-play-bt mt-4"
              />
              <img className="how-to-logo" src={logo} />
            </div>
          </Container>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default HowToPlay;
