import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearch } from "@tanstack/react-location";
import {
  useTrackChallengeMutation,
  useTrackBadGuyMutation,
  useAddChallengeMutation,
} from "../../../redux/api/challenge";
import Button from "../../../common/Buttons";
import BadIcon from "../../../assets/badIcon.svg";
import Hand from "../../../assets/hands.svg";
import Brain from "../../../assets/Brain.svg";
import Heart from "../../../assets/heart.svg";
import Person from "../../../assets/phyRes.svg";
import Notes from "../../../assets/notes.svg";
import toast from "react-hot-toast";

function BadGuyDetails() {
  const navigate = useNavigate();
  const [trackChallenge] = useTrackChallengeMutation();
  const [addChallenge, addChallengeValue] = useAddChallengeMutation();

  const [trackBadGuy] = useTrackBadGuyMutation();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState("");
  const search = useSearch();

  const UserData = useSelector((state) => state.userChallence);
  const { cardDetail = {} } = UserData;
  const Bstratergy = cardDetail?.battle_strategy?.split("|");
  useEffect(() => {
    if (!cardDetail?.isCustomLibraryActivity) {
      setLoading(true);
      trackChallenge(cardDetail).then((response) => {
        if (response?.error) {
          toast.error(response?.error?.message);
        }
      });
      trackBadGuy(cardDetail).then((response) => {
        if (!response?.error) {
          setInfo(response?.data);
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (info) {
      if (info?.last_bad_guy_type === "boss" && info?.is_completed === false) {
        navigate({
          to: "/select/details/bad-guy-Boss",
          search: {
            page: search?.page,
            tips: info?.battle_tips,
          },
        });
      }
    }
  }, [info]);

  const handleAddActivityToChallenge = () => {
    const payload = {
      id: cardDetail?.challenge_id,
      data: {
        type: "bad_guy",
        title: cardDetail?.title,
        clone_from: cardDetail?.id,
        description: cardDetail?.description,
        strength_to_build: cardDetail?.strength_to_build,
        battle_strategy: cardDetail?.battle_strategy,
      },
    };
    addChallenge(payload).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        navigate({
          to: `/discover/dashboard/${search?.page}`,
        });
      }
    });
  };

  const goBackToCustomLibrary = () => {
    localStorage.setItem(
      "customLibraryActivityData",
      JSON.stringify({
        id: cardDetail.challenge_id,
        type: cardDetail.type,
        page: search?.page,
      })
    );
    navigate({ to: `/discover/dashboard/${search?.page}` });
  };

  return (
    <div className="bad-container main bg">
      <div className="top-corner-notes">
        {!cardDetail?.isCustomLibraryActivity && (
          <img
            className="mr-1 index cursor"
            onClick={() =>
              navigate({
                to: "/select/details/notes",
                search: {
                  page: search?.page,
                },
              })
            }
            src={Notes}
            alt=""
          />
        )}
      </div>
      <div className="top-corner-button">
        <img className="mr-1 index" src={Brain} alt="" />
        <img className="mr-1 index" src={Brain} alt="" />
        {cardDetail?.strength_to_build === "physical" && (
          <>
            <img
              className="index mr-1 "
              src={Person}
              alt=""
              width="36px"
              height="36px"
            />
            <img
              className="index mr-1 "
              src={Person}
              alt=""
              width="36px"
              height="36px"
            />
          </>
        )}
        {cardDetail?.strength_to_build === "mental" && (
          <>
            <img className="index mr-1 " src={Brain} alt="" />{" "}
            <img className="index mr-1 " src={Brain} alt="" />
          </>
        )}
        {cardDetail?.strength_to_build === "emotional" && (
          <>
            <img className="index mr-1 " src={Heart} alt="" />{" "}
            <img className="index mr-1 " src={Heart} alt="" />{" "}
          </>
        )}
        {cardDetail?.strength_to_build === "social" && (
          <>
            {" "}
            <img className="index mr-1 " src={Hand} alt="" />{" "}
            <img className="index mr-1 " src={Hand} alt="" />
          </>
        )}
      </div>
      <Container fluid>
        <Row className="justify-content-md-center text-center">
          <Col sm={3} className="bad-main-heading">
            Bad Guy
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="badIcon">
            <img src={BadIcon} alt="" />
          </Col>
        </Row>
        <Container className="middle-activity-sec">
          <Row className="top-inner">
            <Col sm={12} className="bad-heading">
              {cardDetail?.title}
            </Col>
          </Row>
          <Row className="middle-inner">
            <Col className="fs-2.8 text-light wh-pre-wrap  analytics-text">
              {cardDetail?.description}
            </Col>
          </Row>
          {cardDetail?.battle_strategy !== null &&
            cardDetail?.battle_strategy && (
              <>
                <Row className="lower-inner">
                  <Col sm={4} className="battle-heading">
                    Battle strategy
                  </Col>
                </Row>
                <Row className="middle-inner g-0">
                  <Col className="battle-Detail px-5 py-3">
                    {Bstratergy.map((res, i) => (
                      <Row
                        key={i}
                        className="fs-2.8 fw-4 text-light analytics-text inner-battle"
                      >
                        <Col className="fs-2.8 fw-4 text-light wh-pre-wrap  analytics-text inner-text-area px-0">
                          {res}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </>
            )}

          {(cardDetail?.is_completed === false ||
            cardDetail?.isCustomLibraryActivity) &&
            (cardDetail?.is_attempted_today === false ||
              search?.page === "solo-play") && (
              <Row className="justify-content-md-center mt-5">
                {addChallengeValue?.isLoading ? (
                  <Button
                    label={"Loading..."}
                    customizeClass="bad-activated w-4"
                  />
                ) : (
                  <Button
                    label={
                      cardDetail?.isCustomLibraryActivity
                        ? "Add to challenge"
                        : "Battled"
                    }
                    onClick={() =>
                      cardDetail?.isCustomLibraryActivity
                        ? handleAddActivityToChallenge()
                        : navigate({
                            to: "/select/details/bad-form",
                            search: {
                              page: search?.page,
                              strength: cardDetail?.strength_to_build,
                            },
                          })
                    }
                    customizeClass="bad-activated w-4"
                  />
                )}
              </Row>
            )}
          <Row className="justify-content-md-center text-dark ">
            <Col
              sm={1}
              className="lowerbad cursor"
              onClick={() =>
                cardDetail?.isCustomLibraryActivity
                  ? goBackToCustomLibrary()
                  : navigate({
                      to: `/discover/dashboard/${search?.page}${
                        search?.page === "squad-play"
                          ? `/${cardDetail.challenge_id}`
                          : ""
                      }`,
                    })
              }
            >
              {cardDetail?.is_completed === false &&
              !cardDetail?.isCustomLibraryActivity &&
              (cardDetail?.is_attempted_today === false ||
                search?.page === "solo-play")
                ? "Later"
                : "Back"}
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default BadGuyDetails;
