import React, { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-location";
import { useFormik } from "formik";
import { Container } from "react-bootstrap";
import Button from "../../../common/Buttons";
import InputBox from "../../../common/InputBox";
import { APP_CONFIG } from "../../../constants/app-config";
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyResetTokenMutation,
} from "../../../redux/api/auth";
import {
  forgotPasswordSchema,
  resetPasswordSchema,
} from "../../../validation-schemas/auth-schema";
import ResetPassword from "./reset-password";
import toast from "react-hot-toast";
import { IosAndAndroidLinks } from "../../../utils/helper";
import { isAndroid, isIOS } from "react-device-detect";
import SeoLayout from "../../../common/SeoLayout";

function ForgotPassword(props) {
  // tracking which page to show kept it as local state as state is reset after navigating to other page
  const [step, setStep] = useState(props.step || 1);

  const navigate = useNavigate();
  const location = window.location.href;
  const token = location.split("/").pop();

  const [forgotPassword, forgotPasswordResult] = useForgotPasswordMutation();
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation();
  const [verifyResetToken, verifyResetTokenResult] =
    useVerifyResetTokenMutation();

  const [name, setName] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isAndroid || isIOS) {
      IosAndAndroidLinks("forgotPassword", token);
    } else {
      if (token && step !== 1 && count === 0) {
        setCount(1);
        verifyResetToken(token).then((res) => {
          if (res?.error) {
            toast.error(res?.error?.data?.error);
          } else {
            setName(`${res?.data?.first_name}${" "}${res?.data?.last_name}`);
          }
        });
      }
    }
  }, [token, verifyResetToken]);

  const onSubmit = (values) => {
    if (step === 1) {
      forgotPassword({
        email: values.emailOrUsername,
      })
        .then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            toast.success(response?.data?.message);
            navigate({ to: "/auth/login" });
          }
        })
        .catch((e) => toast.error(e?.message));
    } else {
      resetPassword({
        token: token,
        password: values.new_password,
      })
        .then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            toast.success(response?.data?.message);
            navigate({ to: "/auth/login" });
          }
        })
        .catch((e) => toast.error(e?.message));
    }
  };

  const resetPasswordformik = useFormik({
    initialValues: {
      emailOrUsername: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit,
  });
  const forgotPasswordformik = useFormik({
    initialValues: {
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit,
  });

  return (
    <SeoLayout title="Forgot Password" robot>
      <div className="auth-content-container">
        <h2 className="color-s content-heading">
          {step === 1 ? "Forget Password" : "Reset Password"}{" "}
          <span className="bg-color-s"></span>
        </h2>

        {/* hardcoding for now needs to be changed */}
        <p className="mb-5">
          {step === 1
            ? "No Worries, Let’s fix this"
            : name &&
              `Enter New Password for ${
                name !== null && name !== undefined ? name : ""
              }`}
        </p>

        <form
          onSubmit={
            step === 1
              ? resetPasswordformik.handleSubmit
              : forgotPasswordformik.handleSubmit
          }
        >
          {step === 1 ? (
            <InputBox
              label="Email"
              containerClass="mb-5"
              type="email"
              name="emailOrUsername"
              formik={resetPasswordformik}
              values={resetPasswordformik.values.emailOrUsername}
              isError={
                resetPasswordformik.errors.emailOrUsername &&
                resetPasswordformik.touched.emailOrUsername
              }
              error={resetPasswordformik.errors.emailOrUsername}
              placeholder={APP_CONFIG.EMAIL_PLACEHOLDER_LOGIN}
            />
          ) : (
            <ResetPassword formik={forgotPasswordformik} />
          )}

          <Container fluid={true} className="text-center mt-5">
            <Button
              customProps={{
                disabled:
                  forgotPasswordResult.isLoading ||
                  resetPasswordResult.isLoading,
              }}
              type="submit"
              customizeClass="mb-4 dfl-bt"
              label={
                forgotPasswordResult.isLoading || resetPasswordResult.isLoading
                  ? "Please wait..."
                  : step === 1
                  ? "Send"
                  : "Submit"
              }
            />
            {step === 1 && (
              <p className="mb-5 fs-2">{APP_CONFIG.RESET_PASSWORD_HELPER}</p>
            )}

            {/* <p className="mt-5 fs-2">
            By continuing you agree to SuperBetter's{" "}
            <a className="color-s" href="#">
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a className="color-s" href="#">
              Privacy Policy
            </a>
          </p> */}
          </Container>
        </form>
      </div>
    </SeoLayout>
  );
}

export default ForgotPassword;
