import React, { useEffect, useState } from "react";
import { Image, Spinner } from "react-bootstrap";
import { useEmailVerificationMutation } from "../../../redux/api/auth";
import EmailVerified from "../../../assets/email-verified.svg";
import toast from "react-hot-toast";
import { useCallback } from "react";
import { useRef } from "react";
import { useNavigate } from "@tanstack/react-location";
// import { IosAndAndroidLinks } from "../../../utils/helper";
import SeoLayout from "../../../common/SeoLayout";
const EmailVerification = () => {
  const location = window.location.href;
  const token = location.split("/").pop();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const request = useRef(false);
  const [guardian, setGuardian] = useState(false);
  const navigate = useNavigate();

  const [emailVerification, emailVerificationValue] =
    useEmailVerificationMutation();

  const handleEmailVerification = useCallback(() => {
    request.current = true;
    emailVerification(token).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
        request.current = false;
        setEmail(false);
        setError(res?.error?.data?.error);
      } else {
        // toast.success("Email Verified Successfully.");
        setEmail(true);
        const guardian = res?.data?.result?.is_guardian_approved;
        if (guardian) {
          setGuardian(true);
        }
        // IosAndAndroidLinks("verify", null);
      }
    });
  }, [emailVerification, token]);

  useEffect(() => {
    if (!request.current) {
      handleEmailVerification();
    }
  }, [handleEmailVerification]);

  return (
    <SeoLayout title="Email Verification" robot>
      <div className="d-flex align-items-center">
        <div>
          <div className="w-100 text-center">
            <Image src={EmailVerified} className="img-fluid mb-4" />
          </div>
          {email === "" ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : guardian ? (
            <h1 className="title text-center mt-4 f30">
              Child’s Account Is Approved
            </h1>
          ) : (
            <h1 className="title text-center mt-4 f30">Email Verification</h1>
          )}
          {email === true && (
            <h3 className="succesEmail w-100 text-center f16 mt-3">
              Successful
            </h3>
          )}
          {email && !guardian && (
            <p className="w-100 text-center em-v-txt">
              Click here to{" "}
              <span
                className="color-s cursor"
                onClick={() => navigate({ to: "/" })}
              >
                Log in
              </span>
            </p>
          )}
          {email === false && (
            <h4 className="failEmail text-danger w-100 text-center mt-3 f16">
              {error ? error : ""}
            </h4>
          )}
          <p
            className="my-5 w-100 text-center pb-5 fsc-14 "
            style={{ right: 0, left: 0 }}
          >
            Learn more about{" "}
            <a
              className="color-s"
              target="_blank"
              rel="noreferrer noopener"
              href="https://superbetter.com"
            >
              SuperBetter
            </a>{" "}
          </p>
        </div>
      </div>
    </SeoLayout>
  );
};

export default EmailVerification;
