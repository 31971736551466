import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Button from "../../../../common/Buttons";
import flags from "../../../../assets/flags.svg";
import { useNavigate, useSearch } from "@tanstack/react-location";
import { useDispatch, useSelector } from "react-redux";

function FeedBack() {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.userProfile?.data);
  const search = useSearch();
  const dispatch = useDispatch();

  return (
    <div className="power-container feedback">
      <Container className="justify-content-center" sm={4}>
        <Row className="justify-content-center">
          <Col sm={3} className="text-heading pb-3 f26">
            Woohoo!
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={3}>
            <div className="mb-5 d-flex justify-content-center">
              <Image src={flags} className="" />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col sm={4} className="text-heading ">
            You Won the Day
          </Col>
        </Row>
        {userInfo?.user_type === "hero" ? (
          <>
            <Row className="justify-content-center mb-4 ">
              <Col sm={8} lg={7} className="form-text-detail  weekly-text">
                Well done, hero. You activated power-ups, battled a bad guy,
                <br />
                completed a quest and checked in with an ally.
                <br /> You should feel great about stopping for the day.
              </Col>
            </Row>
            <Row className="justify-content-center  ">
              <Col sm={8} lg={7} className="form-text-detail  weekly-text">
                {search?.page === "squad-play"
                  ? "Want to keep playing? Activate all the power-ups you like, or play another challenge if you have one."
                  : " Of course you can keep playing if you choose."}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="justify-content-center mb-4  ">
              <Col sm={8} lg={7} className="form-text-detail  weekly-text">
                Well done. You activated power-ups, battled a bad guy,
                <br />
                completed a quest and checked in with an ally.
                <br /> You should feel great about stopping for the day.
              </Col>
            </Row>
            <Row className="justify-content-center  ">
              <Col sm={8} lg={7} className="form-text-detail  weekly-text">
                {search?.page === "squad-play"
                  ? "Want to keep playing? Activate all the power-ups you like, or play another challenge if you have one."
                  : " Of course you can keep playing if you choose."}
              </Col>
            </Row>
          </>
        )}
        <Row className="justify-content-center  ">
          <Button
            label={"Keep Playing"}
            onClick={() => {
              navigate({
                to: `/discover/dashboard/${search?.page}${
                  search?.challengeId ? `/${search?.challengeId}` : ""
                }`,
              });
              dispatch({
                type: "USER_CHALLENGE_DAYS",
                payload: {},
              });
            }}
            customizeClass="mt-5 pu-btn-m fw-6 "
          />
        </Row>
      </Container>
    </div>
  );
}

export default FeedBack;
