import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { store } from "./redux/index";

/* i18n for language translation */
import "./language/i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
// 7-1 archietectural main file
import "./styles/main.scss";
import { Toaster } from "react-hot-toast";

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <GoogleOAuthProvider clientId="549291136071-ae79sg9cmo8bhiouo6468ursmihg9m0s.apps.googleusercontent.com">
          <App />
          <Toaster position="bottom-center" reverseOrder={false} />
        </GoogleOAuthProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);
