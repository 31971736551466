import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  useVerificationMutation,
  useVerifyResetTokenMutation,
} from "../../../../redux/api/auth";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate, useSearch, Link } from "@tanstack/react-location";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { Container } from "react-bootstrap";
import { isAbove13, storeToken } from "../../../../utils/helper";
import Button from "../../../../common/Buttons";
import { DEFAULT_VALUES } from "../../../../constants/default-values";
import ParentRegister from "../parent-register";
import ChildRegister from "../child-register";
import EmailConfirmation from "../emailVerification";
import {
  registerChildSchema,
  registerSchemaVerification,
} from "../../../../validation-schemas/auth-schema";

export default function Verification() {
  const initialValues = {
    guardian_email: "",
  };
  const firstUpdate = useRef(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useSearch();

  const location = window.location.href;
  const token = location.split("/").pop();

  const [verified, setVerified] = useState("");
  const [parent, setParent] = useState(false);
  const [guardianError, setGuardianError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formData, setFormData] = useState({});
  const [emailID, setEmailID] = useState("");
  const [comingEmail, setComingEmail] = useState(false);
  const [linkInvalid, setLinkInvalid] = useState("");
  const [verification, verificationValue] = useVerificationMutation();
  const [registerUser, setRegisterUser] = useState(false);
  const [emailCheck, emailCheckValue] = useVerifyResetTokenMutation();
  const [disableFields, setDisableFields] = useState({
    isEmailDisable: false,
    isFirstNameDisable: false,
    isLastNameDisable: false,
    isPasswordDisable: false,
  });

  const verifyAccess = (res) => {
    // storeToken(res?.data?.result);
    // storing token and expiration to know when to call refresh token API
    if (!parent && res?.data?.result?.user?.is_email_verified) {
      storeToken(res?.data?.result);
      dispatch({ type: "PROFILE_DATA", payload: res?.data?.result?.user });
      navigate({ to: "/discover/dashboard" });
    } else if (!res?.data?.result?.user?.is_guardian_approved) {
      setRegisterUser(true);
    } else {
      toast.error("Please verify your email then try to login again.");
    }
  };

  DEFAULT_VALUES.REGISTER_INITIAL_VALUES.email = comingEmail;

  const handleEmailVerification = (payload) => {
    const data = {
      payload: payload,
      token: token,
    };
    verification(data).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
        setVerified(false);
      } else {
        setVerified(true);
        verifyAccess(res);
      }
    });
  };

  const onSubmit = (values) => {
    if (!parent && (!values?.month || !values?.year || !values?.day)) {
      registerformik.setFieldError("date", "Please provide valid date");
      formik.setFieldError("date", "Please provide valid date");
      return;
    }
    const dataForm = { ...formData };
    setEmailID(comingEmail);
    const parsedDate = new Date(
      values.year,
      moment.monthsShort().indexOf(values.month),
      values.day
    );
    const dates = moment(parsedDate).format("YYYY-MM-DD");
    let payload = {
      token_type: "invitation_token",
      dob: dates,
      password: values?.password,
      first_name: values?.first_name,
      last_name: values?.last_name ? values?.last_name : null,
      is_terms_accepted: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (!isAbove13(parsedDate) && !parent) {
      setParent(true);
      setFormData(payload);
    } else {
      if (parent) {
        dataForm.guardian_email = values.guardian_email;
        if (
          values?.guardian_email &&
          values?.guardian_email !== values?.email
        ) {
          setEmailID(values.guardian_email);
          payload = {
            ...dataForm,
          };
        } else if (values?.guardian_email) {
          setGuardianError("Guardian email should not match with child email");
        }
      }
      handleEmailVerification(payload);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setGuardianError("");
    }, 2000);
  }, [guardianError]);

  const registerformik = useFormik({
    initialValues: DEFAULT_VALUES.REGISTER_INITIAL_VALUES,
    validationSchema: registerSchemaVerification,
    onSubmit,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registerChildSchema,
    onSubmit,
  });

  useEffect(() => {
    registerformik.setFieldValue("email", search?.email);
    registerformik.setFieldValue(
      "first_name",
      firstName !== null && firstName ? firstName : ""
    );
    registerformik.setFieldValue(
      "last_name",
      lastName !== null && lastName ? lastName : ""
    );
  }, [firstName, lastName]);

  const handleEmailCheck = useCallback(() => {
    emailCheck(token).then((res) => {
      if (!res?.error) {
        toast.success("Email verify successful");
        setComingEmail(res?.data?.email);
        setFirstName(res?.data?.first_name);
        setLastName(res?.data?.last_name);
        DEFAULT_VALUES.REGISTER_INITIAL_VALUES.email = res?.data?.email;
        DEFAULT_VALUES.REGISTER_INITIAL_VALUES.first_name =
          res?.data?.first_name !== null ? res?.data?.first_name : "";
        DEFAULT_VALUES.REGISTER_INITIAL_VALUES.last_name =
          res?.data?.last_name !== null ? res?.data?.last_name : "";
        setDisableFields((prevState) => {
          return {
            ...prevState,
            ...(res?.data?.email && { isEmailDisable: true }),
            ...(res?.data?.first_name && { isFirstNameDisable: true }),
            ...(res?.data?.last_name && { isLastNameDisable: true }),
          };
        });
      } else {
        setLinkInvalid(res?.error?.data?.error);
      }
    });
  }, [emailCheck]);

  useEffect(() => {
    firstUpdate.current = false;
    if (firstUpdate) {
      handleEmailCheck();
    }
  }, [handleEmailCheck]);

  return (
    <div className="auth-content-container">
      {!registerUser ? (
        <>
          <h2 className="color-s content-heading">
            Tell us about youself <span className="bg-color-s ml-1"></span>
          </h2>
          {linkInvalid && <p className="f20 text-danger">{linkInvalid}</p>}
          {comingEmail && (
            <form
              onSubmit={
                !parent ? registerformik.handleSubmit : formik.handleSubmit
              }
            >
              {!parent ? (
                <ParentRegister
                  email={comingEmail}
                  disableFields={disableFields}
                  formik={registerformik}
                />
              ) : (
                <ChildRegister guardianError={guardianError} formik={formik} />
              )}

              <Container fluid={true} className="text-center mt-5 fs-2">
                <Button
                  type="submit"
                  customizeClass="mb-4"
                  label={
                    verificationValue.isLoading
                      ? "Please wait..."
                      : !parent
                      ? "Create Account"
                      : "Request Approval"
                  }
                  customProps={{
                    disabled: verificationValue.isLoading,
                  }}
                />
                <p className={parent ? "mt-4" : "fs-p-14 mt-3"}>
                  Already have an account?{" "}
                  <Link className="color-s" to={"/auth/login"}>
                    Log in
                  </Link>
                </p>
                {!parent && (
                  <p
                    className={
                      !parent ? "mb-5 pb-5 fs-p-11 " : "mb-5 pb-5 fs-p-11 mt150"
                    }
                    style={{ right: 0, left: 0 }}
                  >
                    By continuing you agree to <br /> SuperBetter's{" "}
                    <a className="color-s" href="#">
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a className="color-s" href="#">
                      Privacy Policy
                    </a>
                  </p>
                )}
              </Container>
            </form>
          )}
        </>
      ) : (
        <EmailConfirmation parent={!parent} emailID={emailID} />
      )}
    </div>
  );
}
