import moment from "moment";
import { isAndroid, isIOS } from "react-device-detect";
const PasswordValidator = require("password-validator");

export const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

// accepts date object
export const isAbove13 = (dateOfBirth) => {
  var today = new Date();
  var birthDate = dateOfBirth;
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 13;
};

export const getGreetingTime = (currentTime) => {
  if (!currentTime || !currentTime.isValid()) {
    return "Hello";
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(currentTime.format("HH"));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return "Good afternoon";
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return "Good evening";
  }
  // Between dawn and noon
  return "Good morning";
};

export const prePath = process.env.REACT_APP_S3_URL;

export const storeToken = (tokenDetails) => {
  localStorage.setItem("ACCESS_TOKEN", tokenDetails.tokens.access.token);
  localStorage.setItem(
    "ACCESS_TOKEN_EXPIRES",
    tokenDetails.tokens.access.expires
  );
  localStorage.setItem("REFRESH_TOKEN", tokenDetails.tokens.refresh.token);
  localStorage.setItem(
    "REFRESH_TOKEN_EXPIRES",
    tokenDetails.tokens.refresh.expires
  );
  localStorage.setItem(
    "DETAIL_USER",
    `${tokenDetails.user.first_name}${" "}${tokenDetails.user.last_name}`
  );
  localStorage.setItem("USER", JSON.stringify(tokenDetails.user));
};

export const IosAndAndroidLinks = (type = null, token = null) => {
  if (isAndroid) {
    let url = "";
    if (type === "verify") {
      url = `intent://${process.env.REACT_APP_API_URL}/verification#Intent;scheme=https;package=com.superbetter.paid;end`;
    } else if (type === "forgotPassword") {
      url = `intent://${process.env.REACT_APP_API_URL}/forgotPassword?token=${token}#Intent;scheme=https;package=com.superbetter.paid;end`;
    } else {
      url = `intent://${process.env.REACT_APP_API_URL}/#Intent;scheme=https;package=com.superbetter.paid;end`;
    }

    window.location.replace(url);
  } else if (isIOS) {
    let url = "";
    if (type === "verify") {
      url = `superbetter://verification`;
    } else if (type === "forgotPassword") {
      url = `superbetter://forgotPassword?token=${token}`;
    } else {
      url = `superbetter://`;
    }

    window.location.replace(url);
    setTimeout(() => {
      window.location.replace(
        "https://apps.apple.com/us/app/superbetter/id536634968"
      );
    }, 10000);
  }
};

export const passwordValidation = (value, createError, path) => {
  const schema = new PasswordValidator();
  schema
    .is()
    .min(8)
    .is()
    .max(100)
    .has()
    .uppercase(1)
    .has()
    .lowercase(1)
    .has()
    .digits(1)
    .has()
    .symbols(1)
    .has()
    .not()
    .spaces();

  if (value?.length < 8) {
    return createError({
      path,
      message: "Must be at least 8 characters",
    });
  }

  if (!schema.validate(value)) {
    return createError({
      path,
      message:
        "Must have at least lowercase, uppercase, number and special character",
    });
  }
  return true;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getUTCTimeStamp = (date) => {
  let utxtimes = moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
  let tmsx = moment(utxtimes).format("X");
  return Number(tmsx);
};
export const dateToISOString = (date) => {
  return moment(date).utcOffset(0, true).toISOString();
};
