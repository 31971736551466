import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const library = createApi({
  reducerPath: "libraryData",
  baseQuery: baseQueryWithReAuth({ baseUrl: `${API_BASE_URL}user/library` }),
  endpoints: (builder) => ({
    getLibrary: builder.mutation({
      query: (body) => ({
        url: `/activity`,
        method: "GET",
        params: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    getCustomActivity: builder.mutation({
      query: (body) => ({
        url: `/custom-activity`,
        method: "GET",
        params: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    deleteLibraryActivity: builder.mutation({
      query: (payload) => ({
        url: `/custom-activity/${payload.activity_id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    editLibraryActivity: builder.mutation({
      query: (payload) => ({
        url: `/custom-activity/${payload.activity_id}`,
        method: "PATCH",
        body: payload.data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetLibraryMutation,
  useGetCustomActivityMutation,
  useDeleteLibraryActivityMutation,
  useEditLibraryActivityMutation,
} = library;
