import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Button from "../../../../common/Buttons";
import flags from "../../../../assets/weekIcon.svg";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";

function EpicWinFeedback() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const epicWin = JSON.parse(localStorage.getItem("feedbackData"))?.epicWin;

  return (
    <div className="power-container feedback">
      <Container className="justify-content-center" sm={4}>
        <Row className="justify-content-center">
          <Col sm={5} className="text-heading pb-3 fsc-26">
            Way to Go!
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={3}>
            <div className="mb-5 d-flex justify-content-center">
              <Image src={flags} className="" />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mb-0">
          <Col sm={6} className="text-heading ">
            Congratulations on achieving your epic win{" "}
          </Col>
        </Row>
        <Row className="justify-content-center  mb-4 ">
          <Col sm={6} className="form-text-detail weekly-text">
            {epicWin}
            <br />
            <br />
            Take a moment to really celebrate your epic win and everything it
            took to make it happen.
          </Col>
        </Row>
        <Row className="justify-content-center  ">
          <Button
            label={"Create Your Next Challenge"}
            onClick={() => {
              navigate({
                to: "/discover/dashboard",
                search: { back: "PgGoal" },
              });
              dispatch({
                type: "USER_CHALLENGE_DAYS",
                payload: {},
              });
            }}
            customizeClass="mt-3 w-3 pu-btn-m fw-6 "
          />
        </Row>
      </Container>
    </div>
  );
}

export default EpicWinFeedback;
