import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useNavigate, useSearch } from "@tanstack/react-location";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../common/Buttons";
import { prePath } from "../../../utils/helper";
import {
  useSetGoalsMutation,
  useGetProfileMutation,
} from "../../../redux/api/userProfile";
import toast from "react-hot-toast";

const FurtherGoal = () => {
  const search = useSearch();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { name = "" } = search;
  const UserPro = useSelector((state) => state.userChallence);
  const userData = useSelector((state) => state?.userProfile?.data);
  const [userDataApi] = useGetProfileMutation();
  const [setGoals] = useSetGoalsMutation();
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  const { secretIdentity } = UserPro;

  useEffect(() => {
    secretIdentity?.details?.profile_image &&
      secretIdentity?.details?.profile_image !== null &&
      setImage(`${prePath}${secretIdentity?.details?.profile_image}`);
  }, [secretIdentity]);

  useEffect(() => {
    if (search?.text) {
      setText(search?.text);
    }
  }, [search]);

  const handleSkip = () => {
    if (search?.page && search?.page === "goal") {
      navigate({ to: "/discover/dashboard?switch=Goals" });
    } else {
      navigate({ to: "/select/tutorial" });
    }
    handleUserData();
  };

  const handleUserData = () => {
    userDataApi().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        const data = response?.data;
        dispatch({ type: "PROFILE_DATA", payload: data?.details });
        localStorage.setItem("USER", JSON.stringify(data.details));
      }
    });
  };

  const handleContinue = () => {
    const payload = {
      type: name,
      title: name,
      value: name.toLowerCase(),
      note: text,
    };
    setLoading(true);
    /* set goals */
    setGoals(payload).then((res) => {
      if (!res.error) {
        toast.success("Your goal is updated successfully.");
        if (search?.page && search?.page === "goal") {
          navigate({ to: "/discover/dashboard?switch=Goals&back=PgGoal" });
        } else {
          navigate({ to: "/select/tutorial" });
        }
        handleUserData();
      } else {
        toast.error(res?.error?.message);
      }
      setLoading(false);
    });
  };

  return (
    <Container className="further-goal main" sm={4}>
      <Row className="justify-content-md-center mt-5">
        <Col className="d-flex justify-content-center">
          {image ? (
            <img className="squad-profile-pic" src={image} alt="" />
          ) : (
            <h3 className="squad-profile-pic without-img text-uppercase">
              {userData?.first_name?.charAt(0)}
            </h3>
          )}
        </Col>
      </Row>
      <Row className="justify-content-md-center my-0">
        <Col className="my-0 d-flex flex-column justify-content-center align-items-center">
          <p className="mb-0 further-goal-title mb-1 ml-1">My Goal Area:</p>
          <h5 className="title title-sp text-center fw-600">{name}</h5>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-1">
        <Col md={8}>
          <Form.Group>
            <Form.Label className="black-color fw-6">
              If you have a specific goal in mind, write it here:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="My goal is…"
              className="text-area-input"
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-5">
        <Button
          label={loading ? <Spinner animation="border"></Spinner> : "Continue"}
          onClick={() => handleContinue()}
          customizeClass="lower-button fw-600 dfl-bt"
        />
        <p
          className="text-primary text-center fw-6 f16 mt-3 color-p pointer"
          onClick={() => handleSkip()}
        >
          Skip
        </p>
      </Row>
      <Button
        label={"Back"}
        onClick={() =>
          search?.page
            ? navigate({
                to: "/select/select-challenges",
                search: { page: search?.page },
              })
            : navigate({ to: "/select/select-challenges" })
        }
        customizeClass="back-button fw-600"
        variant="light"
      />
    </Container>
  );
};

export default FurtherGoal;
