import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Button from "../../../common/Buttons";
import moment from "moment";
import { useNavigate, useSearch } from "@tanstack/react-location";
import {
  useActivationChallengeMutation,
  useAddChallengeMutation,
  useGetActivityDetailsMutation,
  useRateActivityMutation,
  useTrackChallengeMutation,
} from "../../../redux/api/challenge";
import { useAllyNewActivityMutation } from "../../../redux/api/userProfile";
import PowerIcon from "../../../assets/powerIcon.svg";
import Dislike from "../../../assets/dislike.svg";
import Like from "../../../assets/like.svg";
import Hand from "../../../assets/hands.svg";
import ActiveLike from "../../../assets/activeLike.svg";
import ActiveDislike from "../../../assets/activeDislike.svg";
import Brain from "../../../assets/Brain.svg";
import Heart from "../../../assets/heart.svg";
import Person from "../../../assets/phyRes.svg";
import Notes from "../../../assets/notes.svg";
import toast from "react-hot-toast";
import { useCallback } from "react";

function PowerDetails() {
  const navigate = useNavigate();
  const search = useSearch();
  const UserData = useSelector((state) => state.userChallence);

  const ActivityDate = useSelector((state) => state?.userProfile?.activityDate);
  const { cardDetail, challengeDaysDetails } = UserData;
  const [triggerActivationChallenge, ActivationChallengeValues] =
    useActivationChallengeMutation();
  const [trackChallenge] = useTrackChallengeMutation();
  const [getActivityDetails] = useGetActivityDetailsMutation();
  const [addChallenge, addChallengeValue] = useAddChallengeMutation();
  const [triggerRateActivity] = useRateActivityMutation();
  const [triggerAllyNewActivity] = useAllyNewActivityMutation();
  const [activityDetail, setActivityDetail] = useState({});
  const currentDate = moment().format("YYYY-MM-DD");
  const Adata =
    ActivityDate?.filter((res) => res?.day === challengeDaysDetails?.order) ||
    [];

  const handleSelectActive = (comingData) => {
    const newData = {
      ...comingData,
      newDate: {
        date: search?.page === "solo-play" ? currentDate : Adata[0]?.date,
      },
    };
    triggerActivationChallenge(newData)
      .then((response) => {
        if (response?.error) {
          toast.error(response?.error?.data?.error);
        } else {
          const result = response?.data?.result;
          navigate({
            to: "/select/details/power-ups-feedback",
            search: {
              ...result,
              page: search?.page,
              strength: cardDetail?.strength_to_build,
              challengeId: newData?.challenge_id,
            },
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleActivated = () => {
    if (cardDetail?.accpted_ch) {
      const payload = {
        c_id: cardDetail?.challenge_id,
        data: {
          type: "power_ups",
          title: cardDetail?.title,
          clone_from: cardDetail?.id,
          description: cardDetail?.description,
          strength_to_build: cardDetail?.strength_to_build,
        },
      };
      triggerAllyNewActivity(payload).then((res) => {
        if (res.error) {
          toast.error(res.error.data.error);
        } else {
          handleSelectActive(res?.data?.result);
        }
      });
    } else {
      handleSelectActive(cardDetail);
    }
  };

  const fetchActivityDetails = useCallback(() => {
    getActivityDetails({
      challenge_id: cardDetail.challenge_id,
      activity_id: cardDetail.id,
    }).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.message);
      } else {
        setActivityDetail(res?.data);
      }
    });
  }, [cardDetail.challenge_id, cardDetail.id, getActivityDetails]);

  useEffect(() => {
    if (
      !cardDetail?.isCustomLibraryActivity &&
      !cardDetail?.isLibraryActivity
    ) {
      trackChallenge(cardDetail).then((response) => {
        if (response?.error) {
          toast.error(response?.error?.message);
        }
      });
    }
  }, []);

  const rateActivity = useCallback(
    (value) => {
      triggerRateActivity({
        challenge_id: cardDetail.challenge_id,
        activity_id: cardDetail.id,
        rate: value,
      }).then((res) => {
        fetchActivityDetails();
      });
    },
    [
      cardDetail.challenge_id,
      cardDetail.id,
      fetchActivityDetails,
      triggerRateActivity,
    ]
  );

  useEffect(() => {
    if (
      !cardDetail?.isCustomLibraryActivity &&
      !cardDetail?.isLibraryActivity
    ) {
      fetchActivityDetails();
    }
  }, [
    cardDetail?.isCustomLibraryActivity,
    cardDetail?.isLibraryActivity,
    fetchActivityDetails,
  ]);

  const handleAddActivityToChallenge = () => {
    const payload = {
      id: cardDetail?.challenge_id,
      data: {
        type: "power_ups",
        title: cardDetail?.title,
        clone_from: cardDetail?.id,
        description: cardDetail?.description,
        strength_to_build: cardDetail?.strength_to_build,
      },
    };
    addChallenge(payload).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        navigate({
          to: `/discover/dashboard/${search?.page}`,
        });
      }
    });
  };

  const goBackToCustomLibrary = () => {
    localStorage.setItem(
      "customLibraryActivityData",
      JSON.stringify({
        id: cardDetail.challenge_id,
        type: cardDetail.type,
        page: search?.page,
      })
    );
    navigate({ to: `/discover/dashboard/${search?.page}` });
  };
  return (
    <div className="power-container main bg">
      <div className="top-corner-notes">
        {!cardDetail?.isCustomLibraryActivity &&
          !cardDetail?.isLibraryActivity && (
            <img
              className="mr-1 index cursor"
              onClick={() =>
                navigate({
                  to: "/select/details/notes",
                  search: {
                    page: search?.page,
                  },
                })
              }
              src={Notes}
              alt=""
            />
          )}
      </div>
      <div className="top-corner-button">
        <img className="index" src={Heart} alt="" />
        {[
          activityDetail?.strength_to_build,
          cardDetail?.strength_to_build,
        ].includes("physical") && (
          <img
            className="index mr-1"
            src={Person}
            alt=""
            width="36px"
            height="36px"
          />
        )}
        {[
          activityDetail?.strength_to_build,
          cardDetail?.strength_to_build,
        ].includes("mental") && (
          <img className="index mr-1" src={Brain} alt="" />
        )}
        {[
          activityDetail?.strength_to_build,
          cardDetail?.strength_to_build,
        ].includes("emotional") && (
          <img className="index mr-1" src={Heart} alt="" />
        )}
        {[
          activityDetail?.strength_to_build,
          cardDetail?.strength_to_build,
        ].includes("social") && (
          <img className="index mr-1" src={Hand} alt="" />
        )}
      </div>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col sm={3} className="power-main-heading text-center">
            Power-up
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="powerIcon">
            <img src={PowerIcon} alt="" />
          </Col>
        </Row>
        <Container className="middle-activity-sec">
          <Row>
            <Col className="power-heading">
              <h3 className="detail_title pt-1 mt-4 f30">
                {activityDetail?.title
                  ? activityDetail.title
                  : cardDetail?.title}
                <hr className="detail_underline"></hr>
              </h3>
            </Col>
            {!cardDetail?.isCustomLibraryActivity &&
              !cardDetail?.isLibraryActivity && (
                <Col className="d-flex justify-content-end mt-4">
                  <span onClick={() => rateActivity(true)}>
                    {activityDetail?.is_rated === true ? (
                      <img
                        src={Dislike}
                        width="36px"
                        height="36px"
                        alt=""
                        className="cursor plike"
                      />
                    ) : (
                      <img
                        src={ActiveDislike}
                        width="36px"
                        height="36px"
                        alt=""
                        className="cursor plike"
                      />
                    )}
                  </span>
                  <span onClick={() => rateActivity(false)}>
                    {activityDetail?.is_rated === false ? (
                      <img
                        className="cursor dlike"
                        width="36px"
                        height="36px"
                        src={Like}
                        alt=""
                      />
                    ) : (
                      <img
                        className="cursor dlike"
                        width="36px"
                        height="36px"
                        src={ActiveLike}
                        alt=""
                      />
                    )}
                  </span>
                </Col>
              )}
          </Row>
          <Row className="middle-inner-lo">
            <Col className="fs-2.8 text-dark wh-pre-wrap analytics-text">
              {activityDetail?.description
                ? activityDetail.description
                : cardDetail?.description}
            </Col>
          </Row>
        </Container>
        {(activityDetail?.is_completed === false ||
          cardDetail?.isLibraryActivity ||
          search?.page === "solo-play") && (
          <Row className="justify-content-md-center w-6 mx-auto">
            {ActivationChallengeValues?.isLoading ||
            addChallengeValue?.isLoading ? (
              <Button
                label={"Loading..."}
                customizeClass="power-activated w-4"
              />
            ) : (
              <Button
                label={
                  cardDetail?.isCustomLibraryActivity
                    ? "Add to challenge"
                    : "Activated!"
                }
                onClick={
                  cardDetail?.isCustomLibraryActivity
                    ? handleAddActivityToChallenge
                    : handleActivated
                }
                customizeClass="power-activated w-4 pu-btn-m"
              />
            )}
          </Row>
        )}
        <Row className="justify-content-md-center text-dark ">
          <Col
            sm={1}
            className="lowerPower cursor"
            onClick={() =>
              cardDetail?.isCustomLibraryActivity
                ? goBackToCustomLibrary()
                : navigate({
                    to: `/discover/dashboard/${search?.page}${
                      search?.page === "squad-play"
                        ? `/${cardDetail.challenge_id}`
                        : ""
                    }`,
                  })
            }
          >
            {search?.page === "solo-play" &&
              (cardDetail?.isCustomLibraryActivity ? "Back" : "Later")}
            {search?.page === "squad-play" &&
              (activityDetail?.is_completed === true ? "Back" : "Later")}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PowerDetails;
