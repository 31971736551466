import ally from "../assets/science-behind/ally.svg";
import badGuy from "../assets/science-behind/bad-guy.svg";
import challengeMindset from "../assets/science-behind/challenge-mindset.svg";
import epicWins from "../assets/science-behind/epic-wins.svg";
import powerUp from "../assets/science-behind/power-up.svg";
import quest from "../assets/science-behind/quest.svg";
import secretIdentity from "../assets/science-behind/secret-identity.svg";

export const SCIENCE_BEHIND = [
  {
    value: "Power ups",
    icon: powerUp,
    text: `A Power-ups is anything that makes you feel good  - no matter what kind of day you are having. It could be listening
    to a favourite song, eating a  favourite food, or spending the time with your pet. Feeling good is essentials to your
    SuperBetter adventure. In fact, its so important that you should try to use atleast 3 power - ups every single day.  `,
    color: "#FFCC1D",
  },
  {
    value: "Bad Guys",
    icon: badGuy,
    text: `A Power-ups is anything that makes you feel good  - no matter what kind of day you are having. It could be listening
    to a favourite song, eating a  favourite food, or spending the time with your pet. Feeling good is essentials to your
    SuperBetter adventure. In fact, its so important that you should try to use atleast 3 power - ups every single day.`,
    color: "#9d59ae",
  },
  {
    value: "Quests",
    icon: quest,
    text: `A Power-ups is anything that makes you feel good  - no matter what kind of day you are having. It could be listening
    to a favourite song, eating a  favourite food, or spending the time with your pet. Feeling good is essentials to your
    SuperBetter adventure. In fact, its so important that you should try to use atleast 3 power - ups every single day. `,
    color: "#48c98e",
  },
  {
    value: "Allies",
    icon: ally,
    text: `A Power-ups is anything that makes you feel good  - no matter what kind of day you are having. It could be listening
    to a favourite song, eating a  favourite food, or spending the time with your pet. Feeling good is essentials to your
    SuperBetter adventure. In fact, its so important that you should try to use atleast 3 power - ups every single day. `,
    color: "#45a2e5",
  },
  {
    value: "Epic Wins",
    icon: epicWins,
    text: `A Power-ups is anything that makes you feel good  - no matter what kind of day you are having. It could be listening
    to a favourite song, eating a  favourite food, or spending the time with your pet. Feeling good is essentials to your
    SuperBetter adventure. In fact, its so important that you should try to use atleast 3 power - ups every single day. `,
    color: "#147095",
  },
  {
    value: "Secret Identities",
    icon: secretIdentity,
    text: `A Power-ups is anything that makes you feel good  - no matter what kind of day you are having. It could be listening
    to a favourite song, eating a  favourite food, or spending the time with your pet. Feeling good is essentials to your
    SuperBetter adventure. In fact, its so important that you should try to use atleast 3 power - ups every single day.  `,
    color: "#e0708e",
  },
  {
    value: "Challenge Mindset",
    icon: challengeMindset,
    text: `A Power-ups is anything that makes you feel good  - no matter what kind of day you are having. It could be listening
    to a favourite song, eating a  favourite food, or spending the time with your pet. Feeling good is essentials to your
    SuperBetter adventure. In fact, its so important that you should try to use atleast 3 power - ups every single day. `,
    color: "#f9a623",
  },
];
