import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import Card from "../../../common/Cards";
import RigthArrow from "../../../assets/right-caret";
import BackArrow from "../../../assets/arrowleft";
import pen from "../../../assets/util-icons/pen.svg";
import { prefrences_data } from "./config";
import {
  useGetProfileMutation,
  useUserPrefrencesMutation,
} from "../../../redux/api/userProfile";
import toast from "react-hot-toast";

const Prefrences = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profileData] = useGetProfileMutation();
  const [prefrencesUpdate] = useUserPrefrencesMutation();

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [dailyReminder, setDailyReminder] = useState(false);
  const [notification, setNotification] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (
      profile !== null &&
      (profile?.is_notifications_enable !== notification) &&
      count !== 0
    ) {
      setLoading(true);
      const payload = {
        is_notifications_enable: notification,
        reminder_time: "string",
      };
      /* updating Prefrences data */
      prefrencesUpdate(payload).then((response) => {
        if (response?.error) {
          toast.error(response?.error?.message);
          return;
        }
        toast.success("Preferences updated successfully.");
        setLoading(false);
      });
    } else {
      setCount(1);
    }
  }, [dailyReminder, notification]);

  const handleChangeCheck = (res) => {
    if (res?.title === "Daily Reminder") {
      setDailyReminder(!dailyReminder);
    } else {
      setNotification(!notification);
    }
  };

  useEffect(() => {
    setLoading(true);
    /* fetching Profile data */
    profileData().then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        const data = response?.data;
        setProfile(data?.details);
        // setDailyReminder(data?.details?.is_daily_reminder_enable);
        setNotification(data?.details?.is_notifications_enable);
        setLoading(false);
        dispatch({ type: "PROFILE_DATA", payload: data?.details });
        localStorage.setItem('USER' , JSON.stringify(data.details));
      }
    });
  }, []);

  const handleClickArrow = (res) => {
    if (res?.title === "App Settings") {
      navigate({ to: "/discover/settings" });
    } else {
      navigate({ to: "/discover/reminder" });
    }
  };

  return (
    <Container className="content-container settings prefrences ml-4">
      <h5 className="title fw-6 mb-3 f22 mar-p">
      <a className="pointer" onClick={() => navigate({ to: "/discover/settings" })}>
          <BackArrow />
        </a>
        Notifications
      </h5>
      {prefrences_data.map((res, i) => (
        <Card
          key={res.title + "-" + i}
          containerClass="mt-4 prefrence-card"
          title={
            <>
              <span className="title fw-4">{res.title}</span>
              {res?.subTitle && <p className="fs-2 mb-2">{res?.subTitle}</p>}
            </>
          }
          customRightBtn={
            <Row>
              <Col>
                {res?.type === "check" ? (
                  <Form.Check type="switch" id="check-api-checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      isValid
                      checked={
                        res?.title === "Daily Reminder"
                          ? dailyReminder
                          : notification
                      }
                      onChange={() => handleChangeCheck(res)}
                    />
                  </Form.Check>
                ) : (
                  <div onClick={() => handleClickArrow(res)}>
                    {res?.title === "Daily Reminder" ? (
                      <img src={pen} alt="pen" />
                    ) : (
                      <RigthArrow />
                    )}
                  </div>
                )}
              </Col>
            </Row>
          }
        />
      ))}
    </Container>
  );
};

export default Prefrences;
