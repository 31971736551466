import React from "react";

const ChallengesCard = (props) => {
  return (
    <div className="select-challenge-card" onClick={props.onClick}>
      <img width="74px" className="mt-4 mb-3" src={props.icon} alt="icon" />
      <h6 className="mt-3 title spaces fw-6">{props.name}</h6>
    </div>
  );
};

export default ChallengesCard;
