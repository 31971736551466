import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useNavigate, useSearch } from "@tanstack/react-location";
import toast from "react-hot-toast";
import { useCallback } from "react";
import { useGetCustomActivityMutation } from "../../../../redux/api/library";
import Button from "../../../../common/Buttons";
import ScrollToTop from "../../../../utils/scrollToTop";
import SQuest from "../../../../assets/sQuest";
import plusIcon from "../../../../assets/util-icons/plusIcon.svg";
import { API_MOCKS } from "../../../../constants/api-mocks";
import SPowerUp from "../../../../assets/sPowerUP";
import SBadGuy from "../../../../assets/sBadGuy";
import LibraryActivityCard from "../../../../common/ActivityCard/LibraryActivityCard";
import { useDispatch } from "react-redux";

const PER_PAGE = 8;

function CustomSoloLibrary({
  isCustomChallenge,
  challengeId,
  customActivityData,
  backHandler,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getCustomActivity] = useGetCustomActivityMutation();
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isActivityDeleted, setIsActivityDeleted] = useState(false);

  const search = useSearch();

  const getLibrary = useCallback(
    (page = 1, PER_PAGE, isDeleted) => {
      setLoading(true);
      const data = {
        page,
        per_page: PER_PAGE,
        type: customActivityData?.type,
      };
      getCustomActivity(data).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.error);
        } else {
          if (currentData?.result && !isDeleted) {
            setCurrentData((prevState) => ({
              ...res?.data,
              result: [...prevState?.result, ...res?.data?.result],
            }));
          } else {
            setCurrentData(res.data);
          }
        }
        setLoading(false);
      });
    },
    [getCustomActivity, page]
  );

  const activityDeleteHandler = (value) => {
    setIsActivityDeleted(value);
  };

  useEffect(() => {
    getLibrary(page, PER_PAGE);
  }, [getLibrary, page]);

  const moreButtonHandler = () => {
    setPage((state) => state + 1);
  };

  useEffect(() => {
    if (isActivityDeleted) {
      getLibrary(1, page * PER_PAGE, true);
      setIsActivityDeleted(false);
    }
  }, [getLibrary, isActivityDeleted, page]);

  const createNewHandler = () => {
    navigate({
      to:
        customActivityData?.type === "quests"
          ? "/select/add/quest-form"
          : customActivityData?.type === "power_ups"
          ? "/select/add/power-up-form"
          : customActivityData?.type === "bad_guy"
          ? "/select/add/bad-guy-form"
          : "",
      search: {
        id: customActivityData?.id,
        page: "solo-play",
      },
    });
    dispatch({
      type: "SELECTED_CARD_DETAIL",
      payload: {},
    });
  };

  return (
    <Container className="ps-0  position-relative">
      <ScrollToTop />
      <h5 className=" mt-5 mb-4 fsc-22 f2-600" style={{ color: "#121212" }}>
        My Personal Library
      </h5>

      {loading && !currentData?.result?.length ? (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex">
              <div>
                {customActivityData?.type === "quests" ? (
                  <SQuest active />
                ) : customActivityData?.type === "power_ups" ? (
                  <SPowerUp active />
                ) : customActivityData?.type === "bad_guy" ? (
                  <SBadGuy active />
                ) : null}
              </div>
              <div className="ml-3  mt-1 fw-5 fsc-18 b-fn">
                {customActivityData?.type === "quests"
                  ? "QUESTS"
                  : customActivityData?.type === "bad_guy"
                  ? "BAD GUYS"
                  : customActivityData?.type === "power_ups"
                  ? "POWER-UPS"
                  : ""}
              </div>
            </div>
            <div className="d-flex align-items-center me-4">
              <span className="fsc-14  me-2">Create New</span>

              <img
                width="24px"
                src={plusIcon}
                alt="icon"
                className="pointer  "
                onClick={createNewHandler}
              />
            </div>
          </div>
          <div className="d-flex w-100 gap-4 mt-4  mb-4  flex-wrap card-container">
            {currentData?.result?.length > 0 ? (
              currentData?.result?.map((res, i) => (
                <LibraryActivityCard
                  key={res.id}
                  containerClass=" mb-3    pu-crd"
                  quest={{ ...res, challenge_id: challengeId }}
                  color={
                    res?.type === "quests"
                      ? API_MOCKS.ACTIVITY.QUEST.color
                      : res?.type === "power_ups"
                      ? API_MOCKS.ACTIVITY.POWER_UPS.color
                      : res?.type === "bad_guy"
                      ? API_MOCKS.ACTIVITY.BAD_GUY.color
                      : ""
                  }
                  soloPlay={search?.page === "solo-play"}
                  isCustomChallenge={isCustomChallenge}
                  setActivityDeleteState={activityDeleteHandler}
                />
              ))
            ) : (
              <div className="no_invitation w-100">
                {" "}
                <div className="d-flex flex-column text-center">
                  <h6 className="black-color lh-1 mb-2 fw-6">
                    {`${
                      customActivityData?.type === "quests"
                        ? "Quests"
                        : customActivityData?.type === "bad_guy"
                        ? "Bad Guys"
                        : customActivityData?.type === "power_ups"
                        ? "Power-ups"
                        : ""
                    } you create will appear here`}
                  </h6>
                  <p
                    className="see-invitaion pointer"
                    onClick={createNewHandler}
                  >
                    {`Create new ${
                      customActivityData?.type === "quests"
                        ? "quest"
                        : customActivityData?.type === "bad_guy"
                        ? "bad guy"
                        : customActivityData?.type === "power_ups"
                        ? "power-up"
                        : ""
                    }`}
                  </p>
                </div>
              </div>
            )}
          </div>
          {currentData?.total > currentData?.result?.length && (
            <div className="w-100 d-flex justify-content-center">
              <Button
                customizeClass=" "
                onClick={moreButtonHandler}
                disable={loading}
                label="More"
              />
            </div>
          )}
          <Button
            label={"Back"}
            onClick={backHandler}
            customizeClass=" back-button-1 position-relative mt-5 pb-0 fw-600"
            variant="light"
          />
        </>
      )}
    </Container>
  );
}

export default CustomSoloLibrary;
