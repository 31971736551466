import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate, useSearch } from "@tanstack/react-location";
import { useFormik } from "formik";
import InputBox from "../../../common/InputBox";
import Select from "../../../common/Select";
import Button from "../../../common/Buttons";
import { soloSchema } from "../../../validation-schemas/auth-schema";
import { optionPowerUp } from "./config";
import {
  useAddChallengeMutation,
  useGetActivityDetailsMutation,
  useUpdateChallengeActivityMutation,
} from "../../../redux/api/challenge";
import toast from "react-hot-toast";
import ScrollToTop from "../../../utils/scrollToTop";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { useEditLibraryActivityMutation } from "../../../redux/api/library";

const data = [
  { label: "M", value: "monday", isSelected: false },
  { label: "T", value: "tuesday", isSelected: false },
  { label: "W", value: "wednesday", isSelected: false },
  { label: "T", value: "thursday", isSelected: false },
  { label: "F", value: "friday", isSelected: false },
  { label: "S", value: "saturday", isSelected: false },
  { label: "S", value: "sunday", isSelected: false },
];

function SoloQuest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useSearch();
  const UserData = useSelector((state) => state.userChallence);

  const [addChallenge, addChallengeValue] = useAddChallengeMutation();
  const [updateActivityChallenge, updateActivityChallengeValue] =
    useUpdateChallengeActivityMutation();
  const [updateLibraryActivity, updateLibraryActivityValue] =
    useEditLibraryActivityMutation();
  const [getActivityDetails] = useGetActivityDetailsMutation();
  const [buildMost, setBuildMost] = useState("");
  const [showRecurringActivity, setShowRecurringActivity] = useState(false);
  // const [isCloned, setIsCloned] = useState(false);
  const { cardDetail } = UserData;

  const initialValues = {
    title: "",
    description: "",
    powerSelect: "-select-",
    recurringActivityDays: [...data],
  };

  const goBackToCustomLibrary = () => {
    localStorage.setItem(
      "customLibraryActivityData",
      JSON.stringify({
        id: cardDetail.challenge_id,
        type: cardDetail.type,
        page: search?.page,
      })
    );
    navigate({ to: `/discover/dashboard/${search?.page}` });
    dispatch({
      type: "SELECTED_CARD_DETAIL",
      payload: {},
    });
  };

  const onSubmit = (values) => {
    if (values?.powerSelect !== "-select-") {
      const repeatDaysArray = values.recurringActivityDays.reduce(
        (result, current) => {
          if (current.isSelected) {
            result.push(current.value);
          }
          return result;
        },
        []
      );
      const payload = {
        id:
          cardDetail?.isCustomLibraryActivity ||
          cardDetail?.isEditCustomLibraryActivity
            ? cardDetail?.challenge_id
            : search?.id,
        data: {
          type: "quests",
          title: values?.title,
          description: values?.description,
          repeat: repeatDaysArray.length ? { weekdays: repeatDaysArray } : null,
          strength_to_build: values?.powerSelect.toLowerCase(),
        },
      };

      if (cardDetail?.isCustomLibraryActivity) {
        payload.data.clone_from = cardDetail?.id;
      }
      if (cardDetail?.isEditCustomLibraryActivity) {
        delete payload.data.type;
        payload.activity_id = cardDetail?.id;
        updateLibraryActivity(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            goBackToCustomLibrary();
          }
        });
      } else if (search?.activityId) {
        delete payload.data.type;
        payload.activity_id = search?.activityId;
        updateActivityChallenge(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            navigate({ to: `/discover/dashboard/${search?.page}` });
          }
        });
      } else {
        addChallenge(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            toast.success("Activity added successfully");
            navigate({ to: `/discover/dashboard/${search?.page}` });
          }
        });
      }
    } else {
      setBuildMost("Please select your strength");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: soloSchema,
    onSubmit,
  });

  useEffect(() => {
    setTimeout(() => {
      setBuildMost("");
    }, 2000);
  }, [buildMost]);

  useEffect(() => {
    if (!showRecurringActivity) {
      formik.setFieldValue("recurringActivityDays", [...data]);
    }
  }, [showRecurringActivity]);

  const changeHandler = (e, day, index) => {
    const newDay = { ...day };
    newDay.isSelected = !newDay.isSelected;
    const dayIndex = formik.values.recurringActivityDays.findIndex(
      (item, i) => i === index
    );
    formik.values.recurringActivityDays[dayIndex] = newDay;
    formik.setFieldValue(
      "recurringActivityDays",
      formik.values.recurringActivityDays
    );
  };

  const fetchActivityDetails = useCallback(() => {
    getActivityDetails({
      challenge_id: search?.id,
      activity_id: search?.activityId,
    }).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.message);
      } else {
        const days = [...data];
        if (res?.data?.repeat?.weekdays?.length) {
          setShowRecurringActivity(true);
        }

        const updatedDays = days.reduce((result, current) => {
          let newCurrent;
          newCurrent = { ...current };
          if (res?.data?.repeat?.weekdays?.includes(current?.value)) {
            newCurrent.isSelected = true;
          }
          result.push(newCurrent);

          return result;
        }, []);
        formik.setFieldValue("title", res?.data?.title);
        formik.setFieldValue("description", res?.data?.description);
        formik.setFieldValue(
          "powerSelect",
          capitalizeFirstLetter(res?.data?.strength_to_build)
        );
        formik.setFieldValue("recurringActivityDays", updatedDays);
      }
    });
  }, [getActivityDetails, search?.activityId, search?.id]);

  useEffect(() => {
    if (
      search?.activityId &&
      !cardDetail?.isCustomLibraryActivity &&
      !cardDetail?.isEditCustomLibraryActivity
    ) {
      fetchActivityDetails();
    }
  }, [
    cardDetail?.isCustomLibraryActivity,
    cardDetail?.isEditCustomLibraryActivity,
    fetchActivityDetails,
    search?.activityId,
  ]);

  useEffect(() => {
    if (
      cardDetail?.isCustomLibraryActivity ||
      cardDetail?.isEditCustomLibraryActivity
    ) {
      const days = [...data];
      if (cardDetail?.repeat?.weekdays?.length) {
        setShowRecurringActivity(true);
      }

      const updatedDays = days.reduce((result, current) => {
        let newCurrent;
        newCurrent = { ...current };
        if (cardDetail?.repeat?.weekdays?.includes(current?.value)) {
          newCurrent.isSelected = true;
        }
        result.push(newCurrent);

        return result;
      }, []);
      formik.setFieldValue("title", cardDetail?.title);
      formik.setFieldValue("description", cardDetail?.description);
      formik.setFieldValue(
        "powerSelect",
        capitalizeFirstLetter(cardDetail?.strength_to_build)
      );
      formik.setFieldValue("recurringActivityDays", updatedDays);
    }
  }, [cardDetail]);

  return (
    <div className="soloPlay form">
      <ScrollToTop />
      <Container className="justify-content-md-center" sm={4}>
        <div className="d-flex justify-content-center head2">
          {search?.activityId || cardDetail?.isEditCustomLibraryActivity
            ? "Edit a Quest"
            : cardDetail?.isCustomLibraryActivity
            ? "Quest"
            : "Add a Quest"}{" "}
        </div>

        <form onSubmit={formik.handleSubmit}>
          <Row className="justify-content-md-center mb-1">
            <Col sm={6}>
              <InputBox
                label="Title*"
                type="title"
                name="title"
                containerClass="mt-3"
                placeholder="e.g. Walk around the block"
                inputClass="input-title"
                lableSize={"md"}
                formik={formik}
                values={formik.values.title}
                isError={formik.errors.title && formik.touched.title}
                error={formik.errors.title}
              />
              <InputBox
                label="Description*"
                type="description"
                name="description"
                placeholder="Enter description"
                containerClass="mt-3"
                inputClass="text-area-input"
                lableSize={"md"}
                formik={formik}
                values={formik.values.description}
                isError={
                  formik.errors.description && formik.touched.description
                }
                error={formik.errors.description}
                asText
              />
              <div className="mt-3">
                <div className={"color-dark fw-6 lable14 mb-2"}>
                  What strength does this build most?
                </div>
                <Select
                  name="powerSelect"
                  options={optionPowerUp}
                  formik={formik}
                  onChange={formik.handleChange}
                  value={formik.values.powerSelect}
                  isError={buildMost && true}
                  error={buildMost}
                />
                <p className="text-danger">{buildMost}</p>
              </div>
              <div className="mt-3 sg-up-n">
                {" "}
                <Form.Check
                  type="checkbox"
                  label={"This is a recurring quest"}
                  className="mt-3 d-flex justify-content-start"
                  name="recurring_activity"
                  style={{ borderColor: "#4a60ab" }}
                  onChange={() =>
                    setShowRecurringActivity(!showRecurringActivity)
                  }
                  checked={showRecurringActivity}
                />
              </div>

              {showRecurringActivity && (
                <>
                  <p className="color-dark fw-6 lable14 my-2"> Days</p>
                  <div className="weekday-container d-flex g-2 align-items-center">
                    <div className="d-flex ">
                      {formik.values.recurringActivityDays?.map((day, i) => (
                        <div className="me-3 " key={"recurring-day-" + i}>
                          <Button
                            customType={"button"}
                            onClick={(e) => {
                              e.stopPropagation();
                              changeHandler(e, day, i);
                            }}
                            label={day?.label}
                            customizeClass={
                              day.isSelected
                                ? "round-btn schedule active text-uppercase"
                                : "round-btn schedule text-uppercase"
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Button
              type="submit"
              label={
                search?.activityId || cardDetail?.isEditCustomLibraryActivity
                  ? "Save"
                  : cardDetail?.isCustomLibraryActivity
                  ? "Add to Challenge"
                  : "Create"
              }
              disabled={
                updateLibraryActivityValue.isLoading ||
                addChallengeValue.isLoading ||
                updateActivityChallengeValue.isLoading
              }
              customizeClass="lower-button"
            />
          </Row>
        </form>
        <Button
          label={"Back"}
          onClick={() =>
            cardDetail?.isCustomLibraryActivity ||
            cardDetail?.isEditCustomLibraryActivity
              ? goBackToCustomLibrary()
              : navigate({ to: `/discover/dashboard/${search?.page}` })
          }
          customizeClass="back-button fw-600"
          variant="light"
        />
      </Container>
    </div>
  );
}

export default SoloQuest;
