import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const profile = createApi({
  reducerPath: "userprofile",
  baseQuery: baseQueryWithReAuth({ baseUrl: `${API_BASE_URL}user` }),
  endpoints: (builder) => ({
    getProfile: builder.mutation({
      query: (body) => ({
        url: `/profile/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    updateProfile: builder.mutation({
      query: (body) => ({
        url: `/profile/update`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    pieChart: builder.mutation({
      query: (body) => ({
        url: `/secret-lab/resilience-score`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    secreteAvatar: builder.mutation({
      query: (body) => ({
        url: `/profile/get-secret-identity`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    secreteIdentitySet: builder.mutation({
      query: (body) => ({
        url: `/profile/set-secret-identity`,
        method: "PATCH",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    userChangePassword: builder.mutation({
      query: (body) => ({
        url: `/profile/change-password`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    userPrefrences: builder.mutation({
      query: (body) => ({
        url: `/profile/user-settings`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    uploadFile: builder.mutation({
      query: (body) => ({
        url: `/utility/upload-file`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    getNotifications: builder.mutation({
      query: (body) => ({
        url: `/profile/notifications?page=${body.page}&per_page=10&is_opened=true`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    getGoals: builder.mutation({
      query: (body) => ({
        url: `/profile/list-goals`,
        method: "GET",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    setGoals: builder.mutation({
      query: (body) => ({
        url: `/profile/set-goal`,
        method: "PATCH",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    getAllGoals: builder.mutation({
      query: (body) => ({
        url: `/profile/goal`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    getChallengeSelect: builder.mutation({
      query: (body) => ({
        url: `/library/challenge?search=${body}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    discoverCount: builder.mutation({
      query: (body) => ({
        url: `/challenge/invitations/new-counts?is_on_tab=${body}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    RefreshAlly: builder.mutation({
      query: (body) => ({
        url: `/library/activity/ally-check-in`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    allyNewActivity: builder.mutation({
      query: (body) => ({
        url: `/challenge/${body.c_id}/activity`,
        method: "POST",
        body: body?.data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    getTotalNotifications: builder.mutation({
      query: (body) => ({
        url: `/profile/notifications?page=1&per_page=10&is_opened=false`,
        method: "GET",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetProfileMutation,
  useUpdateProfileMutation,
  usePieChartMutation,
  useSecreteAvatarMutation,
  useSecreteIdentitySetMutation,
  useUserChangePasswordMutation,
  useUserPrefrencesMutation,
  useUploadFileMutation,
  useGetNotificationsMutation,
  useGetGoalsMutation,
  useSetGoalsMutation,
  useGetAllGoalsMutation,
  useGetChallengeSelectMutation,
  useDiscoverCountMutation,
  useRefreshAllyMutation,
  useAllyNewActivityMutation,
  useUserDataMutation,
  useGetTotalNotificationsMutation,
} = profile;
