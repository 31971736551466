import Rules from "../../../assets/rules.png";
import Challenge from "../../../assets/challenges.png";
import Mountains from "../../../assets/mountain.png";
import Owl from "../../../assets/badOwl.png";
import Powers from "../../../assets/powers.png";
import Ally from "../../../assets/timer.png";
import Mask from "../../../assets/mask.png";
import Multiple from "../../../assets/multiple.jpg";
import Flags from "../../../assets/flags.png";
import Trophy from "../../../assets/trophy.jpg";

export const HowtoPlayData = [
  [
    {
      title: "Unlock Your Heroic Potential",
      para: "Use the psychology of game play to achieve epic wins in all of life. It’s backed by science.",
      picture: Rules,
    },
    {
      title: "Challenge Yourself",
      para: "Bring a gameful mindset to everyday life. Focus on goals and growth.",
      picture: Challenge,
    },
  ],
  [
    {
      title: "Go for an Epic Win",
      para: "An epic win is a goal that motivates you. It can be small, large, or a step to something bigger.",
      picture: Trophy,
    },
    {
      title: "Seek Out and Complete Quests",
      para: "Quests are simple actions that move you toward your epic win.",
      picture: Mountains,
    },
  ],
  [
    {
      title: "Find and Battle the Bad Guys",
      para: "Bad guys are the obstacles that block  your progress.",
      picture: Owl,
    },
    {
      title: "Collect and Activate Power-ups",
      para: "Power-ups are simple positive actions that boost your energy and make you feel happier, healthier or stronger.",
      picture: Powers,
    },
  ],
  [
    {
      title: "Recruit Your Allies",
      para: "Allies are your people who support you along the way. Take a minute to check-in each day.",
      picture: Ally,
    },
    {
      title: "Adopt a Secret Identity",
      para: "Pick a heroic nickname and avatar that inspire you.",
      picture: Mask,
    },
  ],
  [
    {
      title: "Win the Day",
      para: "Your daily goal is to complete 1 quest, battle 1 bad guy, activate 3 power-ups and check-in with an ally.",
      picture: Flags,
    },
    {
      title: "Track Your Progress",
      para: "See your resilience scores and take the How Gameful Are You? Quiz in your Secret Lab.",
      picture: Multiple,
    },
  ],
];
