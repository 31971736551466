import React, { useEffect, useState } from "react";
import { Container, Spinner,Col } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch } from "react-redux";
import Button from "../../../common/Buttons";
import ChallengeCard from "../../../common/select-challenges-card/outline-card";
import { useGetChallengeSelectMutation } from "../../../redux/api/userProfile";
import toast from "react-hot-toast";

const MoreChallenges = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [something, setSomething] = useState('');
  const [section, setSection] = useState("mental");
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getChallenges] = useGetChallengeSelectMutation();


  useEffect(()=>{
    setLoading(true)
    getChallenges(section).then((res)=>{
     if (res?.error) {
        toast.error(res?.error?.data?.error);
      } else {
          const data = res?.data?.[section]
        setCurrentData(data)
      }
      setLoading(false)
    })
  },[section])

  const handleClick = (res) => {
    if (res.name === "Something Else") {
      setShowModal(!showModal);
      setSomething(res)
    } else {
        navigate({ to: "/discover/invitations/" + res.id });
        dispatch({ type: "SELECTED_INVITATION", payload: res });
    }
  };

  const handleOnSubmit =(v)=>{
    navigate({ to: "/select/further-goal", search: {name:v.notes,icon:something.icon} });
  }

  return (
    <Container className="center-container">
    <h5 className="color-p mt-5 mb-4 f26 fw-6">Challenges</h5>
    <div className="d-flex mt-4 mb-5">
      <Button
        customizeClass={section !== "mental" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn pu-active-btn"}
        label="Mental"
        onClick={() => setSection("mental")}
      />
      <Button
        customizeClass={section !== "emotional" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn pu-active-btn"}
        label="Emotional"
        onClick={() => setSection("emotional")}
      />
      <Button
        customizeClass={section !== "Social" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn pu-active-btn"}
        label="Social"
        onClick={() => setSection("social")}
      />
      <Button
        customizeClass={section !== "physical" ? "mr-2  grey-btn pu-btn" : "mr-2 pu-btn pu-active-btn"}
        label="Physical"
        onClick={() => setSection("physical")}
      />
    </div>
    {loading ? (
      <div className="loading">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    ) : (
        <>
      <Col  className="d-flex w-6 mt-3 mb-4 card-container cursor">
      {currentData?.map((res, index) => (
          <Col key={res.id} className="d-flex w-6 mt-2 mb-4 card-container">
            <ChallengeCard
              containerClass="mr-2 mb-3"
              name={res?.title}
              icon={res?.file}
              epic={res?.epic_win}
              onClick={() => handleClick(res)}
            />
          </Col>
        ))}
        </Col>

        <Button customizeClass="mr-2 mt-3 mb-4 pu-btn-m" label="More" />
      </>
    )}
  </Container>
  );
};

export default MoreChallenges;