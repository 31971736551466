import {
  configureStore,
} from "@reduxjs/toolkit";
import { auth } from "./api/auth";
import { challenge } from "./api/challenge"
import { invitation } from "./api/invitation"
import { library } from "./api/library"
import { quizApi } from "./api/quiz"
import { ScreteLab } from "./api/secretLab"
import { SubscriptionApi } from "./api/subscription"
import { challengeSecience } from "./api/user-science-card"
import { profile } from "./api/userProfile"
import Invitation from "./reducer/invitation";
import UserChallence from "./reducer/userChallenge"
import Subscription from "./reducer/subscription"
import Profile from "./reducer/profile";
import AdminCms from "./reducer/adminCms"
import Quiz from "./reducer/quiz";
import Alert from "./reducer/alert"
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
key: "root",
storage,
};

const appReducer = combineReducers({
[auth.reducerPath]: auth.reducer,
[challenge.reducerPath]:challenge.reducer,
[challengeSecience.reducerPath]:challengeSecience.reducer,
[invitation.reducerPath]:invitation.reducer,
[library.reducerPath]:library.reducer,
[quizApi.reducerPath]:quizApi.reducer,
[ScreteLab.reducerPath]:ScreteLab.reducer,
[profile.reducerPath]:profile.reducer,
[SubscriptionApi.reducerPath]:SubscriptionApi.reducer,
invitation: Invitation,
userChallence:UserChallence,
quiz:Quiz,
alert:Alert,
subscription:Subscription,
adminCms:AdminCms,
userProfile:Profile
}); 

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer({}, action)
  }

  return appReducer(state, action)
}


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
reducer: persistedReducer,
middleware: (getDefaultMiddleware) =>
getDefaultMiddleware({
  serializableCheck: false,
}).concat(auth.middleware),
devTools: process.env.NODE_ENV === 'development' ? true : false,
});
