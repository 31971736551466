export const VALIDATION_MESSAGE = {
  EMAIL_OR_USERNAME_EMPTY: "Please enter your email/username",
  PASSWORD_EMPTY: "Please enter your password",
  EMAIL_EMPTY: "Please enter your email",
  EMAIL_INVALID: "Please enter valid email address",
  PASSWORD_MIN_LENGTH: "Please should be at least 8 characters",
  PASSWORD_MATCH: "Password should match",
  PASSWORD_INVALID: "Password should be alphanumeric",
  FIRST_NAME: "Please enter your first name",
  LAST_NAME: "Please enter your last name",
  NOTES: "Please write some notes",
  TITLE: "Please enter your title",
  DESCRIPTION: "Please enter your description",
  GUARDIAN_EMAIL: "Please enter Parent , Guardian or Teacher email.",
};
