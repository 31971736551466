import React from "react";
import InputBox from "../../../../common/InputBox";
import { APP_CONFIG } from "../../../../constants/app-config";
import DatePicker from "../../../../common/DatePicker";

function ParentRegister({
  email,
  formik,
  disableEmail,
  isMigratedUser,
  disableFields,
}) {
  return (
    <>
      {!disableEmail && !disableFields?.isFirstNameDisable && (
        <InputBox
          label="First Name*"
          type="text"
          containerClass="mt-3"
          name="first_name"
          placeholder={APP_CONFIG.FIRST_NAME_PLACEHOLDER}
          formik={formik}
          values={formik.values.first_name}
          isError={formik.errors.first_name && formik.touched.first_name}
          error={formik.errors.first_name}
        />
      )}
      {!disableEmail && !disableFields?.isLastNameDisable && (
        <InputBox
          label="Last Name*"
          type="text"
          containerClass="mt-3"
          name="last_name"
          placeholder={APP_CONFIG.LAST_NAME_PLACEHOLDER}
          formik={formik}
          values={formik.values.last_name}
          isError={formik.errors.last_name && formik.touched.last_name}
          error={formik.errors.last_name}
        />
      )}
      {!disableEmail && !disableFields?.isEmailDisable && (
        <InputBox
          label="Email*"
          type="email"
          containerClass="mt-3"
          name="email"
          placeholder={APP_CONFIG.EMAIL_PLACEHOLDER_LOGIN}
          formik={formik}
          disabled={disableEmail || isMigratedUser}
          values={email ? email : formik.values.email}
          isError={formik.errors.email && formik.touched.email}
          error={formik.errors.email}
        />
      )}

      {!disableEmail &&
        !isMigratedUser &&
        !disableFields?.isPasswordDisable && (
          <InputBox
            label="Password*"
            type="password"
            name="password"
            containerClass="mt-3"
            placeholder="******"
            formik={formik}
            values={formik.values.password}
            isError={formik.errors.password && formik.touched.password}
            error={formik.errors.password}
          />
        )}
      <DatePicker formik={formik} label={APP_CONFIG.DATEPICKER_LABEL} />

      {/* <Form.Check
        type="checkbox"
        label={"Send me newsletter"}
        className="mt-3 "
        name="newsletter_subscribed"
        onChange={formik?.handleChange}
        values={formik.values.newsletter_subscribed}
      /> */}
    </>
  );
}

export default ParentRegister;
