const initState = {
    data: null,
    activityDate: null,
    sideBar:false
  };
  
  const profileReducer = (state = initState, action) => {
    switch (action.type) {
      case "PROFILE_DATA":
        return {
          ...state,
          data: action.payload,
        };
      case "ACTIVITY_CHALLENGE_DATA":  
      return {
        ...state,
        activityDate: action.payload,
      };
      case "UPDATE_SIDE_BAR":  
      return {
        ...state,
        sideBar: action.payload,
      };
      default:
        return state;
    }
  };
  
  export default profileReducer;