const SPowerUp = ({ active = true }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={!active && 0.16}
    >
      <circle cx="14.2495" cy="14.2495" r="14.2495" fill="#F6BF01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2505 28.498C22.1203 28.498 28.5 22.1183 28.5 14.2485C28.5 11.7969 27.8809 9.48998 26.7905 7.47543C24.285 5.28512 21.0056 3.95801 17.4162 3.95801C9.54646 3.95801 3.16675 10.3377 3.16675 18.2075C3.16675 20.6591 3.78585 22.966 4.87623 24.9806C7.38174 27.1709 10.6611 28.498 14.2505 28.498Z"
        fill="#FECC1F"
      />
      <path
        d="M14.7507 5.99116L9.39081 15.8577C9.3649 15.9054 9.40144 15.9621 9.45804 15.9621H12.7647C12.8093 15.9621 12.8443 15.9986 12.8399 16.0407L12.1694 22.5145C12.1613 22.5936 12.2748 22.6246 12.3123 22.5535L17.717 12.3121C17.7422 12.2645 17.7056 12.2085 17.6494 12.2085H14.4303C14.386 12.2085 14.3512 12.1727 14.3549 12.1309L14.8932 6.02991C14.9001 5.95178 14.7885 5.92146 14.7507 5.99116Z"
        fill="#E7C200"
      />
      <path
        d="M14.8932 6.02991C14.9001 5.95178 14.7885 5.92146 14.7507 5.99116L9.39081 15.8577C9.3649 15.9054 9.40144 15.9621 9.45804 15.9621H12.7647C12.8093 15.9621 12.8443 15.9986 12.8399 16.0407L12.1694 22.5145C12.1613 22.5936 12.2748 22.6246 12.3123 22.5535L13.8174 19.7015L14.3067 14.9767C14.3111 14.9346 14.2762 14.8981 14.2315 14.8981H12.0319C11.716 14.8981 11.512 14.5813 11.6566 14.3151L14.648 8.80835L14.8932 6.02991Z"
        fill="#F9D514"
      />
      <path
        d="M19.3535 9.06414C19.2353 8.98164 19.069 9.00555 18.9818 9.11749L18.3111 9.97988C18.224 10.0919 18.2492 10.2495 18.3674 10.332C18.4149 10.3651 18.4701 10.3811 18.5249 10.3811C18.6065 10.3811 18.6869 10.3456 18.7391 10.2786L19.4098 9.41624C19.4969 9.3043 19.4717 9.14667 19.3535 9.06414Z"
        fill="black"
      />
      <path
        d="M18.7391 18.9435C18.652 18.8316 18.4856 18.8077 18.3674 18.8902C18.2492 18.9727 18.224 19.1303 18.3111 19.2423L18.9818 20.1047C19.034 20.1716 19.1145 20.2071 19.196 20.2071C19.2508 20.2071 19.3061 20.1911 19.3535 20.158C19.4717 20.0755 19.4969 19.9179 19.4098 19.8059L18.7391 18.9435Z"
        fill="black"
      />
      <path
        d="M21.5674 14.3592H20.6331C20.4863 14.3592 20.3673 14.472 20.3673 14.611C20.3673 14.7501 20.4863 14.8629 20.6331 14.8629H21.5674C21.7142 14.8629 21.8332 14.7501 21.8332 14.611C21.8332 14.472 21.7142 14.3592 21.5674 14.3592Z"
        fill="black"
      />
      <path
        d="M10.0944 10.2786C10.1465 10.3456 10.227 10.3811 10.3086 10.3811C10.3634 10.3811 10.4186 10.3651 10.4661 10.332C10.5842 10.2494 10.6095 10.0918 10.5224 9.97987L9.85163 9.11748C9.76455 9.00554 9.59816 8.98163 9.47996 9.06413C9.3618 9.14666 9.33657 9.30426 9.42365 9.41623L10.0944 10.2786Z"
        fill="black"
      />
      <path
        d="M10.4661 18.89C10.3479 18.8076 10.1815 18.8314 10.0944 18.9434L9.42366 19.8058C9.33658 19.9177 9.36178 20.0754 9.47997 20.1579C9.52747 20.191 9.58268 20.207 9.63744 20.207C9.71902 20.207 9.79954 20.1715 9.85164 20.1045L10.5224 19.2421C10.6095 19.1302 10.5843 18.9725 10.4661 18.89Z"
        fill="black"
      />
      <path
        d="M8.20006 14.3592H7.26581C7.11901 14.3592 7 14.472 7 14.611C7 14.7501 7.11901 14.8629 7.26581 14.8629H8.20006C8.34686 14.8629 8.46587 14.7501 8.46587 14.611C8.46587 14.472 8.34686 14.3592 8.20006 14.3592Z"
        fill="black"
      />
      <path
        d="M14.4724 19.2738C14.3411 19.2116 14.1814 19.262 14.1158 19.3864L12.8054 21.8695L13.3754 16.3652C13.3848 16.2745 13.3532 16.1838 13.2886 16.1163C13.224 16.0489 13.1318 16.0102 13.0357 16.0102H10.0408L12.7958 10.9386C12.863 10.815 12.8117 10.6631 12.6812 10.5995C12.5506 10.5358 12.3904 10.5845 12.3232 10.7081L9.42545 16.0424C9.37055 16.1435 9.37512 16.2618 9.4376 16.3588C9.50012 16.4559 9.60907 16.5138 9.72907 16.5138H12.8259L12.1759 22.7898C12.1596 22.9475 12.2604 23.0883 12.4211 23.1322C12.4537 23.1411 12.4864 23.1454 12.5185 23.1454C12.6448 23.1454 12.7614 23.079 12.8211 22.966L14.5913 19.6116C14.6569 19.4872 14.6037 19.336 14.4724 19.2738Z"
        fill="black"
      />
      <path
        d="M12.9609 10.0844C13.0915 10.1481 13.2517 10.0994 13.3189 9.97578L14.8194 7.21362L14.3609 12.41C14.353 12.4999 14.3854 12.5895 14.4499 12.6558C14.5143 12.7222 14.6059 12.7602 14.7011 12.7602H15.7229C15.8697 12.7602 15.9887 12.6475 15.9887 12.5084C15.9887 12.3693 15.8697 12.2566 15.7229 12.2566H14.9079L15.429 6.3509C15.4428 6.19507 15.3416 6.05614 15.183 6.01299C15.0244 5.96981 14.8607 6.03683 14.7852 6.17587L12.8462 9.74528C12.779 9.86894 12.8304 10.0208 12.9609 10.0844Z"
        fill="black"
      />
      <path
        d="M18.2115 12.4102C18.1488 12.3141 18.0403 12.2568 17.9211 12.2568H16.8565C16.7097 12.2568 16.5907 12.3696 16.5907 12.5086C16.5907 12.6477 16.7097 12.7605 16.8565 12.7605H17.6133L14.6239 18.4251C14.5583 18.5495 14.6115 18.7007 14.7428 18.7629C14.7809 18.781 14.8215 18.7896 14.8615 18.7896C14.959 18.7896 15.0528 18.7385 15.0994 18.6503L18.2265 12.7249C18.2798 12.6239 18.2742 12.5063 18.2115 12.4102Z"
        fill="black"
      />
    </svg>
  );
};

export default SPowerUp;
