import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const challengeSecience = createApi({
  reducerPath: "userScienceCard",
  baseQuery: baseQueryWithReAuth({
    baseUrl: `${API_BASE_URL}user/science-card`,
  }),
  endpoints: (builder) => ({
    allScienceCard: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    selectedScienceCard: builder.mutation({
      query: (body) => ({
        url: `/${parseInt(body)}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useAllScienceCardMutation, useSelectedScienceCardMutation } =
  challengeSecience;
