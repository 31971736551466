import { React, useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearch } from "@tanstack/react-location";
import {
  useGetInvitationsMutation,
  useUpdateInvitationsMutation,
  useGetChallengeMutation,
  useSuggestionChallengesMutation,
} from "../../../../redux/api/challenge";
import toast from "react-hot-toast";
import Switch from "../../../../common/Switch";
import { useCallback } from "react";
import Explore from "./explore";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import SettingIcon from "../../../../assets/util-icons/setting.svg";
import ActiveFilterIcon from "../../../../assets/util-icons/play-filterss";
import HistoryFilterIcon from "../../../../assets/util-icons/history";
import Hero from "./hero";
import History from "./history";
import moment from "moment";
import AlertModal from "../../../../common/Modal/alertModal";
import { getUTCTimeStamp } from "../../../../utils/helper";

const emptyFunctionPlay = () => {
  return (
    <div className="no_invitation">
      {" "}
      <div className="d-flex flex-column text-center">
        <h6 className="title lh-1 fw-6 mb-3">No Active Squads</h6>
        <p>
          When you accept an invitation to join a squad
          <br /> challenge you will find it here
        </p>
      </div>
    </div>
  );
};

const emptyFunctionExplore = () => {
  return (
    <div className="no_invitation">
      {" "}
      <div className="d-flex flex-column text-center">
        <h6 className="title lh-1 fw-6 mb-3"> No Squad Invitations</h6>
        <p>
          When a host invites you to join a challenge,
          <br />
          you will find it here
        </p>
      </div>
    </div>
  );
};

function Discover() {
  const search = useSearch();

  // state for store challenges
  const userData = useSelector((state) => state?.userProfile?.data);
  const [challenges, setChallenges] = useState([]);
  const [activeChallenges, setActiveChallenges] = useState([]);
  const [lodaing, setLoading] = useState(false);
  const [data] = useGetInvitationsMutation();
  const [updateInvitation] = useUpdateInvitationsMutation();
  const [currentActive] = useGetChallengeMutation();
  const [triggerSuggestionChallenges] = useSuggestionChallengesMutation();
  const [suggestionData, setSuggestionData] = useState([]);
  const [activeSwitch, setActiveSwitch] = useState("Play");
  const [filter, setFilter] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (search?.back === "PgGoal") {
      setActiveSwitch("Explore");
    }
  }, []);

  // api integration for list of invitation

  const getChallenges = useCallback(() => {
    setLoading(true);
    data()
      .then((response) => {
        if (response?.error) {
          toast.error(response?.error?.message);
          return;
        }
        setChallenges(response.data);
        dispatch({ type: "ALL_INVITATION", payload: response });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    dispatch({ type: "USER_CHALLENGE", payload: {} });
    currentActive(filter ? "active" : "completed").then((response) => {
      if (response?.error) {
        toast.error(response?.error?.message);
      } else {
        setActiveChallenges(response.data);
      }
      setLoading(false);
    });
  }, [currentActive, data, dispatch, filter]);

  useEffect(() => {
    getChallenges();
  }, [getChallenges]);

  const handleClick = (response, click) => {
    if (click === true) {
      navigate({ to: "/discover/invitations/" + response.id });
      dispatch({ type: "SELECTED_INVITATION", payload: response });
    } else {
      const data = { id: response.id, value: false };
      updateInvitation(data)
        .then((res) => {
          getChallenges();
        })
        .catch((err) => {
          return;
        });
    }
  };

  const playerButton = [
    {
      label: "Play",
      value: "play",
    },
    {
      label: "Explore",
      value: "explore",
    },
  ];

  const getAllSuggestionChallenges = useCallback(() => {
    triggerSuggestionChallenges().then((res) => {
      if (res?.error) {
        toast.error(res?.error?.message);
      } else {
        setSuggestionData(res.data);
      }
    });
  }, [triggerSuggestionChallenges]);

  useEffect(() => {
    getAllSuggestionChallenges();
  }, [getAllSuggestionChallenges, activeSwitch]);

  const handleLink = (date, link, isSolo) => {
    if (isSolo || moment().unix() >= getUTCTimeStamp(date)) {
      navigate({ to: link });
    } else {
      setIsVisible(true);
    }
  };

  return (
    <Container className="content-container discover mt-1 px-0">
      <AlertModal
        showModal={isVisible}
        title={"Sorry, this challenge is not available until the start date."}
        setShowModal={(e) => setIsVisible(e)}
        handleCancelPlan={() => setIsVisible(false)}
        buttonText="Close"
        txt="general"
      />
      <Row>
        <Col md="6">
          <ListGroup variant="flush">
            <ListGroup.Item className="only_top_border px-0 py-2">
              <Switch
                containerClass="justify-content-start mt-3 ml-0"
                values={playerButton}
                activeSwitch={activeSwitch}
                setActiveSwitch={(l) => {
                  setFilter(true);
                  setActiveSwitch(l);
                }}
              />
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md="6" className="filter-cl">
          {activeSwitch === "Play" && (
            <Dropdown className="filters-discover">
              <Dropdown.Toggle className="d-flex align-item-center">
                <Image src={SettingIcon} className="me-2" />
                Filter
              </Dropdown.Toggle>
              <DropdownMenu>
                <Dropdown.Item className="py-1" onClick={() => setFilter(true)}>
                  <ActiveFilterIcon active={filter} />
                  <span
                    className={filter === true ? "filers-a at-f" : "filers-a"}
                  >
                    Active
                  </span>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="ml-history py-1"
                  onClick={() => setFilter(false)}
                >
                  <HistoryFilterIcon active={!filter} />
                  <span
                    className={filter === false ? "filers-h at-f" : "filers-h"}
                  >
                    History
                  </span>
                </Dropdown.Item>
              </DropdownMenu>
            </Dropdown>
          )}
        </Col>
      </Row>

      {activeSwitch === "Play" && filter && (
        <Hero
          userData={userData}
          activeChallenges={activeChallenges}
          filter={filter}
          changeFilter={() => setFilter(false)}
          getChallenges={getChallenges}
          handleLink={handleLink}
        />
      )}

      {activeSwitch === "Explore" && (
        <Explore
          hero={userData?.user_type === "hero"}
          challenges={challenges}
          activeSwitch={activeSwitch}
          loading={lodaing}
          handleClick={(i, j) => handleClick(i, j)}
          suggestion={suggestionData}
        />
      )}

      {((activeSwitch === "Play" &&
        filter &&
        activeChallenges?.active?.squad?.length === 0 &&
        activeChallenges?.active?.solo.length === 0 &&
        userData?.user_type === "hero") ||
        (activeSwitch === "Play" &&
          filter &&
          activeChallenges?.active?.squad?.length === 0 &&
          userData?.user_type !== "hero")) && (
        <Explore
          activeSwitch={activeSwitch}
          hero={userData?.user_type === "hero"}
          challenges={challenges}
          loading={lodaing}
          handleClick={(i, j) => handleClick(i, j)}
          suggestion={suggestionData}
        />
      )}

      {!filter && activeSwitch !== "Explore" && (
        <History
          activeChallenges={activeChallenges}
          loading={lodaing}
          changeFilter={() => setFilter(true)}
        />
      )}

      {(!lodaing &&
        filter &&
        challenges?.length === 0 &&
        suggestionData?.length === 0 &&
        activeSwitch === "Explore") ||
        (!lodaing &&
          userData?.user_type === "player" &&
          activeSwitch === "Explore" &&
          challenges?.length === 0 &&
          emptyFunctionExplore())}

      {!lodaing &&
        activeChallenges?.active?.squad?.length === 0 &&
        activeChallenges?.active?.solo?.length === 0 &&
        challenges?.length === 0 &&
        filter &&
        activeSwitch !== "Explore" &&
        emptyFunctionPlay()}
    </Container>
  );
}

export default Discover;
