import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Button from "../../common/Buttons";
import NoPage from "../../assets/404.svg";
import { useNavigate } from "@tanstack/react-location";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="power-container feedback">
      <Container className="justify-content-md-center" sm={4}>
        <Row className="justify-content-md-center">
          <Col sm={5}>
            <div className="mb-5 mr-5 ml-2 mt-5">
              <Image src={NoPage} className='mt-2 mt-5'/>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={4} className="text-heading">
          Looks like you’re lost
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={5} className="form-text-detail">
          The page you’re looking for is not available
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Button
            label={"Continue"}
            onClick={() => navigate({ to: "/discover/dashboard" })}
            customizeClass="lower-button"
          />
        </Row>
      </Container>
    </div>
  );
}

export default NotFound;
