export const data = [
  {
    id: 1,
    title: "Challenge Mindset",
    name: "challenge_mindset",
  },
  {
    id: 2,
    title: "Emotional Control",
    name: "emotional_control",
  },
  {
    id: 3,
    title: "Mental Flexibility",
    name: "mental_flexibility",
  },
  {
    id: 4,
    title: "Sense Of Purpose",
    name: "sense_of_purpose",
  },
  {
    id: 5,
    title: "Self Efficacy",
    name: "self_efficacy",
  },
  {
    id: 6,
    title: "Optimism",
    name: "optimism",
  },
  {
    id: 7,
    title: "Social Connectedness",
    name: "social_connectedness",
  },
];
