import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "@tanstack/react-location";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../common/Buttons";
import Card from "../../../common/Cards";
import dummyImage from "../../../assets/challangeLogo.svg";
import {
  useUpdateUserChallengeMutation,
  useUpdateInvitationsMutation,
} from "../../../redux/api/challenge";
import { getUTCTimeStamp, prePath } from "../../../utils/helper";
import epicWin from "../../../assets/invites/epic-win.svg";
import invitees from "../../../assets/invites/invitees.svg";
import skillBadge from "../../../assets/invites/badgeInvitation.svg";
// import addDuration from "../../../assets/invites/duration.svg";
import sDatet from "../../../assets/invites/start-date.svg";
import completed from "../../../assets/invites/complete-by.svg";

function Invitations() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [challengeDescription, setChallengeDescription] = useState({});
  const InvitationData = useSelector((state) => state.invitation);
  const [updateInvitation] = useUpdateInvitationsMutation();
  const { selectedInvitation } = InvitationData;
  const [triggerChallenge] = useUpdateUserChallengeMutation();

  useEffect(() => {
    const id = selectedInvitation.challenge_id || selectedInvitation.id;
    triggerChallenge(id).then((response) => {
      setChallengeDescription(response?.data);
      dispatch({ type: "USER_CHALLENGE", payload: response });
    });
  }, []);

  const handleAccept = () => {
    const data = { id: selectedInvitation.id, value: true };
    updateInvitation(data)
      .then((res) => {
        if (!res?.error) {
          // if (
          //   moment(challengeDescription?.started_at).utc().unix() <=
          //   moment().utc().unix()
          // )
          if (
            moment().unix() >= getUTCTimeStamp(challengeDescription?.started_at)
          ) {
            navigate({
              to: `/discover/dashboard/squad-play/${challengeDescription?.id}`,
            });
          } else {
            navigate({
              to: `/discover/dashboard`,
            });
          }
        }
      })
      .catch((err) => {
        return;
      });
  };

  const payload = [
    {
      mTitle: "Squad:",
      result:
        challengeDescription?.created_by === "admin" &&
        challengeDescription?.invitee === "Everyone"
          ? challengeDescription?.invitee
          : challengeDescription?.squad?.name,
      logo: invitees,
    },
    {
      mTitle: "Epic Win:",
      result: challengeDescription?.epic_win,
      logo: epicWin,
    },

    ...(challengeDescription?.skill_badge
      ? [
          {
            mTitle: "Skill Badge:",
            result: challengeDescription?.skill_badge,
            logo: skillBadge,
          },
        ]
      : []),
    {
      mTitle: "Start Date:",
      result: moment(challengeDescription?.started_at).utc().format("M/D/YYYY"),
      logo: sDatet,
    },
    {
      mTitle: "Last Date Available:",
      result: moment(challengeDescription?.ended_at).utc().format("M/D/YYYY"),
      logo: completed,
    },
  ];

  return (
    <Container className="content-container invitations">
      <Card
        containerClass="mt-4 mb-4 w-8"
        md="12"
        titleClass="ps-0"
        title={
          <div className="d-flex">
            <div className="me-3">
              <img
                width="65px"
                height="65px"
                className="rounded-circle"
                src={
                  !!challengeDescription?.file
                    ? prePath + challengeDescription?.file
                    : dummyImage
                }
                alt=""
              />
            </div>
            <div className="px-0">
              {challengeDescription?.invitee && (
                <p className="mb-1 mt-1 f16">
                  <span className="text-capitalize">
                    {challengeDescription?.created_by === "admin" &&
                    challengeDescription?.invitee === "Everyone"
                      ? challengeDescription?.squad?.name
                      : challengeDescription?.invitee}
                  </span>{" "}
                  is inviting you to join
                </p>
              )}
              <h6 className="title fw-6 f20 mt-1">
                {challengeDescription?.title}
              </h6>
            </div>
          </div>
        }
        customRightBtn={
          InvitationData.allInvitation.length > 1 ? (
            <Row>
              <Button customizeClass="mr-4" label="Next" />
              <Button customizeClass="grey-btn" label="Back" />
            </Row>
          ) : (
            ""
          )
        }
      />
      <p className="w-8 py-3">{challengeDescription?.description}</p>

      <ListGroup className="stats mb-5 w-7 cl-table">
        {payload.map((res, i) => (
          <ListGroup.Item
            key={i}
            className={i + 1 !== payload.length ? "title lt-gr-it" : "title"}
          >
            <Row
              className={
                i + 1 === payload.length
                  ? "mx-2 d-flex align-items-center"
                  : "row-br mx-2 pb-3 d-flex align-items-center"
              }
            >
              <Col
                className={
                  i === 0
                    ? "fw-6 title px-0 mt-1"
                    : i + 1 === payload.length
                    ? "fw-6 title px-0 mb-1"
                    : "fw-6 px-0 title"
                }
              >
                <img src={res?.logo} alt="win" className="mr-2" />
                {res?.mTitle}
              </Col>
              <Col
                className={
                  i === 0 ? "mt-1" : i + 1 === payload.length && "mb-1"
                }
              >
                <span
                  className={
                    res?.mTitle !== "Epic Win:"
                      ? "text-capitalize"
                      : "text-truncate-2"
                  }
                >
                  {res?.result}
                </span>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <div className="d-flex  pt-4">
        <Button
          customizeClass="fw-6 lr-btn"
          label="I Accept the Challenge"
          onClick={handleAccept}
        />
        <Button
          customizeClass="grey-btn ml-4 fw-6 grey-btn-cl dfl-bt"
          label="No Thanks"
          onClick={() =>
            navigate({ to: "/discover/dashboard", search: { back: "PgGoal" } })
          }
        />
      </div>
    </Container>
  );
}

export default Invitations;
