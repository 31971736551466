import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate, useSearch } from "@tanstack/react-location";
import BackArrow from "../../../assets/arrowleft";
import InputBox from "../../../common/InputBox";
import Select from "../../../common/Select";
import Button from "../../../common/Buttons";
import { useRef } from "react";
import ScrollToTop from "../../../utils/scrollToTop";
import { optionPowerUp } from "./config";
import { useCreateChallengeMutation } from "../../../redux/api/challenge";
import { toast } from "react-hot-toast";
import { customSoloChallengeSchema } from "../../../validation-schemas/auth-schema";
import uploadButton from "../../../assets/camera.svg";
import { useUploadFileMutation } from "../../../redux/api/userProfile";
import { prePath } from "../../../utils/helper";

function CustomSoloChallenge() {
  const [loading, setLoading] = useState(false);
  const [triggerCreateChallenge] = useCreateChallengeMutation();
  const [uploadImg] = useUploadFileMutation();
  const [buildMost, setBuildMost] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [imgFile, setImgFile] = useState("");
  const hiddenFileInput = useRef();

  const navigate = useNavigate();
  const search = useSearch();

  const initialValues = useRef({
    challengeTitle: "",
    epicWin: "",
    strengthToBuild: "-select-",
  });

  // Submit the Form and update the data in BE
  const onSubmit = (values) => {
    if (values.strengthToBuild !== "-select-") {
      const challengeTitle = values.challengeTitle;
      const epicWin = values.epicWin;
      const strengthToBuild = values.strengthToBuild.toLowerCase();

      const data = {
        title: challengeTitle,
        epic_win: epicWin,
        resilience: strengthToBuild,
      };
      if (imgLink) {
        data.file = imgLink;
      }
      setLoading(true);
      triggerCreateChallenge(data).then((res) => {
        if (res.error) {
          toast.error(res.error.data.error);
          setLoading(false);
        } else {
          setLoading(false);
          toast.success("Challenge added successfully");
          navigate({
            to: `/discover/dashboard/solo-play/${res?.data?.result?.id}`,
          });
        }
      });
    } else {
      setBuildMost("Please select your strength");
    }
  };

  // Formik Form
  const formik = useFormik({
    initialValues: initialValues.current,
    validationSchema: customSoloChallengeSchema,
    onSubmit,
  });

  useEffect(() => {
    setTimeout(() => {
      setBuildMost("");
    }, 2000);
  }, [buildMost]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let imgString = URL.createObjectURL(img);
      setImgFile(img);
      setImgLink(imgString);
    }
  };

  useEffect(() => {
    if (imgFile !== "") {
      const formData = new FormData();

      formData.append("files", imgFile);
      formData.append("type", "challenge");
      setImgLoading(true);
      /* uploading Img*/
      uploadImg(formData)
        .then((res) => {
          if (!res?.error) {
            toast.success(res?.data?.message);
            setImgLink(res?.data?.result[0]);
          } else {
            toast.error("Image Not Uploaded");
            setImgFile("");
            setImgLink("");
          }
          setImgLoading(false);
        })
        .catch((err) => {
          setImgLoading(false);
          toast.error("Image Not Uploaded");
        });
    }
  }, [imgFile, uploadImg]);

  return (
    <Container className="content-container settings profile ml-4">
      <ScrollToTop />
      <h5 className="title fw-6 ex-s mar-ps  ">
        <div
          className="pointer  d-inline"
          onClick={() =>
            navigate({
              to: "/discover/dashboard",
              search: { back: search?.tab === "Explore" ? "PgGoal" : "Play" },
            })
          }
        >
          <BackArrow />
        </div>
        Create Your Own Challenge
      </h5>

      <form onSubmit={formik.handleSubmit} className="dis-for">
        <Row>
          <Col sm={6}>
            <InputBox
              label="Challenge Name*"
              type="challengeTitle"
              name="challengeTitle"
              containerClass="mt-3"
              placeholder={"Enter challenge name"}
              inputClass="right-br"
              lableSize={"md"}
              formik={formik}
              values={formik.values.challengeTitle}
              isError={
                formik.errors.challengeTitle && formik.touched.challengeTitle
              }
              error={formik.errors.challengeTitle}
            />

            <InputBox
              label="Your Epic Win*"
              type="epicWin"
              name="epicWin"
              containerClass="mt-3"
              lableSize={"md"}
              placeholder={"Enter your epic win"}
              inputClass="right-br"
              formik={formik}
              values={formik.values.epicWin}
              isError={formik.errors.epicWin && formik.touched.epicWin}
              error={formik.errors.epicWin}
            />

            <div className="mt-3">
              <div className={"color-dark fw-6 lable14 mb-2"}>
                What strength will this build most?*
              </div>
              <Select
                name="strengthToBuild"
                onChange={formik.handleChange}
                options={optionPowerUp}
                value={formik.values.strengthToBuild}
                isError={buildMost && true}
                error={buildMost}
              />
              <p className="text-danger">{buildMost}</p>
            </div>

            <div className={"color-dark fw-6 lable14 mb-2"}>
              Challenge Image (Optional)
            </div>
            <div className="d-flex justify-content-start position-relative">
              {imgLoading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : imgLink ? (
                <img
                  style={{ borderRadius: "50%" }}
                  width="120px"
                  height="120px"
                  src={prePath + imgLink}
                  alt="pic"
                />
              ) : (
                imgLink === "" && (
                  <div
                    style={{
                      width: 120,
                      height: 120,
                      background: "#cccccc54",
                      borderRadius: "50%",
                    }}
                  />
                )
              )}
              {!imgLoading && (
                <div className="position-relative" onClick={handleClick}>
                  <img
                    src={uploadButton}
                    alt="upload button"
                    className="position-absolute"
                    style={{ right: "-5px", top: "10px" }}
                  />
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                    accept="image/*"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Container fluid={true} className="mt-5 px-0">
          <Button
            type="submit"
            disabled={loading || imgLoading}
            label={"Next"}
          />
        </Container>
      </form>
    </Container>
  );
}

export default CustomSoloChallenge;
