import { Outlet } from "@tanstack/react-location";
import React from "react";
import Landing from "./landing";
import Layout from "./layout";

function AuthenticationComponent() {
  return (
    <Layout>
      <Landing />
      <Outlet />
    </Layout>
  );
}

export default AuthenticationComponent;
