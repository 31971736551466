const initState = {
  stripeId: null,
};

const subscriptionReducer = (state = initState, action) => {
  switch (action.type) {
    case "STRIPE_ID":
      return {
        ...state,
        stripeId: action.payload,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
