import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useSearch } from "@tanstack/react-location";
import { useFormik } from "formik";
import InputBox from "../../../common/InputBox";
import Select from "../../../common/Select";
import Button from "../../../common/Buttons";
import { soloSchema } from "../../../validation-schemas/auth-schema";
import { optionPowerUp } from "./config";
import {
  useAddChallengeMutation,
  useGetActivityDetailsMutation,
  useUpdateChallengeActivityMutation,
} from "../../../redux/api/challenge";
import toast from "react-hot-toast";
import ScrollToTop from "../../../utils/scrollToTop";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { useEditLibraryActivityMutation } from "../../../redux/api/library";
import { useDispatch, useSelector } from "react-redux";

function SoloBadGuy() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useSearch();
  const search = useSearch();
  const [addChallenge, addChallengeValue] = useAddChallengeMutation();
  const [updateActivityChallenge, updateActivityChallengeValue] =
    useUpdateChallengeActivityMutation();
  const [updateLibraryActivity, updateLibraryActivityValue] =
    useEditLibraryActivityMutation();
  const [getActivityDetails] = useGetActivityDetailsMutation();
  const [buildMost, setBuildMost] = useState("");
  const { cardDetail } = useSelector((state) => state.userChallence);

  const initialValues = {
    title: "",
    description: "",
    powerSelect: "-select-",
  };

  useEffect(() => {
    setTimeout(() => {
      setBuildMost("");
    }, 2000);
  }, [buildMost]);

  const goBackToCustomLibrary = () => {
    localStorage.setItem(
      "customLibraryActivityData",
      JSON.stringify({
        id: cardDetail.challenge_id,
        type: cardDetail.type,
        page: search?.page,
      })
    );
    navigate({ to: `/discover/dashboard/${search?.page}` });
    dispatch({
      type: "SELECTED_CARD_DETAIL",
      payload: {},
    });
  };

  const onSubmit = (values) => {
    if (values?.powerSelect !== "-select-") {
      const payload = {
        id: cardDetail?.isEditCustomLibraryActivity
          ? cardDetail?.challenge_id
          : search?.id,
        data: {
          type: "bad_guy",
          title: values?.title,
          description: values?.description,
          strength_to_build: values?.powerSelect.toLowerCase(),
          battle_strategy: values?.battleStrategy,
        },
      };
      if (cardDetail?.isEditCustomLibraryActivity) {
        delete payload.data.type;
        payload.activity_id = cardDetail?.id;
        updateLibraryActivity(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            goBackToCustomLibrary();
          }
        });
      } else if (search?.activityId) {
        delete payload.data.type;
        payload.activity_id = search?.activityId;
        updateActivityChallenge(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            navigate({
              to: `/discover/dashboard/${search?.page}/${location?.id}${
                location?.order ? `?order=${location?.order}` : ""
              }${location?.date ? `&date=${location?.date}` : ""}`,
            });
          }
        });
      } else {
        addChallenge(payload).then((response) => {
          if (response?.error) {
            toast.error(response?.error?.data?.error);
          } else {
            toast.success("Activity added successfully");
            navigate({
              to: `/discover/dashboard/${search?.page}/${location?.id}`,
            });
          }
        });
      }
    } else {
      setBuildMost("Please select your strength");
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: soloSchema,
    onSubmit,
  });

  const fetchActivityDetails = useCallback(() => {
    getActivityDetails({
      challenge_id: search?.id,
      activity_id: search?.activityId,
    }).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.message);
      } else {
        formik.setFieldValue("title", res?.data?.title);
        formik.setFieldValue("description", res?.data?.description);
        formik.setFieldValue("battleStrategy", res?.data?.battle_strategy);
        formik.setFieldValue(
          "powerSelect",
          capitalizeFirstLetter(res?.data?.strength_to_build)
        );
      }
    });
  }, [getActivityDetails, search?.activityId, search?.id]);

  useEffect(() => {
    if (search?.activityId && !cardDetail?.isEditCustomLibraryActivity) {
      fetchActivityDetails();
    }
  }, [
    cardDetail?.isEditCustomLibraryActivity,
    fetchActivityDetails,
    search?.activityId,
  ]);

  useEffect(() => {
    if (cardDetail?.isEditCustomLibraryActivity) {
      formik.setFieldValue("title", cardDetail?.title);
      formik.setFieldValue("description", cardDetail?.description);
      formik.setFieldValue("battleStrategy", cardDetail?.battle_strategy);

      formik.setFieldValue(
        "powerSelect",
        capitalizeFirstLetter(cardDetail?.strength_to_build)
      );
    }
  }, [cardDetail]);

  return (
    <div className="soloPlay form">
      <ScrollToTop />
      <Container className="justify-content-md-center" sm={4}>
        <Row className="d-flex justify-content-center head2">
          {search?.activityId || cardDetail?.isEditCustomLibraryActivity
            ? "Edit"
            : "Add"}{" "}
          a Bad Guy
        </Row>
        <form onSubmit={formik.handleSubmit}>
          <Row className="justify-content-md-center mb-1">
            <Col sm={6}>
              <InputBox
                label="Title*"
                type="title"
                name="title"
                containerClass="mt-3"
                inputClass="input-title"
                lableSize={"md"}
                placeholder="e.g. My Kryptonite"
                formik={formik}
                values={formik.values.title}
                isError={formik.errors.title && formik.touched.title}
                error={formik.errors.title}
              />
              <InputBox
                label="Description*"
                type="description"
                name="description"
                containerClass="mt-3"
                inputClass="text-area-input"
                placeholder="Enter description"
                lableSize={"md"}
                formik={formik}
                values={formik.values.description}
                isError={
                  formik.errors.description && formik.touched.description
                }
                error={formik.errors.description}
                asText
              />

              <InputBox
                label="Battle Strategy"
                type="battleStrategy"
                name="battleStrategy"
                containerClass="mt-3"
                inputClass="text-area-input"
                placeholder="Enter strategy"
                lableSize={"md"}
                formik={formik}
                values={formik.values.battleStrategy}
                isError={
                  formik.errors.battleStrategy && formik.touched.battleStrategy
                }
                error={formik.errors.battleStrategy}
                asText
              />
              <div className="mt-3">
                <div className={"color-dark fw-6 lable14 mb-2"}>
                  What strength does this build most?
                </div>
                <Select
                  name="powerSelect"
                  options={optionPowerUp}
                  formik={formik}
                  onChange={formik.handleChange}
                  value={formik.values.powerSelect}
                  isError={buildMost && true}
                  error={buildMost}
                />
                <p className="text-danger">{buildMost}</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Button
              type="submit"
              label={
                search?.activityId || cardDetail?.isEditCustomLibraryActivity
                  ? "Save"
                  : "Create"
              }
              customizeClass="lower-button"
              disabled={
                updateLibraryActivityValue.isLoading ||
                addChallengeValue.isLoading ||
                updateActivityChallengeValue.isLoading
              }
            />
          </Row>
        </form>
        <Button
          label={"Back"}
          onClick={() =>
            cardDetail?.isEditCustomLibraryActivity
              ? goBackToCustomLibrary()
              : navigate({ to: `/discover/dashboard/${search?.page}` })
          }
          customizeClass="back-button fw-600"
          variant="light"
        />
      </Container>
    </div>
  );
}

export default SoloBadGuy;
