import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const SubscriptionApi = createApi({
  reducerPath: "subscription",
  baseQuery: baseQueryWithReAuth({ baseUrl: `${API_BASE_URL}` }),
  endpoints: (builder) => ({
    subscriptionPlan: builder.mutation({
      query: (body) => ({
        url: `subscription/plans?user_type=user`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    subscribe: builder.mutation({
      query: (body) => ({
        url: `user/subscription/purchase`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    activeSubscribe: builder.mutation({
      query: (body) => ({
        url: `/user/subscription/active-subscription`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    cancelSubscribe: builder.mutation({
      query: (body) => ({
        url: `/user/subscription/cancel-subscription`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    deleteAccount: builder.mutation({
      query: (body) => ({
        url: `/user/profile/account`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    adminCms: builder.mutation({
      query: (body) => ({
        url: `/user/utility/cms/${body}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    applyPromoCode: builder.mutation({
      query: (body) => ({
        url: `/user/subscription/apply-promo-code`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    freeTrial: builder.mutation({
      query: (body) => ({
        url: `/user/subscription/free-trial`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    checkSubscriptionStatus: builder.mutation({
      query: (body) => ({
        url: `/user/subscription/check-status`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useSubscriptionPlanMutation,
  useSubscribeMutation,
  useActiveSubscribeMutation,
  useCancelSubscribeMutation,
  useDeleteAccountMutation,
  useAdminCmsMutation,
  useApplyPromoCodeMutation,
  useFreeTrialMutation,
  useCheckSubscriptionStatusMutation,
} = SubscriptionApi;
