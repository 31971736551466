import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const quizApi = createApi({
  reducerPath: "quizData",
  baseQuery: baseQueryWithReAuth({ baseUrl: `${API_BASE_URL}user/quiz` }),
  endpoints: (builder) => ({
    getQuiz: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "GET",
        params: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    getQuestions: builder.mutation({
      query: (body) => ({
        url: `/question`,
        method: "GET",
        params: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    updateQuiz: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),

    getHistory: builder.mutation({
      query: (body) => ({
        url: `/histories`,
        method: "GET",
        params: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetQuizMutation,
  useGetQuestionsMutation,
  useUpdateQuizMutation,
  useGetHistoryMutation,
} = quizApi;
