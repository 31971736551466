import React from "react";
import { Row, Card as CardBootstrap, Col } from "react-bootstrap";
import RigthArrow from "../../assets/right-caret";

function Card(props) {
  return (
    <>
      <CardBootstrap
        onClick={props.onClick}
        style={props.style}
        key={props?.id}
        className={`${props.containerClass} default-card-container`}
      >
        <CardBootstrap.Body>
          <Row>
            <Col md={props?.md || "6"} className={`${props.titleClass} title`}>
              {props.title}
            </Col>
            {!(props.hideNavBtn || props.customRightBtn) &&
              props.customRightBtn !== "" && (
                <Col md="6" className="d-flex justify-content-end">
                  <RigthArrow />
                </Col>
              )}

            {props?.customRightBtn !== "" && (
              <Col className="d-flex justify-content-end">
                {props.customRightBtn}
              </Col>
            )}
          </Row>
        </CardBootstrap.Body>
      </CardBootstrap>
    </>
  );
}

export default Card;
