import React, { useState } from "react";
import Card from "../../../../common/Cards";
import { DEFAULT_VALUES } from "../../../../constants/default-values";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import { getUTCTimeStamp, prePath } from "../../../../utils/helper";
import toast from "react-hot-toast";
import stopChallenge from "../../../../assets/stopChallenge.svg";
import plusIcon from "../../../../assets/util-icons/plusIcon.svg";

import stopWatchIcon from "../../../../assets/popupStopwatchIcon.svg";
import CommonPop from "../../../../common/Modal/commonModal";
import { useArchiveOrRestoreChallengeMutation } from "../../../../redux/api/challenge";
import { useNavigate } from "@tanstack/react-location";
import moment from "moment";
import { useDispatch } from "react-redux";

let minimumNumber = 3;

const Hero = ({
  activeChallenges,
  filter,
  userData,
  handleLink,
  lodaing,
  changeFilter,
  getChallenges,
}) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [current, setCurrent] = useState(minimumNumber);
  const [archiveChallengeId, setArchiveChallengeId] = useState("");
  const [archiveChallenge] = useArchiveOrRestoreChallengeMutation();
  const [loading, setLoading] = useState(false);
  const archiveChallengeHandler = (id) => (e) => {
    e.stopPropagation();
    setShowModal(true);
    setArchiveChallengeId(id);
  };

  const closeModal = () => {
    setShowModal(false);
    setArchiveChallengeId("");
  };

  const moreButtonHandler = () => {
    setCurrent((state) => state + minimumNumber);
  };

  const handleConfirm = () => {
    setLoading(true);
    const payload = {
      id: archiveChallengeId,
      body: {
        is_archive: true,
      },
    };
    archiveChallenge(payload).then((response) => {
      if (response?.error) {
        toast.error(response?.error?.data?.error);
        setLoading(false);
      } else {
        setShowModal(!showModal);
        // changeFilter();
        getChallenges();
        setLoading(false);
      }
    });
  };

  return (
    <div>
      {lodaing ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <CommonPop
            showModal={showModal}
            hideModal={closeModal}
            handleConfirm={handleConfirm}
            icon={stopWatchIcon}
            loading={loading}
            title={"Stop this challenge for now?"}
            info={"Restart paused challenges from your history tab"}
          />
          {activeChallenges?.active?.squad.length > 0 && filter && (
            <div>
              <div className="d-flex justify-content-between active-cl align-items-center">
                <h5 className="title fw-6 mb-0 mt-3 f22 fs-p-22">
                  Active Squads
                </h5>
                {!loading &&
                  current < activeChallenges?.active?.squad.length && (
                    <h4
                      className="color-p fsc-16 mb-0 mt-3 fw-6 pointer"
                      onClick={moreButtonHandler}
                    >
                      View More
                    </h4>
                  )}
              </div>
              {/* <h5 className="title fw-6 mb-3 mt-3 f22">
                Active Squad Challenge
              </h5> */}
              {activeChallenges?.active?.squad
                ?.slice(0, current)
                ?.map((v, i) => (
                  <Card
                    containerClass="colorful-card hover-container mt-3 active-cl"
                    style={{ borderColor: "#F5AB38" }}
                    onClick={() => {
                      handleLink(
                        v.started_at,
                        `/discover/dashboard/squad-play/${v.id}`
                      );
                      dispatch({
                        type: "USER_CHALLENGE_DAYS",
                        payload: {},
                      });
                    }}
                    key={i}
                    title={
                      <Row>
                        <Col sm={3} className="ml-1">
                          {v.file !== null && v.file ? (
                            <Image
                              width="66px"
                              height="66px"
                              className="img rounded-circle"
                              src={`${prePath}${v.file}`}
                              alt="active squad image"
                            />
                          ) : (
                            <p
                              className="discover-circle circle-Sb mb-0"
                              style={{
                                backgroundColor: DEFAULT_VALUES.ACTIVE_COLOR[i],
                              }}
                            >
                              SB
                            </p>
                          )}
                        </Col>

                        <Col className="title ml-2">
                          <p
                            style={{
                              color: "#F5AB38",
                              fontSize: "14px",
                              opacity: 0.9,
                            }}
                            className="fw-6 mb-0 fs-p-14"
                          >
                            {v.squad_name}
                          </p>

                          <h6 className="title fs-p-18 mb-1 mt-1 f18 fw-6">
                            {v.title}
                          </h6>
                          <p
                            style={{
                              color: "#121212",
                              fontSize: "13px",
                              opacity: 0.9,
                            }}
                            className="fw-6 mb-0 fs-p-14"
                          >
                            {moment().unix() >= getUTCTimeStamp(v.started_at)
                              ? "Last Date Available:"
                              : "Start Date:"}{" "}
                            <span
                              style={{
                                opacity: 0.7,
                              }}
                            >
                              {moment(
                                moment().unix() >= getUTCTimeStamp(v.started_at)
                                  ? v.ended_at
                                  : v?.started_at
                              )
                                .utc()
                                .format("MM/DD/YYYY")}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    }
                    customRightBtn={
                      <Row className="position-relative">
                        <Col
                          className="hover-icon position-absolute  rounded-circle p-2 "
                          style={{ top: "-3.5rem", right: "3.2rem" }}
                          onClick={archiveChallengeHandler(v.id)}
                        >
                          <img src={stopChallenge} alt="" />
                        </Col>
                      </Row>
                    }
                  />
                ))}
            </div>
          )}

          {userData?.user_type === "hero" &&
            filter &&
            activeChallenges?.active?.solo.length > 0 && (
              <div>
                <div className="d-flex mb-3 mt-4  justify-content-between active-cl align-items-center">
                  <h5 className="title mb-0 fw-6 f22">Active Solo Challenge</h5>
                </div>
                {activeChallenges?.active?.solo.length > 0
                  ? activeChallenges?.active?.solo?.map((v, i) => (
                      <Card
                        containerClass="hover-container colorful-card mt-3 active-cl"
                        style={{ borderColor: "#F5AB38" }}
                        onClick={() =>
                          handleLink(
                            v.started_at,
                            `/discover/dashboard/solo-play/${v.id}`,
                            "isSolo"
                          )
                        }
                        key={i}
                        title={
                          <Row>
                            <Col sm={3} className="ml-1">
                              {v.file !== null && v.file ? (
                                <Image
                                  width="66px"
                                  height="66px"
                                  className="img rounded-circle"
                                  src={`${prePath}${v.file}`}
                                  alt="active squad image"
                                />
                              ) : (
                                <p
                                  className="discover-circle circle-Sb mb-0"
                                  style={{
                                    backgroundColor:
                                      DEFAULT_VALUES.ACTIVE_COLOR[i],
                                  }}
                                >
                                  SB
                                </p>
                              )}
                            </Col>

                            <Col className="title ml-2">
                              <p
                                style={{
                                  color: DEFAULT_VALUES.ACTIVE_COLOR[i],
                                  fontSize: "14px",
                                  opacity: 0.9,
                                }}
                                className="fw-6 mb-0 fs-p-14"
                              >
                                {v.squad_name}
                              </p>

                              <h6 className="title fs-p-18 mb-1 mt-2 f18 fw-6">
                                {v.title}
                              </h6>
                            </Col>
                          </Row>
                        }
                        customRightBtn={
                          <Row className="position-relative">
                            <Col
                              className="hover-icon position-absolute  rounded-circle p-2 "
                              style={{ top: "-3.5rem", right: "3.2rem" }}
                              onClick={archiveChallengeHandler(v.id)}
                            >
                              <img src={stopChallenge} alt="" />
                            </Col>
                          </Row>
                        }
                      />
                    ))
                  : "No active solo challenge."}
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default Hero;
