import { useEffect, useState } from "react";
import { Col, Row, Card, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearch } from "@tanstack/react-location";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "../../../common/Modal";
import RigthArrow from "../../../assets/right-caret";
import {
  useAllScienceCardMutation,
  useSelectedScienceCardMutation,
} from "../../../redux/api/user-science-card";
import {
  useSecreteAvatarMutation,
  useSecreteIdentitySetMutation,
  useUploadFileMutation,
} from "../../../redux/api/userProfile";
import { APP_CONFIG } from "../../../constants/app-config";
import streakMessage from "../../../assets/streak-message.svg";
import sciencePowerUpsIcon from "../../../assets/science-powerups.svg";
import editAvatar from "../../../assets/editAvatar.svg";
import { DEFAULT_VALUES } from "../../../constants/default-values";
import Switch from "../../../common/Switch";
import ProgressSection from "./progress";
import ReflectionSection from "./reflection";
import CustomCard from "../../../common/Cards";
import { Link, useLocation, useNavigate } from "@tanstack/react-location";
import { prePath } from "../../../utils/helper";
import toast from "react-hot-toast";
import Goals from "./goals";

function UserCard(props) {
  const dispatch = useDispatch();
  const search = useSearch();
  const location = useLocation();

  const userData = useSelector((state) => state?.userProfile?.data);
  const { secret_identity: secretIdentity } = userData || {};
  const [cardList, setCardList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [mainIdentity, setMainIdentity] = useState();
  const [identityName, setIdentityName] = useState();
  const [picUpdated, setPicUpdated] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [fileImg, setFileImg] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const [imgFile, setImgFile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState("Progress");

  const [scienceCard] = useAllScienceCardMutation();
  const [selectedCard] = useSelectedScienceCardMutation();
  const [secreteIdentity] = useSecreteAvatarMutation();
  const [uploadImg] = useUploadFileMutation();
  const [secreteSetIdentity] = useSecreteIdentitySetMutation();
  const isScienceBehind = location.current.pathname.includes("/science-behind");
  const navigate = useNavigate();
  const [winStreak, setWinStreak] = useState(0);

  const currentPath = location.current.pathname.split("/").pop();

  const [defaultScienceBehind, setDefaultScienceBehind] = useState(
    currentPath.replace("-", " ")
  );

  useEffect(() => {
    if (search?.switch === "Goals") {
      setActiveSwitch(search?.switch);
    } else if (search?.switch === "Reflections") {
      setActiveSwitch(search?.switch);
    }
  }, [search?.switch]);

  useEffect(() => {
    /**list of cards */
    scienceCard().then((res) => {
      if (!res?.error) {
        setCardList(res.data);
        setDefaultScienceBehind(res.data[0].title);
        setSelectedId(res.data[0].id);
      } else {
        toast.error(res?.error?.data?.error);
      }
    });
  }, [scienceCard]);

  useEffect(() => {
    /**hitting Api to get particular card data */
    if (selectedId) {
      dispatch({ type: "USER_CARD_SELECTED", payload: { loading: true } });

      selectedCard(selectedId).then((res) => {
        if (!res?.error) {
          dispatch({
            type: "USER_CARD_SELECTED",
            payload: { ...res, loading: false },
          });
          if (isScienceBehind && defaultScienceBehind) {
            navigate({
              to:
                "science-behind/" +
                defaultScienceBehind.replace(" ", "-").toLowerCase(),
            });
          }
        } else {
          dispatch({ type: "USER_CARD_SELECTED", payload: { loading: false } });

          toast.error(res?.error?.data?.error);
        }
      });
    }
  }, [dispatch, selectedCard, selectedId]);

  useEffect(() => {
    /* hitting API for Secrete Identity */
    secreteIdentity()
      .then((res) => {
        if (!res?.error) {
          res?.data?.details?.profile_image !== null &&
            setProfilePic(`${res?.data?.details?.profile_image}`) &&
            setFileImg("");
          res?.data.details?.secret_identity !== null &&
            setIdentityName(res?.data.details?.secret_identity);
          res?.data.details?.secret_identity !== null &&
            setMainIdentity(res?.data.details?.secret_identity);
          dispatch({ type: "USER_SECRET_IDENTITY", payload: res.data });
        } else {
          toast.error(res?.error?.data?.error);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  }, [picUpdated]);

  const updateSecretIdentity = (res) => {
    let payload = {
      secret_identity: mainIdentity !== null ? mainIdentity : "",
    };
    if (res || profilePic) {
      payload = {
        ...payload,
        profile_image: !!res ? res : profilePic,
        avatar: !!res ? res : profilePic,
      };
    }
    /*hit Set SecretIdentity API */
    secreteSetIdentity(payload)
      .then((res) => {
        if (!res?.error) {
          toast.success(res?.data?.message);
          setPicUpdated(!picUpdated);
          setShowModal(false);
        } else {
          toast.error(res?.error?.data?.error);
        }
      })
      .catch((err) => {
        toast.error(err?.messager);
      });
  };

  useEffect(() => {
    if (imgFile !== "") {
      const formData = new FormData();

      formData.append("files", imgFile);
      formData.append("type", "profile");
      setImgLoading(true);
      /* uploading Img*/
      uploadImg(formData)
        .then((res) => {
          if (!res?.error) {
            toast.success(res?.data?.message);
            setProfilePic(res?.data?.result[0]);
          } else {
            toast.error("Image Not Uploaded");
            setImgFile("");
            setFileImg("");
          }
          setImgLoading(false);
        })
        .catch((err) => {
          setImgLoading(false);
          toast.error("Image Not Uploaded");
        });
    }
  }, [imgFile]);

  const handleIdentitySave = () => {
    updateSecretIdentity();
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let imgString = URL.createObjectURL(img);
      setImgFile(img);
      setFileImg(imgString);
    }
  };

  const ifPaid =
    userData?.user_type === "hero"
      ? DEFAULT_VALUES.DISCOVER_TOGGLE_BTN
      : DEFAULT_VALUES.DISCOVER_TOGGLE_BTN.filter(
          (res) => res.label !== "Goals"
        );

  return (
    <>
      <Card className="user-card">
        <Card.Header className="border-0 pt-4 left-pl-br">
          {/* user data */}
          <Row className="pt-2 pb-3 px-3">
            <Col
              sm={2}
              className="position-relative icon-w"
              onClick={() => setShowModal(true)}
            >
              {profilePic === null ? (
                <h3 className="squad-profile-pic without-img text-capitalize">
                  {identityName?.charAt(0) || userData?.first_name?.charAt(0)}
                </h3>
              ) : (
                <div onClick={() => setShowModal(true)}>
                  <img
                    src={`${prePath}${profilePic}`}
                    alt="avatar"
                    className="user-avatar"
                    width="80px"
                    height="80px"
                    onClick={() => setShowModal(true)}
                  />
                </div>
              )}
              <div className="avatar-edit">
                <img
                  src={editAvatar}
                  alt="edit"
                  onClick={() => setShowModal(true)}
                />
              </div>
            </Col>
            <Col
              sm={8}
              className="px-4 mx-2 d-flex flex-column justify-content-center"
            >
              <h6 className="title fs-3 fw-6 mb-0">
                {identityName
                  ? identityName || ""
                  : `${userData?.first_name || ""} ${
                      userData?.last_name ? userData.last_name : ""
                    }`}
              </h6>
              {props.user.streak > 1 && (
                <p className="fs-2 mb-0 mt-1 d-flex align-items-center">
                  {APP_CONFIG.STREAKS_MESSAGE}{" "}
                  <span className="fw-6 fs-3 title ms-2">{winStreak}</span>{" "}
                  <img
                    src={streakMessage}
                    alt="streaks"
                    className="mb-1 ml-1"
                  />
                </p>
              )}

              {/* {isScienceBehind && (
                <ProgressBar
                  striped
                  variant="warning"
                  className="mb-4"
                  now={winStreak * 25}
                />
              )} */}
            </Col>
            {!isScienceBehind && (
              <Col className="text-right position-absolute">
                <Link to={"science-behind/power-ups"} className="mr-star">
                  <img src={sciencePowerUpsIcon} alt="science behind" />
                </Link>
              </Col>
            )}
          </Row>
        </Card.Header>
        {!isScienceBehind ? (
          <ListGroup
            variant="flush"
            style={{ boxShadow: "0px -2px 0px rgb(0 0 0 / 4%)" }}
          >
            <ListGroup.Item className="bg-color-gray only_top_border">
              <Switch
                containerClass="mb-4 mt-3"
                values={ifPaid}
                activeSwitch={activeSwitch}
                setActiveSwitch={(l) => setActiveSwitch(l)}
              />
            </ListGroup.Item>
            {activeSwitch === "Progress" && (
              <ProgressSection getWinStreak={(e) => setWinStreak(e)} />
            )}
            {activeSwitch === "Goals" && userData?.user_type === "hero" && (
              <Goals />
            )}
            {activeSwitch === "Reflections" && <ReflectionSection />}
          </ListGroup>
        ) : (
          <ListGroup variant="flush">
            <ListGroup.Item className="bg-color-gray  ">
              <h6 className="title fw-6 ml-3">The Science Behind ...</h6>

              <Container className="mt-4 bottom-border">
                {cardList.map((v) => (
                  <CustomCard
                    key={v.id}
                    id={v?.id}
                    className="bottom-border"
                    md={8}
                    title={
                      <>
                        <img
                          className="cardImg"
                          src={`${prePath}${v.image}`}
                          alt=""
                        />
                        <span className="title-1 text-nowrap fs-16 ml-4 fw-4">
                          {v.title}
                        </span>
                      </>
                    }
                    onClick={() => {
                      setDefaultScienceBehind(v.title);
                      setSelectedId(v.id);
                      dispatch({ type: "UPDATE_SIDE_BAR" });
                    }}
                    containerClass={`science-behind-card mb-2 ${
                      defaultScienceBehind.toLowerCase() ===
                      v.title.toLowerCase()
                        ? "active"
                        : ""
                    }`}
                    customRightBtn={
                      <RigthArrow
                        active={
                          defaultScienceBehind.toLowerCase() ===
                          v.title.toLowerCase()
                            ? true
                            : false
                        }
                      />
                    }
                  />
                ))}
              </Container>
            </ListGroup.Item>
          </ListGroup>
        )}
      </Card>
      <Modal
        showModal={showModal}
        setMainIdentity={(v) => setMainIdentity(v)}
        load={imgLoading}
        picture={
          fileImg
            ? fileImg
            : profilePic && profilePic !== null && `${prePath}${profilePic}`
        }
        mainIdentity={mainIdentity}
        isSecretIdentity={!!secretIdentity}
        identityName={identityName || userData?.first_name?.charAt(0)}
        setShowModal={(l) => {
          setShowModal(l);
          setImgFile("");
          setFileImg("");
        }}
        handleIdentitySave={() => handleIdentitySave()}
        onImageChange={(e) => handleImageChange(e)}
      />
    </>
  );
}

export default UserCard;
