import { createApi } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants/app-config";
import { baseQueryWithReAuth } from "../../utils/customFetchBaseQuery";

// Define a service using a base URL and expected endpoints
export const ScreteLab = createApi({
  reducerPath: "challengeData",
  baseQuery: baseQueryWithReAuth({
    baseUrl: `${API_BASE_URL}user/secret-lab/`,
  }),
  endpoints: (builder) => ({
    getNotes: builder.mutation({
      query: (payload) => ({
        url: `reflections?page=${payload.page}&per_page=${payload.per_page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.data,
    }),
    getNoteDetails: builder.mutation({
      query: (id) => ({
        url: `reflection/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    updateNote: builder.mutation({
      query: (body) => ({
        url: `reflection/${body.id}`,
        method: "PUT",
        body: body.inner,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    deleteNote: builder.mutation({
      query: (id) => ({
        url: `reflection/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    getSkillBadge: builder.mutation({
      query: (payload) => ({
        url: `skill-badges?order_by=created_at&is_ascending=false&page=${payload.page}&per_page=${payload.per_page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetNotesMutation,
  useGetSkillBadgeMutation,
  useUpdateNoteMutation,
  useGetNoteDetailsMutation,
  useDeleteNoteMutation,
} = ScreteLab;
