const initState = {
    quiz: [],
   quizQuestion: [],
  };
  
  const quizReducer = (state = initState, action) => {
    switch (action.type) {
      case "QUIZ":
        return {
          ...state,
          quiz: [action.payload.data],
        };
      case "QUIZ_QUESTIONS":
        return {
          ...state,
          quizQuestion: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default quizReducer;
  