import React from "react";
import { useCallback } from "react";
import { Spinner, Container, Button } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "@tanstack/react-location";
import { useGetNotificationsMutation } from "../../../redux/api/userProfile";
import Icon from "../../../assets/bellIcon.svg";
import { useEffect } from "react";
import { useState } from "react";
import BackArrow from "../../../assets/arrowleft";
import { useRef } from "react";

const Notifications = () => {
  const navigate = useNavigate();

  const [triggerGetNotifications, { isLoading }] =
    useGetNotificationsMutation();
  const [notificationData, setNotificationData] = useState([]);
  const page = useRef(1);
  const totalCount = useRef(0);
  let today = moment();
  let yesterday = moment().subtract(1, "day");

  const getAllNotifications = useCallback(
    (pageNumber) => {
      page.current = pageNumber;
      triggerGetNotifications({ page: page.current }).then((res) => {
        if (!res.error) {
          setNotificationData(res.data.result.result);
          totalCount.current = res.data.result.total;
        }
      });
    },
    [triggerGetNotifications]
  );

  useEffect(() => {
    getAllNotifications(1);
  }, [getAllNotifications]);

  return (
    <Container className="content-container settings prefrences ml-4 w-100">
      <div>
        <h5 className="title fw-6 mb-3 f22 mar-p">
          <a
            className="pointer"
            onClick={() => navigate({ to: "/discover/dashboard" })}
          >
            <BackArrow />
          </a>
          Notifications
        </h5>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5 pt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>
          <div className="mt-4 notification-pg">
            {notificationData.map((item, i) => (
              <>
                <li
                  className={`mb-0 f16 black-color w-8 ${
                    item?.is_read ? "read" : "no-read"
                  }`}
                >
                  {item?.message}
                </li>
                <p
                  className={
                    notificationData.length === i + 1
                      ? "f12 mt-2 pb-2 w-8"
                      : "f12 mt-2 pb-2 w-8 lowerBorder"
                  }
                >
                  {moment(item?.created_at).isSame(today, "day")
                    ? "Today"
                    : moment(item?.created_at).isSame(yesterday, "day")
                    ? "Yesterday"
                    : moment(item?.created_at).format("M/D/YYYY")}
                </p>
              </>
            ))}
          </div>
          {notificationData.length === 0 && (
            <div className="d-flex justify-content-center mt-5 pt-5">
              <div className="mt-5 pt-5">
                <div className="d-flex justify-content-center">
                  <img width="120px" src={Icon} alt="icon" />
                </div>
                <p className="f30 fw-6 black-color text-center mt-5">
                  No Notifications
                </p>
                <p className="f16 text-center">
                  There aren’t any updates at the moment. We’ll <br />
                  notify you when something arrives.
                </p>
              </div>
            </div>
          )}
          {totalCount.current > 10 && (
            <div className="d-flex justify-content-start gap-4 mt-4">
              <Button
                variant="outline-primary"
                disabled={page.current === 1}
                style={{ minWidth: "120px" }}
                onClick={() => getAllNotifications(page.current - 1)}
              >
                Previous
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => getAllNotifications(page.current + 1)}
                disabled={Math.ceil(totalCount.current / 10) === page.current}
                style={{ minWidth: "120px" }}
              >
                Next{" "}
              </Button>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default Notifications;
